import { Directive, HostListener, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { RegexPatternConstants } from '@app/shared/utilities/regex-pattern-constants';

@Directive({
  selector: '[hohoMinutesMask]',
})
export class MinutesMaskDirective implements OnInit {
  constructor(public ngControl: NgControl) {}

  ngOnInit() {
    const initialOnChange = (this.ngControl.valueAccessor as any).onChange;
    (this.ngControl.valueAccessor as any).onChange = (value: any) => initialOnChange(this.onInputChange(value));
  }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event: string | number) {
    this.ngControl.valueAccessor.writeValue(this.onInputChange(event));
  }

  onInputChange(event: string | number): string {
    if (typeof event === 'number') {
      event = event.toString(10);
    }
    let value: number | string = event?.replace(RegexPatternConstants.digit, '');
    if (value === null || value === '') {
      return '00';
    }
    if (!value && !(typeof value === 'number' && value !== 0)) {
      value = null;
    } else {
      value = parseInt(value, 10);
    }

    if (value > 59 && value !== null) {
      const str = value.toString();
      value = str.slice(0, 2);
      value = parseInt(value);
      if (value > 59) {
        value = 59;
      }
    } else if (value < 10) {
      value = '0' + value.toString();
    }
    return value.toString();
  }
}
