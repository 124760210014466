import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import icSearch from '@iconify/icons-uil/search';

@Component({
  selector: 'hoho-text-search',
  templateUrl: './text-search.component.html',
  styleUrls: ['./text-search.component.scss'],
})
export class TextSearchComponent implements OnInit {
  showSearch: boolean = false;
  icSearch = icSearch;
  @Input() revertColor = false;
  @Input() searchCtrl: UntypedFormControl;
  @Input() placeholder: string;

  constructor() {}

  ngOnInit(): void {}
}
