/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { feedback } from '../models/feedback';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FeedbackService {

    /**
     * Select feedback by id
     * @param id Object ID
     * @returns feedback Successful operation
     * @throws ApiError
     */
    public static selectFeedbackById(
        id: string,
    ): CancelablePromise<feedback> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/get/object/feedback/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Save feedback
     * @param requestBody Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static saveFeedback(
        requestBody?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/save/object/feedback',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Delete feedback by id
     * @param id Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static deleteFeedbackById(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/delete/object/feedback/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

}
