import { deepEqual } from '@shared/utilities/objects.untills';

export class GroupByResult<T> {
  [key: string]: T[];
}

export function groupBy<T>(collection: T[], groupingFieldFn: (x: T) => string): GroupByResult<T> {
  return collection.reduce(function (accumulator, currentValue) {
    const key = groupingFieldFn(currentValue);

    if (!accumulator[key]) accumulator[key] = [];
    accumulator[key].push(currentValue);

    return accumulator;
  }, Object.create(null));
}

export function onlyUnique(value: unknown, index: number, self: unknown[]) {
  return self.findIndex((i) => deepEqual(i, value)) === index;
}

export function isEmpty(obj: any) {
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }
  return JSON.stringify(obj) === JSON.stringify({});
}
export interface IEqualityItem {
  name: string;
  id: number;
  symbol: string;
}
export const equalityList: IEqualityItem[] = [
  {
    name: $localize`Равно`,
    id: 0,
    symbol: '=',
  },
  {
    name: $localize`Больше`,
    id: 1,
    symbol: '>',
  },
  {
    name: $localize`Меньше`,
    id: 2,
    symbol: '<',
  },
];

export function isObject(item: any) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

export function mergeDeep(target: any, ...sources: any): any {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (Array.isArray(source[key]) && Array.isArray(target[key])) {
        target[key] = target[key].concat(source[key]);
      } else if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}
