<div class="rounded-lg border px-4 flex items-center">
  <hoho-icon
    [ngClass]="{  'text-texas': !revertColor, 'text-natasha': revertColor }"
    iconName="search"></hoho-icon>
  <input
    [ngClass]="{ 'placeholder-natasha': revertColor, 'placeholder-texas': !revertColor, 'text-natasha': revertColor }"
    [formControl]="searchCtrl"
    [class.border-natasha]="revertColor"
    class="px-3 py-2.5 border-0 outline-none w-full bg-transparent"
    name="search"
    i18n-placeholder="@@search"
    placeholder="{{placeholder ?? 'Поиск'}}"
    type="text"
  />
</div>
