<hoho-secondary-header [crumbs]="crumbs" *ngIf="!isLoading" [title]="group.name">
  <button
    class="sm:my-0 my-3 w-full sm:w-auto mr-4"
    [routerLink]="marketingRoutingNames.groupUpdate.path + '/' + group.id"
    color="accent"
    mat-flat-button
    type="button"
    i18n
  >
    Редактировать
  </button>
</hoho-secondary-header>
<div class="mx-gutter mt-5">
  <div class="relative flex justify-center py-8" *ngIf="isLoading">
    <mat-spinner diameter="50" class="mt-8 self-center"></mat-spinner>
  </div>
  <div *ngIf="!isLoading">
    <div class="mt-6">
      <hoho-list-builder
        context="marketingGroup"
        [multiselect]="false"
        class="mt-3 sm:mt-0 block"
        #tableBuilder
        [showExcel]="true"
        *ngIf="filterPayload"
        [noLink]="true"
        excelFileNameBase="{{ group.name }}"
        [requestPayload]="filterPayload"
        [props]="tableBuilderProps"
      ></hoho-list-builder>
    </div>
  </div>
</div>
