/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { base_entity } from './base_entity';
import type { object_link } from './object_link';
import type { product } from './product';
import type { recipe } from './recipe';

/**
 * Состав продуктовой матрицы
 */
export type product_matrix_item_coffee = (base_entity & {
    /**
     * Идентификатор
     */
    id?: string;
    /**
     * Рецептура
     */
    recipe?: (recipe | object_link);
    /**
     * Тип элемента матрицы
     */
    type: product_matrix_item_coffee.type;
    /**
     * Номер слота
     */
    slotNumber: number;
    /**
     * Номер продукта (число, обозначающее данную ячейку в внутреннем ПО автомата)
     */
    productId?: number;
    /**
     * Определяет налоговую ставку
     */
    tax?: number;
    /**
     * Продукт
     */
    product: (product | object_link);
});

export namespace product_matrix_item_coffee {

    /**
     * Тип элемента матрицы
     */
    export enum type {
        '_1' = 1,
        '_2' = 2,
        '_3' = 3,
    }


}

