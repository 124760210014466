import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EDialogTypes, IDialogModels } from '@shared/modules/dialogs/models/dialog.models';

@Component({
  selector: 'hoho-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogModels,
  ) {
    this.data = {
      type: EDialogTypes.confirm,
      close: true,
      ok: true,
      title: $localize`Подтверждение`,
      okLabel: $localize`Да`,
      cancelLabel: $localize`Отмена`,
      ...this.data,
    };
  }

  ngOnInit() {
    this.dialogRef.afterClosed().subscribe(() => {
      if (this.data.cancelCallback) {
        this.data.cancelCallback();
      }
    });
  }

  public ok(): void {
    this.dialogRef.close();

    this.dialogRef.afterClosed().subscribe(() => {
      if (this.data.okCallback) {
        this.data.okCallback();
      }
    });
  }

  public closeModal(): void {
    this.dialogRef.close();
  }
}
