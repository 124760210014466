/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { user_roles } from '../models/user_roles';
import type { userRoles } from '../models/userRoles';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserRolesService {

    /**
     * Select user_roles by id
     * @param id Object ID
     * @returns user_roles Successful operation
     * @throws ApiError
     */
    public static selectUserRolesById(
        id: string,
    ): CancelablePromise<user_roles> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/get/object/user_roles/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Save user_roles
     * @param requestBody Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static saveUserRoles(
        requestBody?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/save/object/user_roles',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Delete user_roles by id
     * @param id Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static deleteUserRolesById(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/delete/object/user_roles/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Select userRoles by id
     * @param id Object ID
     * @returns userRoles Successful operation
     * @throws ApiError
     */
    public static selectUserRolesById1(
        id: string,
    ): CancelablePromise<userRoles> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/get/object/userRoles/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Save userRoles
     * @param requestBody Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static saveUserRoles1(
        requestBody?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/save/object/userRoles',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Delete userRoles by id
     * @param id Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static deleteUserRolesById1(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/delete/object/userRoles/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

}
