<div *ngIf="state === stateEnum.Error">
  <span class="dot bg-error"></span>
</div>

<div *ngIf="state === stateEnum.Attention" class="flex items-center">
  <span class="dot bg-attention mr-0.5"></span>
  <span class="dot bg-attention"></span>
</div>

<div *ngIf="state === stateEnum.Accept" class="flex flex-col items-center">
  <span class="dot bg-accept mb-0.5"></span>
  <div class="flex items-center">
    <span class="dot bg-accept mr-0.5"></span>
    <span class="dot bg-accept"></span>
  </div>
</div>
