import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { EListBuilderViews, IAccess, IListBuilderProps } from '@core/modules/list-builder/models';
import { ListBuilderComponent } from '@core/modules/list-builder/list-builder.component';
import { EEntityList, EFeedbackFeatures, EModules, IEntity, IServerRequestPayload } from '@shared/models';
import { ICrumb } from '@shared/@vex/components/breadcrumbs/breadcrumbs.component';
import { AppRoutingNames } from '@app/app-routing.module';
import { IAdditionalColumn } from '@core/modules/list-builder/list-builder.models';
import { DataRestService, PermissionsGuard } from '@shared/services';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { vending_machine } from '@src/api';

@UntilDestroy()
@Component({
  selector: 'hoho-vm-feedbacks',
  templateUrl: './vm-feedbacks.component.html',
  styleUrls: ['./vm-feedbacks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VmFeedbacksComponent implements AfterViewInit, OnInit {
  @Input() access: IAccess = { create: true, delete: true, read: true, update: true, entity: null };
  @ViewChild('tableBuilder') tableBuilderComponent: ListBuilderComponent<IEntity>;
  @ViewChild('link') link: TemplateRef<HTMLTemplateElement>;
  tableBuilderProps: IListBuilderProps<IEntity> = {
    entity: EEntityList.feedback,
    views: [EListBuilderViews.table],
    openFunc: () => null,
  };
  crumbs: ICrumb[] = [
    { label: $localize`Обратная связь`, link: AppRoutingNames.feedback.path },
    { label: $localize`Таблица`, link: AppRoutingNames.feedback.path + '/table' },
    { label: $localize`Страница детализации` },
  ];
  additionalColumns: IAdditionalColumn[];
  vendingMachine: vending_machine;
  requestPayload: IServerRequestPayload;
  EModules = EModules;
  EFeedbackFeatures = EFeedbackFeatures;
  constructor(
    private dataRestService: DataRestService,
    private activatedRoute: ActivatedRoute,
    private cd: ChangeDetectorRef,
    public permissionsGuard: PermissionsGuard
  ) {}

  ngOnInit() {
    this.loadVendingMachine(this.activatedRoute.snapshot.params.id);
  }

  ngAfterViewInit(): void {
    this.additionalColumns = [
      {
        name: 'link',
        template: this.link,
        desc: '',
      },
    ];
  }

  loadVendingMachine(id: string): void {
    this.requestPayload = {
      filter: { vendingMachine: id },
    };
    this.dataRestService
      .get<vending_machine>(EEntityList.vending_machine, id, ['id', 'name', 'nameAndSerial', 'address'])
      .pipe(untilDestroyed(this))
      .subscribe((res: vending_machine) => {
        this.vendingMachine = res;
        this.cd.markForCheck();
      });
  }
}
