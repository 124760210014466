export const paths = [
  'displayName',
  'company',
  'id',
  'modules',
  'lastName',
  'firstName',
  'middleName',
  'email',
  'birthday',
  'cityName',
  'phoneNumber',
  'active',
  'flags',
  'entity',
  'rolesText',
  'roles',
  'registerDate',
  'company.id',
  'company.name',
  'company.orgCode',
];
