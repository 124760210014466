/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { base_entity } from './base_entity';

/**
 * Пользователи телеграм бота
 */
export type telegram_bot_users = (base_entity & {
    /**
     * Идентификатор
     */
    id?: string;
    /**
     * Номер телефона
     */
    phoneNumber?: string;
    /**
     * Имя пользователя
     */
    userName?: string;
    /**
     * Идентификатор чата
     */
    chatId?: number;
    /**
     * Статус
     */
    status?: telegram_bot_users.status;
    /**
     * Дата создания
     */
    createdDate?: string;
    /**
     * Доступные категории
     */
    availableCategories?: any;
});

export namespace telegram_bot_users {

    /**
     * Статус
     */
    export enum status {
        '_0' = 0,
        '_1' = 1,
        '_2' = 2,
    }


}

