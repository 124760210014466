import { Component, Input, OnInit } from '@angular/core';
import { DotIndicatorStateEnum } from '@shared/components/dot-indicator/enum/dot-indicator-state.enum';
import { FillingLevelEnum } from '../../enums/filling-level.enum';
import { getFillingLevel } from '@shared/utilities/vendingMachine/state.untils';

@Component({
  selector: 'hoho-machine-fullness-of-ingredients',
  templateUrl: './machine-fullness-of-ingredients.component.html',
  styleUrls: ['./machine-fullness-of-ingredients.component.scss'],
})
export class MachineFullnessOfIngredientsComponent implements OnInit {
  fillingLevelEnum = FillingLevelEnum;
  @Input() fillingLevelPercent: number;
  @Input() fillingLevel: FillingLevelEnum;
  dotIndicatorState: DotIndicatorStateEnum;

  constructor() {}
  ngOnInit(): void {
    this.fillingLevel = getFillingLevel(this.fillingLevelPercent);
    switch (this.fillingLevel) {
      case FillingLevelEnum.FullyFilled:
        this.dotIndicatorState = DotIndicatorStateEnum.Accept;
        break;
      case FillingLevelEnum.HalfFull:
        this.dotIndicatorState = DotIndicatorStateEnum.Attention;
        break;
      case FillingLevelEnum.Empty:
        this.dotIndicatorState = DotIndicatorStateEnum.Error;
        break;
      default:
        break;
    }
  }
}
