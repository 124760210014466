<div class="map-container">
	<ng-content></ng-content>
	<ya-map [center]="mapCenterLatLng$" [zoom]="isSinglePLacemark ? 16: 10" [state]="{controls: isSinglePLacemark ? ['geolocationControl'] : ['geolocationControl', 'zoomControl']}"
		[options]="{yandexMapDisablePoiInteractivity: true}" (ready)="onReady($event)" (yadblclick)="onDblClick($event)">
		<ya-placemark *ngIf="!isSinglePLacemark" [geometry]="placemarkLatLng$"
			[options]="{draggable: true, iconColor: 'red'}" (yadragend)="dragEnd($event)"></ya-placemark>
		<!-- for single placemark -->
		<ya-placemark *ngIf="isSinglePLacemark" [geometry]="placemarkLatLng$"
			[options]="{iconLayout: 'default#image', iconImageHref: './assets/img/base/status_green.svg', iconImageSize: [35, 35]}"></ya-placemark>
	</ya-map>
</div>