<a
  *ngIf="isLink(item) && !isFunction(item.route)"
  [fragment]="item.fragment"
  [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }"
  [routerLink]="item.route"
  class="item row items-center"
  [class.first-level]="level === 0"
  matRipple
  routerLinkActive="active"
>
  <mat-icon *ngIf="level === 0" [icIcon]="item.icon" class="item-icon flex-none"></mat-icon>
  <span class="item-label flex-auto">{{ item.label }}</span>
  <span *ngIf="item.badge" [ngClass]="[item.badge.bgClass, item.badge.textClass]" class="item-badge flex-none">{{
    item.badge.value$ | async
  }}</span>
</a>

<div
  (click)="item.route()"
  *ngIf="isLink(item) && isFunction(item.route)"
  class="item flex-row"
  matRipple
  routerLinkActive="active"
>
  <mat-icon *ngIf="level === 0" [icIcon]="item.icon" class="item-icon flex-none"></mat-icon>
  <span class="item-label flex-auto">{{ item.label }}</span>
  <span *ngIf="item.badge" [ngClass]="[item.badge.bgClass, item.badge.textClass]" class="item-badge flex-none">{{
    item.badge.value$ | async
  }}</span>
</div>

<ng-container *ngIf="isDropdown(item)">
  <div
    (click)="toggleOpen()"
    [class.active]="isOpen || isActive"
    [class.open]="isOpen"
    class="item dropdown flex"
    matRipple
  >
    <mat-icon *ngIf="level === 0" [icIcon]="item.icon" class="item-icon flex-none"></mat-icon>
    <span class="item-label flex-auto">{{ item.label }}</span>
    <span *ngIf="item.badge" [ngClass]="[item.badge.bgClass, item.badge.textClass]" class="item-badge flex-none">{{
      item.badge.value$ | async
    }}</span>
    <mat-icon [icIcon]="angleRight" class="item-dropdown-icon flex-none"></mat-icon>
  </div>
  <div [@dropdown]="isOpen" class="item-dropdown">
    <vex-sidenav-item *ngFor="let subItem of item.children" [item]="subItem" [level]="level + 1"></vex-sidenav-item>
  </div>
</ng-container>

<ng-container *ngIf="isSubheading(item)">
  <div class="subheading">{{ item.label }}</div>
  <vex-sidenav-item *ngFor="let subItem of item.children" [item]="subItem" [level]="0"></vex-sidenav-item>
</ng-container>
