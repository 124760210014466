<ng-container *ngIf="!isLoading">
  <div class="bg-card border-b flex items-center border-colorado mb-4">
    <hoho-icon iconName="search" class="mr-2.5"></hoho-icon>
    <input
      [formControl]="searchCtrl"
      class="py-3 border-0 outline-none w-full bg-transparent"
      name="search"
      [placeholder]="searchPlaceholder"
      type="text"
    />
  </div>
  <div class="flex mb-4" *ngIf="multiply">
<!--    <div class="text-primary cursor-pointer mr-4" (click)="selectAll()" i18n>Выбрать все</div>-->
    <div
      [class.cursor-pointer]="selectedCount"
      [class.text-primary]="selectedCount"
      [class.text-texas]="!selectedCount"
      (click)="removeSelection()"
      i18n
    >
      Снять выделения
    </div>
  </div>
</ng-container>
<div class="list overflow-x-hidden overflow-y-auto mb-0 -mx-4">
  <div class="flex-auto relative h-full" *ngIf="!isLoading">
    <div
      [class.bg-arizona]="isActive(item)"
      class="flex px-4 py-2 cursor-pointer hover:bg-shpongle trans-ease-out"
      *ngFor="let item of items"
      (click)="toggleSelect(item)"
    >
      <ng-container *ngTemplateOutlet="row; context: { item: item }"></ng-container>
    </div>
    <h2 *ngIf="items.length === 0 && !isLoading" class="text-center" i18n="@@noRecords">Нет записей</h2>
  </div>
  <div class="flex items-center justify-center pt-4 h-full" *ngIf="isLoading">
    <mat-spinner diameter="50" class="table-mat-spinner"></mat-spinner>
  </div>
</div>
<button class="w-full mt-4" mat-flat-button (click)="selectEvent()">
  {{ btnText }}
</button>
