import { Component, EventEmitter, forwardRef, Input, Output, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { deepEqual } from '@shared/utilities';
import { EEntityList, IPagingRequestFilters, IPagingResponsePayload, IServerRequestPayload } from '@shared/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DataRestService } from '@shared/services';
import { TransformToEntitiesList, TransformToStringArray } from '@shared/utilities/entity.untills';
import { debounceTime, filter, map, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'hoho-multiselect-entity-with-form-control',
  templateUrl: './multiselect-entity-with-form-control.component.html',
  styleUrls: ['./multiselect-entity-with-form-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiselectEntityComponentWithFormControl),
      multi: true,
    },
  ],
})
export class MultiselectEntityComponentWithFormControl<T> implements ControlValueAccessor {
  @Input() btnText: string = $localize`Добавить выбранные`;

  @Input() row: TemplateRef<HTMLElement>;
  @Input() multiply: boolean = true;
  @Input() isLoadingInput: boolean = null;
  @Input() searchPlaceholder = $localize`Поиск...`;
  @Input() linkedEntityPath: string;
  @Input() linkEntity: EEntityList;
  @Input() autocomplete: boolean;
  @Output() selected = new EventEmitter<any[]>();

  selectedItems: any[] = [];
  selectedCount: number;
  isLoading: boolean = true;
  initState: any[] = [];
  searchCtrl: UntypedFormControl = new UntypedFormControl();
  private _items: any[];

  onChange(value: any): void {}
  constructor(private dataRestService: DataRestService) {}

  selectEvent(): void {
    const items = this.selectedItems.map((i) => {
      return this.linkedEntityPath
        ? {
          id: null,
          entity: this.linkEntity,
          [this.linkedEntityPath]: i,
        }
        : i;
    });

    this.selected.emit(items);

    this.onChange(items);
  }

  isActive(item: any): boolean {
    return !!this.selectedItems.find((i) => {
      return item.id === i?.id;
    });
  }

  selectAll(): void {
    const diffSelectedItems = this.items.filter((elem) => !this.selectedItems.find((i) => deepEqual(i, elem)));
    this.selectedItems = this.selectedItems.concat(diffSelectedItems);
    this.selectedCount = this.selectedItems.length;
  }

  removeSelection(): void {
    this.selectedItems = this.selectedItems.filter((elem) => !this.items.find((i) => deepEqual(i, elem)));
    this.selectedCount = this.selectedItems.length;
    if (this.autocomplete) {
      this._items = [];
    }
  }

  toggleSelect(item: any): void {
    if (this.multiply) {
      if (this.isActive(item)) {
        this.selectedItems = this.selectedItems.filter((i) => i.id !== item.id);
      } else {
        this.selectedItems.push(item);
      }
    } else {
      this.selectedItems = this.selectedItems.length ? [] : [item];
    }
    this.onChange(
      this.selectedItems.map((i) => {
        return {
          id: i.id,
          entity: this.linkEntity,
          [this.linkedEntityPath]: i,
        };
      }),
    );
    this.selectedCount = this.selectedItems.length;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  writeValue(value: any[]): void {
    if (!value) return;
    this.preinstallSelectedItems(value);
  }

  @Input()
  set entityType(params: { entity: string; paths: string[]; filter?: IPagingRequestFilters }) {
    if (this.autocomplete) {
      this.searchPlaceholder = $localize`Начните вводить символы`;
      setTimeout(() => {
        if (this.selectedItems.length) {
          this.loadEntitiesList(
            { paths: params.paths, filter: { id: TransformToStringArray(this.selectedItems) } },
            params.entity,
          );
        } else {
          this.isLoading = false;
        }
      }, 1);

      this.searchCtrl.valueChanges
        .pipe(
          map((res: string) => {
            return res.trim();
          }),
          tap(() => {
            this.isLoading = true;
          }),
          untilDestroyed(this),
          debounceTime(500),
        )
        .subscribe((value: string) => {
          console.log(params);
          if (value.length) {
            this.loadEntitiesList(
              {
                paths: params.paths,
                filter: {
                  ...params.filter,
                  search: { like: value },
                },
              },
              params.entity,
            );
          } else {
            this.setItems(this.selectedItems);
          }
        });
    } else {
      this.loadEntitiesList({ paths: params.paths, filter: params?.filter }, params.entity);
    }
  }

  get items(): T[] {
    if (this.autocomplete) {
      return this._items;
    }
    if (!this.searchCtrl.value) {
      return this._items;
    }
    return this._items?.filter((data: T) =>
      JSON.stringify(data).toLowerCase().includes(this.searchCtrl.value?.toLowerCase()),
    );
  }

  private preinstallSelectedItems(value: any[]): void {
    if (this.linkedEntityPath) {
      this.selectedItems = value.map((i) => i[this.linkedEntityPath]);
    } else {
      this.selectedItems = value;
    }
    this.initState = value;
  }

  private loadEntitiesList(params: IServerRequestPayload, entity: string): void {
    this.isLoading = true;
    const payload: IServerRequestPayload = {
      paths: params.paths,
      sort: null,
      filter: params.filter,
    };
    this.dataRestService
      .page<T>(entity, '', 0, -1, payload)
      .pipe(untilDestroyed(this))
      .subscribe(
        (res: IPagingResponsePayload<T>) => {
          this.setItems(res.content);
        },
        () => {
          this.isLoading = false;
        },
      );
  }

  private setItems(items: T[]): void {
    if (this.selectedItems.length) {
      this.selectedItems = TransformToEntitiesList(this.selectedItems, items);
    }
    this._items = items.sort((a, b) => (this.isActive(a) ? -1 : 1));
    this.isLoading = false;
  }
}
