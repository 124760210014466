<form (ngSubmit)="save()" [formGroup]="formGroup" #ngForm>
  <ng-container *ngIf="!_steps?.length || activeView === 'form'">
    <div matDialogTitle>
      <div class="flex content-between w-full">
        <div class="text-subtitle-3 flex-grow">{{ title }}</div>
        <hoho-icon class="ml-4 text-florida hover:text-margaret transition-colors delay-500 ease-in cursor-pointer"
          size="1rem" iconName="multiply" mat-dialog-close=""></hoho-icon>
      </div>
    </div>
    <div [ngClass]="isLoading ? 'pointer-events-none opacity-50 d-flex' : ''">
      <mat-dialog-content>
        <ng-container *ngIf="!activeView">
          <ng-content></ng-content>
        </ng-container>
        <ng-container *ngIf="activeView === 'form'">
          <ng-container [ngTemplateOutlet]="stepsTmp"></ng-container>
        </ng-container>
      </mat-dialog-content>
      <mat-dialog-actions>
        <ng-container *ngTemplateOutlet="actions"> </ng-container>
        <button class="w-full ml-0" color="primary" mat-flat-button type="submit" [disabled]="isLoading">
          <ng-container *ngIf="isUpdate" i18n="@@update">Обновить</ng-container>
          <ng-container *ngIf="!isUpdate" i18n="@@create">Создать</ng-container>
        </button>
      </mat-dialog-actions>
    </div>
  </ng-container>
  <ng-container *ngIf="activeView !== 'form'">
    <ng-container [ngTemplateOutlet]="stepsTmp"></ng-container>
  </ng-container>
</form>

<ng-template #stepsTmp>
  <ng-container *ngFor="let step of _steps">
    <ng-container *ngIf="activeView === step.view">
      <ng-container [ngTemplateOutlet]="step.template"></ng-container>
    </ng-container>
  </ng-container>
</ng-template>
