import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { VexModule } from '@shared/@vex/vex.module';
import { ComponentsModule } from '@shared/components/components.module';
import { IconModule } from '@visurel/iconify-angular';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { CreateUpdateDialogComponent } from './components/create-update-dialog/create-update-dialog.component';
import { DialogContainerComponent } from './components/dialog-container/dialog-container.component';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';
import { DialogService } from './services/dialog.service';
import { CreateStepComponent } from './components/create-step/create-step.component';

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    CreateUpdateDialogComponent,
    DialogContainerComponent,
    MessageDialogComponent,
    CreateStepComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatDialogModule,
    ReactiveFormsModule,
    IconModule,
    MatDividerModule,
    MatButtonModule,
    VexModule,
    ComponentsModule,
  ],
  providers: [DialogService],
  exports: [
    ConfirmDialogComponent,
    CreateUpdateDialogComponent,
    DialogContainerComponent,
    MessageDialogComponent,
    CreateStepComponent,
  ],
})
export class DialogsModule {}
