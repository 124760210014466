<vex-page-layout>
  <hoho-header i18n-title title="Продажи"></hoho-header>
  <hoho-list-builder
    class="mt-5 block px-gutter"
    #tableBuilder
    [multiselect]="false"
    [props]="tableBuilderProps"
    [noLink]="true"
    excelFileNameBase="Продажи">
  </hoho-list-builder>
</vex-page-layout>
