import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'hoho-minutes-hour',
  templateUrl: './minutes-hour.component.html',
  styleUrls: ['./minutes-hour.component.scss'],
})
export class MinutesHourComponent implements OnInit {
  @Input() minutes: string = '00';
  @Output() minutesChange = new EventEmitter<string>();
  @Input() hours: string = '00';
  @Output() hoursChange = new EventEmitter<string>();
  constructor() {}

  ngOnInit(): void {}

  nextMin() {
    let minutes: number = parseInt(this.minutes);
    if (minutes < 59) {
      minutes++;
      if (minutes < 10) {
        this.minutes = '0' + minutes;
      } else {
        this.minutes = minutes.toString(10);
      }
    } else {
      this.minutes = '00';
    }
    this.minutesChange.emit(this.minutes);
  }
  prevMin() {
    let minutes: number = parseInt(this.minutes);
    if (minutes > 0) {
      minutes--;
      if (minutes < 10) {
        this.minutes = '0' + minutes;
      } else {
        this.minutes = minutes.toString(10);
      }
    } else {
      this.minutes = '59';
    }
    this.minutesChange.emit(this.minutes);
  }
  nextHour(): void {
    let hours: number = parseInt(this.hours);
    if (hours < 23) {
      hours++;
      if (hours < 10) {
        this.hours = '0' + hours;
      } else {
        this.hours = hours.toString(10);
      }
    } else {
      this.hours = '00';
    }
    this.hoursChange.emit(this.hours);
  }
  prevHour(): void {
    let hours: number = parseInt(this.hours);
    if (hours > 0) {
      hours--;
      if (hours < 10) {
        this.hours = '0' + hours;
      } else {
        this.hours = hours.toString(10);
      }
    } else {
      this.hours = '23';
    }
    this.hoursChange.emit(this.hours);
  }
  changeTime(): void {
    this.minutesChange.emit(this.minutes);
    this.hoursChange.emit(this.hours);
  }
}
