<hoho-select
  #selectRef
  [clearable]="true"
  [searchable]="false"
  [multiple]="false"
  [control]="ctrl"
  [bindValue]="null"
  bindLabel="name"
  [items]="items"
  [placeholder]="placeholder"
></hoho-select>
<input
  class="block h-0 opacity-0 overflow-hidden"
  (dateChange)="certainDateSelectedHandler($event)"
  matInput
  [matDatepicker]="certainDatePicker"
/>
<input class="block h-0 opacity-0 overflow-hidden" [max]="max" matInput [matDatepicker]="yearDatePicker" />
<input
  class="block h-0 opacity-0 overflow-hidden"
  (dateChange)="monthSelectedHandler($event, monthDatePicker)"
  matInput
  [matDatepicker]="monthDatePicker"
/>

<mat-date-range-input class="h-0 opacity-0 overflow-hidden" [rangePicker]="rangeDatePicker">
  <input (dateChange)="rangeFormSelectedHandler($event)" matStartDate placeholder="Start date" />
  <input (dateChange)="rangeToSelectedHandler($event)" matEndDate placeholder="End date" />
</mat-date-range-input>

<mat-date-range-picker
  [calendarHeaderComponent]="headerCalendarComponent"
  class="-mt-5"
  #rangeDatePicker
></mat-date-range-picker>
<mat-datepicker [calendarHeaderComponent]="headerCalendarComponent" class="-mt-5" #certainDatePicker></mat-datepicker>
<mat-datepicker
  [calendarHeaderComponent]="yearHeaderCalendarComponent"
  class="-mt-5"
  (yearSelected)="yearSelectedHandler($event, yearDatePicker)"
  startView="multi-year"
  #yearDatePicker
></mat-datepicker>

<mat-datepicker
  [calendarHeaderComponent]="monthHeaderCalendarComponent"
  class="-mt-5"
  startView="year"
  #monthDatePicker
></mat-datepicker>
