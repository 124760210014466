export enum EModuleTypes {
  HOHOROClientCommandUIHandler,
}
export enum EModules {
  'All' = 'All',
  VendingMachineModule = 'VendingMachineModule',
  ReportsModule = 'ReportsModule',
  DictionariesModule = 'DictionariesModule',
  PlanogrammModule = 'PlanogrammModule',
  ClientsModule = 'ClientsModule',
  FeedbackModule = 'FeedbackModule',
  UserRequestModule = 'UserRequestModule',
  PromoModule = 'PromoModule',
  MarketingModule = 'MarketingModule',
  ServiceVendingMachines = 'ServiceVendingMachines',
  SupportModule = 'SupportModule',
  SalesAdministrationModule = 'SalesAdministrationModule',
  MailingErrorModule = 'MailingErrorModule',
  PersonalAreaModule = 'PersonalAreaModule',
  MonitoringModule = 'MonitoringModule',
  KnowledgeBaseModule = 'KnowledgeBaseModule'
}

export enum EMarketingModuleFeatures {
  PushNotifications = 'PushNotifications',
  GroupUsers = 'GroupUsers',
  Stories = 'Stories',
}

export enum ESupportModuleFeatures {
  SlAppeals = 'SlAppeals',
  StatisticsAppeals = 'StatisticsAppeals',
  CustomAppeals = 'CustomAppeals',
}

export enum EServiceVendingMachinesFeatures {
  Routes = 'Routes',
  Encashment = 'Encashment',
  LoadingIngredients = 'LoadingIngredients',
}

export enum EVendingMachineFeatures {
  List = 'List',
  State = 'State',
  Card = 'Card',
  Information = 'Information',
  ResetErrors = 'ResetErrors',
}

export enum EReportsFeatures {
  BySale = 'BySale',
  ByState = 'ByState',
  AutoReports = 'AutoReports',
  OtherReports = 'OtherReports',
}
export enum EDictionariesFeatures {
  Company = 'Company',
  User = 'User',
  Roles = 'Roles',
  Merge = 'Merge',
  RegisterMarketplacePoint = 'RegisterMarketplacePoint',
}
export enum EPromoFeatures {
  Promocodes = 'Promocodes',
  Coupons = 'Coupons',
}

export enum EFeedbackFeatures {
  Chart = 'Chart',
  Table = 'Table',
  Reviews = 'Reviews',
  Page = 'Page',
}

export enum EPlanogramFeatures {
  Product = 'Product',
  Recipe = 'Recipe',
  ProductMatrix = 'ProductMatrix',
  Ingredient = 'Ingredient',
  PriceLists = 'PriceLists',
}

export enum EClientsFeatures {
  Card = 'Card',
}

export enum EUserRequestFeatures {
  New = 'New',
  Blocked = 'Blocked',
  EmailNotConfirmed = 'EmailNotConfirmed',
}

export enum EMarketingFeatures {
  PushNotifications = 'PushNotifications',
  GroupUsers = 'GroupUsers',
  Stories = 'Stories',
}

export enum ESalesAdministrationFeatures {
  ManualWriteOff = 'ManualWriteOff',
  Card = 'Card',
}

export enum EPersonalAreaFeatures {
  Main = 'Main',
  Invoices = 'Invoices',
}

export type ModuleFeatureType =
  | EVendingMachineFeatures
  | ESupportModuleFeatures
  | EReportsFeatures
  | EPlanogramFeatures
  | EDictionariesFeatures
  | EClientsFeatures
  | EUserRequestFeatures
  | EFeedbackFeatures
  | EMarketingFeatures
  | EMarketingModuleFeatures
  | EServiceVendingMachinesFeatures
  | ESalesAdministrationFeatures
  | EPromoFeatures
  | EDictionariesFeatures
	| EPersonalAreaFeatures;
