import { Component, Input } from '@angular/core';
import { IPlacementCardModel } from '../../models/card.models';

@Component({
  selector: 'hoho-placement-card',
  templateUrl: './placement-card.component.html',
  styleUrls: ['./placement-card.component.scss'],
})
export class PlacementCardComponent {
  @Input() model: IPlacementCardModel;

  constructor() {}
}
