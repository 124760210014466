import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EListBuilderViews, IAccess, IListBuilderProps } from '@app/core/modules/list-builder/models';
import {
  EEntityList,
  EModules,
  EUserRequestFeatures,
  IServerRequestPayload,
  ModuleFeatureType,
} from '@app/shared/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ITabContent } from '@shared/components/feature-tabs/feature-tabs.models';
import { UserRequestService, UserService } from '@shared/services';
import { EUserRequestStatus } from '@shared/models';
import { UserRequestsRoutingNames } from './../../user-requests.routing';
import { user_request } from '@src/api';

@UntilDestroy()
@Component({
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent implements OnInit, AfterViewInit {
  listBuilderProps: IListBuilderProps<user_request> = {
    entity: EEntityList.user_request,
    views: [EListBuilderViews.table],
  };
  requestPayloadNew: IServerRequestPayload = {
    filter: {
      requestStatus: EUserRequestStatus.new,
    },
  };
  requestPayloadBlocked: IServerRequestPayload = {
    filter: {
      requestStatus: EUserRequestStatus.blocked,
    },
  };
  requestPayloadUnconfirmed: IServerRequestPayload = {
    filter: {
      requestStatus: EUserRequestStatus.unconfirmed,
    },
  };
  EModules = EModules;
  activeFeature: EUserRequestFeatures;
  tabsContentList: ITabContent[] = [];

  userRequestAccess: IAccess;

  @ViewChild('newTemp') newTemp: TemplateRef<HTMLElement>;
  @ViewChild('blockTemp') blockTemp: TemplateRef<HTMLElement>;
  @ViewChild('unconfirmedTemp') unconfirmedTemp: TemplateRef<HTMLElement>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public userRequestService: UserRequestService,
    private userService: UserService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res.tab) {
        switch (res.tab) {
          case UserRequestsRoutingNames.new.name:
            this.activeFeature = EUserRequestFeatures.New;
            break;
          case UserRequestsRoutingNames.blocked.name:
            this.activeFeature = EUserRequestFeatures.Blocked;
            break;
          case UserRequestsRoutingNames.unconfirmed.name:
            this.activeFeature = EUserRequestFeatures.EmailNotConfirmed;
            break;
        }
      }
      this.cd.markForCheck();
    });

    this.userRequestAccess = this.userService.Access(EEntityList.user_request);
    this.userRequestService.refresh();
  } 

  ngAfterViewInit(): void {
    this.tabsContentList = [
      { name: $localize`Новые заявки`, template: this.newTemp, feature: EUserRequestFeatures.New },
      { name: $localize`Заблокированные`, template: this.blockTemp, feature: EUserRequestFeatures.Blocked },
      {
        name: $localize`Неподтвержденные`,
        template: this.unconfirmedTemp,
        feature: EUserRequestFeatures.EmailNotConfirmed,
      },
    ];
  }

  changeTab(feat: ModuleFeatureType): void {
    let url: string;
    switch (feat) {
      case EUserRequestFeatures.New:
        url = UserRequestsRoutingNames.new.path;
        break;
      case EUserRequestFeatures.Blocked:
        url = UserRequestsRoutingNames.blocked.path;
        break;
      case EUserRequestFeatures.EmailNotConfirmed:
        url = UserRequestsRoutingNames.unconfirmed.path;
        break;
    }
    this.cd.markForCheck();
    this.router.navigate([url], { replaceUrl: true });
  }
}
