/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { vm_group } from '../models/vm_group';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VmGroupService {

    /**
     * Select vm_group by id
     * @param id Object ID
     * @returns vm_group Successful operation
     * @throws ApiError
     */
    public static selectVmGroupById(
        id: string,
    ): CancelablePromise<vm_group> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/get/object/vm_group/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Save vm_group
     * @param requestBody Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static saveVmGroup(
        requestBody?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/save/object/vm_group',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Delete vm_group by id
     * @param id Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static deleteVmGroupById(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/delete/object/vm_group/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

}
