<div class="select w-full relative" [className]="appearance">
  <ng-select
    #selectRef
    class="w-full"
    [class.has-error]="canShowError && control.invalid"
    [multiple]="multiple"
    [formControl]="control"
    [items]="items"
    [bindLabel]="bindLabel"
    [bindValue]="bindValue"
    (blur)="control.markAsDirty()"
    [searchable]="searchable"
    [isOpen]="isOpen"
    [readonly]="disabled"
    [appearance]="appearance"
    [disabled]="disabled"
    i18n-notFoundText
    notFoundText="Ничего не найдено"
    [clearable]="clearable"
    [placeholder]="placeholder"
    [addTag]="addTag"
    [appendTo]="appendTo"
    [addTagText]="'Добавить'"
    [closeOnSelect]="!multiple"
  >
    <ng-template *ngIf="!multiple" ng-label-tmp let-item="item">
      <span class="ng-value-label">{{ $any(item)[bindLabel] | htmlToPlaintext }}</span>
    </ng-template>

    <ng-container *ngIf="multiple">
      <ng-template ng-header-tmp>
        <button type="button" (click)="selectAll()" class="text-margaret mr-4 select-none">Выбрать все</button>
        <button
          type="button"
          (click)="unselectAll()"
          class="select-none"
          [class.text-margaret]="control.value?.length > 0"
          [class.text-texas]="!control.value?.length"
          [class.cursor-default]="!control.value?.length"
        >
          Снять выделения
        </button>
      </ng-template>
      <ng-template ng-multi-label-tmp let-items="items">
        <div class="ng-value" *ngFor="let item of items | slice: 0:1">
          <span class="ng-value-label">{{ $any(item)[bindLabel] | htmlToPlaintext }}</span>
        </div>
        <div class="ng-value" *ngIf="items.length > 1">
          <span class="ng-value-label">{{ items.length - 1 }}+</span>
        </div>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="optionTmp">
      <ng-template ng-option-tmp let-item="item">
        <ng-container *ngTemplateOutlet="optionTmp; context: { item: item }"></ng-container>
      </ng-template>
    </ng-container>
  </ng-select>
  <div class="field-subscript-wrapper" *ngIf="haveSubscriptWrapper">
    <div *ngIf="canShowError && control.invalid">
      <ng-content></ng-content>
    </div>
  </div>
</div>
