<hoho-header i18n-title title="Маркетинг">
  <button
    class="sm:my-0 my-3"
    color="primary"
    mat-flat-button
    type="button"
    (click)="openCreateUpdateModal()"
    *ngIf="activeFeature === EMarketingFeatures.PushNotifications"
    i18n
  >
    Создать новое уведомление
  </button>
  <button
    class="sm:my-0 my-3"
    color="primary"
    mat-flat-button
    type="button"
    [routerLink]="marketingRoutingNames.groupCreate.path"
    *ngIf="activeFeature === EMarketingFeatures.GroupUsers"
    i18n
  >
    Создать новую группу
  </button>
  <button
    class="sm:my-0 my-3"
    color="primary"
    mat-flat-button
    type="button"
    (click)="openCreateUpdateStoriesModal()"
    *ngIf="activeFeature === EMarketingFeatures.Stories"
    i18n
  >
    Создать новый stories
  </button>
</hoho-header>

<hoho-feature-tabs
  [module]="EModules.MarketingModule"
  [activeFeature]="activeFeature"
  [tabsList]="tabsContentList"
  (changeTab)="changeTab($event)"
>
</hoho-feature-tabs>

<ng-template #pushRef>
  <hoho-list-builder
    #pushListBuilder
    [multiselect]="true"
    class="mt-5 block px-gutter"
    [props]="pushBuilderProps"
    [noLink]="true"
    [access]="userRequestAccess"
    [additionalColumns]="additionalColumns"
  ></hoho-list-builder>
</ng-template>
<ng-template #link let-item="item">
  <div class="link flex" *ngIf="item.status === 0" (click)="openCreateUpdateModal(item.id)">
    <hoho-icon class="mr-2" iconName="edit"></hoho-icon>
  </div>
</ng-template>
<ng-template #groupRef>
  <hoho-list-builder
    [multiselect]="true"
    class="mt-5 block px-gutter"
    [props]="groupBuilderProps"
    [noLink]="true"
    [access]="userRequestAccess"
  ></hoho-list-builder>
</ng-template>
<ng-template #storiesRef>
  <hoho-list-builder
    #storiesListBuilder
    [multiselect]="true"
    class="mt-5 block px-gutter"
    [props]="storiesBuilderProps"
    [noLink]="true"
    [access]="userRequestAccess"
    [additionalColumns]="additionalColumnsStories"
  ></hoho-list-builder>
</ng-template>
<ng-template #storieslink let-item="item">
  <div class="link flex" (click)="openCreateUpdateStoriesModal(item.id)">
    <hoho-icon class="mr-2" iconName="edit"></hoho-icon>
  </div>
</ng-template>
