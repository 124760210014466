export const walletPaths = [
  'entity',
  'id',
  'balance',
  'inn',
];

export const agreementPaths = [
  'number',
];

export const tarifPaths = [
  "entity",
  "id",
  "nameAndSerial",
  "calculatedRoyaltyAmount",
  "royaltyStartDatetime",
  "calculatedAppAmount",
  "appStartDatetime",
  "calculatedErpAmount",
  "erpStartDatetime"
];