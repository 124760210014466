<div class="flex justify-center items-center">
  <div
    class="badge-icon mr-3"
    [ngClass]="{
      'bg-accept': status === statusEnum.Accept,
      'bg-attention': status === statusEnum.Attention,
      'bg-error': status === statusEnum.Error
    }"
  >
    <hoho-icon iconName="water-glass" className="text-natasha"></hoho-icon>
  </div>

  <div>
    <h2 class="text-subtitle-3 text-black">{{ model.serial }}, {{ model.modelName }}</h2>
    <p class="text-body-5 text-black" name="title_vm_sm">{{ model.vmType === 0 ? 'Кофемашина' : 'Холодильник'}}</p>
  </div>

  <div *ngIf="!isDialog" class="ml-auto cursor-pointer" (click)="onClick()">
    <hoho-icon iconName="angle-right-b" className="text-system"></hoho-icon>
  </div>

  <div *ngIf="isDialog" class="ml-auto cursor-pointer" (click)="close()">
    <hoho-icon iconName="multiply"></hoho-icon>
  </div>
</div>
