import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutingNames } from '@app/app-routing.module';
import { ListComponent } from '@app/pages/vending-machines/components/list/list.component';
import { VendingMachinesComponent } from '@app/pages/vending-machines/vending-machines.component';
import RoutePath from '@app/shared/models/route-path.model';
import { EModules, EVendingMachineFeatures } from '@shared/models/module.models';
import { PermissionsGuard } from '@shared/services/guards/permissions.guard';
import { QuicklinkModule } from 'ngx-quicklink';
import { GroupPageComponent } from './components/group-page/group-page.component';

export const VendingMachinesRoutingNames = {
  list: new RoutePath(
    'list',
    AppRoutingNames.vendingMachines.path + '/list/' + EVendingMachineFeatures.List.toLowerCase(),
    ':tab',
  ),
  information: new RoutePath(
    'list',
    AppRoutingNames.vendingMachines.path + '/list/' + EVendingMachineFeatures.Information.toLowerCase(),
    ':tab',
  ),
  state: new RoutePath(
    'list',
    AppRoutingNames.vendingMachines.path + '/list/' + EVendingMachineFeatures.State.toLowerCase(),
    ':tab',
  ),
  create: new RoutePath('create', AppRoutingNames.vendingMachines.path + '/create'),
  edit: new RoutePath('edit', AppRoutingNames.vendingMachines.path + '/edit', ':id'),
  createSnack: new RoutePath('create-snack', AppRoutingNames.vendingMachines.path + '/create-snack'),
  editSnack: new RoutePath('edit-snack', AppRoutingNames.vendingMachines.path + '/edit-snack', ':id'),
  page: new RoutePath('page', AppRoutingNames.vendingMachines.path + '/page', ':id'),
  snackPage: new RoutePath('snack-page', AppRoutingNames.vendingMachines.path + '/snack-page', ':id'),
  group: new RoutePath('group', AppRoutingNames.vendingMachines.path + '/group', ':id'),
};

const routes: Routes = [
  {
    path: '',
    component: VendingMachinesComponent,
    canActivate: [PermissionsGuard],
    data: {
      module: EModules.VendingMachineModule,
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: VendingMachinesRoutingNames.information.path,
      },
      {
        path: VendingMachinesRoutingNames.list.name + '/' + VendingMachinesRoutingNames.list.routeParams,
        component: ListComponent,
      },
      {
        path: VendingMachinesRoutingNames.group.name + '/' + VendingMachinesRoutingNames.group.routeParams,
        component: GroupPageComponent,
      },
      {
        path: VendingMachinesRoutingNames.create.name,
        loadChildren: () =>
          import('./modules/create-update-vending-machine/create-update-vending-machine.module').then(
            (m) => m.CreateUpdateVendingMachineModule,
          ),
      },
      {
        path: VendingMachinesRoutingNames.edit.name + '/' + VendingMachinesRoutingNames.edit.routeParams,
        loadChildren: () =>
          import('./modules/create-update-vending-machine/create-update-vending-machine.module').then(
            (m) => m.CreateUpdateVendingMachineModule,
          ),
      },

      {
        path: VendingMachinesRoutingNames.create.name,
        loadChildren: () =>
          import('./modules/create-update-vending-machine/create-update-vending-machine.module').then(
            (m) => m.CreateUpdateVendingMachineModule,
          ),
      },
      {
        path: VendingMachinesRoutingNames.edit.name + '/' + VendingMachinesRoutingNames.edit.routeParams,
        loadChildren: () =>
          import('./modules/create-update-vending-machine/create-update-vending-machine.module').then(
            (m) => m.CreateUpdateVendingMachineModule,
          ),
      },

      {
        path: VendingMachinesRoutingNames.createSnack.name,
        loadChildren: () =>
          import('./modules/create-update-snack-machine/create-update-snack-machine.module').then(
            (m) => m.CreateUpdateSnackMachineModule,
          ),
      },
      {
        path: VendingMachinesRoutingNames.editSnack.name + '/' + VendingMachinesRoutingNames.edit.routeParams,
        loadChildren: () =>
          import('./modules/create-update-snack-machine/create-update-snack-machine.module').then(
            (m) => m.CreateUpdateSnackMachineModule,
          ),
      },
      {
        path: VendingMachinesRoutingNames.page.name + '/' + VendingMachinesRoutingNames.page.routeParams,
        loadChildren: () => import('./modules/page/page.module').then((m) => m.PageModule),
      },
      {
        path: VendingMachinesRoutingNames.snackPage.name + '/' + VendingMachinesRoutingNames.snackPage.routeParams,
        loadChildren: () => import('./modules/snack-page/snack-page.module').then((m) => m.SnackPageModule),
      },
      // {
      //   path: VendingMachinesRoutingNames.group.name + '/' + VendingMachinesRoutingNames.group.routeParams,
      //   loadChildren: () => import('./components/list/list.module').then((m) => m.ListModule),
      // },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule, QuicklinkModule],
})
export class VendingMachinesRouting {}
