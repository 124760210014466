export enum EEntityList {
  vm_model = 'vm_model',
  goods = 'goods',
  recipe_item = 'recipe_item',
  product_matrix = 'product_matrix',
  product_matrix_freezer = 'product_matrix_freezer',
  product_matrix_coffee = 'product_matrix_coffee',
  vending_machine = 'vending_machine',
  vending_machine_coffee = 'vending_machine_coffee',
  vending_machine_freezer = 'vending_machine_freezer',
  ingredient = 'ingredient',
  company = 'company',
  product_matrix_item = 'product_matrix_item',
  product_matrix_item_freezer = 'product_matrix_item_freezer',
  product_matrix_item_coffee = 'product_matrix_item_coffee',
  vm_manufacturer = 'vm_manufacturer',
  brand = 'brand',
  card_payment_type = 'card_payment_type',
  source = 'source',
  recipe = 'recipe',
  product_matrix_ingredient = 'product_matrix_ingredient',
  product = 'product',
  user_request = 'user_request',
  users = 'users',
  role = 'role',
  vm_log = 'vm_log',
  vm_event = 'vm_event',
  vm_vend = 'vm_vend',
  vm_group = 'vm_group',
  machine_in_group = 'machine_in_group',
  location_format = 'location_format',
  vm_location_formats = 'vm_location_formats',
  city = 'city',
  sl_user = 'sl_user',
  sl_order = 'sl_order',
  sl_making_coffee_log = 'sl_making_coffee_log',
  sl_wallet_operation = 'sl_wallet_operation',
  ps_wallet = 'ps_wallet',
  ps_wallet_operation = 'ps_wallet_operation',
  ps_agreement = 'ps_agreement',
  ps_payment_card = 'ps_payment_card',
  ps_invoice = 'ps_invoice',
  price_list = 'price_list',
  vending_machine_product_matrix = 'vending_machine_product_matrix',
  vending_machine_price_list = 'vending_machine_price_list',
  module = 'module',
  role_modules = 'role_modules',
  promocode = 'promocode',
  coupon = 'coupon',
  module_feature = 'module_feature',
  feature_settings = 'feature_settings',
  price_list_item = 'price_list_item',
  auto_report = 'auto_report',
  report_menu_item = 'report_menu_item',
  service_route = 'service_route',
  service_route_waypoint = 'service_route_waypoint',
  custom_waypoint = 'custom_waypoint',
  service_route_responsible_person = 'service_route_responsible_person',
  erp_collection = 'erp_collection',
  erp_ingredient_fill = 'erp_ingredient_fill',
  vm_ingredient_item_state = 'vm_ingredient_item_state',
  erp_ingredient_fill_item = 'erp_ingredient_fill_item',
  vm_ingredient_state = 'vm_ingredient_state',
  vm_cash_state = 'vm_cash_state',
  report_link = 'report_link',
  support_appeal = 'support_appeal',
  support_appeal_subject = 'support_appeal_subject',
  feedback = 'feedback',
  feedback_note = 'feedback_note',
  user_roles = 'user_roles',
  report_elected = 'report_elected',
  report_create_history = 'report_create_history',
  feedback_reason = 'feedback_reason',
  marketing_push_notifications = 'marketing_push_notifications',
  marketing_customers_group = 'marketing_customers_group',
  marketing_push_notification_customer_group = 'marketing_push_notification_customer_group',
  marketing_push_notification_file = 'marketing_push_notification_file',
  marketing_push_notification_individual_customers = 'marketing_push_notification_individual_customers',
  custom_support_appeal = 'custom_support_appeal',
  support_statistics_appeal = 'support_statistics_appeal',
  manual_writeoff_orders = 'manual_writeoff_orders',
  vending_machine_base = 'vending_machine_base',
  manual_writeoff_basis_video = 'manual_writeoff_basis_video',
  mailing_error = 'mailing_error',
  mailing_error_vending_machine = 'mailing_error_vending_machine',
  mailing_error_telegram_bot_user = 'mailing_error_telegram_bot_user',
  mailing_error_mailing_error_category = 'mailing_error_mailing_error_category',
  telegram_bot_users = 'telegram_bot_users',
  mailing_error_category = 'mailing_error_category',
  marketing_stories = 'marketing_stories',
  marketing_stories_file = 'marketing_stories_file',
  marketplace_point = 'marketplace_point',
}

export interface IEntity {
  id?: string | number;
  entity: string | EEntityList;
  displayName?: string;
  name?: string;
}

export class EntityModel implements IEntity {
  entity: EEntityList;
  id: string | number;
  displayName?: string;
  name?: string;
  constructor(value?: EntityModel) {
    this.entity = value?.entity;
    this.id = value?.id;
    this.displayName = value?.displayName;
    this.name = value?.name;
  }
}

export enum EVendingMachineContextsList {
  info,
  list,
  state,
  address,
}

export enum ECompanyContextsList {
  info,
  placement,
  address,
}

export interface IChoseItem {
  name: string;
  id: number;
}

export interface IDeleteEntityItem {
  id: string | number;
  entity: EEntityList;
  path?: string;
}
