import { Directive, HostListener, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { RegexPatternConstants } from '@app/shared/utilities/regex-pattern-constants';

@Directive({
  selector: '[hohoNoSpaceMask]',
})
export class NoSpaceMaskDirective implements OnInit {
  constructor(public ngControl: NgControl) {}

  ngOnInit() {
    const initialOnChange = (this.ngControl.valueAccessor as any).onChange;
    (this.ngControl.valueAccessor as any).onChange = (value: any) => initialOnChange(this.onInputChange(value));
  }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event: any) {
    this.ngControl.valueAccessor.writeValue(this.onInputChange(event));
  }

  onInputChange(event: any): string {
    return event?.replace(RegexPatternConstants.space, '');
  }
}
