/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { vm_cash_state } from '../models/vm_cash_state';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VmCashStateService {

    /**
     * Select vm_cash_state by id
     * @param id Object ID
     * @returns vm_cash_state Successful operation
     * @throws ApiError
     */
    public static selectVmCashStateById(
        id: string,
    ): CancelablePromise<vm_cash_state> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/get/object/vm_cash_state/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Save vm_cash_state
     * @param requestBody Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static saveVmCashState(
        requestBody?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/save/object/vm_cash_state',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Delete vm_cash_state by id
     * @param id Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static deleteVmCashStateById(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/delete/object/vm_cash_state/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

}
