export const DaysList = [
  {
    id: 1,
    name: $localize`Вс`,
    active: false,
  },
  {
    id: 64,
    name: $localize`Сб`,
    active: false,
  },
  {
    id: 32,
    name: $localize`Пт`,
    active: false,
  },
  {
    id: 16,
    name: $localize`Чт`,
    active: false,
  },
  {
    id: 8,
    name: $localize`Ср`,
    active: false,
  },
  {
    id: 4,
    name: $localize`Вт`,
    active: false,
  },
  {
    id: 2,
    name: $localize`Пн`,
    active: false,
  },
];

export enum EPeriodicity {
  everyDay,
  weekly,
}
