/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { feedback_note } from '../models/feedback_note';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FeedbackNoteService {

    /**
     * Select feedback_note by id
     * @param id Object ID
     * @returns feedback_note Successful operation
     * @throws ApiError
     */
    public static selectFeedbackNoteById(
        id: string,
    ): CancelablePromise<feedback_note> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/get/object/feedback_note/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Save feedback_note
     * @param requestBody Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static saveFeedbackNote(
        requestBody?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/save/object/feedback_note',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Delete feedback_note by id
     * @param id Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static deleteFeedbackNoteById(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/delete/object/feedback_note/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

}
