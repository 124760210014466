import { Component, Input, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'hoho-toggles',
  templateUrl: './toggles.component.html',
  styleUrls: ['./toggles.component.scss'],
})
export class TogglesComponent implements AfterViewInit {
  @Input() items: any[] = [];
  @Input() bindLabel: string = 'displayName';
  @Input() bindValue: string = 'id';
  @Input() ctrl: UntypedFormControl = new UntypedFormControl();
  @Output()
  activeIndex: number;
  constructor() {}

  ngAfterViewInit() {
    const setActive = (val: any) => {
      if (val || val === 0) {
        const index = this.items.findIndex((i: any) => i[this.bindValue] === val);
        if (index !== this.activeIndex) {
          this.setActive(index);
        }
      }
    };
    this.ctrl.valueChanges.pipe(untilDestroyed(this)).subscribe((val) => {
      setActive(val);
    });
    setActive(this.ctrl.value);
  }

  @Input()
  set setActiveIndex(value: number) {
    if (value === undefined) {
      return;
    }
    this.setActive(value);
  }

  setActive(value: number) {
    setTimeout(() => {
      this.activeIndex = value;
      this.ctrl.setValue(this.items[value][this.bindValue]);
    }, 10);
  }
}
