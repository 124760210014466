import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutingNames } from '@app/app-routing.module';
import { TabsComponent } from '@app/pages/directory/components/tabs/tabs.component';
import { DirectoryComponent } from '@app/pages/directory/directory.component';
import { PageComponent as CompanyPageComponent } from '@app/pages/directory/modules/companies/components/page/page.component';
import { PageComponent as UserPageComponent } from '@app/pages/directory/modules/users/components/page/page.component';

import RoutePath from '@app/shared/models/route-path.model';
import { QuicklinkModule } from 'ngx-quicklink';

export const DirectoryRoutingNames = {
  companies: new RoutePath('companies', AppRoutingNames.directory.path + '/companies'),
  companyPage: new RoutePath('page', AppRoutingNames.directory.path + '/companies/page', ':id'),
  create: new RoutePath('create', AppRoutingNames.directory.path + '/create'),
  users: new RoutePath('users', AppRoutingNames.directory.path + '/users'),
  userPage: new RoutePath('page', AppRoutingNames.directory.path + '/users/page', ':id'),
  roles: new RoutePath('roles', AppRoutingNames.directory.path + '/roles'),
  rolePage: new RoutePath('page', AppRoutingNames.directory.path + '/roles/page', ':id'),
};

const routes: Routes = [
  {
    path: '',
    component: DirectoryComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: DirectoryRoutingNames.companies.path,
      },
      {
        path: AppRoutingNames.directory.routeParams,
        component: TabsComponent,
      },
      {
        path:
          DirectoryRoutingNames.companies.name +
          '/' +
          DirectoryRoutingNames.companyPage.name +
          '/' +
          DirectoryRoutingNames.companyPage.routeParams,
        component: CompanyPageComponent,
      },
      {
        path:
          DirectoryRoutingNames.users.name +
          '/' +
          DirectoryRoutingNames.userPage.name +
          '/' +
          DirectoryRoutingNames.userPage.routeParams,
        component: UserPageComponent,
      },
      {
        path:
          DirectoryRoutingNames.roles.name +
          '/' +
          DirectoryRoutingNames.rolePage.name +
          '/' +
          DirectoryRoutingNames.rolePage.routeParams,
        loadChildren: () => import('./modules/role-page/role-page.module').then((m) => m.RolePageModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule, QuicklinkModule],
})
export class DirectoryRouting {}
