import { Component } from '@angular/core';
import { NewAgreementModal } from '@app/shared/components/new-agreement-modal/new-agreement-modal.component';
import { MatDialog } from '@angular/material/dialog';
import telegram from '@iconify/icons-uil/telegram';
import { TarifPriceIncreaseModal } from '@app/shared/components/tarif-price-increase/tarif-price-increase.component';

@Component({
  selector: 'vex-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  constructor(private dialog: MatDialog) {}
  telegram = telegram;

  openNewAgreementModal(): void {
    this.dialog.open(NewAgreementModal, {
      width: '40rem',
      disableClose: true,
    });
  }

  openTarifPriceIncreaseModal(): void {
    this.dialog.open(TarifPriceIncreaseModal, {
      width: '40rem',
      disableClose: true,
    });
  }
}
