import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'hoho-create-step',
  templateUrl: './create-step.component.html',
  styleUrls: ['./create-step.component.scss'],
})
export class CreateStepComponent implements OnInit {
  @Input()
  view: string;
  @ViewChild('itemTemplate')
  template: TemplateRef<HTMLElement>;
  constructor() {}

  ngOnInit(): void {}
}
