<div class="flex items-center">
  <hoho-dot-indicator [state]="dotIndicatorState" class="mr-2"></hoho-dot-indicator>
  <p *ngIf="fillingLevel === fillingLevelEnum.FullyFilled" class="text-body-4 text-black" i18n>
    Заполнен полностью
  </p>

  <p *ngIf="fillingLevel === fillingLevelEnum.HalfFull" class="text-body-4 text-black" i18n>
    Заполнен наполовину
  </p>

  <p *ngIf="fillingLevel === fillingLevelEnum.Empty" class="text-body-4 text-black" i18n>
    Мало
  </p>
</div>
