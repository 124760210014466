import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EModules, EPersonalAreaFeatures, ModuleFeatureType } from '@app/shared/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ITabContent } from '@shared/components/feature-tabs/feature-tabs.models';
import { PersonalAreaRoutingNames } from './../../personal-area.routing';

@UntilDestroy()
@Component({
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent implements OnInit, AfterViewInit {
  EModules = EModules;
	activeFeature: EPersonalAreaFeatures;
  EPersonalAreaFeatures = EPersonalAreaFeatures;
  tabsContentList: ITabContent[] = [];

  @ViewChild('mainTab') mainTabTemp: TemplateRef<HTMLElement>;
  @ViewChild('invoicesTab') invoicesTabTemp: TemplateRef<HTMLElement>;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.activatedRoute.params.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res.tab) {
        switch (res.tab) {
          case PersonalAreaRoutingNames.main.name:
            this.activeFeature = EPersonalAreaFeatures.Main;
            break;
          case PersonalAreaRoutingNames.invoices.name:
            this.activeFeature = EPersonalAreaFeatures.Invoices;
            break;
        }
        this.cd.markForCheck();
      }
    });
  }

  ngAfterViewInit(): void {
    this.tabsContentList = [
      { name: 'Главная', template: this.mainTabTemp, feature: EPersonalAreaFeatures.Main },
      { name: 'Счета', template: this.invoicesTabTemp, feature: EPersonalAreaFeatures.Invoices },
    ];
  }

  changeTab(feat: ModuleFeatureType): void {
    let url: string;
    switch (feat) {
      case EPersonalAreaFeatures.Main:
        url = PersonalAreaRoutingNames.main.path;
        break;
      case EPersonalAreaFeatures.Invoices:
        url = PersonalAreaRoutingNames.invoices.path;
        break;
    }
    this.router.navigate([url], { replaceUrl: true, queryParams: { filter: null } });
  }
}
