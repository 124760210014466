import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MatDialogRef } from '@angular/material/dialog';

@UntilDestroy()
@Component({
  selector: 'tarif-price-increase',
  templateUrl: './tarif-price-increase.component.html',
  styleUrls: ['./tarif-price-increase.component.scss'],
})
export class TarifPriceIncreaseModal implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<TarifPriceIncreaseModal>,
  ) {}

  ngOnInit(): void {}

  saveToLocalStorage(): void {
    let count = 0;
    let countString = localStorage.getItem('tarif_price_increase');
    if (countString) {
      count = Number(countString);
    }
    count++;
		localStorage.setItem('tarif_price_increase', count.toString());
    this.dialogRef.close();
  }
}
