<div class="w-full bg-arizona rounded-xl p-1 flex mb-5 h-12">
  <div
    class="flex-grow cursor-pointer p-2 rounded-md text-center whitespace-nowrap"
    *ngFor="let item of items; let i = index"
    [class.bg-natasha]="i === activeIndex"
    [class.text-california]="i !== activeIndex"
    (click)="setActive(i)"
  >
    {{ item[bindLabel] }}
  </div>
</div>
