import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { YearHeaderCalendarComponent } from './components/year-header-calendar/year-header-calendar.component';
import { MonthHeaderCalendarComponent } from './components/month-header-calendar/month-header-calendar.component';
import { HeaderCalendarComponent } from './components/header-calendar/header-calendar.component';
import { ComponentsModule } from '@shared/components/components.module';

@NgModule({
  declarations: [YearHeaderCalendarComponent, MonthHeaderCalendarComponent, HeaderCalendarComponent],
  imports: [CommonModule, ComponentsModule],
  exports: [YearHeaderCalendarComponent, MonthHeaderCalendarComponent, HeaderCalendarComponent],
})
export class CalendarHeadersModule {}
