/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { erp_collection } from '../models/erp_collection';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ErpCollectionService {

    /**
     * Select erp_collection by id
     * @param id Object ID
     * @returns erp_collection Successful operation
     * @throws ApiError
     */
    public static selectErpCollectionById(
        id: string,
    ): CancelablePromise<erp_collection> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/get/object/erp_collection/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Save erp_collection
     * @param requestBody Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static saveErpCollection(
        requestBody?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/save/object/erp_collection',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Delete erp_collection by id
     * @param id Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static deleteErpCollectionById(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/delete/object/erp_collection/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

}
