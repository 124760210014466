import { Injectable } from '@angular/core';
import { Settings } from 'luxon';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(private cookieService: CookieService) {}

  get languageCode() {
    return Settings.defaultLocale;
  }

  changeLanguage(lng: string) {
    console.log('changeLanguage: ' + lng);
    this.cookieService.set('lng', lng, null, '/');
    document.location.replace(`/${lng}`);
  }
}
