import { Injectable } from '@angular/core';
import { IProfileForm, ISaveValue } from '@shared/@vex/layout/toolbar/modules/profile/profile.models';
import { users } from '@src/api';
import moment from 'moment';

@Injectable()
export class ProfileService {
  constructor() {}

  getFormValue(user: users): users {
    return user;
  }

  getEntitySaveValue(user: IProfileForm): ISaveValue {
    if (typeof user.birthday === 'string' || user.birthday instanceof String) {
      user.birthday = moment(user.birthday, 'YYYY-MM-DD');
    }
    return {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      middleName: user.middleName,
      email: user.email,
      birthday: user.birthday?.toISOString(),
      cityName: user.cityName,
    };
  }
}
