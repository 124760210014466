import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutingNames } from '@app/app-routing.module';
import RoutePath from '@app/shared/models/route-path.model';
import { EModules, EPlanogramFeatures } from '@shared/models';
import { PermissionsGuard } from '@shared/services';
import { QuicklinkModule } from 'ngx-quicklink';

export const PlanogramsRoutingNames = {
  recipes: new RoutePath('recipes', AppRoutingNames.planograms.path + '/recipes'),
  productMatrices: new RoutePath('product-matrices', AppRoutingNames.planograms.path + '/product-matrices'),
  ingredients: new RoutePath('ingredients', AppRoutingNames.planograms.path + '/ingredients'),
  products: new RoutePath('products', AppRoutingNames.planograms.path + '/products'),
  prices: new RoutePath('prices', AppRoutingNames.planograms.path + '/prices'),
};

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: PlanogramsRoutingNames.recipes.path,
      },
      {
        path: PlanogramsRoutingNames.productMatrices.name,
        loadChildren: () =>
          import('./modules/product-matrices/product-matrix.module').then((m) => m.ProductMatrixModule),
        canActivate: [PermissionsGuard],
        data: {
          module: EModules.PlanogrammModule,
          feature: EPlanogramFeatures.ProductMatrix,
        },
      },
      {
        path: PlanogramsRoutingNames.ingredients.name,
        loadChildren: () => import('./modules/ingredients/ingredients.module').then((m) => m.IngredientsModule),
        canActivate: [PermissionsGuard],
        data: {
          module: EModules.PlanogrammModule,
          feature: EPlanogramFeatures.Ingredient,
        },
      },
      {
        path: PlanogramsRoutingNames.products.name,
        loadChildren: () => import('./modules/products/products.module').then((m) => m.ProductsModule),
        canActivate: [PermissionsGuard],
        data: {
          module: EModules.PlanogrammModule,
          feature: EPlanogramFeatures.Product,
        },
      },
      {
        path: PlanogramsRoutingNames.recipes.name,
        loadChildren: () => import('./modules/recipes/recipes.module').then((m) => m.RecipesModule),
        canActivate: [PermissionsGuard],
        data: {
          module: EModules.PlanogrammModule,
          feature: EPlanogramFeatures.Recipe,
        },
      },
      {
        path: PlanogramsRoutingNames.prices.name,
        loadChildren: () => import('./modules/prices/prices.module').then((m) => m.PricesModule),
        canActivate: [PermissionsGuard],
        data: {
          module: EModules.PlanogrammModule,
          feature: EPlanogramFeatures.PriceLists,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule, QuicklinkModule],
})
export class Routing {}
