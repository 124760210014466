import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { VendingMachinesRoutingNames } from '@app/pages/vending-machines/vending-machines.routing';
import { IEnumerableItem } from '@core/modules/list-builder/models/grid.models';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { scaleFadeIn400ms } from '@shared/@vex/animations/scale-fade-in.animation';
import { EEntityList, IDeleteEntityItem, IPagingResponsePayload, IServerRequestPayload } from '@shared/models';
import { DialogService } from '@shared/modules/dialogs/services/dialog.service';
import { DataRestService } from '@shared/services';
import { machine_in_group, vending_machine, vm_group } from '@src/api';
import { SaveVMGroup } from '../../models/group.models';

@UntilDestroy()
@Component({
  selector: 'hoho-create-update-group',
  templateUrl: './create-update-group.component.html',
  styleUrls: ['./create-update-group.component.scss'],
  animations: [scaleFadeIn400ms],
})
export class CreateUpdateGroupComponent implements OnInit {
  @HostListener('window:beforeunload', ['$event'])
  formGroup: UntypedFormGroup;
  itemsList: vending_machine[] = [];
  selectedVMList: vending_machine[] = [];
  sourceSelectedVMList: machine_in_group[] = [];
  isSelectView = false;

  pageIndex = 0;
  pageSize = -1;
  totalElements: number;
  isLoading = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public group: { name: string; id: string },
    public dialogRef: MatDialogRef<CreateUpdateGroupComponent>,
    private fb: UntypedFormBuilder,
    private dataRestService: DataRestService,
    private router: Router,
    private dialogService: DialogService,
  ) {}

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      name: [this.group ? this.group.name : '', [Validators.required, Validators.maxLength(24)]],
    });

    this.loadVMList();
  }

  selected(items: (vending_machine | IEnumerableItem)[]): void {
    this.selectedVMList = items as vending_machine[];
    this.changeView();
  }

  changeView() {
    this.isSelectView = !this.isSelectView;
  }

  submitForm(): void {
    if (this.formGroup.invalid || !this.selectedVMList?.length) {
      this.formGroup.markAllAsTouched();
    } else {
      let addItemsList: vending_machine[] = [];
      let delItemsList: string[] = [];
      if (this.sourceSelectedVMList) {
        this.selectedVMList.forEach((element) => {
          if (this.sourceSelectedVMList.find((x) => x.vendingMachine.id == element.id) == null) {
            addItemsList.push(element);
          }
        });
        this.sourceSelectedVMList.forEach((element) => {
          if (this.selectedVMList.find((x) => x.id == element.vendingMachine.id) == null) {
            delItemsList.push(element.id);
          }
        });
      } else {
        addItemsList = this.selectedVMList;
      }

      this.dataRestService
        .save<SaveVMGroup>(
          new SaveVMGroup(this.formGroup.get('name').value, addItemsList, this.group?.id),
          EEntityList.vm_group,
        )
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          if (delItemsList.length > 0) {
            this.dataRestService
              .deleteList(
                delItemsList.map(
                  (i): IDeleteEntityItem => {
                    return {
                      id: i,
                      entity: EEntityList.machine_in_group,
                    };
                  },
                ),
              )
              .pipe(untilDestroyed(this))
              .subscribe(() => {
                this.isLoading = false;
                this.dialogRef.close(true);
              });
          } else {
            this.isLoading = false;
            this.dialogRef.close(true);
          }
        });
    }
  }

  deleteGroup(): void {
    const okCallback = () => {
      this.dataRestService
        .delete(EEntityList.vm_group, this.group.id)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.router.navigate([VendingMachinesRoutingNames.list.path]);
        });
    };
    this.dialogService.openConfirm({
      cancelCallback: null,
      okCallback: okCallback,
      content: $localize`Вы действительно хотите удалить группу? Отменить удаление будет невозможно.`,
    });
  }

  private loadVMGroup(groupId: string): void {
    const paths = ['machines.id', 'machines.vendingMachine.id'];
    this.dataRestService
      .get<vm_group>(EEntityList.vm_group, groupId, paths)
      .pipe(untilDestroyed(this))
      .subscribe((res: vm_group) => {
        this.sourceSelectedVMList = res.machines;
        res.machines.forEach((element) => {
          const vm = this.itemsList.find((x) => x.id == element.vendingMachine.id);
          if (vm) {
            this.selectedVMList.push(vm);
          }
        });
      });
  }

  private loadVMList(): void {
    const payload: IServerRequestPayload = {
      paths: ['id', 'name', 'address', 'nameAndSerial'],
      sort: null,
    };
    this.dataRestService
      .page<vending_machine>(EEntityList.vending_machine, '', this.pageIndex, this.pageSize, payload)
      .pipe(untilDestroyed(this))
      .subscribe(
        (res: IPagingResponsePayload<vending_machine>) => {
          this.totalElements = res.total;
          this.itemsList = res.content;

          if (this.group) {
            this.loadVMGroup(this.group.id);
          }

          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        },
      );
  }
}
