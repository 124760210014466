import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DirectoryRoutingNames } from '@app/pages/directory/directory.routing';
import { ICrumb } from '@app/shared/@vex/components/breadcrumbs/breadcrumbs.component';
import { EDictionariesFeatures, EEntityList, EModules } from '@app/shared/models';
import { DialogService } from '@app/shared/modules/dialogs/services/dialog.service';
import { DataRestService, PermissionsGuard, UserService } from '@app/shared/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CreateUpdateCompaniesComponent } from '../create-update-companies/create-update-companies.component';
import { IAccess } from '@app/core/modules/list-builder/models';
import { company } from '@src/api';
import { paths } from './page.config';
import { MergeCompaniesComponent } from '../merge-companies/merge-companies.component';
import { RegisterMarketplacePointComponent } from '../register-marketplace-point/register-marketplace-point.component';

@UntilDestroy()
@Component({
  selector: 'hoho-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent implements OnInit {
  cID: string;
  isLoading: boolean = false;
  crumbs: ICrumb[] = [
    { label: $localize`Компании`, link: DirectoryRoutingNames.companies.path },
    { label: 'Страница компании' },
  ];
  company: company;
  access: IAccess;
  EModules = EModules;
  EDictionariesFeatures = EDictionariesFeatures;

  constructor(
    private dataRestService: DataRestService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private dialog: MatDialog,
    private userService: UserService,
    public permissionsGuard: PermissionsGuard,
  ) {}

  ngOnInit(): void {
    this.access = this.userService.Access(EEntityList.company);
    this.cID = this.activatedRoute.snapshot.params['id'];
    this.getCompany();
  }

  changeTab(event: number): void {
    this.router.navigate(['.'], { relativeTo: this.activatedRoute, queryParams: { tabIndex: event } });
  }

  getCompany(): void {
    if (this.cID) {
      this.dataRestService
        .get<company>(EEntityList.company, this.cID, paths)
        .pipe(untilDestroyed(this))
        .subscribe((res: company) => {
          this.company = res;
        });
    }
  }

  edit(): void {
    const dialogRef = this.dialog.open(CreateUpdateCompaniesComponent, {
      data: this.company,
      width: '20rem',
    });
    dialogRef.afterClosed().subscribe((isSuccess: boolean) => {
      if (isSuccess) {
        this.getCompany();
      }
    });
  }

  remove(): void {
    const okCallback = () => {
      this.isLoading = true;
      setTimeout(() => {
        if (this.cID) {
          this.dataRestService
            .delete(EEntityList.company, this.cID)
            .pipe(untilDestroyed(this))
            .subscribe(
              () => {
                this.isLoading = false;
                this.router.navigate([DirectoryRoutingNames.companies.path]);
              },
              () => {
                this.isLoading = false;
              },
            );
        }
      }, 1000);
    };
    this.dialogService.openConfirm({
      cancelCallback: null,
      okCallback: okCallback,
      content: $localize`Вы действительно хотите удалить компанию?`,
    });
  }

  openMerge(): void {
    const dialogRef = this.dialog.open(MergeCompaniesComponent, {
      data: this.company,
      width: '20rem',
    });
    dialogRef.afterClosed().subscribe((isSuccess: boolean) => {
      if (isSuccess) {
        openList();
      }
    });


    const openList = async () => {
      this.router.navigate([DirectoryRoutingNames.companies.path]);
    };
  }

  openMarketplace(): void {
    const dialogRef = this.dialog.open(RegisterMarketplacePointComponent, {
      data: this.company,
      width: '100%',
    });
  }
}
