/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { base_entity } from './base_entity';
import type { custom_waypoint } from './custom_waypoint';
import type { object_link } from './object_link';
import type { service_route } from './service_route';
import type { service_route_execution_waypoint } from './service_route_execution_waypoint';
import type { service_route_performer } from './service_route_performer';

/**
 * Исполнение маршрута
 */
export type service_route_execution = (base_entity & {
    /**
     * Идентификатор
     */
    id?: string;
    /**
     * Маршрут
     */
    serviceRoute?: (service_route | object_link);
    /**
     * Начало выполнения
     */
    startOfExecution?: string;
    /**
     * Завершение
     */
    endOfExecution?: string;
    /**
     * Исполнители маршрута
     */
    serviceRoutePerformers?: Array<service_route_performer>;
    /**
     * Стартовая точка маршрута
     */
    startWaypoint?: (custom_waypoint | object_link);
    /**
     * Конечная точка маршрута
     */
    finishWaypoint?: (custom_waypoint | object_link);
    /**
     * Статус
     */
    status?: service_route_execution.status;
    /**
     * Точка выполнения маршрута
     */
    serviceRouteExecutionWaypoints?: Array<service_route_execution_waypoint>;
});

export namespace service_route_execution {

    /**
     * Статус
     */
    export enum status {
        '_1' = 1,
        '_2' = 2,
    }


}

