/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { base_entity } from './base_entity';
import type { object_link } from './object_link';
import type { role } from './role';

/**
 * Ссылка меню на отчет
 */
export type report_menu_item = (base_entity & {
    /**
     * Идентификатор
     */
    id?: string;
    /**
     * Наименование
     */
    readonly name: string;
    /**
     * Порядковый номер
     */
    readonly orderNum: string;
    /**
     * Тип меню
     */
    readonly menuType?: report_menu_item.menuType;
    /**
     * Родительский элемент
     */
    readonly parent?: (report_menu_item | object_link);
    /**
     * Тип отчета
     */
    readonly reportType: report_menu_item.reportType;
    /**
     * Роли, имеющие право доступа
     */
    readonly cRoles?: Array<role>;
    readonly isElected?: boolean;
    readonly electedId?: string;
    readonly search?: string;
});

export namespace report_menu_item {

    /**
     * Тип меню
     */
    export enum menuType {
        '_0' = 0,
        '_1' = 1,
        '_2' = 2,
        '_4' = 4,
        '_5' = 5,
        '_6' = 6,
    }

    /**
     * Тип отчета
     */
    export enum reportType {
        '_0' = 0,
        '_1' = 1,
    }


}

