/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { mailing_error_vending_machine } from '../models/mailing_error_vending_machine';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MailingErrorVendingMachineService {

    /**
     * Select mailing_error_vending_machine by id
     * @param id Object ID
     * @returns mailing_error_vending_machine Successful operation
     * @throws ApiError
     */
    public static selectMailingErrorVendingMachineById(
        id: string,
    ): CancelablePromise<mailing_error_vending_machine> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/get/object/mailing_error_vending_machine/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Save mailing_error_vending_machine
     * @param requestBody Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static saveMailingErrorVendingMachine(
        requestBody?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/save/object/mailing_error_vending_machine',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Delete mailing_error_vending_machine by id
     * @param id Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static deleteMailingErrorVendingMachineById(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/delete/object/mailing_error_vending_machine/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

}
