<footer class="w-full">
  <div
    class="mx-gutter py-4 flex flex-wrap lg:gap-8 gap-3 sm:flex-row flex-col lg:items-center items-center justify-between">
    <div>
      <a class="item footer-link" (click)="openNewAgreementModal()">
        Информация о смене получателя платежа</a>
      <a class="item footer-link" (click)="openTarifPriceIncreaseModal()">
        Новый тариф ERP и мобильного приложения</a>
    </div>
    <a class="item tg-bot sm:order-2 order-first" href="http://t.me/vendig_it_bot" target="_blank">
      <ic-icon class="flex" [icon]="telegram" [inline]="true" height="18px" width="18px"></ic-icon>
      <span class="ml-1">Бот IT-поддержки</span>
    </a>
    <img alt="" src="./assets/img/base/logo_horizontal_black.svg" class="h-8 sm:flex block order-last" />
  </div>
</footer>
