import { Component, forwardRef, Inject, Injector, INJECTOR, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormControlName,
  NG_VALUE_ACCESSOR,
  NgControl,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { getFormValidationErrors } from '@shared/utilities/forms.untils';

@Component({
  selector: 'hoho-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordInputComponent),
      multi: true,
    },
  ],
})
export class PasswordInputComponent implements OnInit, ControlValueAccessor {
  @Input() placeholder: string = null;
  @Input() appearance: 'outline' | 'fill' = 'outline';
  inputType = 'password';
  passwordVisible = false;
  formControl: UntypedFormControl;
  private _currentValue: string;
  get currentValue() {
    return this._currentValue;
  }
  @Input() set currentValue(value: string) {
    this._currentValue = value;
    this.onChange(value);
    this.onTouched();
  }

  constructor(@Inject(INJECTOR) private injector: Injector) {}
  ngOnInit(): void {
    const ngControl = this.injector.get(NgControl) as FormControlName;
    const form = ngControl.formDirective.form as UntypedFormGroup;
    this.formControl = form.controls[ngControl.name] as UntypedFormControl;
  }

  //#region implement ControlValueAccessor interface
  onChange(value: string) {}
  onTouched() {}

  writeValue(value: string): void {
    this._currentValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  //#endregion

  togglePasswordVisibility() {
    if (this.passwordVisible) {
      this.inputType = 'password';
      this.passwordVisible = false;
    } else {
      this.inputType = 'text';
      this.passwordVisible = true;
    }
  }

  getErrorMessage(): string {
    const errors = getFormValidationErrors({
      passwordInput: this.formControl,
    });
    const currentFieldErrors = errors.filter((x) => x.controlName === 'passwordInput');

    return currentFieldErrors?.pop()?.errorDescription;
  }
}
