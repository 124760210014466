/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { report_create_history } from '../models/report_create_history';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReportCreateHistoryService {

    /**
     * Select report_create_history by id
     * @param id Object ID
     * @returns report_create_history Successful operation
     * @throws ApiError
     */
    public static selectReportCreateHistoryById(
        id: string,
    ): CancelablePromise<report_create_history> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/get/object/report_create_history/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Save report_create_history
     * @param requestBody Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static saveReportCreateHistory(
        requestBody?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/save/object/report_create_history',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Delete report_create_history by id
     * @param id Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static deleteReportCreateHistoryById(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/delete/object/report_create_history/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

}
