<mat-dialog-content>
	<p class="text-subtitle-3">Уважаемый клиент</p>
	<div class="mt-5">
		<p class="text-body-4">Чтобы и дальше иметь возможность пользоваться ERP-системой, вам необходимо до 15-го числа
			внести оплату в размере: 
			<span class="text-body-1">{{data.ammount}}</span> руб.
		</p>
	</div>
	<div class="mt-5">
		<button (click)="toPersonalArea()" class="w-full" color="primary" mat-flat-button type="submit">В личный
			кабинет</button>
		<button class="w-full ml-0 mt-3" color="accent" mat-flat-button mat-dialog-close type="submit">Закрыть</button>
	</div>
</mat-dialog-content>