import * as moment from 'moment';

export interface IRangeItem {
  from: string;
  to: string;
}
moment.locale('ru');
export function getMoment(isUtc: boolean = false): moment.Moment {
  return isUtc ? moment() : moment().utcOffset(0, true);
}
export function getCurrentWeekRangeISO(isUtc: boolean = false): IRangeItem {
  return {
    from: moment.utc(getMoment(isUtc).subtract(0, 'weeks').startOf('week')).toISOString(true),
    to: moment.utc(getMoment(isUtc).subtract(0, 'weeks').endOf('week')).toISOString(true),
  };
}
export function getLastWeekRangeISO(isUtc: boolean = false): IRangeItem {
  return {
    from: moment.utc(getMoment(isUtc).subtract(1, 'weeks').startOf('week')).toISOString(true),
    to: moment.utc(getMoment(isUtc).subtract(1, 'weeks').endOf('week')).toISOString(true),
  };
}

export function getYesterdayRangeISO(isUtc: boolean = false): IRangeItem {
  return {
    from: moment.utc(getMoment(isUtc).subtract(1, 'day').startOf('day')).toISOString(true),
    to: moment.utc(getMoment(isUtc).subtract(1, 'day').endOf('day')).toISOString(true),
  };
}

export function getTodayRangeISO(isUtc: boolean = false): IRangeItem {
  return {
    from: moment.utc(getMoment(isUtc).startOf('day')).toISOString(true),
    to: moment.utc(getMoment(isUtc).endOf('day')).toISOString(true),
  };
}

export function getCurrentMonthRangeISO(isUtc: boolean = false): IRangeItem {
  return {
    from: moment.utc(getMoment(isUtc).subtract(0, 'month').startOf('month')).toISOString(true),
    to: moment.utc(getMoment(isUtc).subtract(0, 'month').endOf('month')).toISOString(true),
  };
}
export function getLastMonthRangeISO(isUtc: boolean = false): IRangeItem {
  return {
    from: moment.utc(getMoment(isUtc).subtract(1, 'month').startOf('month')).toISOString(true),
    to: moment.utc(getMoment(isUtc).subtract(1, 'month').endOf('month')).toISOString(true),
  };
}

export function getCurrentYearRangeISO(isUtc: boolean = false): IRangeItem {
  return {
    from: moment.utc(getMoment(isUtc).subtract(0, 'year').startOf('year')).toISOString(true),
    to: moment.utc(getMoment(isUtc).subtract(0, 'year').endOf('year')).toISOString(true),
  };
}
export function getLastYearRangeISO(isUtc: boolean = false): IRangeItem {
  return {
    from: moment.utc(getMoment(isUtc).subtract(1, 'year').startOf('year')).toISOString(true),
    to: moment.utc(getMoment(isUtc).subtract(1, 'year').endOf('year')).toISOString(true),
  };
}

export function getLastWeekRangeWithOffset(isUtc: boolean = false): IRangeItem {
  return {
    from: moment.utc(getMoment(isUtc).subtract(1, 'weeks').startOf('week')).toISOString(false),
    to: moment.utc(getMoment(isUtc).subtract(1, 'weeks').endOf('week')).toISOString(false),
  };
}
