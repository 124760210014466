import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AlertService, DataRestService, PermissionsGuard, UserService } from '@shared/services';
import { EEntityList, EModules, ICurrentUser, IPagingResponsePayload } from '@shared/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tarifPaths } from './personal-area.config';
import {
  PSAgreementModel,
  PSWalletModel,
  PSWalletOperation,
  PaymentCard,
  VmTarif,
} from '@app/shared/models/personal-area.models';
import { PersonalAreaService } from './personal-area-service/personal-area-service';
import { Observable, catchError, combineLatest, map, of } from 'rxjs';
import { PSRestService } from '@app/shared/services/rest/ps-rest.service';
import moment from 'moment';

@UntilDestroy()
@Component({
  selector: 'personal-area',
  templateUrl: './personal-area.component.html',
  styleUrls: ['./personal-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonalAreaComponent {
  user: ICurrentUser;
  paymentCards: PaymentCard[];
  firstDayOfMonth: string;
  firstDayOfNextMonth: string;
  EModules = EModules;

  readonly ADD_CARD_PAYMENT_TYPE: string = this.personalAreaService.ADD_CARD_PAYMENT_TYPE;
  readonly CARD_AUTOPAYMENT_TYPE: number = this.personalAreaService.CARD_AUTOPAYMENT_TYPE;

  constructor(
    private userService: UserService,
    private psRestService: PSRestService,
    private dataRestService: DataRestService,
    private personalAreaService: PersonalAreaService,
    public permissionsGuard: PermissionsGuard,
    private alertService: AlertService,
  ) {
    this.firstDayOfMonth = moment().startOf('month').format('YYYY-MM-DD');
    this.firstDayOfNextMonth = moment().add(1, 'M').startOf('month').format('YYYY-MM-DD');
  }

  ngOnInit() {
    if (!this.permissionsGuard.haveAccess(EModules.PersonalAreaModule)) {
      this.alertService.showError($localize`Доступ запрещен.`);
      return;
    }
    this.getUser();
  }

  getUser(): void {
    this.userService.user$.pipe(untilDestroyed(this)).subscribe((user: ICurrentUser) => {
      if (user && user.wallet) {
        this.user = user;
        this.personalAreaService.setUser(user);
        this.getAllData();
      }
    });
  }

  private getAllData(): void {
    combineLatest([this.getWallet(), this.getAgreement(), this.getVmTarifs(), this.getWalletOperations(), this.getPaymentCards()])
      .pipe(untilDestroyed(this))
      .subscribe(([wallet, agreement, tarifs, walletOperations, paymentCards]) => {
        if (wallet) {
          this.personalAreaService.setWallet(wallet);
          this.getPaymentCards();
        }
        if (paymentCards) {
          this.personalAreaService.setPaymentCards(paymentCards);
        }
        if (agreement) {
          this.personalAreaService.setAgreement(agreement);
        }
        if (tarifs) {
          this.personalAreaService.setTarifs(tarifs);
        }
        if (walletOperations) {
          this.personalAreaService.setWalletOperations(walletOperations);
        }
      });
  }

  getWallet(): Observable<PSWalletModel> {
    return this.psRestService.wallet().pipe(
      untilDestroyed(this),
      catchError(() => of(new PSWalletModel())),
    );
  }

  private getAgreement(): Observable<PSAgreementModel | null> {
    return this.psRestService.agreement().pipe(
      untilDestroyed(this),
      map((res: PSAgreementModel) => {
        return res;
      }),
    );
  }

  getWalletOperations(): Observable<PSWalletOperation[]> {
    return this.psRestService.walletOperations().pipe(
      untilDestroyed(this),
      catchError(() => of(new Array<PSWalletOperation>())),
    );
  }

  getVmTarifs(): Observable<VmTarif[]> {
    const payload = {
      paths: tarifPaths,
      filter: [
        ['companyId', '=', this.user.company.id],
        'and',
        [
          ['calculatedRoyaltyAmount', '>', '0'],
          'or',
          ['calculatedAppAmount', '>', '0'],
          'or',
          ['calculatedErpAmount', '>', '0'],
        ],
      ],
      parameters: [
        {
          name: 'tarifStartDate',
          value: this.firstDayOfMonth,
        },
        {
          name: 'tarifEndDate',
          value: this.firstDayOfNextMonth,
        },
      ],
    };
    return this.dataRestService.page<VmTarif>(EEntityList.vending_machine_coffee, '', 0, -1, payload).pipe(
      untilDestroyed(this),
      map((res: IPagingResponsePayload<VmTarif>) => res.content),
      catchError(() => of([])),
    );
  }

  getPaymentCards(): Observable<PaymentCard[]> {
    return this.psRestService.paymentCards().pipe(
      untilDestroyed(this),
      catchError(() => of(new Array<PaymentCard>())),
    );
  }
}
