import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutingNames } from '@app/app-routing.module';
import { GroupCreateUpdateComponent } from '@app/pages/marketing/components/group-create-update/group-create-update.component';
import { GroupPageComponent } from '@app/pages/marketing/components/group-page/group-page.component';
import { TabsComponent } from '@app/pages/marketing/components/tabs/tabs.component';
import { MarketingComponent } from '@app/pages/marketing/marketing.component';
import RoutePath from '@app/shared/models/route-path.model';
import { QuicklinkModule } from 'ngx-quicklink';

export const MarketingRoutingNames = {
  lists: new RoutePath('lists', AppRoutingNames.marketing.path, ':tab'),
  push: new RoutePath('push', AppRoutingNames.marketing.path + '/lists/push'),
  group: new RoutePath('group', AppRoutingNames.marketing.path + '/lists/group'),
  stories: new RoutePath('stories', AppRoutingNames.marketing.path + '/lists/stories'),
  groupPage: new RoutePath('page', AppRoutingNames.marketing.path + '/lists/group/page', ':id'),
  groupCreate: new RoutePath('create', AppRoutingNames.marketing.path + '/lists/group/create'),
  groupUpdate: new RoutePath('update', AppRoutingNames.marketing.path + '/lists/group/update', ':id'),
};

const routes: Routes = [
  {
    path: '',
    component: MarketingComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: MarketingRoutingNames.push.path,
      },
      {
        path: MarketingRoutingNames.lists.name + '/' + MarketingRoutingNames.lists.routeParams,
        component: TabsComponent,
      },
      {
        path:
          MarketingRoutingNames.lists.name +
          '/' +
          MarketingRoutingNames.group.name +
          '/' +
          MarketingRoutingNames.groupPage.name +
          '/' +
          MarketingRoutingNames.groupPage.routeParams,
        component: GroupPageComponent,
      },
      {
        path:
          MarketingRoutingNames.lists.name +
          '/' +
          MarketingRoutingNames.group.name +
          '/' +
          MarketingRoutingNames.groupCreate.name,
        component: GroupCreateUpdateComponent,
      },
      {
        path:
          MarketingRoutingNames.lists.name +
          '/' +
          MarketingRoutingNames.group.name +
          '/' +
          MarketingRoutingNames.groupUpdate.name + '/' +
          MarketingRoutingNames.groupUpdate.routeParams,
        component: GroupCreateUpdateComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule, QuicklinkModule],
})
export class MarketingRouting {}
