import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PageLayoutComponent } from './page-layout.component';
import { PageLayoutHeaderDirective } from './page-layout-header.directive';
import { PageLayoutContentDirective } from './page-layout-content.directive';
import { HeaderComponent } from '@shared/@vex/components/page-layout/header/header.component';
import { BreadcrumbsModule } from '@shared/@vex/components/breadcrumbs/breadcrumbs.module';
import { SecondaryHeaderComponent } from './secondary-header/secondary-header.component';
import { SecondaryToolbarModule } from '@shared/@vex/components/secondary-toolbar/secondary-toolbar.module';

@NgModule({
  imports: [CommonModule, BreadcrumbsModule, SecondaryToolbarModule, MatProgressSpinnerModule],
  declarations: [
    PageLayoutComponent,
    PageLayoutHeaderDirective,
    PageLayoutContentDirective,
    HeaderComponent,
    SecondaryHeaderComponent,
  ],
  exports: [
    PageLayoutComponent,
    PageLayoutHeaderDirective,
    PageLayoutContentDirective,
    HeaderComponent,
    SecondaryHeaderComponent,
  ],
})
export class PageLayoutModule {}
