<hoho-header i18n-title title="Обратная связь"></hoho-header>
<div class="mt-5">

  <hoho-feature-tabs
    [module]="EModules.FeedbackModule"
    [activeFeature]="activeFeature"
    [tabsList]="tabsContentList"
    (changeTab)="changeTab($event)"
  >
  </hoho-feature-tabs>

  <ng-template #list>
    <hoho-list #listComponent class="block mt-5 px-gutter"></hoho-list>
  </ng-template>

  <ng-template #dashboard>
    <hoho-dashboard #dashboardComponent class="block mt-5 px-gutter"></hoho-dashboard>
  </ng-template>

  <ng-template #reviews>
    <hoho-reviews #reviewsComponent class="block mt-5 px-gutter"></hoho-reviews>
  </ng-template>
</div>
