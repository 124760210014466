/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { base_entity } from './base_entity';
import type { object_link } from './object_link';
import type { service_route_execution } from './service_route_execution';
import type { users } from './users';

/**
 * Исполнитель маршрута
 */
export type service_route_performer = (base_entity & {
    /**
     * Идентификатор
     */
    id?: string;
    /**
     * Пользователи системы
     */
    user?: (users | object_link);
    /**
     * Статус
     */
    status?: service_route_performer.status;
    /**
     * Маршрут
     */
    serviceRoute?: (service_route_execution | object_link);
});

export namespace service_route_performer {

    /**
     * Статус
     */
    export enum status {
        '_0' = 0,
        '_1' = 1,
    }


}

