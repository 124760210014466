import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'hoho-password-with-repeat',
  templateUrl: './password-with-repeat.component.html',
  styleUrls: ['./password-with-repeat.component.scss'],
})
export class PasswordWithRepeatComponent implements OnInit, OnDestroy {
  @Input() mainPasswordControl: AbstractControl = null;
  @Input() repeatPasswordControl: AbstractControl = null;
  @Input() mainPasswordPlaceholder: string = $localize`Придумайте пароль`;
  @Input() repeatPasswordPlaceholder: string = $localize`Повторите пароль`;
  @Input() appearance: 'fill' | 'outline' = 'outline';
  formGroup: UntypedFormGroup;
  mainPasswordControlName: string;
  repeatPasswordControlName: string;
  private subscriptions: Subscription[] = [];

  constructor() {}
  ngOnInit(): void {
    this.formGroup = this.mainPasswordControl.parent as UntypedFormGroup;
    this.mainPasswordControlName = this.getControlName(this.mainPasswordControl);
    this.repeatPasswordControlName = this.getControlName(this.repeatPasswordControl);

    this.subscriptions.push(
      this.mainPasswordControl.valueChanges.pipe(untilDestroyed(this)).subscribe((_) => {
        this.validateRepeatPassword();
      }),
    );
    this.subscriptions.push(
      this.repeatPasswordControl.valueChanges.pipe(untilDestroyed(this)).subscribe((_) => {
        this.validateRepeatPassword();
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }

  getControlName(control: AbstractControl): string {
    return Object.keys(this.formGroup.controls).find((name) => control === this.formGroup.get(name)) || null;
  }

  validateRepeatPassword() {
    if (!this.mainPasswordControl || !this.repeatPasswordControl) {
      return;
    }

    if (this.mainPasswordControl.value != this.repeatPasswordControl.value) {
      this.repeatPasswordControl.setErrors({
        repeatPasswordNotEquals: true,
      });
    } else {
      this.repeatPasswordControl.setErrors(null);
    }
  }
}
