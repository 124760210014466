import { Moment } from 'moment';

export interface IDateTypeListItem {
  type: EMultiTypes;
  name?: string;
  value: Moment | IDatePeriod;
}

export enum EMultiTypes {
  certainDatePicker,
  yearDatePicker,
  monthDatePicker,
  rangeDatePicker,
}

export interface IDatePeriod {
  to?: Moment;
  from?: Moment;
}
