<mat-form-field
  [class.mat-form-field-invalid]="submitted && !ngModel"
  class="w-full"
  [appearance]="appearance"
  (click)="show = !show"
  [matMenuTriggerFor]="menu"
>
  <mat-label>{{ placeholder }}</mat-label>
  <input #input [ngModel]="ngModel" class="cursor-pointer" readonly matInput />
  <hoho-icon iconName="calender" matSuffix></hoho-icon>
  <mat-hint class="mat-error" *ngIf="submitted && !ngModel">Поле обязательно для заполнения</mat-hint>
</mat-form-field>

<mat-menu #menu>
  <div class="px-4 bg-natasha z-10 custom-space" (click)="$event.preventDefault(); $event.stopPropagation()">
    <div class="w-full bg-arizona rounded-xl p-1 flex mb-2 h-9">
      <div
        class="flex-grow cursor-pointer p-1 rounded-md text-center whitespace-nowrap text-body-2"
        [class.bg-natasha]="!time"
        [class.text-california]="time"
        (click)="time = !time"
        i18n
      >
        Дата
      </div>
      <div
        class="flex-grow cursor-pointer p-1 rounded-md text-center whitespace-nowrap text-body-2"
        [class.bg-natasha]="time"
        [class.text-california]="!time"
        (click)="time = !time"
        i18n
      >
        Время
      </div>
    </div>

    <div *ngIf="!time">
      <mat-calendar [headerComponent]="headerCalendarComponent" [minDate]="minDate" (selectedChange)="changeDate()" [(selected)]="selectedDate"></mat-calendar>
    </div>
    <div *ngIf="time">
      <hoho-minutes-hour
        [hours]="hours"
        (hoursChange)="hours = $event; changeTime()"
        [minutes]="minutes"
        (minutesChange)="minutes = $event; changeTime()"
      ></hoho-minutes-hour>
    </div>
  </div>
</mat-menu>
