<vex-page-layout>
  <hoho-header i18n-title title="Заявки"></hoho-header>
  <p class="text-body-2 mt-4 px-gutter">
    Всего {{ (userRequestService.requests$ | async)?.newCount }} неподтверждённых пользователей
  </p>

  <hoho-feature-tabs
    [module]="EModules.UserRequestModule"
    [activeFeature]="activeFeature"
    [tabsList]="tabsContentList"
    (changeTab)="changeTab($event)"
  >
  </hoho-feature-tabs>

  <ng-template #newTemp>
    <hoho-list-builder
      [context]="'new'"
      [multiselect]="false"
      class="mt-5 block px-gutter"
      [props]="listBuilderProps"
      [requestPayload]="requestPayloadNew"
      [noLink]="true"
      [access]="userRequestAccess"
    ></hoho-list-builder>
  </ng-template>

  <ng-template #blockTemp>
    <hoho-list-builder
      [context]="'blocked'"
      [multiselect]="false"
      class="mt-5 block px-gutter"
      [props]="listBuilderProps"
      [requestPayload]="requestPayloadBlocked"
      [noLink]="true"
      [access]="userRequestAccess"
    ></hoho-list-builder>
  </ng-template>

  <ng-template #unconfirmedTemp>
    <hoho-list-builder
      [context]="'unconfirmed'"
      [multiselect]="false"
      class="mt-5 block px-gutter"
      [props]="listBuilderProps"
      [requestPayload]="requestPayloadUnconfirmed"
      [noLink]="true"
      [access]="userRequestAccess"
    ></hoho-list-builder>
  </ng-template>
</vex-page-layout>
