import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hoho-grid-cards',
  templateUrl: './grid-cards.component.html',
  styleUrls: ['./grid-cards.component.scss'],
})
export class GridCardsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
