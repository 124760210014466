import { Injectable } from '@angular/core';
import { PSAgreementModel, PSInvoice, PSWalletModel, PSWalletOperation, PaymentCard } from '@app/shared/models/personal-area.models';
import { HttpService } from '@shared/services/http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PSRestService {
  constructor(private http: HttpService) {}

  wallet(): Observable<PSWalletModel> {
    return this.http.post<PSWalletModel>(
      `ps/wallet`
    );
  }

  paymentCards(): Observable<PaymentCard[]> {
    return this.http.post<PaymentCard[]>(
      `ps/paymentcards`
    );
  }

  agreement(): Observable<PSAgreementModel> {
    return this.http.post<PSAgreementModel>(
      `ps/agreement`
    );
  }

  invoices(): Observable<PSInvoice[]> {
    return this.http.post<PSInvoice[]>(
      `ps/invoices`
    );
  }

  walletOperations(): Observable<PSWalletOperation[]> {
    return this.http.post<PSWalletOperation[]>(
      `ps/walletoperations`
    );
  }
}
