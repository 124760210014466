import { EquipmentStateEnum } from './enums/equipment-state.enum';
import { Injectable } from '@angular/core';
import { CardTitleStatusEnum } from './enums/card-title-status.enum';
import { EVendingMachineContextsList } from '@shared/models';
import { CommunicationLevelEnum } from './enums/communication-level.enum';
import { FillingLevelEnum } from './enums/filling-level.enum';
import { MachineStateEnum } from './enums/machine-state.enum';
import { IEquipmentCardModel, IInfoCardModel, IPlacementCardModel } from './models/card.models';

@Injectable()
export class VendingMachineCardService {
  constructor() {}

  getTitleStatus(
    model: IPlacementCardModel | IInfoCardModel | IEquipmentCardModel,
    cardType: EVendingMachineContextsList,
  ): CardTitleStatusEnum {
    if (cardType === EVendingMachineContextsList.state) {
      const cardModel = model as IEquipmentCardModel;

      const notWorkEquipmentCount = cardModel.equipmentStates.filter((x) => x.state == EquipmentStateEnum.HasErrors)
        .length;
      if (
        cardModel.machineState === MachineStateEnum.Off ||
        cardModel.communicationLevel === CommunicationLevelEnum.NoConnection ||
        cardModel.communicationLevel === CommunicationLevelEnum.LowConnectionLevel ||
        notWorkEquipmentCount > 2
      ) {
        return CardTitleStatusEnum.Error;
      } else if (
        cardModel.machineState === MachineStateEnum.NotStable ||
        cardModel.communicationLevel === CommunicationLevelEnum.ConnectionEstablished ||
        notWorkEquipmentCount > 0
      ) {
        return CardTitleStatusEnum.Attention;
      } else {
        return CardTitleStatusEnum.Accept;
      }
    } else if (cardType === EVendingMachineContextsList.info) {
      const cardModel = model as IInfoCardModel;
      if (
        cardModel.machineState === MachineStateEnum.Off ||
        cardModel.ingredientsFillingLevel === FillingLevelEnum.Empty
      ) {
        return CardTitleStatusEnum.Error;
      } else if (
        cardModel.machineState === MachineStateEnum.NotStable ||
        cardModel.ingredientsFillingLevel === FillingLevelEnum.HalfFull
      ) {
        return CardTitleStatusEnum.Attention;
      } else {
        return CardTitleStatusEnum.Accept;
      }
    } else if (cardType === EVendingMachineContextsList.list) {
      const cardModel = model as IPlacementCardModel;
      if (cardModel.machineState === MachineStateEnum.Off) return CardTitleStatusEnum.Error;
      if (cardModel.machineState == MachineStateEnum.NotStable) return CardTitleStatusEnum.Attention;
      else return CardTitleStatusEnum.Accept;
    }
  }
}
