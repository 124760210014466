<mat-dialog-content>
  <div>
    <p class="text-subtitle-3">Информация о смене получателя платежа за предоставление права доступа и использования
      мобильного приложения и ERP-системы:</p>
    <div class="mt-5" style="padding-bottom: 100px;">
      <div>
        <b>Уважаемый партнер,</b><br>
        настоящим уведомляем Вас о том, что в связи с заключением между ООО «ХОХОРО ДИДЖИТАЛ» и АО «Вендинг будущего»
        договора о передаче прав на использование программного обеспечения (ERP-система (телеметрия) и мобильное
        приложение), в том числе прав по подключению клиентов и взимания платы за использование программного
        обеспечения,
        с 1 мая произошли следующие изменения: <br><br>

        <b>1. Смена получателя платежа на</b> Акционерное общество «Вендинг будущего»<br><br>

        <b>2. Изменение платежных реквизитов:</b><br>
        Получатель платежа: Акционерное общество «Вендинг будущего»<br>
        - ИНН: 9719034830<br>
        - КПП: 771901001<br>
        Банковские реквизиты:<br>
        - Корреспондентский счет: 30101810200000000593<br>
        - Расчетный счет: 40702810002340003485<br>
        - Банк: АО "АЛЬФА-БАНК"<br>
        - БИК: 044525593<br><br>

        Что это означает для вас:<br><br>

        1. Все существующие условия и положения Вашей лицензии на использование мобильного приложения и ERP-системы
        остаются в силе.<br>
        2. Все платежи за использование мобильного приложения и ERP-системы будут направляться новому получателю платежа
        по указанным выше банковским реквизитам автоматически.<br>
        3. В случае возникновения вопросов или необходимости получения дополнительной информации, пожалуйста, свяжитесь
        с Вашим персональным менеджером. <br><br>

        Мы благодарим вас за понимание и надеемся на дальнейшее успешное сотрудничество.<br><br>

        С уважением, Общество с ограниченной ответственностью «ХОХОРО ДИДЖИТАЛ» и Акционерное общество «Вендинг
        будущего»
      </div>
    </div>

    <div class="bg-white sticky w-full" style="padding-bottom: 20px; bottom: -1px;">
      <button (click)="saveToLocalStorage()" class="w-full" color="primary" mat-flat-button type="submit"
        i18n>Ознакомился</button>
    </div>
  </div>
</mat-dialog-content>
