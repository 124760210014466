/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { service_route_waypoint } from '../models/service_route_waypoint';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ServiceRouteWaypointService {

    /**
     * Select service_route_waypoint by id
     * @param id Object ID
     * @returns service_route_waypoint Successful operation
     * @throws ApiError
     */
    public static selectServiceRouteWaypointById(
        id: string,
    ): CancelablePromise<service_route_waypoint> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/get/object/service_route_waypoint/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Save service_route_waypoint
     * @param requestBody Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static saveServiceRouteWaypoint(
        requestBody?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/save/object/service_route_waypoint',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Delete service_route_waypoint by id
     * @param id Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static deleteServiceRouteWaypointById(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/delete/object/service_route_waypoint/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

}
