<hoho-secondary-header [crumbs]="crumbs" *ngIf="!isLoading" [title]="fio">
</hoho-secondary-header>
<div class="mx-gutter mt-5">
  <ng-container *ngIf="!isLoading && user">
    <div>
      <div class="rounded-xl bg-shpongle p-3 mb-5">
        <div class="text-subtitle-3" i18n>Основная информация</div>
      </div>
      <div class="grid lg:grid-cols-2 lg:grid-rows-1 md:grid-cols-1 md:grid-rows-2 lg:gap-4 ml-3">
        <!--ФИО-->
        <div class="pb-4 mb-4 border-b border-arizona">
          <div class="text-body-5 mb-2 text-green-4" i18n>ФИО</div>
          <div>{{fio}}</div>
        </div>
        <!--Email-->
        <div class="pb-4 mb-4 border-b border-arizona">
          <div class="text-body-5 mb-2 text-green-4" i18n>Email</div>
          <div i18n>{{user.email}}</div>
        </div>
      </div>
      <div class="grid lg:grid-cols-2 lg:grid-rows-1 md:grid-cols-1 md:grid-rows-2 lg:gap-4 ml-3">
        <!--Компания-->
        <div class="pb-4 mb-4 border-b border-arizona">
          <div class="text-body-5 mb-2 text-green-4" i18n>Компания</div>
          <div i18n>{{$company(user.company)?.name}}</div>
        </div>
        <!--Дата регистрации-->
        <div class="pb-4 mb-4 border-b border-arizona">
          <div class="text-body-5 mb-2 text-green-4" i18n>Дата регистрации</div>
          <div i18n>{{user.registerDate |date}}</div>
        </div>
      </div>
      <div class="grid lg:grid-cols-2 lg:grid-rows-1 md:grid-cols-1 md:grid-rows-2 lg:gap-4 ml-3">
        <!--ИНН компании-->
        <div class="pb-4 mb-4 border-b border-arizona">
          <div class="text-body-5 mb-2 text-green-4" i18n>ИНН компании</div>
          <div i18n>{{$company(user.company)?.orgCode}}</div>
        </div>
        <!--Роль-->
        <div class="pb-4 mb-4 border-b border-arizona">
          <div class="text-body-5 mb-2 text-green-4" i18n>Роль</div>
          <div i18n>{{user.rolesText}}</div>
        </div>
      </div>
      <div class="grid lg:grid-cols-2 lg:grid-rows-1 md:grid-cols-1 md:grid-rows-1 lg:gap-4 ml-3">
        <!--Телефон-->
        <div class="pb-4 mb-4 border-b border-arizona">
          <div class="text-body-5 mb-2 text-green-4" i18n>Телефон</div>
          <div i18n>{{user.phoneNumber}}</div>
        </div>
      </div>
      <div class="text-body-5 mb-4 text-green-4 mx-3 " i18n>Управление</div>
      <div class="grid lg:flex md:grid-cols-2 md:grid-rows-1 gap-4 ml-3">
        <hoho-button [ngClass]="!access?.update ? 'disabled' : ''" (click)="access?.update && edit()" iconName="edit" color="margaret" i18n>Редактировать</hoho-button>
        <hoho-button class="lg:w-40" [ngClass]="!access?.update ? 'disabled' : ''" *ngIf="user.active" (click)="access?.update && block()" iconName="trash-alt" color="barbara" i18n>Заблокировать</hoho-button>
        <hoho-button class="lg:w-40" [ngClass]="!access?.update ? 'disabled' : ''" *ngIf="!user.active" (click)="access?.update && unblock()" iconName="trash-alt" color="tiffany" i18n>Разблокировать</hoho-button>
      </div>
    </div>
  </ng-container>
</div>
