<hoho-create-update-dialog
  *ngIf="!selectRecipientsView && formGroup"
  [formGroup]="formGroup"
  [entityType]="EEntityList.auto_report"
  [deleteEntityList]="deleteEntityList"
  (afterSave)="afterSave()"
  (submitted)="submit()"
  [title]="title"
>
  <hoho-info-snippet class="mb-4 block" i18n>
    В созданной группе отображается выбранный вами список автоматов.
  </hoho-info-snippet>
  <div class="grid grid-cols-1 sm:grid-cols-1">
    <hoho-select
      class="w-full"
      [items]="reportLinksList"
      [submitted]="submitted"
      appendTo="body"
      [bindValue]="null"
      bindLabel="name"
      appearance="fill"
      [control]="reportCtrl"
      [addTag]="false"
      placeholder="Выберите отчет"
      i18n-placeholder
    >
      <mat-error *ngIf="reportCtrl.invalid">{{ getErrorMessage('report', formGroup) }}</mat-error>
    </hoho-select>

    <hoho-time-period placeholder="Выберите период и время отправки" i18n-placeholder [periodicity]="periodicityCtrl" [days]="daysCtrl" [sendTime]="sendTimeCtrl"></hoho-time-period>

    <button class="sm:mt-0 mt-3 w-full" color="accent" mat-flat-button type="button" (click)="selectRecipients()">
      <ng-container *ngIf="!formGroup.get('recipients').value.length" i18n> Выбрать получателей </ng-container>
      <ng-container *ngIf="formGroup.get('recipients').value.length">
        <ng-container>
          {{
            formGroup.get('recipients').value.length
              | i18nPlural: { '=1': 'Выбран', 'few': 'Выбрано', 'other': 'Выбрано' }
          }}
        </ng-container>
        {{ formGroup.get('recipients').value.length }}
        <ng-container>
          {{
            formGroup.get('recipients').value.length
              | i18nPlural: { '=1': 'получатель', 'few': 'получателя', 'other': 'получателей' }
          }}
        </ng-container>
      </ng-container>
    </button>
    <div class="h-4 ml-3.5 mb-2 relative">
      <mat-error
        class="absolute"
        name="periodicityError"
        *ngIf="formGroup.get('recipients').errors?.required && submitted"
        i18n
      >
        Небходимо добавить получателей
      </mat-error>
    </div>
  </div>
</hoho-create-update-dialog>
<ng-container *ngIf="selectRecipientsView">
  <div class="dialog-container">
    <div class="flex">
      <hoho-icon class="mr-3 cursor-pointer" iconName="angle-left-b" (click)="selectRecipientsView = false"></hoho-icon>
      <div class="text-subtitle-3 mb-4">{{ title }}</div>
    </div>
    <mat-form-field class="w-full sm:mr-4 flex-grow" [ngClass]="{'-mb-2': !emailCtrl.invalid}">
      <mat-label i18n>Введите эл.почту и нажмите ввод</mat-label>
      <input [formControl]="emailCtrl" (keydown.enter)="addRecipientItem()" matInput />
      <mat-error *ngIf="emailCtrl.invalid">Введите валидную эл.почту</mat-error>
    </mat-form-field>
    <div class="flex gap-4 mb-4 flex-wrap">
      <div class="bg-margaret text-natasha rounded-md p-1.5 flex" *ngFor="let item of recipients; let i = index">
        <div class="flex items-center justify-center pr-1.5" (click)="removeRecipientItem(i)">
          <hoho-icon iconName="multiply"></hoho-icon>
        </div>
        {{ item }}
      </div>
    </div>
    <button
      class="sm:mt-0 mt-3 w-full mb-4"
      color="accent"
      [disabled]="!recipients.length"
      mat-flat-button
      type="button"
      (click)="applyRecipients(); selectRecipientsView = false"
      i18n
    >
      Применить
    </button>
  </div>
</ng-container>
