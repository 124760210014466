import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IPagingResponsePayload, IServerRequestPayload } from '@shared/models';
import { EEntityList } from '@shared/models/entity.models';
import { DataRestService } from '@shared/services';
import { ControlsOf, getErrorMessage } from '@shared/utilities/forms.untils';
import { object_link, role, user_roles, users } from '@src/api';

@UntilDestroy()
@Component({
  selector: 'hoho-modal-roles',
  templateUrl: './create-update-role.component.html',
  styleUrls: ['./create-update-role.component.scss'],
})
export class CreateUpdateRoleComponent implements OnInit {
  EEntityList = EEntityList;
  title: string;
  submitted: boolean = false;
  formGroup = this.fb.group({
    id: [null],
    name: [null, [Validators.required, Validators.maxLength(24)]],
    roleUsers: this.fb.array([]),
  });
  selectUsersView = false;
  users: users[];
  selectedItems: users[] = [];
  getErrorMessage = getErrorMessage;
  constructor(
    @Inject(MAT_DIALOG_DATA) protected entity: role,
    private dialogRef: MatDialogRef<CreateUpdateRoleComponent>,
    private fb: UntypedFormBuilder,
    private dataRestService: DataRestService,
  ) {}

  ngOnInit() {
    if (this.entity) {
      this.formGroup.patchValue(this.entity);
      this.title = 'Редактировать роль';
    } else {
      this.title = 'Добавить роль';
    }
    this.loadUsers();
  }

  roleUserControl(userId: string, roleUserId: string): FormGroup<ControlsOf<user_roles>> {
    return new FormGroup<ControlsOf<user_roles>>({
      id: new FormControl(roleUserId),
      entity: new FormControl(EEntityList.user_roles),
      user: new FormGroup<ControlsOf<users | object_link>>({
        id: new FormControl(userId),
        entity: new FormControl(EEntityList.users),
      }),
    });
  }

  afterSave(): void {
    this.dialogRef.close(true);
  }

  submittedEvent(): void {
    this.submitted = true;
  }

  get usersControl(): UntypedFormArray {
    return this.formGroup.get('roleUsers') as UntypedFormArray;
  }

  userSelected(users: users[]) {
    this.usersControl.clear();
    users.forEach((i) => {
      let roleUser = this.entity?.roleUsers.find((item) => item.user.id == i.id);
      this.usersControl.push(this.roleUserControl(i.id, roleUser?.id));
    });
    this.selectedItems = users;
    this.selectUsersView = false;
  }

  loadUsers() {
    const payload: IServerRequestPayload = {
      paths: ['id', 'displayName', 'company.name'],
    };
    this.dataRestService
      .page<users>(EEntityList.users, null, 0, 200, payload)
      .pipe(untilDestroyed(this))
      .subscribe((res: IPagingResponsePayload<users>) => {
        this.users = res.content;
        if (this.entity) {
          const userIds: string[] = this.entity.roleUsers.map((i) => i.user.id.toString());
          this.userSelected(this.users.filter((i) => !!userIds.find((j) => j === i.id)));
        }
      });
  }
}
