import { users } from '@src/api';
import { EEntityList, EntityModel, IVendingMachineBase } from '.';

export enum EServiceRouteStatus {
  NotStarted,
  InJob,
  Completed,
}

export enum EServiceRouteWaypointStatus {
  NotStarted,
  InJob,
  Completed,
}

export class ServiceRouteWaypointModel extends EntityModel {
  vendingMachine: IVendingMachineBase;
  orderNumber: number;
  serviceTime: number;
  status?: EServiceRouteWaypointStatus;
  name?: string;
  constructor(value?: ServiceRouteWaypointModel) {
    super();
    this.vendingMachine = value?.vendingMachine ? value.vendingMachine : new IVendingMachineBase();
    this.orderNumber = value?.orderNumber;
    this.serviceTime = value?.serviceTime;
    this.status = value?.status;
    this.name = value?.name;
  }
}

export interface ICompleteWaypoint {
  waypointId: string;
  collection: {
    actualAmountVm: number;
    collectedAmount: number;
    loadedForChange: number;
    unloadedFromChange: number;
  };
  endOfExecution: string;
  startOfExecution: string;
  ingredientFill: {
    items: { ingredientId: number; loadedAmount: number }[];
  };
}
export interface ILoadIngredients {
  entity: EEntityList.erp_ingredient_fill;
  vendingMachine: {
    entity: EEntityList.vending_machine;
    id: string;
  };
  erpIngredientFillItems: {
    entity: EEntityList.erp_ingredient_fill_item;
    ingredient: {
      entity: EEntityList.ingredient;
      id: number;
    };
    loadedAmount: number;
  }[];
}

export interface IProductMatrixApply {
  productMatrixId: string;
  vendingMachineIds: string[];
}

export interface IChangeClientBlock {
  clientId: string | number;
  isBlocked: boolean;
}