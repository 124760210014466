import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { PopoverComponent } from './popover.component';

@NgModule({
  declarations: [PopoverComponent],
  imports: [CommonModule, OverlayModule],
})
export class PopoverModule {}
