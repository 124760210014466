import { SafeHtmlPipe } from '@shared/@vex/pipes/safe-html.pipe';
import { SecondsToStringPipe } from './pipes/seconds-to-string.pipe';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { NumericWithCurrencyPipe } from './pipes/numeric.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { LayoutModule } from './layout/layout.module';
import { AsPipe } from './pipes/as.pipe';
import { VmEquipmentStatePipe } from './pipes/vm-equipment-state.pipe';
import { HtmlToPlaintextPipe } from './pipes/html-to-plaintext.pipe';
import { MinutesToHoursPipe } from './pipes/minutes-to-hours.pipe';
import { IngredientUnitPipe } from '@shared/@vex/pipes/ingredint-unit.pipe';
import { BytesPipe } from '@shared/@vex/pipes/bytes.pipe';

@NgModule({
  declarations: [
    NumericWithCurrencyPipe,
    SearchFilterPipe,
    SecondsToStringPipe,
    SafeHtmlPipe,
    AsPipe,
    VmEquipmentStatePipe,
    HtmlToPlaintextPipe,
    MinutesToHoursPipe,
    IngredientUnitPipe,
    BytesPipe,
  ],
  imports: [CommonModule, LayoutModule],
  exports: [
    LayoutModule,
    MinutesToHoursPipe,
    NumericWithCurrencyPipe,
    SearchFilterPipe,
    SecondsToStringPipe,
    SafeHtmlPipe,
    AsPipe,
    VmEquipmentStatePipe,
    HtmlToPlaintextPipe,
    IngredientUnitPipe,
    BytesPipe,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill',
      } as MatFormFieldDefaultOptions,
    },
  ],
})
export class VexModule {}
