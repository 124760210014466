/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { base_entity } from './base_entity';
import type { object_link } from './object_link';
import type { report_link } from './report_link';

/**
 * Авто отчет
 */
export type auto_report = (base_entity & {
    /**
     * Идентификатор
     */
    id?: string;
    recipients?: any;
    /**
     * Тип периодичности
     */
    periodicity: auto_report.periodicity;
    /**
     * Отчет
     */
    report: (report_link | object_link);
    /**
     * Время отправки
     */
    sendTime: string;
    readonly search?: string;
    /**
     * Дни недели
     */
    days: auto_report.days;
    readonly recipientsText?: string;
});

export namespace auto_report {

    /**
     * Тип периодичности
     */
    export enum periodicity {
        '_0' = 0,
        '_1' = 1,
    }

    /**
     * Дни недели
     */
    export enum days {
        '_1' = 1,
        '_2' = 2,
        '_4' = 4,
        '_8' = 8,
        '_16' = 16,
        '_32' = 32,
        '_64' = 64,
    }


}

