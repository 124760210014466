import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { EEntityList } from '@shared/models/entity.models';
import { ControlsOf, getErrorMessage } from '@shared/utilities/forms.untils';
import { feedback_note } from '@src/api';

@UntilDestroy()
@Component({
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.scss'],
})


export class AddNoteComponent implements OnInit {
  EEntityList = EEntityList;
  title: string;
  submitted: boolean = false;
  formGroup = new FormGroup<ControlsOf<feedback_note>>({
    entity: new FormControl(EEntityList.feedback_note),
    feedback: new FormControl(null),
    users: new FormControl(null),
    text: new FormControl(null, [Validators.required, Validators.maxLength(250)]),
  });
  getErrorMessage = getErrorMessage;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: {
      feedback: feedback_note;
      userId: string;
    },
    private dialogRef: MatDialogRef<AddNoteComponent>,
    private fb: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    this.title = $localize`Добавить примечание`;
    this.formGroup.get('feedback').patchValue({
      id: this.data.feedback.id,
      entity: EEntityList.feedback,
    });
    this.formGroup.get('users').patchValue({
      id: this.data.userId,
      entity: EEntityList.users,
    });
  }

  afterSave(): void {
    this.dialogRef.close(this.formGroup.get('text').value);
  }

  submittedEvent(): void {
    this.submitted = true;
  }
}