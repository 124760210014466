import { IDeleteEntityItem } from '@shared/models';

export const deepClone = (obj: Object) => {
  return JSON.parse(JSON.stringify(obj));
};

export const deepEqual = (a: Object, b: Object) => {
  return JSON.stringify(a) === JSON.stringify(b);
};

export const getCollectionDeleteEntities = (initEntity: any, entity: any, path: string = ''): IDeleteEntityItem[] => {
  if (
    !(
      initEntity &&
      typeof initEntity === 'object' &&
      !Array.isArray(initEntity) &&
      entity &&
      typeof entity === 'object'
    )
  ) {
    return [...[]];
  }
  let tmpDeletedItems: IDeleteEntityItem[] = [];
  for (const [initKey, initValue] of Object.entries(initEntity)) {
    if (Array.isArray(initValue) && Array.isArray(entity[initKey])) {
      for (let i = 0; i < initValue.length; i++) {
        const item = initValue[i];
        const isFound = findInCollection(item, entity[initKey]);
        if (!isFound && item.entity) {
          const deleteItem = {
            id: item.id,
            entity: item.entity,
            path: path ? `${path}.${initKey}` : `${initKey}`,
          } as IDeleteEntityItem;
          tmpDeletedItems = [...tmpDeletedItems, ...[deleteItem]];
        }
      }
    }
    if (initValue && typeof initValue === 'object' && entity[initKey] && typeof entity[initKey] === 'object') {
      tmpDeletedItems = [
        ...tmpDeletedItems,
        ...getCollectionDeleteEntities(initValue, entity[initKey], path ? `${path}.${initKey}` : `${initKey}`),
      ];
    }
  }
  return tmpDeletedItems;
};

const findInCollection = (entity: any, collection: any[]): boolean => {
  return !!collection.find((item) => entity.entity && item.id == entity.id);
};

export const encodeQueryData = (data: { [key: string]: string | number }) => {
  const ret = [];
  for (let d in data) ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
  return ret.join('&');
};

export function toType<T>(): (obj: unknown) => T {
  return (obj) => obj as T;
}
export function removeNullAndUndefinedProperties(obj: any) {
  if (!obj) {
    return;
  }
  Object.keys(obj).forEach((key) => {
    let value = obj[key];
    let hasProperties = value && Object.keys(value).length > 0;
    if (value === null || value === undefined) {
      delete obj[key];
    } else if (typeof value !== 'string' && hasProperties) {
      removeNullAndUndefinedProperties(value);
    }
  });
  return obj;
}

export function objIsEmpty(obj: Object): boolean {
  return Object.keys(obj).length === 0;
}
