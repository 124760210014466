import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutingNames } from '@app/app-routing.module';
import RoutePath from '@app/shared/models/route-path.model';
import { QuicklinkModule } from 'ngx-quicklink';
import { TabsComponent } from '@app/pages/personal-area/components/tabs/tabs.component';
import { PersonalAreaComponent } from './personal-area.component';
import { EModules } from '@app/shared/models';
import { PermissionsGuard } from '@app/shared/services';

export const PersonalAreaRoutingNames = {
  root: new RoutePath('root', '', ':tab'),
  main: new RoutePath('main', AppRoutingNames.personalArea.path + '/main', ':tab'),
  invoices: new RoutePath('invoices', AppRoutingNames.personalArea.path + '/invoices', ':tab'),
};

const routes: Routes = [
  {
    path: '',
    component: PersonalAreaComponent,
    canActivate: [PermissionsGuard],
    data: {
      module: EModules.PersonalAreaModule,
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: PersonalAreaRoutingNames.main.path,
      },
      {
        path: PersonalAreaRoutingNames.root.routeParams,
        component: TabsComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule, QuicklinkModule],
})
export class PersonalAreaRouting {}
