import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EEntityList, IPagingResponsePayload, ISaveParameter, IServerRequestPayload } from '@app/shared/models';
import { DataRestService } from '@app/shared/services';
import { dateWithoutTime, getErrorMessage, passwordValidator } from '@app/shared/utilities/forms.untils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { city, company, role, user_roles, users } from '@src/api';

@UntilDestroy()
@Component({
  selector: 'hoho-create-update-users',
  templateUrl: './create-update-users.component.html',
  styleUrls: ['./create-update-users.component.scss'],
})
export class CreateUpdateUsersComponent implements OnInit {
  cities: city[];
  companyList: company[] = [];
  roleList: role[] = [];
  entityType: EEntityList;
  parameters: ISaveParameter<unknown>[] = null;
  title: string;
  isLoading: boolean = false;
  submitted: boolean = false;
  maxDate = new Date();
  formGroup = this.fb.group({
    id: [null],
    lastName: [null, [Validators.required, Validators.maxLength(250)]],
    firstName: [null, [Validators.required, Validators.maxLength(250)]],
    middleName: [null, [Validators.maxLength(250)]],
    email: [null, [Validators.required, Validators.maxLength(40), Validators.email]],
    birthday: [null, [Validators.required, Validators.maxLength(10)]],
    cityName: [null, [Validators.required, Validators.maxLength(250)]],
    requestDate: [this.maxDate, [Validators.required]],
    company: this.fb.group({
      id: [null, [Validators.required]],
      entity: [EEntityList.company],
    }),
  });
  getErrorMessage = getErrorMessage;
  constructor(
    @Inject(MAT_DIALOG_DATA) public entity: users,
    private dialogRef: MatDialogRef<CreateUpdateUsersComponent>,
    private fb: UntypedFormBuilder,
    private dataRestService: DataRestService,
  ) {}

  ngOnInit() {
    if (this.entity) {
      this.entityType = EEntityList.users;
      this.formGroup.patchValue(this.entity);
      this.formGroup.get('company.id').patchValue(this.entity.company?.id);
      this.formGroup.get('birthday').patchValue(dateWithoutTime(this.entity.birthday));
      this.formGroup.addControl('roles', new UntypedFormControl(null, Validators.required));
      this.title = 'Редактировать пользователя';
    } else {
      this.entityType = EEntityList.user_request;
      this.title = 'Добавить пользователя';
      this.formGroup.addControl(
        'password',
        new UntypedFormControl('', [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(20),
          passwordValidator(),
        ]),
      );
      this.formGroup.addControl('confirmPassword', new UntypedFormControl(''));
      this.formGroup.addControl('requestStatus', new UntypedFormControl(2, [Validators.required]));
      this.formGroup.addControl('requestType', new UntypedFormControl(0, [Validators.required]));
    }
    this.getCites();
    this.loadCompany();
    if (this.entity) {
      this.loadRoles();
    }
  }

  loadCompany(): void {
    const payload: IServerRequestPayload = {
      paths: ['id', 'name'],
      sort: null,
    };
    this.dataRestService
      .page<company>(EEntityList.company, '', 0, -1, payload)
      .pipe(untilDestroyed(this))
      .subscribe((res: IPagingResponsePayload<company>) => {
        this.companyList = res.content;
      });
  }

  loadRoles(): void {
    const payload: IServerRequestPayload = {
      paths: ['id', 'name'],
      sort: null,
    };
    this.isLoading = true;
    this.dataRestService
      .page<role>(EEntityList.role, '', 0, -1, payload)
      .pipe(untilDestroyed(this))
      .subscribe((res: IPagingResponsePayload<role>) => {
        this.roleList = res.content;
        this.loadUserRoles();
      });
  }

  loadUserRoles(): void {
    const payload: IServerRequestPayload = {
      filter: { 'user.id': this.entity.id },
      paths: ['role.id', 'role.name'],
      sort: null,
    };
    this.dataRestService
      .page<user_roles>(EEntityList.user_roles, '', 0, -1, payload)
      .pipe(untilDestroyed(this))
      .subscribe((res: IPagingResponsePayload<user_roles>) => {
        var roleIds = res.content.map((item: user_roles) => {
          return item.role.id;
        });
        this.formGroup.get('roles').patchValue(roleIds);
        this.isLoading = false;
      });
  }

  afterSave(): void {
    this.dialogRef.close(true);
  }

  submittedEvent(): void {
    this.submitted = true;
  }

  get cityControl(): UntypedFormControl {
    return this.formGroup.get('cityName') as UntypedFormControl;
  }

  get companyControl(): UntypedFormControl {
    return this.formGroup.get('company.id') as UntypedFormControl;
  }

  get rolesControl(): UntypedFormControl {
    this.parameters = [
      {
        name: 'roles',
        value: this.formGroup.get('roles').value,
      },
    ];
    return this.formGroup.get('roles') as UntypedFormControl;
  }

  private getCites() {
    const citesPayload: IServerRequestPayload = {
      filter: {},
      paths: ['id', 'name', 'entity'],
    };
    this.dataRestService
      .page<city>(EEntityList.city, '', 0, -1, citesPayload)
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.cities = res.content;
      });
  }
}
