/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { marketing_push_notification_file } from '../models/marketing_push_notification_file';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MarketingPushNotificationFileService {

    /**
     * Select marketing_push_notification_file by id
     * @param id Object ID
     * @returns marketing_push_notification_file Successful operation
     * @throws ApiError
     */
    public static selectMarketingPushNotificationFileById(
        id: string,
    ): CancelablePromise<marketing_push_notification_file> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/get/object/marketing_push_notification_file/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Save marketing_push_notification_file
     * @param requestBody Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static saveMarketingPushNotificationFile(
        requestBody?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/save/object/marketing_push_notification_file',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Delete marketing_push_notification_file by id
     * @param id Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static deleteMarketingPushNotificationFileById(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/delete/object/marketing_push_notification_file/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

}
