/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { base_entity } from './base_entity';

/**
 * Продаваемый продукт
 */
export type product = (base_entity & {
    /**
     * Идентификатор
     */
    id: number;
    /**
     * Наименование
     */
    name: string;
    readonly search?: string;
    /**
     * Тип
     */
    type?: product.type;
    barcode?: string;
    readonly typeName?: string;
    readonly isArchived?: boolean;
});

export namespace product {

    /**
     * Тип
     */
    export enum type {
        '_0' = 0,
        '_1' = 1,
    }


}

