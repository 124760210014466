import { EEntityList, EntityModel } from '@shared/models/entity.models';
import { product } from '@src/api';

export class PriceListModel extends EntityModel {
  entity: EEntityList;

  id: string;
  name?: string;
  isActive?: boolean;
  company?: EntityModel;
  priceListItems: PriceListItemModel[];
  constructor(value?: PriceListModel) {
    super(value);
    this.id = value?.id;
    this.name = value?.name;
    this.isActive = value?.isActive;
    this.company = value?.company;
    this.entity = EEntityList.price_list;
    this.priceListItems = value?.priceListItems || [new PriceListItemModel()];
  }
}

export class PriceListItemModel extends EntityModel {
  id: string;
  mainPrice: number;
  priceGroup1: number;
  priceGroup2: number;
  priceGroup3: number;
  product: product;
  constructor(value?: PriceListItemModel) {
    super();
    this.id = value?.id;
    this.mainPrice = value?.mainPrice;
    this.priceGroup1 = value?.priceGroup1;
    this.priceGroup2 = value?.priceGroup2;
    this.priceGroup3 = value?.priceGroup3;
    this.product = value?.product || null;
    this.entity = EEntityList.price_list_item;
  }
}
