import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'hoho-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input() color: string = 'suzanne';
  @Input() iconName: string;
  @Input() iconOnly = false;
  constructor() {}

  ngOnInit(): void {}
}
