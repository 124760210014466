<hoho-secondary-header [crumbs]="crumbs" *ngIf="!isLoading" [title]="title"> </hoho-secondary-header>
<div class="mx-gutter mt-5">
  <ng-container *ngIf="!isLoading && entity">
    <div>
      <div class="rounded-xl bg-shpongle p-3 mb-5">
        <div class="text-subtitle-3" i18n>Информация по автомату</div>
      </div>
      <div class="grid lg:grid-cols-2 lg:grid-rows-1 md:grid-cols-1 md:grid-rows-2 lg:gap-4 ml-3">
        <!--ФИО-->
        <div class="pb-4 mb-4 border-b border-arizona">
          <div class="text-body-5 mb-2 text-green-4" i18n>Название автомата</div>
          <div>{{ $vending_machine(entity.vendingMachine).name }}</div>
        </div>
        <!--Номер, модель-->
        <div class="pb-4 mb-4 border-b border-arizona">
          <div class="text-body-5 mb-2 text-green-4" i18n>Номер, модель</div>
          <div>{{ $vending_machine(entity.vendingMachine).nameAndSerial }}</div>
        </div>
      </div>
      <div class="grid lg:grid-cols-2 lg:grid-rows-1 md:grid-cols-1 md:grid-rows-2 lg:gap-4 ml-3">
        <!--Адрес-->
        <div class="pb-4 mb-4 border-b border-arizona">
          <div class="text-body-5 mb-2 text-green-4" i18n>Адрес</div>
          <div i18n>{{ entity.address }}</div>
        </div>
      </div>
    </div>
    <div class="grid lg:grid-cols-2 lg:grid-rows-1 md:grid-cols-1 md:grid-rows-2 lg:gap-4 ml-3">
      <div>
        <div class="rounded-xl bg-shpongle p-3 mb-5">
          <div class="text-subtitle-3" i18n>Детали отзыва</div>
        </div>
        <div class="flex items-center border-b border-arizona ml-2 mr-2 pb-4">
          <div class="rounded-full w-11 h-11 flex items-center justify-center bg-seattle mr-3.5">
            <hoho-icon iconName="user"></hoho-icon>
          </div>
          <div>
            <div class="text-body-3 mb-2 link" [routerLink]="clientsRoutingNames.clientPage.path + '/' + entity.slUserId">
              {{ entity.fio }}
            </div>
            <div class="text-body-4 flex">
              <div class="flex" *ngIf="entity.phone">
                <hoho-icon class="mr-1.5" iconName="phone"></hoho-icon>
                <div class="mr-4">{{ entity.phone }}</div>
              </div>
              <div class="flex" *ngIf="entity.email">
                <hoho-icon class="mr-1.5" iconName="envelope-alt"></hoho-icon>
                <a class="text-betty cursor-pointer mr-4" href="mailto:{{ entity.email }}" target="_blank">{{
                  entity.email
                }}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4 border-b border-arizona">
          <div class="flex justify-between mb-4">
            <div class="text-body-5 text-california" i18n>Приобретенный товар</div>
            <div class="text-body-4">{{ entity.productName }}</div>
          </div>
          <div class="flex justify-between mb-4">
            <div class="text-body-5 text-california" i18n>Дата и время покупки</div>
            <div class="text-body-4">{{ dateTime }}</div>
          </div>
          <div class="flex justify-between mb-4">
            <div class="text-body-5 text-california" i18n>Причина отзыва</div>
            <div class="text-body-4">{{ $feedback_reason(entity.feedbackReason)?.name }}</div>
          </div>
        </div>
        <div class="rounded-tr-xl rounded-br-xl rounded-bl-xl bg-shpongle p-3 mb-5 mt-4" *ngIf="entity.text">
          <div class="text-body-5" i18n>Текст отзыва</div>
          <div>{{ entity.text }}</div>
        </div>
      </div>
      <div>
        <div class="rounded-xl bg-shpongle p-3 mb-5">
          <div class="text-subtitle-3" i18n>Примечания операторов</div>
        </div>
        <div class="rounded-tr-xl rounded-br-xl rounded-bl-xl bg-shpongle p-3 mb-5 mt-4" *ngFor="let item of notes">
          <div>{{ item.text }}</div>
          <div class="text-body-5" i18n>{{ item.dateCreated | date: 'dd.MM.yyyy HH:mm' }}</div>
        </div>
        <div class="flex items-center flex-col">
          <button (click)="openAddNoteModal()" color="accent" mat-flat-button type="submit">
            <ng-container i18n>Добавить примечание</ng-container>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</div>
