import { Injectable } from '@angular/core';
import { IAuthReceivedData, ICurrentUser, ILoginSpecs, IRegisterUser, ISetPassword } from '@shared/models';
import { HttpService } from '@shared/services/http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IChatFictitiousUser } from '@core/modules/chat/chat.models';

@Injectable({
  providedIn: 'root',
})
export class UserRestService {
  constructor(private http: HttpService) {}

  public login(data: ILoginSpecs): Observable<IAuthReceivedData> {
    return this.http.post<IAuthReceivedData>(`login`, data);
  }

  public getUserInfo(): Observable<ICurrentUser> {
    return this.http.post<ICurrentUser>(`info/user`);
  }
  public register(model: IRegisterUser): Observable<string> {
    return this.http.post<string>(`register/user`, model);
  }
  public confirmRegistration(code: string, token: string): Observable<string> {
    const options = Object.assign({ params: { code: code } });
    return this.http.get<string>(`register/confirm/${token}`, options);
  }
  public recovery(email: string): Observable<string> {
    return this.http.post<string>(`register/recovery`, { email: email });
  }

  public checkCode(code: string, token: string): Observable<string> {
    const options = Object.assign({ params: { code: code } });
    return this.http.get<{ token: string }>(`register/check/${token}`, options).pipe(map((i) => i.token));
  }

  public resendConfirmCode(id: string): Observable<void> {
    return this.http.get<void>(`register/renew/${id}`);
  }

  public setPassword(model: ISetPassword, userId: string): Observable<string> {
    return this.http.post<string>(`register/setpassword/${userId}`, model);
  }

  public chatRegistration(): Observable<void> {
    return this.http.post<void>(`user/chat/registration`);
  }

  public refreshChatToken(): Observable<{ chatToken: string }> {
    return this.http.get<{ chatToken: string }>(`user/chat/refreshtoken`);
  }

  getFictitiousChatToken(): Observable<IChatFictitiousUser> {
    return this.http.get<IChatFictitiousUser>(`user/chat/refreshfictitiouschattoken`);
  }
}
