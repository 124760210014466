import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';
import { UserService } from '@shared/services/user.service';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  constructor(private userService: UserService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let requestToForward = req;
    const token = this.userService.token;
    if (token !== '') {
      const tokenValue = 'Bearer ' + token;
      requestToForward = req.clone({
        setHeaders: {
          Authorization: tokenValue,
        },
      });
    }

    return next.handle(requestToForward);
  }
}
