import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'hoho-small-summary-card',
  templateUrl: './small-summary-card.component.html',
  styleUrls: ['./small-summary-card.component.scss'],
})
export class SmallSummaryCardComponent {
  @Input() title: string;
  @Input() value: string;
  @Input() iconName: string;

  constructor() {}
}
