/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { marketing_customers_group } from '../models/marketing_customers_group';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MarketingCustomersGroupService {

    /**
     * Select marketing_customers_group by id
     * @param id Object ID
     * @returns marketing_customers_group Successful operation
     * @throws ApiError
     */
    public static selectMarketingCustomersGroupById(
        id: string,
    ): CancelablePromise<marketing_customers_group> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/get/object/marketing_customers_group/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Save marketing_customers_group
     * @param requestBody Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static saveMarketingCustomersGroup(
        requestBody?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/save/object/marketing_customers_group',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Delete marketing_customers_group by id
     * @param id Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static deleteMarketingCustomersGroupById(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/delete/object/marketing_customers_group/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

}
