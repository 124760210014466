<vex-page-layout>
  <hoho-secondary-header [crumbs]="crumbs"> </hoho-secondary-header>
  <div class="px-gutter flex items-center w-full justify-between" *ngIf="vendingMachine">
    <div>
      <h2>{{ vendingMachine.name }}</h2>
      <div class="text-body-5">
        {{ vendingMachine.nameAndSerial }}
      </div>
    </div>
    <div class="flex items-center">
      <div class="badge-icon bg-gren mr-3 cursor-pointer">
        <hoho-icon iconName="location-point" className="text-gren-4"></hoho-icon>
      </div>
      <p class="text-body-5 text-black">
        {{ vendingMachine.address }}
      </p>
    </div>
  </div>
  <div class="px-gutter mt-4" *ngIf="vendingMachine">
    <hoho-list-builder
      class="mt-3 sm:mt-0 block"
      #tableBuilder
      [noLink]="true"
      [multiselect]="false"
      [requestPayload]="requestPayload"
      [additionalColumns]="additionalColumns"
      [props]="tableBuilderProps"
      context="vendingMachine"
    ></hoho-list-builder>
  </div>
  <ng-template #link let-item="item">
    <div class="link flex whitespace-nowrap" *ngIf="permissionsGuard.haveAccess(EModules.FeedbackModule, EFeedbackFeatures.Page)" [routerLink]="'/feedback/page/' + item.id">
      <ng-container i18n>Карточка отзыва</ng-container> <hoho-icon class="ml-2" iconName="arrow-right"></hoho-icon>
    </div>
  </ng-template>
</vex-page-layout>
