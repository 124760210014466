<hoho-create-update-dialog
  *ngIf="!selectUsersView"
  [formGroup]="formGroup"
  [initEntity]="entity"
  [entityType]="EEntityList.role"
  (afterSave)="afterSave()"
  [actions]="actions"
  (submitted)="submittedEvent()"
  [title]="title"
>
  <mat-form-field class="w-full mb-4">
    <mat-label i18n>Наименование</mat-label>
    <input cdkFocusInitial #name name="name" required formControlName="name" matInput />
    <mat-error name="nameError" *ngIf="formGroup.get('name').invalid">{{ getErrorMessage('name', formGroup) }}</mat-error>
    <mat-hint align="end">{{ name.value.length }} / 24</mat-hint>
  </mat-form-field>
  <ng-template #actions>
    <button
      class="sm:mt-0 mt-3 w-full mb-4"
      color="accent"
      (click)="selectUsersView = true"
      mat-flat-button
      type="button"
    >
      <ng-container *ngIf="!usersControl.value?.length" i18n>Выберите пользователей</ng-container>
      <ng-container *ngIf="usersControl.value?.length">
        <ng-container i18n
          >{usersControl.value?.length, plural, =1 {Выбран} few {Выбрано} other {Выбрано}}</ng-container
        >
        {{ usersControl.value?.length }}
        <ng-container i18n>
          {usersControl.value?.length, plural, =1 {пользователель.} few {пользователя} other
          {пользователей.}}</ng-container
        >
      </ng-container>
    </button>
  </ng-template>
</hoho-create-update-dialog>

<ng-container *ngIf="selectUsersView">
  <div class="dialog-container">
    <div class="flex">
      <hoho-icon class="mr-3 cursor-pointer" iconName="angle-left-b" (click)="selectUsersView = false"></hoho-icon>
      <div class="text-subtitle-3 mb-4" i18n>Список пользователей</div>
    </div>
    <hoho-multiselect-entity [selectedItems]="selectedItems" (selected)="userSelected($event)" [row]="row" [items]="users"></hoho-multiselect-entity>

    <ng-template #row let-item="item">
      <div>
        <div class="text-body-2">{{ item.displayName }}</div>
        <div class="text-body-5">{{ item.company.name }}</div>
      </div>
    </ng-template>
  </div>
</ng-container>
