export enum ETimeZones {
  'UTC - 12' = -12,
  'UTC - 11' = -11,
  'UTC - 10' = -10,
  'UTC - 09' = -9,
  'UTC - 08' = -8,
  'UTC - 07' = -7,
  'UTC - 06' = -6,
  'UTC - 05' = -5,
  'UTC - 04' = -4,
  'UTC - 03' = -3,
  'UTC - 02' = -2,
  'UTC - 01' = -1,
  'UTC' = 0,
  'UTC + 01' = 1,
  'UTC + 02' = 2,
  'UTC + 03' = 3,
  'UTC + 04' = 4,
  'UTC + 05' = 5,
  'UTC + 06' = 6,
  'UTC + 07' = 7,
  'UTC + 08' = 8,
  'UTC + 09' = 9,
  'UTC + 10' = 10,
  'UTC + 11' = 11,
  'UTC + 12' = 12,
}

export const timeZoneList: { name: string; value: number }[] = [
  { name: 'UTC - 12', value: -12 },
  { name: 'UTC - 11', value: -11 },
  { name: 'UTC - 10', value: -10 },
  { name: 'UTC - 09', value: -9 },
  { name: 'UTC - 08', value: -8 },
  { name: 'UTC - 07', value: -7 },
  { name: 'UTC - 06', value: -6 },
  { name: 'UTC - 05', value: -5 },
  { name: 'UTC - 04', value: -4 },
  { name: 'UTC - 03', value: -3 },
  { name: 'UTC - 02', value: -2 },
  { name: 'UTC - 01', value: -1 },
  { name: 'UTC', value: 0 },
  { name: 'UTC + 01', value: 1 },
  { name: 'UTC + 02', value: 2 },
  { name: 'UTC + 03', value: 3 },
  { name: 'UTC + 04', value: 4 },
  { name: 'UTC + 05', value: 5 },
  { name: 'UTC + 06', value: 6 },
  { name: 'UTC + 07', value: 7 },
  { name: 'UTC + 08', value: 8 },
  { name: 'UTC + 09', value: 9 },
  { name: 'UTC + 10', value: 10 },
  { name: 'UTC + 11', value: 11 },
  { name: 'UTC + 12', value: 12 },
];
