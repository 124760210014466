/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { role } from '../models/role';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RoleService {

    /**
     * Select role by id
     * @param id Object ID
     * @returns role Successful operation
     * @throws ApiError
     */
    public static selectRoleById(
        id: string,
    ): CancelablePromise<role> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/get/object/role/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Save role
     * @param requestBody Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static saveRole(
        requestBody?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/save/object/role',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Delete role by id
     * @param id Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static deleteRoleById(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/delete/object/role/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

}
