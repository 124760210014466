/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { sl_order } from '../models/sl_order';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SlOrderService {

    /**
     * Select sl_order by id
     * @param id Object ID
     * @returns sl_order Successful operation
     * @throws ApiError
     */
    public static selectSlOrderById(
        id: string,
    ): CancelablePromise<sl_order> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/get/object/sl_order/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Save sl_order
     * @param requestBody Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static saveSlOrder(
        requestBody?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/save/object/sl_order',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Delete sl_order by id
     * @param id Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static deleteSlOrderById(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/delete/object/sl_order/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

}
