import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgSelectModule } from '@ng-select/ng-select';
import { VexModule } from '@shared/@vex/vex.module';
import { ComponentsModule } from '@shared/components/components.module';
import { DirectivesModule } from '@shared/directives/directives.module';
import { MultiselectEntityComponent } from './components/multiselect-entity/multiselect-entity.component';
import { PasswordInputComponent } from './components/password-input/password-input.component';
import { PasswordWithRepeatComponent } from './components/password-with-repeat/password-with-repeat.component';
import { SelectComponent } from './components/select/select.component';
import { DateTimeComponent } from './components/date-time/date-time.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MultiDatepickerComponent } from './components/multi-datepicker/multi-datepicker.component';
import { MultiselectEntityComponentWithFormControl } from '@shared/modules/custom-inputs/components/multiselect-entity-with-form-control/multiselect-entity-with-form-control.component';

@NgModule({
  declarations: [
    SelectComponent,
    PasswordWithRepeatComponent,
    PasswordInputComponent,
    MultiselectEntityComponent,
    DateTimeComponent,
    MultiselectEntityComponentWithFormControl,
    MultiDatepickerComponent,
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    MatInputModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    ComponentsModule,
    VexModule,
    MatButtonModule,
    DirectivesModule,
    FormsModule,
    MatMenuModule,
    MatCardModule,
  ],
  exports: [
    SelectComponent,
    PasswordWithRepeatComponent,
    PasswordInputComponent,
    MultiselectEntityComponent,
    DateTimeComponent,
    MultiDatepickerComponent,
    MultiselectEntityComponentWithFormControl,
  ],
})
export class CustomInputsModule {}
