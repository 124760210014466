import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HttpClient } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { UntypedFormControl, FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ErrorStateMatcher, MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { NgSelectModule } from '@ng-select/ng-select';
import { BreadcrumbsModule } from '@shared/@vex/components/breadcrumbs/breadcrumbs.module';
import { PageLayoutModule } from '@shared/@vex/components/page-layout/page-layout.module';
import { ScrollbarModule } from '@shared/@vex/components/scrollbar/scrollbar.module';
import { ContainerModule } from '@shared/@vex/directives/container/container.module';
import { DateTokensModule } from '@shared/@vex/pipes/date-tokens/date-tokens.module';
import { VexModule } from '@shared/@vex/vex.module';
import { DirectivesModule } from '@shared/directives/directives.module';
import { CustomInputsModule } from '@shared/modules/custom-inputs/custom-inputs.module';
import { DialogsModule } from '@shared/modules/dialogs/dialogs.module';
import { GridCardsModule } from '@shared/modules/grid-cards/grid-cards.module';
import { VendingMachineCardModule } from '@shared/modules/grid-cards/modules/vending-machine-card/vending-machine-card.module';
import { InputMaskModule } from '@shared/modules/input-mask/input-mask.module';
import { MapsModule } from '@shared/modules/maps/maps.module';
import { TimePeriodModule } from '@shared/modules/time-period/time-period.module';
import { WebsocketModule } from '@shared/modules/websocket';
import { AlertService, HttpService, httpServiceCreator } from '@shared/services';
import { IconModule } from '@visurel/iconify-angular';
import { NgApexchartsModule } from 'ng-apexcharts';
import { RecaptchaModule } from 'ng-recaptcha';
import { CookieService } from 'ngx-cookie-service';
import { MomentModule } from 'ngx-moment';
import { ComponentsModule } from './components/components.module';
import { CalendarHeadersModule } from '@shared/modules/calendar-headers/calendar-headers.module';
import { PopoverModule } from '@shared/@vex/components/popover/popover.module';
import { MatRadioModule } from '@angular/material/radio';

class CustomErrorStateMatcher implements ErrorStateMatcher {
  constructor() {}

  isErrorState(control: UntypedFormControl | null, form: NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || isSubmitted));
  }
}

@NgModule({
  imports: [
    RecaptchaModule,
    WebsocketModule.config({
      url: environment.WS,
      garbageCollectInterval: 60 * 1000,
      options: {
        connectionTimeout: 2000,
        maxRetries: 10,
      },
    }),
  ],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: ErrorStateMatcher, useClass: CustomErrorStateMatcher },
  ],
  exports: [
    MatInputModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatButtonModule,
    MatCheckboxModule,
    MatRadioModule,
    PageLayoutModule,
    PopoverModule,
    BreadcrumbsModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatIconModule,
    MatMenuModule,
    IconModule,
    FormsModule,
    MatSelectModule,
    MatButtonToggleModule,
    NgSelectModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MapsModule,
    MatDialogModule,
    DateTokensModule,
    DragDropModule,
    ScrollbarModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    ComponentsModule,
    MatSidenavModule,
    ReactiveFormsModule,
    ContainerModule,
    MatTabsModule,
    MatDatepickerModule,
    MatCardModule,
    VendingMachineCardModule,
    MomentModule,
    RecaptchaModule,
    VexModule,
    NgSelectModule,
    DialogsModule,
    CustomInputsModule,
    MatMomentDateModule,
    DirectivesModule,
    GridCardsModule,
    InputMaskModule,
    TimePeriodModule,
    ClipboardModule,
    WebsocketModule,
    MatFormFieldModule,
    NgApexchartsModule,
    CalendarHeadersModule,
  ],
  declarations: [],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        {
          provide: MAT_RIPPLE_GLOBAL_OPTIONS,
          useValue: { disabled: true },
        },
        {
          provide: HttpService,
          useFactory: httpServiceCreator,
          deps: [HttpClient, Router, AlertService],
        },
        CookieService,
      ],
    };
  }
}
