import { Component, Input, OnInit } from '@angular/core';
import { EquipmentStateEnum } from '../../enums/equipment-state.enum';
import { EquipmentStateWithIcon, IEquipmentCardModel } from '../../models/card.models';
import { EquipmentIconService } from '../../services/equipment-icon.service';

@Component({
  selector: 'hoho-equipment-card',
  templateUrl: './equipment-card.component.html',
  styleUrls: ['./equipment-card.component.scss'],
})
export class EquipmentCardComponent implements OnInit {
  @Input() model: IEquipmentCardModel;
  equipmentStates: EquipmentStateWithIcon[];
  equipmentStateEnum = EquipmentStateEnum;

  constructor() {}

  ngOnInit() {
    this.equipmentStates = this.model.equipmentStates.map((x) => {
      return new EquipmentStateWithIcon(x.equipment, x.state, EquipmentIconService.getIconName(x.equipment));
    });
  }
}
