import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AddressSearchComponent } from '@shared/modules/maps/components/address-search/address-search.component';
import { VendingMachineCardModule } from '@shared/modules/grid-cards/modules/vending-machine-card/vending-machine-card.module';
import { MapsComponent } from './components/maps/maps.component';
import { MapsService } from './maps.service';
import { AngularYandexMapsModule, YaConfig } from 'angular8-yandex-maps';

export const mapConfig = <YaConfig>({
	apikey: '7820a6a5-847a-4d9f-8f71-382b7f94611c',
	lang: 'ru_RU',
});
@NgModule({
  declarations: [MapsComponent, AddressSearchComponent],
  imports: [CommonModule, NgSelectModule, FormsModule, ReactiveFormsModule, VendingMachineCardModule, AngularYandexMapsModule.forRoot(mapConfig),],
  exports: [MapsComponent, AddressSearchComponent],
  providers: [MapsService],
})
export class MapsModule {}
