import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DigitMaskDirective } from '@shared/directives/digit-mask.directive';
import { HoursMaskDirective } from '@shared/directives/hours-mask.directive';
import { MinutesMaskDirective } from '@shared/directives/minutes-mask.directive';
import { NoSpaceMaskDirective } from '@shared/directives/no-space-mask.directive';
import { NoSpecialCharactersMaskDirective } from '@shared/directives/no-special-characters-mask.directive';
import { DragDirectiveDirective } from './drag-directive.directive';

@NgModule({
  declarations: [
    NoSpecialCharactersMaskDirective,
    DigitMaskDirective,
    NoSpaceMaskDirective,
    MinutesMaskDirective,
    HoursMaskDirective,
    DragDirectiveDirective,
  ],
  imports: [CommonModule],
  exports: [
    NoSpecialCharactersMaskDirective,
    DigitMaskDirective,
    NoSpaceMaskDirective,
    MinutesMaskDirective,
    HoursMaskDirective,
    DragDirectiveDirective,
  ],
})
export class DirectivesModule {}
