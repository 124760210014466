/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { role_modules } from '../models/role_modules';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RoleModulesService {

    /**
     * Select role_modules by id
     * @param id Object ID
     * @returns role_modules Successful operation
     * @throws ApiError
     */
    public static selectRoleModulesById(
        id: string,
    ): CancelablePromise<role_modules> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/get/object/role_modules/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Save role_modules
     * @param requestBody Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static saveRoleModules(
        requestBody?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/save/object/role_modules',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Delete role_modules by id
     * @param id Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static deleteRoleModulesById(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/delete/object/role_modules/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

}
