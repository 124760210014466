/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { base_entity } from './base_entity';
import type { company } from './company';
import type { object_link } from './object_link';
import type { user_roles } from './user_roles';

/**
 * Пользователи системы
 */
export type users = (base_entity & {
    /**
     * Идентификатор
     */
    id?: string;
    /**
     * Роли
     */
    readonly roles?: Array<user_roles>;
    /**
     * Фамилия
     */
    lastName?: string;
    /**
     * Имя
     */
    firstName?: string;
    /**
     * Отчество
     */
    middleName?: string;
    /**
     * Дата рождения
     */
    birthday?: string;
    /**
     * Электронная почта
     */
    email?: string;
    /**
     * Номер телефона
     */
    phoneNumber?: string;
    /**
     * Город
     */
    cityName?: string;
    /**
     * Текстовая информация о пользователе
     */
    readonly userTextInfo?: string;
    /**
     * Активная компания
     */
    company?: (company | object_link);
    /**
     * Активный
     */
    active?: boolean;
    /**
     * ФИО
     */
    readonly fio?: string;
    /**
     * Флаги
     */
    flags?: users.flags;
    readonly rolesText?: string;
    /**
     * Дата регистрации
     */
    readonly registerDate?: string;
    readonly search?: string;
    readonly chatUserId?: number;
    readonly chatFollowerCode?: string;
});

export namespace users {

    /**
     * Флаги
     */
    export enum flags {
        '_1' = 1,
        '_4' = 4,
    }


}

