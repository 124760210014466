export const paths = [
    'displayName',
    'company',
    'id',
    'modules',
    'lastName',
    'firstName',
    'middleName',
    'email',
    'birthday',
    'cityName',
    'phoneNumber',
    'roles',
    'active',
    'flags',
    'entity',
    'rolesText',
    'registerDate',
    'company.id',
    'company.entity',
    'company.name',
]