/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { feature_settings } from '../models/feature_settings';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FeatureSettingsService {

    /**
     * Select feature_settings by id
     * @param id Object ID
     * @returns feature_settings Successful operation
     * @throws ApiError
     */
    public static selectFeatureSettingsById(
        id: string,
    ): CancelablePromise<feature_settings> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/get/object/feature_settings/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Save feature_settings
     * @param requestBody Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static saveFeatureSettings(
        requestBody?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/save/object/feature_settings',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Delete feature_settings by id
     * @param id Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static deleteFeatureSettingsById(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/delete/object/feature_settings/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

}
