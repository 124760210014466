import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutingNames } from '@app/app-routing.module';

import RoutePath from '@app/shared/models/route-path.model';
import { EModules, EServiceVendingMachinesFeatures } from '@shared/models';
import { PermissionsGuard } from '@shared/services';
import { QuicklinkModule } from 'ngx-quicklink';

export const ServiceMachineRoutingNames = {
  encashment: new RoutePath('encashment', AppRoutingNames.serviceMachine.path + '/encashment'),
  routes: new RoutePath('routes', AppRoutingNames.serviceMachine.path + '/routes'),
  loadingIngredients: new RoutePath(
    'loading-ingredients',
    AppRoutingNames.serviceMachine.path + '/loading-ingredients',
  ),
};

export const RoutersRoutingNames = {
  list: new RoutePath('list', ServiceMachineRoutingNames.routes.path + '/list'),
  page: new RoutePath('page', ServiceMachineRoutingNames.routes.path + '/page', ':id'),
  waypoints: new RoutePath('waypoints', ServiceMachineRoutingNames.routes.path + '/waypoints'),
};

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: ServiceMachineRoutingNames.routes.path,
      },
      {
        path: ServiceMachineRoutingNames.routes.name,
        loadChildren: () => import('./modules/routers/routers.module').then((m) => m.RoutersModule),
        canActivate: [PermissionsGuard],
        data: {
          module: EModules.ServiceVendingMachines,
          feature: EServiceVendingMachinesFeatures.Routes,
        },
      },
      {
        path: ServiceMachineRoutingNames.encashment.name,
        loadChildren: () => import('./modules/encashment/encashment.module').then((m) => m.EncashmentModule),
        canActivate: [PermissionsGuard],
        data: {
          module: EModules.ServiceVendingMachines,
          feature: EServiceVendingMachinesFeatures.Encashment,
        },
      },
      {
        path: ServiceMachineRoutingNames.loadingIngredients.name,
        loadChildren: () =>
          import('./modules/loading-ingredients/loading-ingredients.module').then((m) => m.LoadingIngredientsModule),
        canActivate: [PermissionsGuard],
        data: {
          module: EModules.ServiceVendingMachines,
          feature: EServiceVendingMachinesFeatures.LoadingIngredients,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule, QuicklinkModule],
})
export class Routing {}
