import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GridCardsComponent } from '@shared/modules/grid-cards/grid-cards.component';
import { VendingMachineCardModule } from '@shared/modules/grid-cards/modules/vending-machine-card/vending-machine-card.module';
import { RouterCardComponent } from './modules/router-card/router-card.component';

@NgModule({
  declarations: [GridCardsComponent, RouterCardComponent],
  imports: [CommonModule, VendingMachineCardModule],
  exports: [GridCardsComponent],
})
export class GridCardsModule {}
