import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  EMultiTypes,
  IDatePeriod,
  IDateTypeListItem,
} from '@shared/modules/custom-inputs/components/multi-datepicker/multi-datepicker.models';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatMenuTrigger } from '@angular/material/menu';
import { HeaderCalendarComponent } from '@shared/modules/calendar-headers/components/header-calendar/header-calendar.component';
import { MatDatepicker, MatDatepickerInputEvent, MatDateRangePicker } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { YearHeaderCalendarComponent } from '@shared/modules/calendar-headers/components/year-header-calendar/year-header-calendar.component';
import { MonthHeaderCalendarComponent } from '@shared/modules/calendar-headers/components/month-header-calendar/month-header-calendar.component';
import { SelectComponent } from '@shared/modules/custom-inputs/components/select/select.component';
import { IPagingRequestFilters } from '@shared/models';

const moment = _rollupMoment || _moment;
const defaultItems: IDateTypeListItem[] = [
  {
    type: EMultiTypes.certainDatePicker,
    name: $localize`Конкретный день`,
    value: null,
  },
  {
    type: EMultiTypes.rangeDatePicker,
    name: $localize`Период дат`,
    value: null,
  },
  {
    type: EMultiTypes.yearDatePicker,
    name: $localize`Год рождения`,
    value: null,
  },
  {
    type: EMultiTypes.monthDatePicker,
    name: $localize`Число и месяц`,
    value: null,
  },
];
@UntilDestroy()
@Component({
  selector: 'hoho-multi-datepicker',
  templateUrl: './multi-datepicker.component.html',
  styleUrls: ['./multi-datepicker.component.scss'],
})
export class MultiDatepickerComponent implements AfterViewInit {
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  @ViewChild('rangeDatePicker') rangeDatePicker: MatDateRangePicker<Moment>;
  @ViewChild('certainDatePicker') certainDatePicker: MatDatepicker<Moment>;
  @ViewChild('yearDatePicker') yearDatePicker: MatDatepicker<Moment>;
  @ViewChild('monthDatePicker') monthDatePicker: MatDatepicker<Moment>;
  @ViewChild('selectRef') selectRef: SelectComponent<IDateTypeListItem>;
  @Input() placeholder: string;
  @Input() filtersParams: IPagingRequestFilters;
  @Input() ctrl = new UntypedFormControl(null);
  @Output() setFiltersEvent = new EventEmitter<IPagingRequestFilters>();

  items: IDateTypeListItem[] = defaultItems;
  headerCalendarComponent = HeaderCalendarComponent;
  yearHeaderCalendarComponent = YearHeaderCalendarComponent;
  monthHeaderCalendarComponent = MonthHeaderCalendarComponent;
  max = moment();
  selected: Date | null;
  constructor() {}

  ngAfterViewInit(): void {
    if (this.ctrl.value) {
      let item: IDateTypeListItem = this.ctrl.value;
      switch (item.type) {
        case EMultiTypes.certainDatePicker:
          this.selectRef.setSelectedLabel(item.name + ': ' + moment(item.value as string).format('DD/MM/YYYY'));
          break;
        case EMultiTypes.rangeDatePicker:
          this.rangeDatePicker.open();
          break;
        case EMultiTypes.yearDatePicker:
          this.yearDatePicker.open();
          break;
        case EMultiTypes.monthDatePicker:
          this.monthDatePicker.open();
          break;
      }
    }
    this.ctrl.valueChanges.pipe(untilDestroyed(this)).subscribe((res: IDateTypeListItem) => {
      if (!res) {
        this.items = defaultItems;
        return;
      }

      let label: string;
      switch (res.type) {
        case EMultiTypes.certainDatePicker:
          if (!res.name) {
            const value: Moment = res.value as Moment;
            this.selectRef.setSelectedLabel(
              this.items.find((i) => i.type === res.type).name + ': ' + value.format('DD/MM/YYYY'),
            );
          } else {
            this.certainDatePicker.open();
          }
          break;
        case EMultiTypes.rangeDatePicker:
          if (!res.name) {
            const value: IDatePeriod = res.value as IDatePeriod;
            label =
              this.items.find((i) => i.type === res.type).name +
              ': ' +
              value.from.format('DD.MM.YY') +
              ' - ' +
              value.to.format('DD.MM.YY');
            this.selectRef.setSelectedLabel(label);
          } else {
            this.rangeDatePicker.open();
          }
          break;
        case EMultiTypes.yearDatePicker:
          if (!res.name) {
            const value: Moment = res.value as Moment;
            label = this.items.find((i) => i.type === res.type).name + ': ' + value.format('YYYY');
            this.selectRef.setSelectedLabel(label);
          } else {
            this.yearDatePicker.open();
          }
          break;
        case EMultiTypes.monthDatePicker:
          if (!res.name) {
            const value: Moment = res.value as Moment;
            label = this.items.find((i) => i.type === res.type).name + ': ' + value.format('DD/MM');
            this.selectRef.setSelectedLabel(label);
          } else {
            this.monthDatePicker.open();
          }
          break;
      }
      this.items = this.items.map((i) => {
        if (i.type === res.type) {
          i = res;
          if (label) {
            i.name = label;
          }
        }
        return i;
      });
    });
  }

  certainDateSelectedHandler(event: MatDatepickerInputEvent<Moment>): void {
    let item: IDateTypeListItem = this.ctrl.value;
    item.value = event.value;
    this.ctrl.setValue(item);
    this.selectRef.setSelectedLabel(item.name + ': ' + event.value.format('DD/MM/YYYY'));
  }

  yearSelectedHandler(chosenDate: Moment, datepicker: MatDatepicker<Moment>): void {
    let item: IDateTypeListItem = this.ctrl.value;
    item.value = chosenDate;
    this.ctrl.setValue(item);
    this.selectRef.setSelectedLabel(item.name + ': ' + chosenDate.format('YYYY'));
    datepicker.close();
  }

  monthSelectedHandler(chosenDate: MatDatepickerInputEvent<Moment>, datepicker: MatDatepicker<Moment>): void {
    let item: IDateTypeListItem = this.ctrl.value;
    item.value = chosenDate.value;
    this.ctrl.setValue(item);
    this.selectRef.setSelectedLabel(item.name + ': ' + chosenDate.value.format('DD/MM'));
    datepicker.close();
  }

  rangeFormSelectedHandler(event: MatDatepickerInputEvent<Moment>): void {
    let item: IDateTypeListItem = this.ctrl.value;
    if (!item.value) {
      item.value = {
        from: event.value,
      };
    } else {
      let value = item.value as IDatePeriod;
      value.from = event.value;
    }
    let value = item.value as IDatePeriod;
    this.ctrl.setValue(item);
    this.selectRef.setSelectedLabel(
      item.name + ': ' + event.value.format('DD.MM.YY') + (value.from ? ' - ' + value.from.format('DD.MM.YY') : ''),
    );
  }
  rangeToSelectedHandler(event: MatDatepickerInputEvent<Moment>): void {
    let item: IDateTypeListItem = this.ctrl.value;
    if (!item.value) {
      item.value = {
        to: event.value,
      };
    } else {
      let value = item.value as IDatePeriod;
      value.to = event.value;
    }
    let value = item.value as { to: Moment; from: Moment };
    this.ctrl.setValue(item);
    this.selectRef.setSelectedLabel(
      item.name + ': ' + (value.from ? value.from.format('DD.MM.YY') + ' - ' : '') + event.value.format('DD.MM.YY'),
    );
  }
}
