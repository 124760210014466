export const paths = [
  'entity',
  'id',
  'displayName',
  'name',
  'slUser',
  'login',
  'email',
  'phoneNumber',
  'dateCreated',
  'genderType',
  'orderCount',
  'walletBalance',
  'refCount',
  'lastOrderDate',
  'birthday',
  'cityName',
  'favoriteProductName',
  'isBlocked',
  'status',
  'freezeBalance',
  'slUser.login',
  'slUser.id',
];
