import { HttpHeaders, HttpParams } from '@angular/common/http';

export enum EAPIEndpoints {
  BASE = 'APP_API_BASE_URL',
  OSM = 'OSM_URL',
}

export interface IRequestOptions {
  headers?: HttpHeaders;
  observe?: 'body';
  params?: HttpParams;
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
  body?: any;
}

export interface IFileRequestOptions {
  headers?: HttpHeaders;
  observe?: 'events';
  params?: HttpParams;
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
  body?: any;
}
