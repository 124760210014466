import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MatDialogRef } from '@angular/material/dialog';

@UntilDestroy()
@Component({
  selector: 'new-agreement-modal',
  templateUrl: './new-agreement-modal.component.html',
  styleUrls: ['./new-agreement-modal.component.scss'],
})
export class NewAgreementModal implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<NewAgreementModal>,
  ) {}

  ngOnInit(): void {}

  saveToLocalStorage(): void {
		localStorage.setItem('new_agreement', 'true')
    this.dialogRef.close();
  }
}
