import { Component, OnInit } from '@angular/core';

import exclamationCircle from '@iconify/icons-uil/exclamation-circle';

@Component({
  selector: 'hoho-info-snippet',
  templateUrl: './info-snippet.component.html',
  styleUrls: ['./info-snippet.component.scss'],
})
export class InfoSnippetComponent implements OnInit {
  exclamationCircle = exclamationCircle;
  constructor() {}

  ngOnInit(): void {}
}
