<div class="summary-card">
  <div class="flex justify-between align-middle mb-1.5">
    <p class="text-body-5">
      {{ title }}
    </p>

    <hoho-icon [iconName]="iconName" className="text-system"></hoho-icon>
  </div>

  <h2 class="text-subtitle-3 text-text-black">
    {{ value }}
  </h2>
</div>
