import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IDeleteEntityItem,
  IGroupingRequestPayload,
  IPagingResponsePayload,
  IRequestOptions,
  ISaveEntities,
  ISaveParameter,
  IServerRequestPayload,
} from '@shared/models';
import { EEntityList } from '@shared/models/entity.models';
import { HttpService } from '@shared/services/http.service';
import { Observable } from 'rxjs';
import { IAccess } from '@core/modules/list-builder/models/access.models';
import { IServerGridColumn } from '@core/modules/list-builder/models/grid.models';
import { RegisterMarketplacePointResponse } from '@app/shared/models/register-marketplace-point.models';
import { CreatePaymentResponse } from '@app/shared/models/personal-area.models';

@Injectable({
  providedIn: 'root',
})
export class DataRestService {
  constructor(private http: HttpService) {}

  getGridInfo(entity: string, context?: string, alias?: string): Observable<IServerGridColumn[]> {
    const options = Object.assign({ params: { context: context } });
    return this.http.get<IServerGridColumn[]>(`info/grid/${entity}${alias ? '/' + alias : ''}`, options);
  }

  accessTree(entity: string): Observable<IAccess[]> {
    return this.http.get<IAccess[]>(`info/access/${entity}`);
  }

  access(entities: string[]): Observable<IAccess[]> {
    return this.http.post<IAccess[]>(`info/access`, entities);
  }

  searches(entity: string): Observable<any> {
    return this.http.post<any>(`info/searches/${entity}`);
  }

  commands(entity: string): Observable<any> {
    return this.http.post<any>(`info/commands/${entity}`);
  }

  getDictionary<T>(url: string): Observable<T[]> {
    return this.http.get<T[]>(`dictionary/${url}`);
  }

  save<T>(
    entity: T,
    entityType: EEntityList,
    deleteObject?: any,
    parameters?: ISaveParameter<unknown>[],
  ): Observable<string> {
    let data: ISaveEntities<T> | any[];
    if (Array.isArray(entity)) {
      data = entity;
    } else {
      data = {
        save: {
          ...entity,
          entity: EEntityList[entityType],
        },
        delete: deleteObject,
        parameters: parameters,
      };
    }

    return this.http.post<string>(`save/object/`, data);
  }

  saveList<T>(
    entity: T,
    entityType: EEntityList,
    deleteEntityList?: any[],
    parameters?: ISaveParameter<unknown>[],
  ): Observable<string> {
    let data: ISaveEntities<T> | any;
    if (Array.isArray(entity)) {
      data = new Array();
      entity.forEach((element) => {
        data.push({
          save: {
            ...element,
          },
          delete: deleteEntityList,
          parameters: parameters,
        });
      });
    } else {
      data = {
        save: {
          ...entity,
          entity: EEntityList[entityType],
        },
        delete: deleteEntityList,
        parameters: parameters,
      };
    }

    return this.http.post<string>(`save/list/`, data);
  }

  deleteList(entities: IDeleteEntityItem[]): Observable<string> {
    return this.http.post<string>(`delete/list/`, entities);
  }

  delete(entity: EEntityList, entityId: number | string): Observable<string> {
    return this.http.post<string>(`delete/object/${entity}/${entityId}`);
  }

  get<T>(entity: EEntityList, id: string, paths: string[], alias?: string): Observable<T> {
    const options = Object.assign({ paths: paths });
    return this.http.post<T>(`get/object/${entity}${alias ? `/${alias}` : ''}/${id}`, options);
  }

  page<T>(
    entity: string,
    alias: string = '',
    page: number,
    length: number,
    payload: IServerRequestPayload,
  ): Observable<IPagingResponsePayload<T>> {
    return this.http.post<IPagingResponsePayload<T>>(
      `get/page/${entity}${alias ? `/${alias}` : ''}/${page + 1}/${length}`,
      payload,
    );
  }

  group<T>(
    entity: string,
    alias: string,
    page: number,
    length: number,
    payload: IGroupingRequestPayload,
  ): Observable<IPagingResponsePayload<T>> {
    return this.http.post<IPagingResponsePayload<T>>(
      `get/groups/${entity}${alias ? '/' + alias : ''}/${page + 1}/${length}`,
      payload,
    );
  }

  excel(entity: string, alias: string = '', payload: IServerRequestPayload, context: string) {
    const offset = new Date().getTimezoneOffset() / -60;
    const options = Object.assign({ params: { context: context, timeZone: offset } });
    delete payload.aggregate;
    return this.http.postBlob<any>(`get/excel/${entity}${alias ? `/${alias}` : ''}`, payload, options);
  }

  postFile<T>(fileToUpload: FormData): Observable<HttpEvent<T>> {
    return this.http.postFile<T>('save/files', fileToUpload);
  }

  //todo вынести в отдельный сервис

  getPlanogramm<T>(vmId: string, targetDate: string): Observable<T[]> {
    const options: IRequestOptions = Object.assign({ params: { vmId, targetDate } });
    return this.http.get<T[]>(`Planogramm/get`, options);
  }

  createWriteoff<T, R>(payload: R): Observable<T> {
    return this.http.post<T>(`ManualWriteoff/create`, payload);
  }

  changePassword<T>(oldPassword: string, newPassword: string): Observable<string> {
    return this.http.post<string>(`user/changepassword`, { oldPassword, newPassword });
  }

  refreshVendingMachine<T>(id: string): Observable<void> {
    return this.http.post<void>(`execute/process/SyncVmInforamtion`, { id });
  }

  refreshVMLogs<T>(id: string): Observable<void> {
    return this.http.post<void>(`execute/process/SetRemovedVmLog`, { id });
  }

  mergeCompany<T>(currentCompanyId: string, mainCompanyId: string): Observable<void> {
    return this.http.post<void>(`execute/process/MergeCompany`, { currentCompanyId, mainCompanyId });
  }

  registerMarketplacePoint<T>(model: any): Observable<RegisterMarketplacePointResponse> {
    return this.http.post<RegisterMarketplacePointResponse>(`RegisterMarketPlacePoint/register`, model);
  }

  getAgreementUrl<T>(): Observable<string> {
    return this.http.post<string>(`Payment/getAgreementUrl`);
  }

  createPayment<T>(model: any): Observable<CreatePaymentResponse> {
    return this.http.post<CreatePaymentResponse>(`Payment/createPayment`, model);
  }

  createInvoice<T>(model: any): Observable<any> {
    return this.http.postBlob<any>(`Payment/createInvoice`, model);
  }

  getInvoiceFile<T>(model: any): Observable<any> {
    return this.http.postBlob<any>(`Payment/getInvoiceFile`, model);
  }

  removeCard<T>(model: any): Observable<boolean> {
    return this.http.post<boolean>(`Payment/removeCard`, model);
  }

  saveAutoPaymentSettings<T>(model: any): Observable<any> {
    return this.http.post<any>(`Payment/saveAutoPaymentSettings`, model);
  }

  generateDataForCreateUserCard<T>(model: any): Observable<CreatePaymentResponse> {
    return this.http.post<CreatePaymentResponse>(`Payment/generateDataForCreateUserCard`, model);
  }

  loadTarifs(): Observable<string> {
    return this.http.post<string>(`execute/process/LoadTarifs`);
  }
}
