<p class="text-body-5 mt-4 mb-1.5 text-green-4" i18n>Средства в автомате</p>
<div class="grid grid-cols-2 gap-x-3">
  <hoho-small-summary-card
    i18n-title
    title="Получено"
    [value]="model.fundsReceivedAmount | numericWithCurrency: model.currencySymbol"
    iconName="money-withdraw"
    class=""
    name="received_sm"
  ></hoho-small-summary-card>
  <hoho-small-summary-card
    i18n-title
    title="Сдача"
    [value]="model.changeAmount | numericWithCurrency: model.currencySymbol"
    iconName="money-insert"
    class=""
    name="change_sm"
  ></hoho-small-summary-card>
</div>

<p class="text-body-5 mb-1.5 text-green-4 mt-4" i18n>Выручка за сутки</p>
<div class="grid grid-cols-2 gap-x-3">
  <hoho-small-summary-card
    i18n-title
    title="Нал"
    [value]="model.cashProceedsAmount | numericWithCurrency: model.currencySymbol"
    iconName="money-bill"
    class=""
    name="cash_sm"
  ></hoho-small-summary-card>
  <hoho-small-summary-card
    i18n-title
    title="Безнал"
    [value]="model.cashlessProceedsAmount | numericWithCurrency: model.currencySymbol"
    iconName="credit-card"
    class=""
    name="no_cash_sm"
  ></hoho-small-summary-card>
</div>

<p class="text-body-5 mb-1.5 text-green-4 mt-4" i18n>Состояние</p>
<hoho-machine-state [state]="model.machineState" name="condition_sm"></hoho-machine-state>

<p class="text-body-5 mb-1.5 text-green-4 mt-4" i18n>Ингредиенты</p>
<hoho-machine-fullness-of-ingredients
  [fillingLevelPercent]="model.ingredientsFillingLevel"
  name="ingridients_sm"
></hoho-machine-fullness-of-ingredients>

<div class="w-full border-b border-gren-1 my-4"></div>
