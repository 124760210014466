<ng-container [formGroup]="addressGroup">
  <ng-select
	#mySelect
    [class.has-error]="canShowError && addressGroup.invalid"
    name="address"
    [items]="addressesList$ | async"
    bindLabel="display_name"
    [clearable]="true"
    [hideSelected]="true"
    [trackByFn]="trackByFn"
    i18n-placeholder
    [appearance]="appearance"
    placeholder="Адрес"
    [minTermLength]="3"
    [editableSearchTerm]="true"
    [loading]="addressLoading"
    i18n-typeToSearchText
    (change)="onChange()"
    typeToSearchText="Пожалуйста введите минимум 3 символа для поиска"
    [typeahead]="addressInput$"
    formControlName="address"
    i18n-notFoundText
    notFoundText="Ничего не найдено"
    i18n-loadingText
    loadingText="Ищем..."
    i18n-addTagText
    addTagText="Выбрать"
  ></ng-select>
  <div class="field-subscript-wrapper">
    <div *ngIf="canShowError && validator.required">
      <ng-content></ng-content>
    </div>
  </div>
</ng-container>
