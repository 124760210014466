/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { base_entity } from './base_entity';
import type { object_link } from './object_link';
import type { service_route_execution } from './service_route_execution';
import type { service_route_performer } from './service_route_performer';
import type { vending_machine } from './vending_machine';

/**
 * Точка выполнения маршрута
 */
export type service_route_execution_waypoint = (base_entity & {
    /**
     * Идентификатор
     */
    id?: string;
    /**
     * Торговый аппарат
     */
    vendingMachine: (vending_machine | object_link);
    /**
     * Порядковый номер
     */
    orderNumber: number;
    /**
     * Время обслуживания
     */
    serviceTime?: number;
    /**
     * Статус
     */
    status?: service_route_execution_waypoint.status;
    /**
     * Исполнение маршрута
     */
    serviceRouteExecution?: (service_route_execution | object_link);
    /**
     * Начала обслуживания
     */
    startOfExecution?: string;
    /**
     * Завершение обслуживания
     */
    endOfExecution?: string;
    /**
     * Исполнитель маршрута
     */
    serviceRoutePerformer?: (service_route_performer | object_link);
    /**
     * Ручной ввод начала обслуживания
     */
    manualStartOfExecution?: string;
    /**
     * Ручной ввод завершения обслуживания
     */
    manualEndOfexecution?: string;
});

export namespace service_route_execution_waypoint {

    /**
     * Статус
     */
    export enum status {
        '_1' = 1,
        '_2' = 2,
    }


}

