<form (ngSubmit)="merge()" [formGroup]="formGroup" #ngForm>
  <ng-container>
    <div matDialogTitle>
      <div class="flex content-between w-full">
        <div class="text-subtitle-3 flex-grow">Слить компанию в</div>
        <hoho-icon
          class="ml-4 text-florida hover:text-margaret transition-colors delay-500 ease-in cursor-pointer"
          size="1rem"
          iconName="multiply"
          mat-dialog-close=""
        ></hoho-icon>
      </div>
    </div>
    <mat-dialog-content>
      <ng-container>
        <hoho-select
            [submitted]="submitted"
            [items]="companyList"
            appearance="fill"
            [control]="companyControl"
            [addTag]="false"
            [searchable]="true"
            placeholder="Выберите компанию*"
            i18n-placeholder
          >
            <mat-error *ngIf="companyControl.invalid">{{ getErrorMessage('id', formGroup) }}</mat-error>
        </hoho-select>
        <div style="height: 240px;"></div>
      </ng-container>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button class="w-full ml-0" color="primary" mat-flat-button type="submit" [disabled]="isLoading">
        <ng-container>Слияние</ng-container>
      </button>
    </mat-dialog-actions>
  </ng-container>
</form>
