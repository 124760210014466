import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutingNames } from '@app/app-routing.module';
import RoutePath from '@app/shared/models/route-path.model';
import { QuicklinkModule } from 'ngx-quicklink';
import { TabsComponent } from './components/tabs/tabs.component';

export const UserRequestsRoutingNames = {
  list: new RoutePath('list', AppRoutingNames.userRequests.path + '/list', ':tab'),
  new: new RoutePath('new', AppRoutingNames.userRequests.path + '/list/new'),
  blocked: new RoutePath('blocked', AppRoutingNames.userRequests.path + '/list/blocked'),
  unconfirmed: new RoutePath('unconfirmed', AppRoutingNames.userRequests.path + '/list/unconfirmed'),
};

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: UserRequestsRoutingNames.new.path,
      },
      {
        path: UserRequestsRoutingNames.list.name + '/' + UserRequestsRoutingNames.list.routeParams,
        component: TabsComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule, QuicklinkModule],
})
export class UserRequestsRouting {}
