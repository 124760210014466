import { Injectable } from '@angular/core';
import { IAccess } from '@app/core/modules/list-builder/models';
import { NavigationService } from '@shared/@vex/services/navigation.service';
import { EEntityList, ICurrentUser, IUserActiveModule } from '@shared/models';
import { UserRestService } from '@shared/services/rest/user-rest.service';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { DataRestService } from '.';
import { map, switchMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AppRoutingNames } from '@app/app-routing.module';
import { AutoDebitInfoComponent } from '@app/pages/personal-area/components/auto-debit-info/auto-debit-info.component';
import { MatDialog } from '@angular/material/dialog';
import { TarifPriceIncreaseModal } from '../components/tarif-price-increase/tarif-price-increase.component';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class UserService {
  public user$: BehaviorSubject<ICurrentUser> = new BehaviorSubject<ICurrentUser>(null);
  private currentUser: ICurrentUser = null;
  constructor(
    private userRestService: UserRestService,
    private navigationService: NavigationService,
    private dataRestService: DataRestService,
    private dialog: MatDialog,
    private router: Router,
  ) { }

  public auth(token: string, chatToken: string): void {
    this.token = token;
    this.chatToken = chatToken;
    this.loadUser().subscribe((res) => {
      if (res) {
        this.navigationService.goToDefaultUrl(this.modules);
      }
    });
  }

  get permission(): boolean {
    return !!this.token;
  }

  get isAdmin(): boolean {
    return !!this.currentUser?.roles?.find((x) => x.roleName == 'Администратор');
  }

  set token(value: string) {
    if (!value) {
      return;
    }
    localStorage.setItem('token', value);
  }

  set chatToken(value: string) {
    if (!value) {
      return;
    }
    localStorage.setItem('chatToken', value);
  }

  get modules(): IUserActiveModule[] {
    return JSON.parse(localStorage.getItem('modules'));
  }

  get token(): string {
    return localStorage.getItem('token');
  }

  get chatToken(): string {
    return localStorage.getItem('chatToken');
  }

  public Access(entity: string): IAccess {
    return (JSON.parse(localStorage.getItem('access')) as IAccess[]).find((x) => x.entity == entity);
  }

  set user(value: ICurrentUser) {
    this.currentUser = value;
    this.user$.next(this.currentUser);
  }

  loadUser(): Observable<boolean> {
    const isLoad: Subject<boolean> = new Subject();
    if (this.token) {
      const chatToken$: Observable<{ chatToken: string }> = this.chatToken
        ? of({ chatToken: this.chatToken })
        : this.userRestService.chatRegistration().pipe(
          untilDestroyed(this),
          switchMap(() => this.userRestService.refreshChatToken()),
        );
      const user$: Observable<ICurrentUser> = this.userRestService.getUserInfo();

      forkJoin([chatToken$, user$])
        .pipe(untilDestroyed(this))
        .subscribe((res: [{ chatToken: string }, ICurrentUser]) => {
          this.chatToken = res[0].chatToken;
          this.user = res[1];
          if (res[1]?.wallet?.Debit < 0 && !res[1]?.agreementExist) {
            this.openAutoDebitInfo(res[1]?.wallet?.Debit * -1);
          }
          localStorage.setItem('modules', JSON.stringify(res[1].modules));
          this.getAccess(isLoad);

          let isOldTarifExists = res[1]?.isOldTarifExists;
          if (isOldTarifExists) {
            let count = 0;
            let countString = localStorage.getItem('tarif_price_increase');
            if (countString) {
              count = Number(countString);
            }
            if (count < 2) {
              this.openTarifPriceIncreaseModal();
            }
          }
        });
      const tokenObj = this.parseJwt(this.token);
      if (tokenObj?.expired && new Date().getTime() * 10000 + 621355968000000000 > tokenObj.expired) return isLoad;
    }
    return isLoad;
  }
  parseJwt(token: string): any {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );
    return JSON.parse(jsonPayload);
  }
  private getAccess(isLoad: Subject<boolean>): void {
    this.dataRestService
      .access([
        EEntityList.vm_group,
        EEntityList.vending_machine,
        EEntityList.company,
        EEntityList.users,
        EEntityList.role,
        EEntityList.user_request,
        EEntityList.product,
        EEntityList.ingredient,
        EEntityList.product_matrix,
        EEntityList.recipe,
        EEntityList.recipe_item,
        EEntityList.price_list,
        EEntityList.promocode,
        EEntityList.sl_user,
        EEntityList.sl_order,
        EEntityList.service_route,
        EEntityList.custom_waypoint,
        EEntityList.vm_cash_state,
        EEntityList.vm_ingredient_state,
        EEntityList.erp_ingredient_fill,
        EEntityList.mailing_error,
      ])
      .subscribe((res: IAccess[]) => {
        localStorage.setItem('access', JSON.stringify(res));
        isLoad.next(true);
      });
  }

  logOut(): void {
    this.user = null;
    localStorage.removeItem('token');
    localStorage.removeItem('access');
    this.router.navigate([AppRoutingNames.login.path]);
  }

  refreshFictitiousChatToken(): Observable<{ userId: number; token: string }> {
    return this.userRestService.getFictitiousChatToken().pipe(
      map((i) => {
        return { userId: i.user.id, token: i.tokenId };
      }),
    );
  }

  refreshUserChatToken(): Observable<{ userId: number; token: string }> {
    return this.userRestService.refreshChatToken().pipe(
      map((token: { chatToken: string }) => {
        return { userId: this.currentUser.chatUserId, token: token.chatToken };
      }),
    );
  }

  checkUserPermission(route: ActivatedRouteSnapshot, url: any): boolean {
    if (!!this.modules) {
      const userModules: IUserActiveModule[] = this.modules;
      const foundModule: IUserActiveModule = userModules.find((i) => i.module === route.data.module);
      if (!foundModule || (route.data.feature && foundModule.features.indexOf(route.data.feature) === -1)) {
        this.router.navigate([AppRoutingNames.login.path]);
        return false;
      } else {
        return true;
      }
    }

    this.router.navigate([AppRoutingNames.login.path]);
    return false;
  }

  openAutoDebitInfo(amount: number): void {
    this.dialog.open(AutoDebitInfoComponent, {
      data: {
        ammount: amount,
      },
      width: '25rem',
    });
  }

  openTarifPriceIncreaseModal(): void {
    this.dialog.open(TarifPriceIncreaseModal, {
      width: '40rem',
      disableClose: true,
    });
  }
}
