/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { vm_log } from '../models/vm_log';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VmLogService {

    /**
     * Select vm_log by id
     * @param id Object ID
     * @returns vm_log Successful operation
     * @throws ApiError
     */
    public static selectVmLogById(
        id: string,
    ): CancelablePromise<vm_log> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/get/object/vm_log/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Save vm_log
     * @param requestBody Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static saveVmLog(
        requestBody?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/save/object/vm_log',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Delete vm_log by id
     * @param id Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static deleteVmLogById(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/delete/object/vm_log/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

}
