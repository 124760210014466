import { AbstractControl, FormControl, FormGroup, UntypedFormArray, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { RegexPatternConstants } from '@app/shared/utilities/regex-pattern-constants';

export interface IAllValidationErrors {
  controlName: string;
  errorName: string;
  errorValue: any;
  errorDescription: string;
}

export interface IFormGroupControls {
  [key: string]: AbstractControl;
}

export function getFormValidationErrors(controls: IFormGroupControls): IAllValidationErrors[] {
  let errors: IAllValidationErrors[] = [];
  Object.keys(controls).forEach((key) => {
    const control = controls[key];
    if (control instanceof UntypedFormGroup) {
      errors = errors.concat(getFormValidationErrors(control.controls));
    }
    const controlErrors: ValidationErrors = controls[key].errors;
    if (controlErrors !== null) {
      Object.keys(controlErrors).forEach((keyError) => {
        errors.push({
          controlName: key,
          errorName: keyError,
          errorValue: controlErrors[keyError],
          errorDescription: getErrorDescription(keyError, controlErrors[keyError]),
        });
      });
    }
  });
  return errors;
}

export function getErrorDescription(errorName: string, value?: any): string {
  switch (errorName) {
    case 'required':
      return $localize`Поле обязательно для заполнения`;
    case 'email':
      return $localize`Неверный адрес элетронной почты`;
    case 'minlength':
      return $localize`Длина должна быть не менее` + ` ${value.requiredLength} ` + $localize`символов`;
    case 'maxlength':
      return $localize`Длина должна быть не более` + ` ${value.requiredLength} ` + $localize`символов`;
    case 'repeatPasswordNotEquals':
      return $localize`Пароли отличаются`;
    case 'orgCode':
      return $localize`ИНН должен содержать 10 или 12 цифр`;
    case 'forbiddenPasswordSymbols':
      return $localize`Пароль должен содержать не менее восьми знаков, включать латинские буквы, цифры и специальные символы .!@_$-+*`;
    default:
      return $localize`Поле введено не корректно`;
  }
}

export function orgCodeValidator(): ValidatorFn {
  const onlyNumberReg = /^\d+$/;
  return (control: AbstractControl): ValidationErrors | null => {
    return (control.value?.length === 10 || control.value?.length === 12) && onlyNumberReg.test(control.value)
      ? null
      : { orgCode: true };
  };
}

export function passwordValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return RegexPatternConstants.password.test(control.value) ? null : { forbiddenPasswordSymbols: true };
  };
}
export function dateWithoutTime(date: string) {
  const tmpDate = new Date(date);
  tmpDate.setHours(0, 0, 0, 0);
  return tmpDate;
}

export const getErrorMessage = (fieldName: string, formGroup: UntypedFormGroup): string => {
  const errors = getFormValidationErrors(formGroup.controls);
  const currentFieldErrors = errors.filter((x: IAllValidationErrors) => {
    return x.controlName === fieldName;
  });
  return currentFieldErrors?.pop()?.errorDescription;
};
export const getErrorMessageForArray = (fieldName: string, index: number, formArray: UntypedFormArray): string => {
  const arrayFormGroup = formArray.controls[index] as UntypedFormGroup;
  const errors = getFormValidationErrors(arrayFormGroup.controls);
  const currentFieldErrors = errors.filter((x: IAllValidationErrors) => x.controlName === fieldName);
  return currentFieldErrors?.pop()?.errorDescription;
};

export type ControlsOf<T extends Record<string, any>> = {
  [K in keyof T]: T[K] extends Record<any, any>
  ? (T extends T[K] ? FormGroup<ControlsOf<any>> | FormControl<any> : FormGroup<ControlsOf<T[K]>> | FormControl<T[K]>)
  : FormControl<T[K]>;
};
