import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthCanActivateGuard } from '@core/guards/auth.can-activate.guard';
import { VexRoutes } from '@shared/@vex/interfaces/vex-route.interface';
import { EModules } from '@shared/models';
import { PermissionsGuard } from '@shared/services';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { BaseLayoutComponent } from './base-layout/base-layout.component';
import RoutePath from './shared/models/route-path.model';

export const AppRoutingNames = {
  default: new RoutePath('', '/'),
  registration: new RoutePath('registration', '/registration'),
  login: new RoutePath('login', '/login'),
  forgotPassword: new RoutePath('forgot-password', '/forgot-password'),
  notFound: new RoutePath('404', '/404'),
  serverError: new RoutePath('500', '/500'),
  vendingMachines: new RoutePath('vending-machines', '/vending-machines'),
  reports: new RoutePath('reports', '/reports'),
  planograms: new RoutePath('planograms', '/planograms'),
  userRequests: new RoutePath('user-requests', '/user-requests'),
  users: new RoutePath('users', '/users'),
  directory: new RoutePath('directory', '/directory', ':tab'),
  serviceMachine: new RoutePath('service-machine', '/service-machine'),
  clients: new RoutePath('clients', '/clients'),
  salesAdministration: new RoutePath('sales-administration', '/sales-administration'),
  support: new RoutePath('support', '/support'),
  promo: new RoutePath('promo', '/promo', ':tab'),
  feedback: new RoutePath('feedback', '/feedback', ':tab'),
  marketing: new RoutePath('marketing', '/marketing'),
  chatBot: new RoutePath('chat-bot', '/chat-bot'),
  reportExcelView: new RoutePath('report-view', '/report-view', ':id'),
  personalArea: new RoutePath('personal-area', '/personal-area'),
  monitoring: new RoutePath('monitoring', '/monitoring'),
  // static
  successPayment: new RoutePath('success-payment', '/success-payment'),
  unsuccessPayment: new RoutePath('unsuccess-payment', '/unsuccess-payment'),
  successCardBind: new RoutePath('success-card-bind', '/success-card-bind'),
  unsuccessCardBind: new RoutePath('unsuccess-card-bind', '/unsuccess-card-bind'),
  knowledgeBase: new RoutePath('knowledge-base', '/knowledge-base'),
};

export const PromoRoutingNames = {
  promocodes: new RoutePath('promocodes', AppRoutingNames.promo.path + '/promocodes'),
  coupons: new RoutePath('coupons', AppRoutingNames.promo.path + '/coupons'),
};

const routes: VexRoutes = [
  {
    path: AppRoutingNames.reportExcelView.name + '/' + AppRoutingNames.reportExcelView.routeParams,
    loadChildren: () => import('./pages/reports/components/preview/preview.module').then((m) => m.PreviewModule),
  },
  {
    path: AppRoutingNames.registration.name,
    loadChildren: () => import('./pages/auth/register/register.module').then((m) => m.RegisterModule),
  },
  {
    path: AppRoutingNames.login.name,
    loadChildren: () => import('./pages/auth/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: AppRoutingNames.forgotPassword.name,
    loadChildren: () =>
      import('./pages/auth/forgot-password/forgot-password.module').then((m) => m.ForgotPasswordModule),
  },
  {
    path: AppRoutingNames.notFound.name,
    loadChildren: () => import('./pages/errors/error-404/error-404.module').then((m) => m.Error404Module),
  },
  {
    path: AppRoutingNames.serverError.name,
    loadChildren: () => import('./pages/errors/error-500/error-500.module').then((m) => m.Error500Module),
  },
  {
    path: '',
    component: BaseLayoutComponent,
    canActivateChild: [AuthCanActivateGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: AppRoutingNames.vendingMachines.name,
      },
      {
        path: AppRoutingNames.vendingMachines.name,
        loadChildren: () =>
          import('./pages/vending-machines/vending-machines.module').then((m) => m.VendingMachinesModule),
        canLoad: [PermissionsGuard],
        data: {
          module: EModules.VendingMachineModule,
        },
      },
      {
        path: AppRoutingNames.reports.name,
        loadChildren: () => import('./pages/reports/reports.module').then((m) => m.ReportsModule),
        canLoad: [PermissionsGuard],
        data: {
          module: EModules.ReportsModule,
        },
      },
      {
        path: AppRoutingNames.planograms.name,
        loadChildren: () => import('./pages/planograms/planograms.module').then((m) => m.PlanogramsModule),
        canLoad: [PermissionsGuard],
        data: {
          module: EModules.PlanogrammModule,
        },
      },
      {
        path: AppRoutingNames.userRequests.name,
        loadChildren: () => import('./pages/user-requests/user-requests.module').then((m) => m.UserRequestsModule),
        canLoad: [PermissionsGuard],
        data: {
          module: EModules.UserRequestModule,
        },
      },
      {
        path: AppRoutingNames.directory.name,
        loadChildren: () => import('./pages/directory/directory.module').then((m) => m.DirectoryModule),
        canLoad: [PermissionsGuard],
        data: {
          module: EModules.DictionariesModule,
        },
      },
      {
        path: AppRoutingNames.promo.name,
        loadChildren: () => import('./pages/promo/promo.module').then((m) => m.PromoModule),
        canLoad: [PermissionsGuard],
        data: {
          module: EModules.PromoModule,
        },
      },
      {
        path: AppRoutingNames.clients.name,
        loadChildren: () => import('./pages/clients/clients.module').then((m) => m.ClientsModule),
        canLoad: [PermissionsGuard],
        data: {
          module: EModules.ClientsModule,
        },
      },
      {
        path: AppRoutingNames.salesAdministration.name,
        loadChildren: () =>
          import('./pages/sales-administration/sales-administration.module').then((m) => m.SalesAdministrationModule),
        canLoad: [PermissionsGuard],
        data: {
          module: EModules.ClientsModule,
        },
      },
      {
        path: AppRoutingNames.serviceMachine.name,
        loadChildren: () =>
          import('./pages/service-machines/service-machines.module').then((m) => m.ServiceMachinesModule),
        canLoad: [PermissionsGuard],
        data: {
          module: EModules.ServiceVendingMachines,
        },
      },
      {
        path: AppRoutingNames.support.name,
        loadChildren: () => import('./pages/support/support.module').then((m) => m.SupportModule),
        canLoad: [PermissionsGuard],
        data: {
          module: EModules.SupportModule,
        },
      },
      {
        path: AppRoutingNames.feedback.name,
        loadChildren: () => import('./pages/feedback/feedback.module').then((m) => m.FeedbackModule),
        canLoad: [PermissionsGuard],
        data: {
          module: EModules.FeedbackModule,
        },
      },
      {
        path: AppRoutingNames.marketing.name,
        loadChildren: () => import('./pages/marketing/marketing.module').then((m) => m.MarketingModule),
        canLoad: [PermissionsGuard],
        data: {
          module: EModules.MarketingModule,
        },
      },
      {
        path: AppRoutingNames.chatBot.name,
        loadChildren: () => import('./pages/chat-bot/chat-bot.module').then((m) => m.ChatBotModule),
        canLoad: [PermissionsGuard],
        data: {
          module: EModules.MailingErrorModule,
        },
      },
			{
        path: AppRoutingNames.personalArea.name,
        loadChildren: () => import('./pages/personal-area/personal-area.module').then((m) => m.PersonalAreaModule),
        canLoad: [PermissionsGuard],
        data: {
          module: EModules.PersonalAreaModule,
        },
      },
      {
        path: AppRoutingNames.monitoring.name,
        loadChildren: () => import('./pages/monitoring/monitoring.module').then((m) => m.MonitoringModule),
        canLoad: [PermissionsGuard],
        data: {
          module: EModules.MonitoringModule,
        }
      },
      //static
      {
        path: AppRoutingNames.successPayment.name,
        loadChildren: () => import('./pages/static/success-payment/success-payment.module').then((m) => m.SuccessPaymentModule),
      },
      {
        path: AppRoutingNames.unsuccessPayment.name,
        loadChildren: () => import('./pages/static/unsuccess-payment/unsuccess-payment.module').then((m) => m.UnsuccessPaymentModule),
      },
      {
        path: AppRoutingNames.successCardBind.name,
        loadChildren: () => import('./pages/static/success-card-bind/success-card-bind.module').then((m) => m.SuccessCardBindModule),
      },
      {
        path: AppRoutingNames.unsuccessCardBind.name,
        loadChildren: () => import('./pages/static/unsuccess-card-bind/unsuccess-card-bind.module').then((m) => m.UnsuccessCardBindModule),
      },
      {
        path: AppRoutingNames.knowledgeBase.name,
        loadChildren: () => import('./pages/static/knowledge-base/knowledge-base.module').then((m) => m.KnowledgeBaseModule),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '404',
  },
  {
    path: '404',
    loadChildren: () => import('./pages/errors/error-404/error-404.module').then((m) => m.Error404Module),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: QuicklinkStrategy,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule, QuicklinkModule],
})
export class AppRoutingModule {}
