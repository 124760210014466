import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NavigationDropdown, NavigationItem, NavigationLink } from '@shared/@vex/interfaces/navigation-item.interface';
import { PermissionsGuard } from '@shared/services';
import { map } from 'rxjs/operators';
import { NavigationService } from '../../services/navigation.service';
import { LayoutService } from '../../services/layout.service';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'vex-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavComponent implements OnInit {
  @Input() collapsed: boolean;

  collapsedOpen$ = this.layoutService.sidenavCollapsedOpen$;

  //  Вариант использования в будущем - показывать роль пользователя
  title$ = this.configService.config$.pipe(map((config) => config.sidenav.title));

  imageUrl$ = this.configService.config$.pipe(map((config) => config.sidenav.imageUrl));

  items: NavigationItem[];

  constructor(
    private navigationService: NavigationService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private permissionsGuard: PermissionsGuard,
  ) {}

  ngOnInit() {
    this.items = this.navigationService.items
      .filter((i) => {
        const item = i as NavigationLink | NavigationDropdown;
        return this.permissionsGuard.haveAccess(item.module);
      })
      .map((i) => {
        const item = i as NavigationDropdown;
        if (item.children) {
          item.children = item.children.filter((j) => {
            const childrenItem = j as NavigationLink;
            return this.permissionsGuard.haveAccess(item.module, childrenItem.feature);
          });
        }
        return i;
      });
  }

  onMouseEnter() {
    this.layoutService.collapseOpenSidenav();
  }

  onMouseLeave() {
    this.layoutService.collapseCloseSidenav();
  }

  // toggleCollapse() {
  //   this.configService.modifyUserSetting(
  //     EConfigModifications.SIDE_NAV_COLLAPSED,
  //     this.collapsed ? 'expanded' : 'collapsed',
  //   );
  //   this.collapsed ? this.layoutService.expandSidenav() : this.layoutService.collapseSidenav();
  // }
}
