import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hoho-profile-dropdown',
  templateUrl: './profile-dropdown.component.html',
  styleUrls: ['./profile-dropdown.component.scss'],
})
export class ProfileDropdownComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
