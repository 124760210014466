import { Component, Input, OnInit } from '@angular/core';
import { ICrumb } from '@shared/@vex/components/breadcrumbs/breadcrumbs.component';

@Component({
  selector: 'hoho-secondary-header',
  templateUrl: './secondary-header.component.html',
  styleUrls: ['./secondary-header.component.scss'],
})
export class SecondaryHeaderComponent implements OnInit {
  @Input() crumbs: ICrumb[];
  @Input() title: string;
  @Input() badgeTitle: string;
  @Input() badgeColor: string;
  @Input() subtitle: string;
  constructor() {}

  ngOnInit(): void {}
}
