export const paths = [
  'id', 
  'billingDescriptor', 
  'fullName',
  'name', 
  'inn', 
  'kpp', 
  'addresses', 
  'email', 
  'ceo', 
  'siteUrl', 
  'bankAccount',
  'shopCode'
];
