import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutingNames } from '@app/app-routing.module';
import RoutePath from '@app/shared/models/route-path.model';
import { QuicklinkModule } from 'ngx-quicklink';
import { ListComponent } from './components/list/list.component';
import { OrdersComponent } from './components/orders/orders.component';
import { PageComponent } from './components/page/page.component';

export const ClientsRoutingNames = {
  clientPage: new RoutePath('page', AppRoutingNames.clients.path + '/page', ':id'),
  orders: new RoutePath('orders', AppRoutingNames.clients.path + '/orders'),
  list: new RoutePath('list', AppRoutingNames.clients.path + '/list'),
};

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'list',
      },

      {
        path: ClientsRoutingNames.clientPage.name + '/' + ClientsRoutingNames.clientPage.routeParams,
        component: PageComponent,
      },
      {
        path: ClientsRoutingNames.list.name,
        component: ListComponent,
      },
      {
        path: ClientsRoutingNames.orders.name,
        component: OrdersComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule, QuicklinkModule],
})
export class Routing {}
