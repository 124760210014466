<button
  class="hover:border-natasha duration-500 transition-colors ease-in-out hover:bg-{{
    color
  }} hover:text-natasha  w-full flex rounded-md border-{{ color }} border text-{{ color }} px-{{
    iconOnly ? '4' : '7'
  }} py-2 relative"
>
  <hoho-icon *ngIf="iconName && !iconOnly" class="absolute left-2 top-3" [iconName]="iconName"></hoho-icon>
  <hoho-icon *ngIf="iconName && iconOnly" [iconName]="iconName"></hoho-icon>
  <span class="block w-full"><ng-content></ng-content></span>
</button>
