import { VendingMachinesRoutingNames } from '../../../../../../../pages/vending-machines/vending-machines.routing';
import { Router } from '@angular/router';
import { IVendingMachineBase } from '@shared/models/vending-machines.models';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CardTitleStatusEnum } from '../../enums/card-title-status.enum';

@Component({
  selector: 'hoho-card-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
})
export class CardTitleComponent {
  @Input() model: IVendingMachineBase;
  @Input() status: CardTitleStatusEnum;
  @Input() isDialog: boolean = false;
  @Output() closeEvent = new EventEmitter();

  statusEnum = CardTitleStatusEnum;
  constructor(private router: Router) {}

  onClick() {
    this.router.navigate([VendingMachinesRoutingNames.page.path + '/' + this.model.id]);
  }
  close() {
    this.closeEvent.emit();
  }
}
