import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hoho-router-card',
  templateUrl: './router-card.component.html',
  styleUrls: ['./router-card.component.scss'],
})
export class RouterCardComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
