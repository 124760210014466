import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRoutingNames } from '@app/app-routing.module';
import { IFilterFormSpec } from '@app/pages/marketing/components/group-create-update/group-create-update.models';
import {
  getFilters,
  parseFilters,
} from '@app/pages/marketing/components/group-create-update/grpup-create-update.utilities';
import { MarketingRoutingNames } from '@app/pages/marketing/marketing.routing';
import { ListBuilderComponent } from '@core/modules/list-builder/list-builder.component';
import { EListBuilderViews, IAccess, IListBuilderProps } from '@core/modules/list-builder/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ICrumb } from '@shared/@vex/components/breadcrumbs/breadcrumbs.component';
import { EEntityList, IPagingResponsePayload, IServerRequestPayload } from '@shared/models';
import { DataRestService, UserService } from '@shared/services';
import { getErrorMessage } from '@shared/utilities';
import { debounceTime } from 'rxjs/operators';
import { fadeInUp400ms } from '@shared/@vex/animations/fade-in-up.animation';
import { stagger40ms } from '@shared/@vex/animations/stagger.animation';
import { scaleFadeIn400ms } from '@shared/@vex/animations/scale-fade-in.animation';
import { city, marketing_customers_group, user_request, users, vending_machine } from '@src/api';
import { merge } from 'rxjs';

@UntilDestroy()
@Component({
  templateUrl: './group-create-update.component.html',
  styleUrls: ['./group-create-update.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],
})
export class GroupCreateUpdateComponent implements OnInit {
  submitted: boolean;
  cities: city[];
  equality: number = 0;

  genderTypeList: {
    id: number;
    name: string;
  }[] = [
    {
      id: 1,
      name: $localize`Мужской`,
    },
    {
      id: 0,
      name: $localize`Женский`,
    },
  ];
  crumbs: ICrumb[];
  isLoading: boolean = false;
  formGroup: UntypedFormGroup;
  showRest: boolean;
  groupBuilderProps: IListBuilderProps<user_request> = {
    entity: EEntityList.sl_user,
    views: [EListBuilderViews.table],
  };
  userRequestAccess: IAccess;
  vmsList: IPagingResponsePayload<vending_machine> = null;
  requestPayload: IServerRequestPayload;
  EEntityList = EEntityList;
  vmsFilterPaths: string[] = ['id', 'name', 'address', 'nameAndSerial', 'displayName'];
  title: string;
  marketingGroup: marketing_customers_group;
  @ViewChild('tableBuilder') tableBuilderComponent: ListBuilderComponent<users>;
  getErrorMessage = getErrorMessage;
  constructor(
    private fb: UntypedFormBuilder,
    private dataRestService: DataRestService,
    private userService: UserService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.getMmsList();
    this.userRequestAccess = this.userService.Access(EEntityList.user_request);
    this.formGroup = this.fb.group({
      id: [],
      name: [null, Validators.required],
      genderType: [],
      birthday: [],
      city: [],
      daysAfterRegistration: [],
      ordersInLastMonth: [],
      orderCount: [],
      orderSum: [],
      walletBalance: [],
      vendingMachineId: [],
    });
    const controls = merge(
      this.genderTypeControl.valueChanges,
      this.birthdayControl.valueChanges,
      this.cityControl.valueChanges,
      this.daysAfterRegistrationCtrl.valueChanges,
      this.formGroup.get('ordersInLastMonth').valueChanges,
      this.formGroup.get('orderCount').valueChanges,
      this.formGroup.get('orderSum').valueChanges,
      this.walletBalanceCtrl.valueChanges,
      this.vendingMachineIdControl.valueChanges,
    );
    if (this.activatedRoute.snapshot.params.id) {
      this.getGroup(this.activatedRoute.snapshot.params.id);
    }
    this.crumbs = [
      { label: $localize`Маркетинг`, link: AppRoutingNames.marketing.path },
      { label: $localize`Группы клиентов`, link: AppRoutingNames.marketing.path + '/lists/group' },
      { label: this.activatedRoute.snapshot.params.id ? $localize`Редактировать` : $localize`Создать` },
    ];
    this.title = this.activatedRoute.snapshot.params.id ? 'Редактировать группу клиентов' : 'Создать группу клиентов';
    this.formGroup.valueChanges.pipe().subscribe(() => {
      this.showRest = true;
    });

    const citesPayload: IServerRequestPayload = {
      filter: {},
      paths: ['id', 'name', 'entity'],
    };
    this.dataRestService.page<city>(EEntityList.city, '', 0, -1, citesPayload).subscribe((res) => {
      this.cities = res.content;
    });

    controls.pipe(untilDestroyed(this), debounceTime(500)).subscribe(() => {
      const filterValues = this.formGroup.value as IFilterFormSpec;
      getFilters(filterValues);
      this.requestPayload = {
        filter: getFilters(filterValues),
      };
      this.tableBuilderComponent.loadEntitiesList(this.requestPayload);
    });
  }

  private getGroup(id: string): void {
    this.isLoading = true;
    this.dataRestService
      .get<marketing_customers_group>(EEntityList.marketing_customers_group, id, ['id', 'name', 'filter', 'entity'])
      .subscribe((res: marketing_customers_group) => {
        this.marketingGroup = res;
        this.formGroup.patchValue({ name: res.name, id: res.id, ...parseFilters(res.filter) });
        this.isLoading = false;
      });
  }

  restFilter(): void {
    const name = this.formGroup.value.name;
    this.formGroup.reset();
    if (this.marketingGroup) {
      this.formGroup.get('id').setValue(this.marketingGroup.id);
      this.formGroup.get('name').setValue(name);
    }
    this.showRest = false;
  }

  get genderTypeControl(): UntypedFormControl {
    return this.formGroup.get('genderType') as UntypedFormControl;
  }

  get cityControl(): UntypedFormControl {
    return this.formGroup.get('city') as UntypedFormControl;
  }
  get birthdayControl(): UntypedFormControl {
    return this.formGroup.get('birthday') as UntypedFormControl;
  }

  get vendingMachineIdControl(): UntypedFormControl {
    return this.formGroup.get('vendingMachineId') as UntypedFormControl;
  }

  getMmsList(): void {
    const payload: IServerRequestPayload = {
      paths: ['id', 'name', 'address', 'nameAndSerial', 'displayName'],
      sort: null,
    };
    this.dataRestService
      .page<vending_machine>(EEntityList.vending_machine, '', 0, -1, payload)
      .pipe(untilDestroyed(this))
      .subscribe(
        (res: IPagingResponsePayload<vending_machine>) => {
          this.vmsList = res;
        },
        () => {},
      );
  }

  get walletBalanceCtrl(): UntypedFormControl {
    return this.formGroup.get('walletBalance') as UntypedFormControl;
  }

  get daysAfterRegistrationCtrl(): UntypedFormControl {
    return this.formGroup.get('daysAfterRegistration') as UntypedFormControl;
  }

  submit(): void {
    const data: marketing_customers_group = {
      id: this.formGroup.value.id,
      name: this.formGroup.value.name,
      entity: EEntityList.marketing_customers_group,
      filter: this.requestPayload.filter,
    };
    this.isLoading = true;
    this.dataRestService
      .save<marketing_customers_group>(data, EEntityList.marketing_customers_group)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.router.navigate([MarketingRoutingNames.group.path]);
        },
        () => {
          this.isLoading = false;
        },
      );
  }
}
