import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AppRoutingNames } from '@app/app-routing.module';
import { PermissionsGuard } from '@app/shared/services';
import { ListBuilderComponent } from '@core/modules/list-builder/list-builder.component';
import { IAdditionalColumn } from '@core/modules/list-builder/list-builder.models';
import { EListBuilderViews, IAccess, IListBuilderProps } from '@core/modules/list-builder/models';
import { ICrumb } from '@shared/@vex/components/breadcrumbs/breadcrumbs.component';
import { EEntityList, EFeedbackFeatures, EModules, IEntity, IPagingRequestFilters } from '@shared/models';

@Component({
  templateUrl: './graphic-list.component.html',
  styleUrls: ['./graphic-list.component.scss'],
  selector: 'hoho-graphic-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GraphicListComponent implements OnInit, AfterViewInit {
  @Input() access: IAccess = { create: true, delete: true, read: true, update: true, entity: null };
  @ViewChild('tableBuilder') tableBuilderComponent: ListBuilderComponent<IEntity>;
  @ViewChild('link') link: TemplateRef<HTMLTemplateElement>;
  tableBuilderProps: IListBuilderProps<IEntity> = {
    entity: EEntityList.feedback,
    views: [EListBuilderViews.table],
    openFunc: () => null,
  };
  crumbs: ICrumb[];
  title = $localize`Детализация отметок`;
  additionalColumns: IAdditionalColumn[];
  EModules = EModules;
  EFeedbackFeatures = EFeedbackFeatures;
  constructor(
    private activatedRoute: ActivatedRoute,
    public permissionsGuard: PermissionsGuard,
    ) {}

  ngOnInit(): void {
    const filter = this.activatedRoute.snapshot.queryParamMap.get('filter');
    const params: IPagingRequestFilters = JSON.parse(filter);
    const datePurchase = params['datePurchase'];
    const vendingMachine = params['vendingMachine'];
    this.crumbs = [
      { label: $localize`Обратная связь`, link: AppRoutingNames.feedback.path },
      {
        label: $localize`График`,
        link: AppRoutingNames.feedback.path + '/dashboard',
        queryParams: { datePurchase: JSON.stringify(datePurchase), vendingMachine: vendingMachine },
      },
      { label: $localize`Страница детализации` },
    ];
  }

  ngAfterViewInit(): void {
    this.additionalColumns = [
      {
        name: 'link',
        template: this.link,
        desc: '',
      },
    ];
  }
}
