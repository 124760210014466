/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { vm_location_formats } from '../models/vm_location_formats';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VmLocationFormatsService {

    /**
     * Select vm_location_formats by id
     * @param id Object ID
     * @returns vm_location_formats Successful operation
     * @throws ApiError
     */
    public static selectVmLocationFormatsById(
        id: string,
    ): CancelablePromise<vm_location_formats> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/get/object/vm_location_formats/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Save vm_location_formats
     * @param requestBody Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static saveVmLocationFormats(
        requestBody?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/save/object/vm_location_formats',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Delete vm_location_formats by id
     * @param id Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static deleteVmLocationFormatsById(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/delete/object/vm_location_formats/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

}
