export class RegisterMarketplacePointResponse {
  isSuccess: boolean;
  errorMessage: string;
}

export const AddressTypeChose: any[] = [
  {
    name: 'Юридический',
    id: 'legal',
  },
  {
    name: 'Фактический',
    id: 'actual',
  },
  {
    name: 'Почтовый',
    id: 'post',
  },
  {
    name: 'Прочий',
    id: 'other',
  },
];

export const ConutryCodes: any[] = [
  {
      "name": "Австралия",
      "id": "AUS"
  },
  {
      "name": "Австрия",
      "id": "AUT"
  },
  {
      "name": "Азербайджан",
      "id": "AZE"
  },
  {
      "name": "Албания",
      "id": "ALB"
  },
  {
      "name": "Алжир",
      "id": "DZA"
  },
  {
      "name": "Американское Самоа",
      "id": "ASM"
  },
  {
      "name": "Ангилья",
      "id": "AIA"
  },
  {
      "name": "Ангола",
      "id": "AGO"
  },
  {
      "name": "Андорра",
      "id": "AND"
  },
  {
      "name": "Антарктида",
      "id": "ATA"
  },
  {
      "name": "Антигуа и Барбуда",
      "id": "ATG"
  },
  {
      "name": "Аргентина",
      "id": "ARG"
  },
  {
      "name": "Армения",
      "id": "ARM"
  },
  {
      "name": "Аруба",
      "id": "ABW"
  },
  {
      "name": "Афганистан",
      "id": "AFG"
  },
  {
      "name": "Багамы",
      "id": "BHS"
  },
  {
      "name": "Бангладеш",
      "id": "BGD"
  },
  {
      "name": "Барбадос",
      "id": "BRB"
  },
  {
      "name": "Бахрейн",
      "id": "BHR"
  },
  {
      "name": "Беларусь",
      "id": "BLR"
  },
  {
      "name": "Белиз",
      "id": "BLZ"
  },
  {
      "name": "Бельгия",
      "id": "BEL"
  },
  {
      "name": "Бенин",
      "id": "BEN"
  },
  {
      "name": "Бермуды",
      "id": "BMU"
  },
  {
      "name": "Болгария",
      "id": "BGR"
  },
  {
      "name": "Боливия",
      "id": "BOL"
  },
  {
      "name": "Босния и Герцеговина",
      "id": "BIH"
  },
  {
      "name": "Ботсвана",
      "id": "BWA"
  },
  {
      "name": "Бразилия",
      "id": "BRA"
  },
  {
      "name": "Британская Территория в Индийском Океане",
      "id": "IOT"
  },
  {
      "name": "Бруней-Даруссалам",
      "id": "BRN"
  },
  {
      "name": "Буркина-Фасо",
      "id": "BFA"
  },
  {
      "name": "Бурунди",
      "id": "BDI"
  },
  {
      "name": "Бутан",
      "id": "BTN"
  },
  {
      "name": "Вануату",
      "id": "VUT"
  },
  {
      "name": "Ватикан",
      "id": "VAT"
  },
  {
      "name": "Венгрия",
      "id": "HUN"
  },
  {
      "name": "Венесуэла",
      "id": "VEN"
  },
  {
      "name": "Виргинские острова, Британские",
      "id": "VGB"
  },
  {
      "name": "Виргинские острова, США",
      "id": "VIR"
  },
  {
      "name": "Вьетнам",
      "id": "VNM"
  },
  {
      "name": "Габон",
      "id": "GAB"
  },
  {
      "name": "Гаити",
      "id": "HTI"
  },
  {
      "name": "Гайана",
      "id": "GUY"
  },
  {
      "name": "Гамбия",
      "id": "GMB"
  },
  {
      "name": "Гана",
      "id": "GHA"
  },
  {
      "name": "Гваделупа",
      "id": "GLP"
  },
  {
      "name": "Гватемала",
      "id": "GTM"
  },
  {
      "name": "Гвинея",
      "id": "GIN"
  },
  {
      "name": "Гвинея-Бисау",
      "id": "GNB"
  },
  {
      "name": "Германия",
      "id": "DEU"
  },
  {
      "name": "Гибралтар",
      "id": "GIB"
  },
  {
      "name": "Гондурас",
      "id": "HND"
  },
  {
      "name": "Гонконг",
      "id": "HKG"
  },
  {
      "name": "Гренада",
      "id": "GRD"
  },
  {
      "name": "Гренландия",
      "id": "GRL"
  },
  {
      "name": "Греция",
      "id": "GRC"
  },
  {
      "name": "Грузия",
      "id": "GEO"
  },
  {
      "name": "Гуам",
      "id": "GUM"
  },
  {
      "name": "Дания",
      "id": "DNK"
  },
  {
      "name": "Джибути",
      "id": "DJI"
  },
  {
      "name": "Доминика",
      "id": "DMA"
  },
  {
      "name": "Доминиканская Республика",
      "id": "DOM"
  },
  {
      "name": "Египет",
      "id": "EGY"
  },
  {
      "name": "Замбия",
      "id": "ZMB"
  },
  {
      "name": "Западная Сахара",
      "id": "ESH"
  },
  {
      "name": "Зимбабве",
      "id": "ZWE"
  },
  {
      "name": "Израиль",
      "id": "ISR"
  },
  {
      "name": "Индия",
      "id": "IND"
  },
  {
      "name": "Индонезия",
      "id": "IDN"
  },
  {
      "name": "Иордания",
      "id": "JOR"
  },
  {
      "name": "Ирак",
      "id": "IRQ"
  },
  {
      "name": "Иран, Исламская Республика",
      "id": "IRN"
  },
  {
      "name": "Ирландия",
      "id": "IRL"
  },
  {
      "name": "Исландия",
      "id": "ISL"
  },
  {
      "name": "Испания",
      "id": "ESP"
  },
  {
      "name": "Италия",
      "id": "ITA"
  },
  {
      "name": "Йемен",
      "id": "YEM"
  },
  {
      "name": "Кабо-Верде",
      "id": "CPV"
  },
  {
      "name": "Казахстан",
      "id": "KAZ"
  },
  {
      "name": "Камбоджа",
      "id": "KHM"
  },
  {
      "name": "Камерун",
      "id": "CMR"
  },
  {
      "name": "Канада",
      "id": "CAN"
  },
  {
      "name": "Катар",
      "id": "QAT"
  },
  {
      "name": "Кения",
      "id": "KEN"
  },
  {
      "name": "Кипр",
      "id": "CYP"
  },
  {
      "name": "Киргизия",
      "id": "KGZ"
  },
  {
      "name": "Кирибати",
      "id": "KIR"
  },
  {
      "name": "Китай",
      "id": "CHN"
  },
  {
      "name": "Кокосовые (Килинг) острова",
      "id": "CCK"
  },
  {
      "name": "Колумбия",
      "id": "COL"
  },
  {
      "name": "Коморы",
      "id": "COM"
  },
  {
      "name": "Конго",
      "id": "COG"
  },
  {
      "name": "Конго, Демократическая Республика",
      "id": "COD"
  },
  {
      "name": "Корея, Народно-Демократическая Республика",
      "id": "PRK"
  },
  {
      "name": "Корея, Республика",
      "id": "KOR"
  },
  {
      "name": "Коста-Рика",
      "id": "CRI"
  },
  {
      "name": "Кот Д'Ивуар",
      "id": "CIV"
  },
  {
      "name": "Куба",
      "id": "CUB"
  },
  {
      "name": "Кувейт",
      "id": "KWT"
  },
  {
      "name": "Лаосская Народно-Демократическая Республика",
      "id": "LAO"
  },
  {
      "name": "Латвия",
      "id": "LVA"
  },
  {
      "name": "Лесото",
      "id": "LSO"
  },
  {
      "name": "Либерия",
      "id": "LBR"
  },
  {
      "name": "Ливан",
      "id": "LBN"
  },
  {
      "name": "Ливийская Арабская Джамахирия",
      "id": "LBY"
  },
  {
      "name": "Литва",
      "id": "LTU"
  },
  {
      "name": "Лихтенштейн",
      "id": "LIE"
  },
  {
      "name": "Люксембург",
      "id": "LUX"
  },
  {
      "name": "Маврикий",
      "id": "MUS"
  },
  {
      "name": "Мавритания",
      "id": "MRT"
  },
  {
      "name": "Мадагаскар",
      "id": "MDG"
  },
  {
      "name": "Майотта",
      "id": "MYT"
  },
  {
      "name": "Македония, бывшая Югославская Республика",
      "id": "MKD"
  },
  {
      "name": "Малави",
      "id": "MWI"
  },
  {
      "name": "Малайзия",
      "id": "MYS"
  },
  {
      "name": "Мали",
      "id": "MLI"
  },
  {
      "name": "Мальдивы",
      "id": "MDV"
  },
  {
      "name": "Мальта",
      "id": "MLT"
  },
  {
      "name": "Марокко",
      "id": "MAR"
  },
  {
      "name": "Мартиника",
      "id": "MTQ"
  },
  {
      "name": "Маршалловы острова",
      "id": "MHL"
  },
  {
      "name": "Мексика",
      "id": "MEX"
  },
  {
      "name": "Микронезия, Федеративные Штаты",
      "id": "FSM"
  },
  {
      "name": "Мозамбик",
      "id": "MOZ"
  },
  {
      "name": "Молдова, Республика",
      "id": "MDA"
  },
  {
      "name": "Монако",
      "id": "MCO"
  },
  {
      "name": "Монголия",
      "id": "MNG"
  },
  {
      "name": "Монтсеррат",
      "id": "MSR"
  },
  {
      "name": "Мьянма",
      "id": "MMR"
  },
  {
      "name": "Намибия",
      "id": "NAM"
  },
  {
      "name": "Науру",
      "id": "NRU"
  },
  {
      "name": "Непал",
      "id": "NPL"
  },
  {
      "name": "Нигер",
      "id": "NER"
  },
  {
      "name": "Нигерия",
      "id": "NGA"
  },
  {
      "name": "Нидерландские Антилы",
      "id": "ANT"
  },
  {
      "name": "Нидерланды",
      "id": "NLD"
  },
  {
      "name": "Никарагуа",
      "id": "NIC"
  },
  {
      "name": "Ниуэ",
      "id": "NIU"
  },
  {
      "name": "Новая Зеландия",
      "id": "NZL"
  },
  {
      "name": "Новая Каледония",
      "id": "NCL"
  },
  {
      "name": "Норвегия",
      "id": "NOR"
  },
  {
      "name": "Объединенные Арабские Эмираты",
      "id": "ARE"
  },
  {
      "name": "Оман",
      "id": "OMN"
  },
  {
      "name": "Остров Буве",
      "id": "BVT"
  },
  {
      "name": "Остров Норфолк",
      "id": "NFK"
  },
  {
      "name": "Остров Рождества",
      "id": "CXR"
  },
  {
      "name": "Остров Херд и острова Макдональд",
      "id": "HMD"
  },
  {
      "name": "Острова Кайман",
      "id": "CYM"
  },
  {
      "name": "Острова Кука",
      "id": "COK"
  },
  {
      "name": "Острова Теркс и Кайкос",
      "id": "TCA"
  },
  {
      "name": "Пакистан",
      "id": "PAK"
  },
  {
      "name": "Палау",
      "id": "PLW"
  },
  {
      "name": "Панама",
      "id": "PAN"
  },
  {
      "name": "Папуа-Новая Гвинея",
      "id": "PNG"
  },
  {
      "name": "Парагвай",
      "id": "PRY"
  },
  {
      "name": "Перу",
      "id": "PER"
  },
  {
      "name": "Питкерн",
      "id": "PCN"
  },
  {
      "name": "Польша",
      "id": "POL"
  },
  {
      "name": "Португалия",
      "id": "PRT"
  },
  {
      "name": "Пуэрто-Рико",
      "id": "PRI"
  },
  {
      "name": "Реюньон",
      "id": "REU"
  },
  {
      "name": "Россия",
      "id": "RUS"
  },
  {
      "name": "Руанда",
      "id": "RWA"
  },
  {
      "name": "Румыния",
      "id": "ROU"
  },
  {
      "name": "Сальвадор",
      "id": "SLV"
  },
  {
      "name": "Самоа",
      "id": "WSM"
  },
  {
      "name": "Сан-Марино",
      "id": "SMR"
  },
  {
      "name": "Сан-Томе и Принсипи",
      "id": "STP"
  },
  {
      "name": "Саудовская Аравия",
      "id": "SAU"
  },
  {
      "name": "Свазиленд",
      "id": "SWZ"
  },
  {
      "name": "Святая Елена",
      "id": "SHN"
  },
  {
      "name": "Северные Марианские острова",
      "id": "MNP"
  },
  {
      "name": "Сейшелы",
      "id": "SYC"
  },
  {
      "name": "Сен-Пьер и Микелон",
      "id": "SPM"
  },
  {
      "name": "Сенегал",
      "id": "SEN"
  },
  {
      "name": "Сент-Винсент и Гренадины",
      "id": "VCT"
  },
  {
      "name": "Сент-Китс и Невис",
      "id": "KNA"
  },
  {
      "name": "Сент-Люсия",
      "id": "LCA"
  },
  {
      "name": "Сербия и Черногория",
      "id": "SCG"
  },
  {
      "name": "Сингапур",
      "id": "SGP"
  },
  {
      "name": "Сирийская Арабская Республика",
      "id": "SYR"
  },
  {
      "name": "Словакия",
      "id": "SVK"
  },
  {
      "name": "Словения",
      "id": "SVN"
  },
  {
      "name": "Соединенное Королевство",
      "id": "GBR"
  },
  {
      "name": "Соединенные Штаты",
      "id": "USA"
  },
  {
      "name": "Соломоновы острова",
      "id": "SLB"
  },
  {
      "name": "Сомали",
      "id": "SOM"
  },
  {
      "name": "Судан",
      "id": "SDN"
  },
  {
      "name": "Суринам",
      "id": "SUR"
  },
  {
      "name": "Сьерра-Леоне",
      "id": "SLE"
  },
  {
      "name": "Таджикистан",
      "id": "TJK"
  },
  {
      "name": "Таиланд",
      "id": "THA"
  },
  {
      "name": "Танзания, Объединенная Республика",
      "id": "TZA"
  },
  {
      "name": "Тимор-Лесте",
      "id": "TLS"
  },
  {
      "name": "Того",
      "id": "TGO"
  },
  {
      "name": "Токелау",
      "id": "TKL"
  },
  {
      "name": "Тонга",
      "id": "TON"
  },
  {
      "name": "Тринидад и Тобаго",
      "id": "TTO"
  },
  {
      "name": "Тувалу",
      "id": "TUV"
  },
  {
      "name": "Тунис",
      "id": "TUN"
  },
  {
      "name": "Туркмения",
      "id": "TKM"
  },
  {
      "name": "Турция",
      "id": "TUR"
  },
  {
      "name": "Уганда",
      "id": "UGA"
  },
  {
      "name": "Узбекистан",
      "id": "UZB"
  },
  {
      "name": "Украина",
      "id": "UKR"
  },
  {
      "name": "Уоллис и Футуна",
      "id": "WLF"
  },
  {
      "name": "Уругвай",
      "id": "URY"
  },
  {
      "name": "Фарерские острова",
      "id": "FRO"
  },
  {
      "name": "Фиджи",
      "id": "FJI"
  },
  {
      "name": "Филиппины",
      "id": "PHL"
  },
  {
      "name": "Финляндия",
      "id": "FIN"
  },
  {
      "name": "Фолклендские острова (Мальвинские)",
      "id": "FLK"
  },
  {
      "name": "Франция",
      "id": "FRA"
  },
  {
      "name": "Французская Гвиана",
      "id": "GUF"
  },
  {
      "name": "Французская Полинезия",
      "id": "PYF"
  },
  {
      "name": "Французские Южные Территории",
      "id": "ATF"
  },
  {
      "name": "Хорватия",
      "id": "HRV"
  },
  {
      "name": "Центрально-Африканская Республика",
      "id": "CAF"
  },
  {
      "name": "Чад",
      "id": "TCD"
  },
  {
      "name": "Чешская Республика",
      "id": "CZE"
  },
  {
      "name": "Чили",
      "id": "CHL"
  },
  {
      "name": "Швейцария",
      "id": "CHE"
  },
  {
      "name": "Швеция",
      "id": "SWE"
  },
  {
      "name": "Шпицберген и Ян Майен",
      "id": "SJM"
  },
  {
      "name": "Шри-Ланка",
      "id": "LKA"
  },
  {
      "name": "Эквадор",
      "id": "ECU"
  },
  {
      "name": "Экваториальная Гвинея",
      "id": "GNQ"
  },
  {
      "name": "Эритрея",
      "id": "ERI"
  },
  {
      "name": "Эстония",
      "id": "EST"
  },
  {
      "name": "Эфиопия",
      "id": "ETH"
  },
  {
      "name": "Южная Африка",
      "id": "ZAF"
  },
  {
      "name": "Южная Джорджия и Южные Сандвичевы острова",
      "id": "SGS"
  },
  {
      "name": "Ямайка",
      "id": "JAM"
  },
  {
      "name": "Япония",
      "id": "JPN"
  }
];