import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutingNames } from '@app/app-routing.module';
import { GraphicListComponent } from '@app/pages/feedback/components/graphic-list/graphic-list.component';
import { PageComponent } from '@app/pages/feedback/components/page/page.component';
import RoutePath from '@app/shared/models/route-path.model';
import { QuicklinkModule } from 'ngx-quicklink';
import { TabsComponent } from './components/tabs/tabs.component';
import { FeedbackComponent } from './feedback.component';
import { VmFeedbacksComponent } from '@app/pages/feedback/components/vm-feedbacks/vm-feedbacks.component';

export const FeedbackRoutingNames = {
  feedbackPage: new RoutePath('page', AppRoutingNames.feedback.path + '/page', ':id'),
  vmFeedbackListPage: new RoutePath('vending-machine', AppRoutingNames.feedback.path + '/vending-machine', ':id'),
  table: new RoutePath('table', AppRoutingNames.feedback.path + '/table'),
  dashboard: new RoutePath('dashboard', AppRoutingNames.feedback.path + '/dashboard'),
  reviews: new RoutePath('reviews', AppRoutingNames.feedback.path + '/reviews'),
  graphicList: new RoutePath('graphic', AppRoutingNames.feedback.path + '/dashboard/graphic'),
};

const routes: Routes = [
  {
    path: '',
    component: FeedbackComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: FeedbackRoutingNames.dashboard.path,
      },
      {
        path: AppRoutingNames.feedback.routeParams,
        component: TabsComponent,
      },
    ],
  },
  {
    path: FeedbackRoutingNames.dashboard.name + '/' + FeedbackRoutingNames.graphicList.name,
    component: GraphicListComponent,
  },
  {
    path: FeedbackRoutingNames.feedbackPage.name + '/' + FeedbackRoutingNames.feedbackPage.routeParams,
    component: PageComponent,
  },
  {
    path: FeedbackRoutingNames.vmFeedbackListPage.name + '/' + FeedbackRoutingNames.vmFeedbackListPage.routeParams,
    component: VmFeedbacksComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule, QuicklinkModule],
})
export class Routing {}
