import { EUserRequestStatus } from './../models/user-request';
import { EEntityList } from '@shared/models';
import { BehaviorSubject } from 'rxjs';
import { DataRestService } from '@shared/services';
import { Injectable } from '@angular/core';
import { UserService } from './user.service';

export class UserRequestAggregatedInfo {
  constructor(public newCount: number, public blockedCount: number) {}
}

@Injectable({
  providedIn: 'root',
})
export class UserRequestService {
  public requests$ = new BehaviorSubject<UserRequestAggregatedInfo>(null);
  private refreshIntervalInMinutes: number = 5;
  private interval: NodeJS.Timer;
  constructor(private dataRestService: DataRestService, private userService: UserService) {
    if (this.userService.isAdmin) {
      this.init();
    }

    this.userService.user$.subscribe((x) => {
      if (x && this.userService.isAdmin) this.init();
      if (!x) clearInterval(this.interval);
    });
  }

  public refresh() {
    const requestPayload = {
      paths: ['requestStatus'],
      group: ['requestStatus'],
      aggregate: {
        count: true,
      },
    };
    this.dataRestService
      .group(EEntityList.user_request, null, 0, 200, requestPayload)
      .toPromise()
      .then((response: any) => {
        this.requests$.next(
          new UserRequestAggregatedInfo(
            response.content.find((group: any) => group.requestStatus === EUserRequestStatus.new)?.count || 0,
            response.content.find((group: any) => group.requestStatus === EUserRequestStatus.blocked)?.count || 0,
          ),
        );
      });
  }

  private init() {
    this.refresh();

    this.interval = setInterval(() => {
      this.refresh();
    }, this.refreshIntervalInMinutes * 60 * 1000);
  }
}
