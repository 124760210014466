<mat-icon *ngIf="isDefaultIcon" [icIcon]="icon" 
  [height]="size" [width]="size" 
  [size]="size"
  [ngClass]="className"
  [ngStyle]="{ 'height': size, 'width': size }"
></mat-icon>

<mat-icon *ngIf="!isDefaultIcon" [svgIcon]="iconName"
  [ngClass]="(className ? 'custom-color ' : '') +  className"
  [ngStyle]="{ 'height': size, 'width': size }"
></mat-icon>
