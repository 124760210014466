import { Injectable } from '@angular/core';
import { IServerGridColumn } from '@core/modules/list-builder/models/grid.models';
import { EEntityList } from '@shared/models/entity.models';
import { IPagingResponsePayload, IServerRequestPayload } from '@shared/models/rest.models';
import { DataRestService } from '@shared/services';
import { vending_machine } from '@src/api';
import { Observable } from 'rxjs';

@Injectable()
export class MapsService {
  list: vending_machine[];

  constructor(private dataRestService: DataRestService) {
    this.dataRestService.getGridInfo(EEntityList.vending_machine);
  }

  getPayload(): Observable<IServerGridColumn[]> {
    return this.dataRestService.getGridInfo(EEntityList.vending_machine);
  }

  loadVendingMachines(payload: IServerRequestPayload): Observable<IPagingResponsePayload<vending_machine>> {
    return this.dataRestService.page<vending_machine>(EEntityList.vending_machine, null, 0, 1000, payload);
  }
}
