/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { custom_support_appeal } from '../models/custom_support_appeal';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomSupportAppealService {

    /**
     * Select custom_support_appeal by id
     * @param id Object ID
     * @returns custom_support_appeal Successful operation
     * @throws ApiError
     */
    public static selectCustomSupportAppealById(
        id: string,
    ): CancelablePromise<custom_support_appeal> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/get/object/custom_support_appeal/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Save custom_support_appeal
     * @param requestBody Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static saveCustomSupportAppeal(
        requestBody?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/save/object/custom_support_appeal',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Delete custom_support_appeal by id
     * @param id Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static deleteCustomSupportAppealById(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/delete/object/custom_support_appeal/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

}
