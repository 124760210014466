import { SafeHtml } from "@angular/platform-browser";

export interface IDialogModels {
  type?: EDialogTypes;
  close?: boolean;
  title?: string;
  content?: string;
  contentHtml?: SafeHtml;
  ok?: boolean;
  cancel?: boolean;
  okCallback?: Function;
  cancelCallback?: Function;
  okLabel?: string;
  cancelLabel?: string;
}

export enum EDialogTypes {
  message,
  confirm,
}
