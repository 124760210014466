<div class="flex items-center">
  <hoho-icon class="mr-2"
    [iconName]="value === communicationLevelEnum.NoConnection
      ? 'communication-level-no'
      : value === communicationLevelEnum.LowConnectionLevel
        ? 'communication-level-low'
        : value === communicationLevelEnum.ConnectionEstablished
          ? 'communication-level-middle'
          : 'communication-level-high'"
  ></hoho-icon>

  <p *ngIf="value === communicationLevelEnum.NoConnection" class="text-body-4 text-black" i18n>
    Соединение отсутствует
  </p>

  <p *ngIf="value === communicationLevelEnum.LowConnectionLevel" class="text-body-4 text-black" i18n>
    Низкий уровень соединения
  </p>

  <p *ngIf="value === communicationLevelEnum.ConnectionEstablished" class="text-body-4 text-black" i18n>
    Соединение установлено
  </p>

  <p *ngIf="value === communicationLevelEnum.GoodConnectionLevel" class="text-body-4 text-black" i18n>
    Соединение установлено
  </p>
</div>
