import { Component, Input, OnInit } from '@angular/core';
import { IPlacementCardModel } from '../../models/card.models';

@Component({
  selector: 'hoho-address-card',
  templateUrl: './address-card.component.html',
  styleUrls: ['./address-card.component.scss'],
})
export class AddressCardComponent implements OnInit {
  @Input() model: IPlacementCardModel;

  constructor() {}
  ngOnInit(): void {}
}
