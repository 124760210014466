import { Directive, HostListener, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { RegexPatternConstants } from '@app/shared/utilities/regex-pattern-constants';

@Directive({
  selector: 'input [hohoDigitMask]',
})
export class DigitMaskDirective implements OnInit {
  constructor(public ngControl: NgControl) {}

  ngOnInit() {
    const initialOnChange = (this.ngControl.valueAccessor as any).onChange;
    (this.ngControl.valueAccessor as any).onChange = (value: any) => initialOnChange(this.onInputChange(value));
  }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event: string | number) {
    let value: string | number = this.onInputChange(event);
    if (!value && !(typeof value === 'number' && value !== 0)) {
      value = null;
    } else {
      value
        .split('')
        .map((i) => parseInt(i, 10))
        .toString();
    }

    this.ngControl.valueAccessor.writeValue(value);
  }

  onInputChange(event: string | number): string {
    if (typeof event === 'number') {
      event = event.toString(10);
    }
    return event?.replace(RegexPatternConstants.digit, '');
  }
}
