/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { users } from '../models/users';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersService {

    /**
     * Select users by id
     * @param id Object ID
     * @returns users Successful operation
     * @throws ApiError
     */
    public static selectUsersById(
        id: string,
    ): CancelablePromise<users> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/get/object/users/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Save users
     * @param requestBody Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static saveUsers(
        requestBody?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/save/object/users',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Delete users by id
     * @param id Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static deleteUsersById(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/delete/object/users/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

}
