<p class="text-body-5 mt-4 mb-1.5 text-green-4" i18n>Организация</p>
<p class="text-body-4">
  {{ model.organization }}
</p>

<p class="text-body-5 mb-1.5 text-green-4 mt-4" i18n>Место установки</p>
<p class="text-body-4">
  {{ model.placementLocation }}
</p>

<div class="w-full border-b border-gren-1 my-4"></div>
