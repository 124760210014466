/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { base_entity } from './base_entity';
import type { city } from './city';
import type { company } from './company';
import type { object_link } from './object_link';

/**
 * Пользовательская точка маршрута
 */
export type custom_waypoint = (base_entity & {
    /**
     * Идентификатор
     */
    id?: string;
    /**
     * Название
     */
    name?: string;
    /**
     * Город
     */
    city?: (city | object_link);
    /**
     * Компания
     */
    company?: (company | object_link);
    /**
     * Координаты
     */
    coordinates?: string;
    /**
     * Тип точки
     */
    type?: custom_waypoint.type;
    /**
     * Поиск
     */
    readonly search?: string;
});

export namespace custom_waypoint {

    /**
     * Тип точки
     */
    export enum type {
        '_0' = 0,
    }


}

