<hoho-dialog-container [dialogRef]="dialogRef">
  <form [formGroup]="formGroup" (ngSubmit)="submitForm()">
    <ng-container *ngIf="!isSelectView">
      <div class="text-subtitle-3 mb-4" i18n *ngIf="!group">Создать группу</div>
      <div class="text-subtitle-3 mb-4" i18n *ngIf="group">Редактировать группу</div>
      <hoho-info-snippet i18n> В созданной группе отображается выбранный вами список автоматов. </hoho-info-snippet>
      <div class="mt-4">
        <mat-form-field class="w-full">
          <mat-label i18n>Введите название группы</mat-label>
          <input #name matInput maxlength="24" formControlName="name" name="name" />
          <mat-hint align="start" i18n="@@required_error">Поле обязательно для заполнения</mat-hint>
          <mat-hint align="end">{{ name.value.length }}/24</mat-hint>
        </mat-form-field>
        <button class="w-full mt-4" mat-flat-button *ngIf="!selectedVMList.length" (click)="changeView()" i18n>
          Выбрать автоматы
        </button>
        <button class="w-full mt-4" mat-flat-button *ngIf="selectedVMList.length" (click)="changeView()">
          <ng-container>
            {{ selectedVMList.length | i18nPlural: { '=1': 'Выбран', 'few': 'Выбрано', 'other': 'Выбрано' } }}
          </ng-container>
          {{ selectedVMList.length }}
          <ng-container>
            {{ selectedVMList.length | i18nPlural: { '=1': 'автомат', 'few': 'автомата', 'other': 'автоматов' } }}
          </ng-container>
        </button>
        <div class="flex justify-center mt-4" *ngIf="group">
          <div class="text-texas cursor-pointer hover:text-margaret" (click)="deleteGroup()" i18n>Удалить группу</div>
        </div>
        <button
          class="w-full mt-4"
          [disabled]="formGroup.invalid || !selectedVMList?.length"
          *ngIf="!group"
          mat-flat-button
          color="primary"
          type="submit"
          i18n
        >
          Создать
        </button>
        <button
          class="w-full mt-4"
          [disabled]="formGroup.invalid || !selectedVMList?.length"
          *ngIf="group"
          mat-flat-button
          color="primary"
          type="submit"
          i18n
        >
          Редактировать
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="isSelectView">
      <div class="flex items-center mb-4">
        <hoho-icon
          class="mr-4 h-4 w-4 cursor-pointer hover:text-primary trans-ease-out"
          [iconName]="'angle-left-b'"
          (click)="changeView()"
        ></hoho-icon>
        <div class="text-subtitle-3" i18n>Список автоматов</div>
      </div>
      <hoho-multiselect-entity
        (selected)="selected($event)"
        [row]="row"
        [selectedItems]="selectedVMList"
        [items]="itemsList"
      ></hoho-multiselect-entity>
      <ng-template #row let-item="item">
        <div class="flex-col">
          <div class="text-body-2 select-none">{{ item.name || 'Наименование автомата' }}</div>
          <div class="text-body-5 select-none">{{ item.nameAndSerial }}</div>
          <div class="text-body-5 text-california select-none">{{ item.address }}</div>
        </div>
      </ng-template>
    </ng-container>
  </form>
</hoho-dialog-container>
