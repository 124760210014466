import { CommonModule } from '@angular/common';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { NgSelectModule } from '@ng-select/ng-select';
import { VexModule } from '@shared/@vex/vex.module';
import { DragAndDropComponent } from '@shared/components/drag-and-drop/drag-and-drop.component';
import { FormErrorComponent } from '@shared/components/form-error/form-error.component';
import { TextSearchComponent } from '@shared/components/text-search/text-search.component';
import { DirectivesModule } from '@shared/directives/directives.module';
import { IconModule } from '@visurel/iconify-angular';
import { ButtonComponent } from '../button/button.component';
import { DotIndicatorComponent } from './dot-indicator/dot-indicator.component';
import { FeatureTabsComponent } from './feature-tabs/feature-tabs.component';
import { IconComponent } from './icon/icon.component';
import { InfoSnippetComponent } from './info-snippet/info-snippet.component';
import { SmallSummaryCardComponent } from './small-summary-card/small-summary-card.component';
import { TogglesComponent } from './toggles/toggles.component';
import { MinutesHourComponent } from '@shared/components/minuts-hour/minutes-hour.component';
import { NewAgreementModal } from './new-agreement-modal/new-agreement-modal.component';
import { TarifPriceIncreaseModal } from './tarif-price-increase/tarif-price-increase.component';

@NgModule({
  declarations: [
    InfoSnippetComponent,
    IconComponent,
    SmallSummaryCardComponent,
    DotIndicatorComponent,
    ButtonComponent,
    TogglesComponent,
    TextSearchComponent,
    FormErrorComponent,
    FeatureTabsComponent,
    DragAndDropComponent,
    MinutesHourComponent,
		NewAgreementModal,
    TarifPriceIncreaseModal
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    IconModule,
    FormsModule,
    NgSelectModule,
    VexModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    DirectivesModule,
  ],
  exports: [
    InfoSnippetComponent,
    IconComponent,
    SmallSummaryCardComponent,
    TextSearchComponent,
    DotIndicatorComponent,
    ButtonComponent,
    TogglesComponent,
    FormErrorComponent,
    FeatureTabsComponent,
    DragAndDropComponent,
    MinutesHourComponent,
  ],
  schemas: [NO_ERRORS_SCHEMA],
})
export class ComponentsModule {}
