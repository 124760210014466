import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EEntityList } from '@shared/models';
import { UntilDestroy } from '@ngneat/until-destroy';
import { getErrorMessage } from '@shared/utilities/forms.untils';
import { DataRestService } from '@shared/services';
import { company } from '@src/api';
import { paths } from './create-update-companies.config';

@UntilDestroy()
@Component({
  selector: 'hoho-modal-companies',
  templateUrl: './create-update-companies.component.html',
  styleUrls: ['./create-update-companies.component.scss'],
})
export class CreateUpdateCompaniesComponent implements OnInit {
  @HostListener('window:beforeunload', ['$event'])
  EEntityList = EEntityList;
  title: string;
  submitted: boolean = false;
  formGroup = this.fb.group({
    id: [null],
    name: [null, [Validators.required, Validators.maxLength(100)]],
    address: [null, [Validators.required, Validators.maxLength(100)]],
    phone: [null, [Validators.required, Validators.maxLength(40)]],
    orgCode: [null, [Validators.required, Validators.maxLength(12), Validators.minLength(10)]],
    comment: [null, [Validators.maxLength(40)]],
    responsiblePerson: [null, [Validators.maxLength(40)]],
    code: ['', [Validators.maxLength(10)]],
    lkEnabled: [null],
  });
  setAddress = (entity: company): company => {
    // todo странный баг, видима нужно как то тыкать form group, чтобы он обновился
    entity.address = this.formGroup.get('address').value;
    return entity;
  };
  getErrorMessage = getErrorMessage;
  constructor(
    @Inject(MAT_DIALOG_DATA) private entity: company,
    private dialogRef: MatDialogRef<CreateUpdateCompaniesComponent>,
    private fb: UntypedFormBuilder,
    private dataRestService: DataRestService,
  ) {}

  ngOnInit() {
    if (this.entity) {
      this.formGroup.controls['id'].setValue(this.entity.id);
      this.dataRestService
        .get<company>(EEntityList.company, this.entity.id, paths)
        .pipe()
        .subscribe((res: company) => {
          this.formGroup.patchValue(res);
        });
      this.title = 'Редактировать компанию';
    } else {
      this.title = 'Добавить компанию';
    }
  }

  afterSave(): void {
    this.dialogRef.close(true);
  }

  submittedEvent(): void {
    this.submitted = true;
  }
}
