import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import userCircle from '@iconify/icons-uil/user-circle';
import { PopoverService } from '../../../../components/popover/popover.service';
import { UserDropdownComponent } from './user-dropdown/user-dropdown.component';
import { UserService } from '@shared/services/user.service';
import { ICurrentUser } from '@shared/models';

@Component({
  selector: 'vex-toolbar-user',
  templateUrl: './user.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserComponent implements OnInit {
  dropdownOpen: boolean;
  user: ICurrentUser;
  userCircle = userCircle;

  constructor(private popover: PopoverService, private cd: ChangeDetectorRef, private userService: UserService) {}

  ngOnInit() {
    this.userService.user$.subscribe((res) => {
      this.user = res;
      this.cd.detectChanges();
    });
  }

  showPopover(originRef: HTMLElement) {
    this.dropdownOpen = true;
    this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: UserDropdownComponent,
      data: this.user,
      origin: originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ],
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });
  }
}
