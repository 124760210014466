import { Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';

@Injectable()
export class UnsubscribeClass implements OnDestroy {
  public unsubscribeAll: Subject<void> = new Subject();

  constructor() {}

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
