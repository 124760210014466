/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { product_matrix_freezer } from '../models/product_matrix_freezer';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProductMatrixFreezerService {

    /**
     * Select product_matrix_freezer by id
     * @param id Object ID
     * @returns product_matrix_freezer Successful operation
     * @throws ApiError
     */
    public static selectProductMatrixFreezerById(
        id: string,
    ): CancelablePromise<product_matrix_freezer> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/get/object/product_matrix_freezer/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Save product_matrix_freezer
     * @param requestBody Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static saveProductMatrixFreezer(
        requestBody?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/save/object/product_matrix_freezer',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Delete product_matrix_freezer by id
     * @param id Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static deleteProductMatrixFreezerById(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/delete/object/product_matrix_freezer/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

}
