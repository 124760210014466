/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { base_entity } from './base_entity';
import type { company } from './company';
import type { object_link } from './object_link';
import type { price_list } from './price_list';
import type { product_matrix_ingredient } from './product_matrix_ingredient';
import type { product_matrix_item_coffee } from './product_matrix_item_coffee';
import type { vending_machine_product_matrix } from './vending_machine_product_matrix';
import type { vm_model } from './vm_model';

/**
 * Товарная матрица кофейная
 */
export type product_matrix_coffee = (base_entity & {
    /**
     * Идентификатор
     */
    id?: string;
    /**
     * Название
     */
    name?: string;
    /**
     * Тип
     */
    matrixType?: product_matrix_coffee.matrixType;
    /**
     * Состав продуктовой матрицы
     */
    productMatrixItems?: Array<product_matrix_item_coffee>;
    /**
     * Ингредиенты
     */
    productMatrixIngredients?: Array<product_matrix_ingredient>;
    /**
     * Модель ТА
     */
    vmModel?: (vm_model | object_link);
    /**
     * Компания
     */
    readonly company?: (company | object_link);
    readonly vmCount?: number;
    readonly slotCount?: number;
    defaultPriceList?: (price_list | object_link);
    /**
     * Список ТА
     */
    productMatrixVendingMachines?: Array<vending_machine_product_matrix>;
    readonly search?: string;
    readonly matrixTypeName?: string;
    isTemplate?: boolean;
    readonly telemetronId?: string;
});

export namespace product_matrix_coffee {

    /**
     * Тип
     */
    export enum matrixType {
        '_0' = 0,
        '_1' = 1,
    }


}

