import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { config } from './websocket.config';
import { WebSocketConfig } from './websocket.interfaces';

@NgModule({
  imports: [CommonModule],
})
export class WebsocketModule {
  public static config(wsConfig: WebSocketConfig): ModuleWithProviders<any> {
    return {
      ngModule: WebsocketModule,
      providers: [{ provide: config, useValue: wsConfig }],
    };
  }
}
