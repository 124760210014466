/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { manual_writeoff_orders } from '../models/manual_writeoff_orders';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ManualWriteoffOrdersService {

    /**
     * Select manual_writeoff_orders by id
     * @param id Object ID
     * @returns manual_writeoff_orders Successful operation
     * @throws ApiError
     */
    public static selectManualWriteoffOrdersById(
        id: string,
    ): CancelablePromise<manual_writeoff_orders> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/get/object/manual_writeoff_orders/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Save manual_writeoff_orders
     * @param requestBody Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static saveManualWriteoffOrders(
        requestBody?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/save/object/manual_writeoff_orders',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Delete manual_writeoff_orders by id
     * @param id Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static deleteManualWriteoffOrdersById(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/delete/object/manual_writeoff_orders/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

}
