import { IChoseItem } from '@shared/models/entity.models';

export enum EPromocodeType {
  Individual,
  Group,
  All,
}

export const PromoCodeChose: IChoseItem[] = [
  {
    name: $localize`Индивидуальный`,
    id: EPromocodeType.Individual,
  },
  {
    name: $localize`Групповой`,
    id: EPromocodeType.Group,
  },
  {
    name: $localize`Всем`,
    id: EPromocodeType.All,
  },
];

export enum EPromocodeGiftType {
  Promo,
  Coupon,
}
