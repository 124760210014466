export const RegexPatternConstants = {
  email: /^\S+@\S+\.\S+$/,
  noSpecialCharacters: /[^А-Яа-яA-Za-z0-9_]/,
  digit: /\D/g,
  space: / /g,
  password: /^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d)(?=.*[-.!@_$+* "]).*$/,
};

export const RegexPatternURL = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w@:%_\+.~#?&\/\/=]*/?';

export const LatLonRegExp =
  '^[-+]?([1-8]?\\d(\\.\\d+)?|90(\\.0+)?),\\s*[-+]?(180(\\.0+)?|((1[0-7]\\d)|([1-9]?\\d))(\\.\\d+)?)$';

/* *
Matches
+90.0, -127.554334
45, 180
-90, -180
-90.000, -180.0000
+90, +180
47.1231231, 179.99999999
Doesn't Match
-90., -180.
+90.1, -100.111
-91, 123.456
045, 180
* */

export const PhoneRegExp = '^(?:[+\\d].*\\d|\\d)$';
export const TelegramBotUserRegExp = '^\\+7[0-9]{10}$|^\\@.+$'

export const SpecialSymbolsRegExp = '/[^\\w\\s]/gi';
