/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { base_entity } from './base_entity';
import type { city } from './city';
import type { company } from './company';
import type { custom_waypoint } from './custom_waypoint';
import type { object_link } from './object_link';
import type { service_route_execution } from './service_route_execution';
import type { service_route_responsible_person } from './service_route_responsible_person';
import type { service_route_waypoint } from './service_route_waypoint';
import type { users } from './users';

/**
 * Маршрут
 */
export type service_route = (base_entity & {
    /**
     * Идентификатор
     */
    id?: string;
    /**
     * Название
     */
    name: string;
    /**
     * Город
     */
    city: (city | object_link);
    /**
     * Компания
     */
    company?: (company | object_link);
    /**
     * Пользователь создавший
     */
    createUser?: (users | object_link);
    /**
     * Дата создания
     */
    createDate?: string;
    /**
     * Ответственные лица
     */
    serviceRouteResponsiblePersons?: Array<service_route_responsible_person>;
    /**
     * Точка маршрута
     */
    serviceRouteWaypoints?: Array<service_route_waypoint>;
    /**
     * Стартовая точка
     */
    startWaypoint: (custom_waypoint | object_link);
    /**
     * Конечная точка
     */
    finishWaypoint: (custom_waypoint | object_link);
    /**
     * Исполнение маршрута
     */
    serviceRouteExecutions?: Array<service_route_execution>;
    /**
     * Статус
     */
    readonly status?: service_route.status;
});

export namespace service_route {

    /**
     * Статус
     */
    export enum status {
        '_0' = 0,
        '_1' = 1,
        '_2' = 2,
    }


}

