import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputMaskDirective } from '@shared/modules/input-mask/input-mask.directive';
import { InputMaskService } from '@shared/modules/input-mask/input-mask.service';

@NgModule({
  declarations: [InputMaskDirective],
  providers: [InputMaskService],
  imports: [FormsModule],
  exports: [InputMaskDirective],
})
export class InputMaskModule {}
