import { Component, Inject, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppRoutingNames } from '@app/app-routing.module';

@UntilDestroy()
@Component({
  selector: 'auto-debit-info',
  templateUrl: './auto-debit-info.component.html',
  styleUrls: ['./auto-debit-info.component.scss'],
})
export class AutoDebitInfoComponent implements OnInit {
  constructor(
		private router: Router,
    public dialogRef: MatDialogRef<AutoDebitInfoComponent>,

    @Inject(MAT_DIALOG_DATA)
    public data: {
      ammount: string;
    },
  ) {}

  ngOnInit(): void {}

  toPersonalArea(): void {
		this.router.navigate([AppRoutingNames.personalArea.path]);
  }
}