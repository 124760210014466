import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EDialogTypes, IDialogModels } from '@shared/modules/dialogs/models/dialog.models';

@Component({
  selector: 'hoho-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss'],
})
export class MessageDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<MessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogModels,
  ) {
    this.data = {
      type: EDialogTypes.message,
      close: false,
      ok: true,
      title: $localize`Успешно`,
      okLabel: $localize`Понятно`,
      ...this.data,
    };
  }

  ngOnInit() {
    this.dialogRef.afterClosed().subscribe(() => {
      if (this.data.cancelCallback) {
        this.data.cancelCallback();
      }
    });
  }

  public ok(): void {
    this.dialogRef.close();

    this.dialogRef.afterClosed().subscribe(() => {
      if (this.data.okCallback) {
        this.data.okCallback();
      }
    });
  }

  public closeModal(): void {
    this.dialogRef.close();
  }
}
