import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { deepClone, deepEqual } from '@shared/utilities';
import { EEntityList, IPagingResponsePayload, IServerRequestPayload } from '@shared/models';
import { vending_machine } from '@src/api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DataRestService } from '@shared/services';

@UntilDestroy()
@Component({
  selector: 'hoho-multiselect-entity',
  templateUrl: './multiselect-entity.component.html',
  styleUrls: ['./multiselect-entity.component.scss'],
})
export class MultiselectEntityComponent<T> implements OnInit {
  @Input() btnText: string = $localize`Добавить выбранные`;
  @Input() selectedItems: T[] = [];
  @Input() row: TemplateRef<HTMLElement>;
  @Input() multiply = true;
  @Input() isLoadingInput: boolean = null;
  @Input() searchPlaceholder = $localize`Поиск...`;
  @Input() autocomplete: boolean = false;
  @Output() selected = new EventEmitter<T[]>();
  selectedCount: number;
  isLoading: boolean = true;
  searchCtrl: UntypedFormControl = new UntypedFormControl();
  selectedItems_: T[] = [];
  private _items: T[];
  constructor(private dataRestService: DataRestService) {}

  ngOnInit(): void {
    this.selectedItems_ = deepClone(this.selectedItems);
    this.selectedCount = this.selectedItems_.length;
    this.items?.sort((a, b) => (this.isActive(a) ? -1 : 1));
  }
  selectEvent(): void {
    this.selected.emit(this.selectedItems_);
  }

  @Input()
  set items(value: T[]) {
    if (!value?.length && this.isLoadingInput == null) {
      return;
    }
    this._items = value;
    this.isLoading = false;
  }

  @Input()
  set entityType(value: { entity: string; paths: string[] }) {
    const payload: IServerRequestPayload = {
      paths: value.paths,
      sort: null,
    };
    this.dataRestService
      .page<T>(value.entity, '', 0, -1, payload)
      .pipe(untilDestroyed(this))
      .subscribe(
        (res: IPagingResponsePayload<T>) => {
          this._items = res.content;
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        },
      );
  }

  get items(): T[] {
    if (!this.searchCtrl.value) {
      return this._items;
    }
    return this._items.filter((data: T) =>
      JSON.stringify(data).toLowerCase().includes(this.searchCtrl.value?.toLowerCase()),
    );
  }

  isActive(item: T): boolean {
    return !!this.selectedItems_.find((i) => deepEqual(i, item));
  }

  selectAll(): void {
    var diffSelectedItems = this.items.filter((elem) => !this.selectedItems_.find((i) => deepEqual(i, elem)));
    this.selectedItems_ = this.selectedItems_.concat(diffSelectedItems);
    this.selectedCount = this.selectedItems_.length;
  }
  removeSelection(): void {
    this.selectedItems_ = this.selectedItems_.filter((elem) => !this.items.find((i) => deepEqual(i, elem)));
    this.selectedCount = this.selectedItems_.length;
  }
  toggleSelect(item: T): void {
    if (this.multiply) {
      if (this.isActive(item)) {
        this.selectedItems_ = this.selectedItems_.filter((i) => !deepEqual(i, item));
      } else {
        this.selectedItems_.push(item);
      }
    } else {
      this.selectedItems_ = [item];
    }
    this.selectedCount = this.selectedItems_.length;
  }
}
