import { Inject, Injectable, OnDestroy } from '@angular/core';
import { $localize } from '@angular/localize/init';
import { AlertService } from '@shared/services';
import { encodeQueryData } from '@shared/utilities';
import { sha256 } from 'js-sha256';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { Observable, Subject } from 'rxjs';
import { config } from './websocket.config';
import { EWebsocketsEvents } from './websocket.events';

import { IListeners, ITopic, IWebsocketService, MessageSubject, WebSocketConfig } from './websocket.interfaces';
import { EWebsocketConnectStatus, IWsMessage, modelParser } from './websocket.models';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService implements IWebsocketService, OnDestroy {
  private listeners: IListeners;
  private uniqueId: number;
  private websocket: ReconnectingWebSocket;
  public webSocketsStatus: Subject<EWebsocketConnectStatus> = new Subject<EWebsocketConnectStatus>();

  constructor(@Inject(config) private wsConfig: WebSocketConfig, private alertService: AlertService) {
    this.uniqueId = -1;
    this.listeners = {};
    this.wsConfig.ignore = wsConfig.ignore ? wsConfig.ignore : [];
  }

  ngOnDestroy() {
    this.disconnect();
  }
  disconnect() {
    if (this.websocket) {
      this.websocket.close();
    }
  }
  /*
   * connect to WebSocked
   * */
  connect(userId: number, token: string): void {
    // ReconnectingWebSocket config
    const options = {
      connectionTimeout: 1000,
      maxRetries: 10,
      ...this.wsConfig.options,
    };

    // connect to WebSocked
    this.websocket = new ReconnectingWebSocket(
      this.wsConfig.url + '?' + encodeQueryData({ UserID: userId, SessionId: token, PlatformId: 'browser' }),
      [],
      options,
    );

    this.websocket.addEventListener('open', () => {
      console.log(`[${Date()}] WebSocket connected!`);
      this.webSocketsStatus.next(EWebsocketConnectStatus.Open);
    });

    this.websocket.addEventListener('close', () => {
      console.log(`[${Date()}] WebSocket close!`);
      this.webSocketsStatus.next(EWebsocketConnectStatus.Close);
    });

    this.websocket.addEventListener('error', () => {
      console.error(`[${Date()}] WebSocket error!`);
      this.webSocketsStatus.next(EWebsocketConnectStatus.Error);
      this.alertService.showError($localize`Переподключаемся`);
    });

    this.websocket.addEventListener('message', (event: MessageEvent) => {
      // dispatch message to subscribers
      this.onMessage(event);
    });

    setInterval(() => {
      this.garbageCollect(); // remove subjects without subscribe
    }, this.wsConfig.garbageCollectInterval || 10000);
  }

  /*
   * garbage collector
   * */
  private garbageCollect(): void {
    for (const event in this.listeners) {
      if (this.listeners.hasOwnProperty(event)) {
        const topic = this.listeners[event];

        for (const key in topic) {
          if (topic.hasOwnProperty(key)) {
            const subject = topic[key];

            if (!subject.observers.length) {
              // if not subscribes
              delete topic[key];
            }
          }
        }

        if (!Object.keys(topic).length) {
          // if not subjects
          delete this.listeners[event];
        }
      }
    }
  }

  /*
   * call messages to Subject
   * */
  private callMessage<T>(topic: ITopic<T>, data: T): void {
    for (const key in topic) {
      if (topic.hasOwnProperty(key)) {
        const subject = topic[key];

        if (subject) {
          // dispatch message to subscriber
          subject.next(data);
        } else {
          console.log(`[${Date()}] Topic Subject is "undefined"`);
        }
      }
    }
  }

  /*
   * dispatch messages to subscribers
   * */
  private onMessage(event: MessageEvent): void {
    const message = JSON.parse(event.data);
    for (const name in this.listeners) {
      if (this.listeners.hasOwnProperty(name) && !this.wsConfig.ignore.includes(name)) {
        const topic = this.listeners[name];
        const keys = name.split('/'); // if multiple events

        //TODO описать возможные типы сокетов
        const callMsg = (msg: any) => {
          const isMessage = keys.includes(EWebsocketsEvents[msg.event]);
          const model = modelParser(msg.event, msg.data); // get model
          if (isMessage && typeof model !== 'undefined') {
            model.then((data: IWsMessage[]) => {
              this.callMessage<IWsMessage[]>(topic, data);
            });
          }
        };
        if (Array.isArray(message)) {
          message.forEach((i) => {
            callMsg(i);
          });
        } else {
          callMsg(message);
        }
      }
    }
  }

  /*
   * add topic for subscribers
   * */
  private addTopic<T>(topic: string, id?: number): MessageSubject<T> {
    const token = (++this.uniqueId).toString(); // token for personal subject
    const key = id ? token + id : token; // id for more personal subject
    const hash = sha256.hex(key); // set hash for personal

    if (!this.listeners[topic]) {
      this.listeners[topic] = <any>{};
    }

    return (this.listeners[topic][hash] = new MessageSubject<T>(this.listeners, topic, hash));
  }

  /*
   * subscribe method
   * */
  public addEventListener<T>(topics: string | string[], id?: number): Observable<T> {
    if (topics) {
      const topicsKey = typeof topics === 'string' ? topics : topics.join('/'); // one or multiple

      return this.addTopic<T>(topicsKey, id).asObservable();
    } else {
      console.log(`[${Date()}] Can't add EventListener. Type of event is "undefined".`);
    }
  }

  /*
   * on message to server
   * */
  public sendMessage<T>(data: T): void {
    if (data && this.websocket.readyState === 1) {
      this.websocket.send(JSON.stringify(data));
    } else {
      console.log('Send error!');
    }
  }

  /*
   * runtime add ignore list
   * */
  public runtimeIgnore(topics: string[]): void {
    if (topics && topics.length) {
      this.wsConfig.ignore.push(...topics);
    }
  }

  /*
   * runtime remove from ignore list
   * */
  public runtimeRemoveIgnore(topics: string[]): void {
    if (topics && topics.length) {
      topics.forEach((topic: string) => {
        const topicIndex = this.wsConfig.ignore.findIndex((t) => t === topic); // find topic in ignore list

        if (topicIndex > -1) {
          this.wsConfig.ignore.splice(topicIndex, 1);
        }
      });
    }
  }
}
