import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { getErrorMessage } from '@shared/utilities/forms.untils';

@Component({
  selector: 'hoho-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss'],
})
export class FormErrorComponent implements OnInit {
  @Input() formGroup: UntypedFormGroup;
  @Input() name: string;
  getErrorMessage = getErrorMessage;
  constructor() {}

  ngOnInit(): void {}
}
