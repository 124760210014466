<p class="text-body-5 mb-1.5 text-green-4" i18n>Адрес</p>
<p class="text-body-4">
  {{ model.address }}
</p>

<p class="text-body-5 mb-1.5 text-green-4 mt-4" i18n>Тип здания</p>
<div class="flex mb-4">
  <p *ngFor="let item of model.locationFormats" class="text-body-4 mr-2 py-1 px-1.5 bg-shpongle rounded-md">
    {{ item.locationFormat.name }}
  </p>
  <p *ngIf="!model.locationFormats || model.locationFormats.length === 0">
    -
  </p>
</div>

<p class="text-body-5 mb-1.5 text-green-4" i18n>Место установки</p>
<p class="text-body-4">
  {{ model.placementLocation || '-' }}
</p>
