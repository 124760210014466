/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { base_entity } from './base_entity';

/**
 * История формирования отчетов
 */
export type report_create_history = (base_entity & {
    /**
     * Идентификатор
     */
    id?: string;
    /**
     * Идентификатор отчета
     */
    readonly reportID?: string;
    /**
     * Дата и время начала формирования
     */
    readonly createStart: string;
    /**
     * Дата и время окончания формирования
     */
    readonly createEnd: string;
    /**
     * Результат операции
     */
    readonly status: report_create_history.status;
    /**
     * Текст ошибки
     */
    readonly errorText?: string;
    /**
     * Наименование отчета
     */
    readonly name?: string;
    /**
     * Идентификатор меню
     */
    readonly reportMenuID?: string;
    /**
     * Расширение результирующего файла
     */
    readonly fileExtension?: string;
});

export namespace report_create_history {

    /**
     * Результат операции
     */
    export enum status {
        '_0' = 0,
        '_1' = 1,
        '_2' = 2,
        '_3' = 3,
    }


}

