import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EEntityList, IPagingResponsePayload, IServerRequestPayload } from '@shared/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { getErrorMessage } from '@shared/utilities/forms.untils';
import { DataRestService } from '@shared/services';
import { company, marketplace_point } from '@src/api';
import { DialogService } from '@app/shared/modules/dialogs/services/dialog.service';
import { AddressTypeChose, ConutryCodes } from '@app/shared/models/register-marketplace-point.models';
import { RegexPatternConstants } from '@app/shared/utilities';
import { paths } from './register-marketplace-point.config';
import { pid } from 'process';

@UntilDestroy()
@Component({
  selector: 'hoho-register-marketplace-point',
  templateUrl: './register-marketplace-point.component.html',
  styleUrls: ['./register-marketplace-point.component.scss'],
})
export class RegisterMarketplacePointComponent implements OnInit {
  companyList: company[] = [];
  entityType: EEntityList;
  title: string;
  isLoading: boolean = false;
  submitted: boolean = false;
  isEdit: boolean = false;
  nowDate = new Date();
  AddressTypes: any[] = AddressTypeChose;
  ConutryCodes: any[] = ConutryCodes;
  point: marketplace_point = null;
  formGroup = this.fb.group({
    shopCode: [null, []],
    billingDescriptor: [null, [Validators.required, Validators.maxLength(14), Validators.pattern("^[A-z0-9.\-_ ]+$")]],
    fullName: [null, [Validators.required]],
    name: [null, [Validators.required]],
    inn: [null, [Validators.required, Validators.pattern("^[0-9]*$")]],
    kpp: [null, [Validators.required, Validators.pattern("^[0-9]*$")]],
    addresses: this.fb.group({
      type: [null, [Validators.required]],
      zip: [null, [Validators.required, Validators.pattern("^[0-9]{6}$")]],
      country: [null, [Validators.required]],
      city: [null, [Validators.required]],
      street: [null, [Validators.required]],
    }),
    email: [null, [Validators.required, Validators.pattern(RegexPatternConstants.email)]],
    ceo: this.fb.group({
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      middleName: [null, [Validators.required]],
      birthDate: [null, [Validators.required]],
      phone: [null, [Validators.required]],
      country: [null, [Validators.required]],
    }),
    bankAccount: this.fb.group({
      account: [null, [Validators.required, Validators.pattern("^[0-9]{20}$")]],
      bankName: [null, [Validators.required]],
      bik: [null, [Validators.required, Validators.pattern("^[0-9]{9}$")]],
      details: [null, [Validators.required, Validators.maxLength(24)]],
    }),
  });
  getErrorMessage = getErrorMessage;
  constructor(
    @Inject(MAT_DIALOG_DATA) private entity: company,
    private dialogRef: MatDialogRef<RegisterMarketplacePointComponent>,
    private fb: UntypedFormBuilder,
    private dialogService: DialogService,
    private dataRestService: DataRestService,
  ) { }

  ngOnInit() {
    this.loadMarketplacePoint();
  }
  
  loadMarketplacePoint(): void {
    this.isLoading = true;
    const payload: IServerRequestPayload = {
      paths: paths,
      filter: { 'comapny.id': this.entity.id },
      sort: null,
    };
    this.dataRestService
      .page<marketplace_point>(EEntityList.marketplace_point, '', 0, 1, payload)
      .pipe(untilDestroyed(this))
      .subscribe((res: IPagingResponsePayload<marketplace_point>) => {
        if (res.count == 0) {
          this.isLoading = false;
          return;
        }
        this.point = res.content[0];
        this.formGroup.patchValue(this.point);
        this.formGroup.controls.addresses.patchValue(this.lowerize(this.point.addresses[0]));
        this.formGroup.controls.ceo.patchValue(this.lowerize(this.point.ceo));
        this.formGroup.controls.bankAccount.patchValue(this.lowerize(this.point.bankAccount));
        this.setDisabled();
        this.isLoading = false;
      });
  }

  afterSave(that: any): void {
    that.dialogRef.close(true);
  }

  submittedEvent(): void {
    this.submitted = true;
  }

  setDisabled(): void {
    this.isEdit = true;
    this.formGroup.controls['shopCode'].disable();
    this.formGroup.controls['billingDescriptor'].disable();
    this.formGroup.controls['fullName'].disable();
    this.formGroup.controls['name'].disable();
    this.formGroup.controls['inn'].disable();
    this.formGroup.controls['kpp'].disable();
    this.addressesTypeControl.disable();
    this.addressesZipControl.disable();
    this.addressesCountryControl.disable();
    this.addressesCityControl.disable();
    this.addressesStreetControl.disable();
    this.formGroup.controls['email'].disable();
    this.ceoFirstNameControl.disable();
    this.ceoLastNameControl.disable();
    this.ceoMiddleNameControl.disable();
    this.ceoBirthDateControl.disable();
    this.ceoCountryControl.disable();
    this.ceoPhoneControl.disable();
  }

  get addressesTypeControl(): UntypedFormControl {
    return this.formGroup.get('addresses.type') as UntypedFormControl;
  }

  get addressesZipControl(): UntypedFormControl {
    return this.formGroup.get('addresses.zip') as UntypedFormControl;
  }

  get addressesCountryControl(): UntypedFormControl {
    return this.formGroup.get('addresses.country') as UntypedFormControl;
  }

  get addressesCityControl(): UntypedFormControl {
    return this.formGroup.get('addresses.city') as UntypedFormControl;
  }

  get addressesStreetControl(): UntypedFormControl {
    return this.formGroup.get('addresses.street') as UntypedFormControl;
  }

  get ceoFirstNameControl(): UntypedFormControl {
    return this.formGroup.get('ceo.firstName') as UntypedFormControl;
  }

  get ceoLastNameControl(): UntypedFormControl {
    return this.formGroup.get('ceo.lastName') as UntypedFormControl;
  }

  get ceoMiddleNameControl(): UntypedFormControl {
    return this.formGroup.get('ceo.middleName') as UntypedFormControl;
  }

  get ceoBirthDateControl(): UntypedFormControl {
    return this.formGroup.get('ceo.birthDate') as UntypedFormControl;
  }

  get ceoPhoneControl(): UntypedFormControl {
    return this.formGroup.get('ceo.phone') as UntypedFormControl;
  }

  get ceoCountryControl(): UntypedFormControl {
    return this.formGroup.get('ceo.country') as UntypedFormControl;
  }

  get bankAccountAccountControl(): UntypedFormControl {
    return this.formGroup.get('bankAccount.account') as UntypedFormControl;
  }

  get bankAccountBankNameControl(): UntypedFormControl {
    return this.formGroup.get('bankAccount.bankName') as UntypedFormControl;
  }

  get bankAccountBikControl(): UntypedFormControl {
    return this.formGroup.get('bankAccount.bik') as UntypedFormControl;
  }

  get bankAccountDetailsControl(): UntypedFormControl {
    return this.formGroup.get('bankAccount.details') as UntypedFormControl;
  }

  register(): void {
    if (this.formGroup.invalid)
      return;
    this.isLoading = true;
    let marketPlacePoint = null;
    if (this.point) {
      marketPlacePoint = this.point;
      marketPlacePoint.bankAccount = this.formGroup.value.bankAccount;
      marketPlacePoint.ceo = this.lowerize(marketPlacePoint.ceo);
      marketPlacePoint.addresses = this.lowerize(marketPlacePoint.addresses[0]);
    }
    else {
      marketPlacePoint = this.formGroup.value;
    }
    var request = {
      marketPlacePoint: marketPlacePoint,
      companyId: this.entity.id
    };
    if (!Array.isArray(request.marketPlacePoint.addresses))
      request.marketPlacePoint.addresses = Array(1).fill(request.marketPlacePoint.addresses);
    if (request.marketPlacePoint.ceo && typeof request.marketPlacePoint.ceo.birthDate !== 'string' && !(request.marketPlacePoint.ceo.birthDate instanceof String))
      request.marketPlacePoint.ceo.birthDate = request.marketPlacePoint.ceo.birthDate.format("yyyy-MM-DD");

    this.dataRestService
      .registerMarketplacePoint(request)
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.isLoading = false;
        let message = res.errorMessage 
          ? res.errorMessage.replace(/(?:\r\n|\r|\n)/g, '<br>')
          : 'Успешно';
        this.dialogService.openMessage({
          title: 'Результат регистрации',
          okLabel: 'OK',
          cancelCallback: null,
          okCallback: () => {
            if (res.isSuccess)
              this.afterSave(this);
          },
          content: message,
        });
      });
  }

  lowerize(obj: object): object {
    return Object.keys(obj).reduce((accumulator, key) => {
      let newKey = key.charAt(0).toLowerCase() + key.slice(1);
      accumulator[newKey as keyof typeof obj] = obj[key as keyof typeof obj];
      return accumulator;
    }, {});  
  }

}
