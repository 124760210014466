import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EEntityList } from '@app/shared/models';
import { getErrorMessage } from '@app/shared/utilities/forms.untils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DataRestService } from '@shared/services';
import { RegexPatternURL } from '@shared/utilities';
import { SafeUrl } from '@angular/platform-browser';
import {
  marketing_stories, marketing_stories_file,
} from '@src/api';
import { ICreateSpecStories } from './create-update-stories.models';

@UntilDestroy()
@Component({
  templateUrl: './create-update-stories.component.html',
  styleUrls: ['./create-update-stories.component.scss'],
})
export class CreateUpdateStoriesComponent implements OnInit {
  EEntityList = EEntityList;
  submitted: boolean = false;
  formGroup: UntypedFormGroup;
  contentUrl: SafeUrl;
  previewUrl: SafeUrl;
  title: string;
  getErrorMessage = getErrorMessage;
  constructor(
    @Inject(MAT_DIALOG_DATA) public entity: marketing_stories,
    private dialogRef: MatDialogRef<CreateUpdateStoriesComponent>,
    private fb: UntypedFormBuilder,
    private dataRestService: DataRestService,
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    if (this.entity) {
      let content: marketing_stories_file = null;
      let preview: marketing_stories_file = null;
      for (let i = 0; i < this.entity.files.length; i++) {
        let file = this.entity.files[i];
        let url = file?.url;
        if (url != null) {
          if (file.orderNumber == 1) {
            this.contentUrl = file.url;
            content = file;
          } else {
            this.previewUrl = file.url;
            preview = file;
          }
        }
      }
      this.title = $localize`Просмотр stories`;
      this.formGroup.patchValue(this.entity);
      if (content)
        this.formGroup.get('content').setValue(content);
      if (preview)
        this.formGroup.get('preview').setValue(preview);
    } else {
      this.title = $localize`Создать новый stories`;
    }
  }

  private initForm(): void {
    this.formGroup = this.fb.group(
      {
        id: [],
        entity: [EEntityList.marketing_stories],
        storiesSubject: [null, [Validators.maxLength(250)]],
        storiesText: [null, [Validators.maxLength(250)]],
        linkToUrl: [null, [Validators.pattern(RegexPatternURL)]],
        isActual: [true, [Validators.required]],
        isMustSee: [null],
        content: [null, [Validators.required]],
        preview: [null, [Validators.required]],
      }
    );
  }

  afterSave(): void {
    this.dialogRef.close(true);
  }

  get contentCtrl(): UntypedFormControl {
    return this.formGroup.get('content') as UntypedFormControl;
  }

  get previewCtrl(): UntypedFormControl {
    return this.formGroup.get('preview') as UntypedFormControl;
  }

  submittedEvent(): void {
    this.submitted = true;
  }

  transformRequestData(entity: any): ICreateSpecStories {
    let tmp = new Array();
    if (entity.content) { 
      tmp.push(entity.content);
    }
    if (entity.preview) { 
      tmp.push(entity.preview);
    }
    entity.files = tmp;
    entity.files[0].orderNumber = 1;
    entity.files[1].orderNumber = 0;

    return entity as ICreateSpecStories;
  }

  public isLinkToUrl(): boolean {
    const url = this.formGroup.get('linkToUrl').value;
    return url != null;
  }
  public LinkToUrl() {
    const url = this.formGroup.get('linkToUrl').value;
    if (url) window.open(url, '_blank');
  }
}
