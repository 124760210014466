<div class="flex h-60 items-center justify-center">
  <div class="flex flex-col justify-center items-center">
    <hoho-icon class="mb-3.5 text-margaret cursor-pointer" (click)="nextHour()" iconName="angle-up"></hoho-icon>
    <input
      class="h-11 w-11 text-subtitle-1 bg-arizona rounded-md flex items-center select-none justify-center text-center"
      hohoHoursMask
      [ngModel]="hours"
      (ngModelChange)="hours = $event; changeTime()"
    />
    <hoho-icon class="mt-3.5 text-margaret cursor-pointer" (click)="prevHour()" iconName="angle-down"></hoho-icon>
  </div>
  <div class="text-subtitle-1 text-texas mx-4 select-none">:</div>
  <div class="flex flex-col justify-center items-center">
    <hoho-icon
      class="mb-3.5 text-margaret cursor-pointer select-none"
      (click)="nextMin()"
      iconName="angle-up"
    ></hoho-icon>
    <input
      class="h-11 w-11 text-subtitle-1 bg-arizona rounded-md flex items-center select-none justify-center text-center"
      hohoMinutesMask
      [ngModel]="minutes"
      (ngModelChange)="minutes = $event; changeTime()"
    />

    <hoho-icon
      class="mt-3.5 text-margaret cursor-pointer select-none"
      (click)="prevMin()"
      iconName="angle-down"
    ></hoho-icon>
  </div>
</div>
