import { EquipmentEnum } from '../enums/equipment.enum';

export class EquipmentIconService {
  public static getIconName(equipmentType: EquipmentEnum) {
    switch (equipmentType) {
      case EquipmentEnum.Network:
        return 'signal-alt-3';
      case EquipmentEnum.CashlessPaymentTerminal:
        return 'card-atm';
      case EquipmentEnum.BillAcceptor:
        return 'money-withdrawal';
      case EquipmentEnum.CoinBox:
        return 'coin-withdrawal';
      case EquipmentEnum.Receipt:
        return 'receipt';

      default:
        break;
    }
  }
}
