import { Injectable } from '@angular/core';
import { HttpService } from '@shared/services';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  YaMapSuggestResponse,
  YaMapGeocodeResponse,
  LatLngAndPlaceName,
  ILatLng,
} from '@shared/modules/maps/maps.models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MapRestService {
  constructor(private http: HttpService) {}

  public geoCodeResponseSubject: BehaviorSubject<LatLngAndPlaceName> = new BehaviorSubject<LatLngAndPlaceName>(null);

  public searchSuggest(place: string): Observable<string[]> {
    if (!place) {
      return of([]);
    }
    const query = {
      text: place,
    };
    return this.http.post<YaMapSuggestResponse>('geoyandex/AddressSuggest', query).pipe(
      map((response: YaMapSuggestResponse) => {
        return response.results.map((result) => {
          const subtitleText = result.subtitle?.text != null ? result.subtitle?.text + ' , ' : '';
          const titleText = result.title.text;
          return subtitleText + titleText;
        });
      }),
    );
  }

  public geoCode(
    { latLng }: { latLng?: ILatLng },
    { placeName }: { placeName?: string },
    { isNeedCenter }: { isNeedCenter?: boolean } = {},
  ): Observable<LatLngAndPlaceName> {
    if (!latLng && !placeName) {
      return of(null);
    }
		//swap: YaGeoDecode need lng first, not lat
    const lngLat = latLng?.lng + ',' + latLng?.lat;

    const query = {
      text: placeName ?? lngLat,
    };
    return this.http.post<YaMapGeocodeResponse>('geoyandex/GeoDecode', query).pipe(
      map((response: YaMapGeocodeResponse) => {
        const featureMember = response?.response?.geoObjectCollection?.featureMember[0];
        const lngLatResponse = featureMember?.geoObject?.point?.pos.split(' ');
        const latLng = `${lngLatResponse[1]} ${lngLatResponse[0]}`;
        const geoObjectResponse = featureMember?.geoObject;
        const placeNameNew = geoObjectResponse?.name + ',' + geoObjectResponse?.description;

        let result: LatLngAndPlaceName;

        if (placeName) {
          result = { latLng: latLng, placeName: placeNameNew, isNeedCenter: isNeedCenter ?? false };
        } else {
          result = { latLng: undefined, placeName: placeNameNew, isNeedCenter: isNeedCenter ?? false };
        }
        this.geoCodeResponseSubject.next(result);
        return result;
      }),
    );
  }
}
