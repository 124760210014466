import { IEntity } from '@shared/models';

export interface IUserRequest extends IEntity {
  requestStatus?: number;
}

export enum EUserRequestStatus {
  new = 1,
  blocked = 4,
  unconfirmed = 0,
}
