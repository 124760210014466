import { Component, Input } from '@angular/core';
import { IInfoCardModel } from '../../models/card.models';

@Component({
  selector: 'hoho-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss'],
})
export class InfoCardComponent {
  @Input() model: IInfoCardModel;
}
