import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'as',
})
export class AsPipe implements PipeTransform {
  transform<T>(value: any, className: new (...args: any[]) => T): T {
    return value as T;
  }
}
