import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minutesToHours',
})
export class MinutesToHoursPipe implements PipeTransform {
  transform(value: number): string {
    let hours = Math.floor(value / 60);
    let minutes = Math.floor(value % 60);
    return value ? hours + ' ' + $localize`ч.` + ' ' + minutes + ' ' + $localize`м.` : $localize`Не задано`;
  }
}
