import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ListBuilderComponent } from '@app/core/modules/list-builder/list-builder.component';
import { EListBuilderViews, IListBuilderProps } from '@app/core/modules/list-builder/models';
import { EEntityList } from '@app/shared/models';
import { users } from '@src/api';

@Component({
  selector: 'hoho-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrdersComponent implements OnInit {
  @ViewChild('tableBuilder') tableBuilderComponent: ListBuilderComponent<users>;
  tableBuilderProps: IListBuilderProps<users> = {
    entity: EEntityList.sl_order,
    views: [EListBuilderViews.table],
  };
  constructor(private router: Router) {}

  ngOnInit(): void {}
}
