/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { base_entity } from './base_entity';
import type { feature_settings } from './feature_settings';
import type { module } from './module';
import type { object_link } from './object_link';
import type { role } from './role';

/**
 * Модуль роли
 */
export type role_modules = (base_entity & {
    /**
     * Идентификатор
     */
    id?: string;
    /**
     * Порядок загрузки
     */
    loadOrder?: number;
    /**
     * Модуль
     */
    module?: (module | object_link);
    /**
     * Роль
     */
    readonly role?: (role | object_link);
    /**
     * Подключенные настройки клиентского модуля
     */
    features?: Array<feature_settings>;
    /**
     * Доступ
     */
    access?: role_modules.access;
});

export namespace role_modules {

    /**
     * Доступ
     */
    export enum access {
        '_1' = 1,
        '_2' = 2,
        '_4' = 4,
        '_8' = 8,
    }


}

