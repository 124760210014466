import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ListComponent } from '@app/pages/feedback/components/list/list.component';
import { ReviewsComponent } from '@app/pages/feedback/components/reviews/reviews.component';
import { IPipeListItem } from '@app/pages/feedback/feedback.models';
import { FeedbackRoutingNames } from '@app/pages/feedback/feedback.routing';
import { DashboardComponent } from '@app/pages/feedback/modules/dashboard/dashboard.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ITabContent } from '@shared/components/feature-tabs/feature-tabs.models';
import { EFeedbackFeatures, EModules, ModuleFeatureType } from '@shared/models';
import { IAdditionalColumn } from '@core/modules/list-builder/list-builder.models';

@UntilDestroy()
@Component({
  selector: 'hoho-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent implements OnInit {
  EModules = EModules;
  activeFeature: EFeedbackFeatures;
  tabsContentList: ITabContent[] = [];
  @Input() pipeListItems: IPipeListItem[];
  @Input() isLoading: boolean;
  @ViewChild('listComponent') listComponent: ListComponent;
  @ViewChild('dashboardComponent') dashboardComponent: DashboardComponent;
  @ViewChild('reviewsComponent') reviewsComponent: ReviewsComponent;

  @ViewChild('list') listTemp: TemplateRef<HTMLElement>;
  @ViewChild('reviews') reviewsTemp: TemplateRef<HTMLElement>;
  @ViewChild('dashboard') dashboardTemp: TemplateRef<HTMLElement>;

  @ViewChild('link') link: TemplateRef<HTMLTemplateElement>;
  additionalColumns: IAdditionalColumn[];

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.activatedRoute.params.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res.tab) {
        switch (res.tab) {
          case FeedbackRoutingNames.dashboard.name:
            this.activeFeature = EFeedbackFeatures.Chart;
            break;
          case FeedbackRoutingNames.table.name:
            this.activeFeature = EFeedbackFeatures.Table;
            break;
          case FeedbackRoutingNames.reviews.name:
            this.activeFeature = EFeedbackFeatures.Reviews;
            break;
        }
        this.cd.markForCheck();
      }
    });
  }

  ngAfterViewInit(): void {
    this.tabsContentList = [
      { name: $localize`График`, template: this.dashboardTemp, feature: EFeedbackFeatures.Chart },
      { name: $localize`Таблица`, template: this.listTemp, feature: EFeedbackFeatures.Table },
      { name: $localize`Отзывы`, template: this.reviewsTemp, feature: EFeedbackFeatures.Reviews },
    ];

    this.additionalColumns = [
      {
        name: 'link',
        template: this.link,
        desc: '',
      },
    ];
  }

  changeTab(feat: ModuleFeatureType): void {
    let url: string;
    switch (feat) {
      case EFeedbackFeatures.Chart:
        url = FeedbackRoutingNames.dashboard.path;
        break;
      case EFeedbackFeatures.Table:
        url = FeedbackRoutingNames.table.path;
        break;
      case EFeedbackFeatures.Reviews:
        url = FeedbackRoutingNames.reviews.path;
        break;
    }

    this.router.navigate([url], { queryParamsHandling: 'merge' });
  }
}
