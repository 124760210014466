/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { base_entity } from './base_entity';
import type { company } from './company';
import type { object_link } from './object_link';
import type { users } from './users';

/**
 * Запрос пользователя на регистрацию
 */
export type user_request = (base_entity & {
    /**
     * Идентификатор
     */
    id?: string;
    /**
     * Фамилия
     */
    lastName: string;
    /**
     * Имя
     */
    firstName: string;
    /**
     * Электронная почта
     */
    email: string;
    /**
     * Номер телефона
     */
    readonly phoneNumber?: string;
    /**
     * Код организации
     */
    orgCode?: string;
    /**
     * Host
     */
    readonly host?: string;
    /**
     * Статус рассмотрения
     */
    requestStatus: user_request.requestStatus;
    /**
     * Утвердивший пользователь
     */
    userVerifier?: (users | object_link);
    /**
     * Дата запроса
     */
    requestDate?: string;
    /**
     * Пользователь системы
     */
    users?: (users | object_link);
    /**
     * Комментарий
     */
    comment?: string;
    /**
     * Тип заявки
     */
    requestType: user_request.requestType;
    /**
     * Город
     */
    cityName?: string;
    /**
     * Дата рождения
     */
    birthday?: string;
    /**
     * Код восстановления
     */
    readonly secretCode?: string;
    /**
     * Пароль
     */
    password?: string;
    /**
     * Соль
     */
    salt?: string;
    /**
     * Количество попыток
     */
    attemptCount?: number;
    /**
     * Время следующего запроса на отправку кода
     */
    codeNextChangeTime?: string;
    /**
     * Компания
     */
    company?: (company | object_link);
    /**
     * Отчество
     */
    middleName?: string;
    /**
     * Дата блокировки
     */
    blockedDate?: string;
    /**
     * ФИО
     */
    readonly fio?: string;
    /**
     * Компания
     */
    readonly companyName?: string;
    /**
     * Роль пользователя
     */
    readonly userRolesText?: string;
    /**
     * Поиск
     */
    readonly search?: string;
});

export namespace user_request {

    /**
     * Статус рассмотрения
     */
    export enum requestStatus {
        '_0' = 0,
        '_1' = 1,
        '_2' = 2,
        '_3' = 3,
        '_4' = 4,
    }

    /**
     * Тип заявки
     */
    export enum requestType {
        '_0' = 0,
        '_1' = 1,
    }


}

