/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { feedback_reason } from '../models/feedback_reason';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FeedbackReasonService {

    /**
     * Select feedback_reason by id
     * @param id Object ID
     * @returns feedback_reason Successful operation
     * @throws ApiError
     */
    public static selectFeedbackReasonById(
        id: string,
    ): CancelablePromise<feedback_reason> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/get/object/feedback_reason/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Save feedback_reason
     * @param requestBody Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static saveFeedbackReason(
        requestBody?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/save/object/feedback_reason',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Delete feedback_reason by id
     * @param id Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static deleteFeedbackReasonById(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/delete/object/feedback_reason/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

}
