/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { vm_manufacturer } from '../models/vm_manufacturer';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VmManufacturerService {

    /**
     * Select vm_manufacturer by id
     * @param id Object ID
     * @returns vm_manufacturer Successful operation
     * @throws ApiError
     */
    public static selectVmManufacturerById(
        id: string,
    ): CancelablePromise<vm_manufacturer> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/get/object/vm_manufacturer/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Save vm_manufacturer
     * @param requestBody Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static saveVmManufacturer(
        requestBody?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/save/object/vm_manufacturer',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Delete vm_manufacturer by id
     * @param id Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static deleteVmManufacturerById(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/delete/object/vm_manufacturer/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

}
