/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { product_matrix_item } from '../models/product_matrix_item';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProductMatrixItemService {

    /**
     * Select product_matrix_item by id
     * @param id Object ID
     * @returns product_matrix_item Successful operation
     * @throws ApiError
     */
    public static selectProductMatrixItemById(
        id: string,
    ): CancelablePromise<product_matrix_item> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/get/object/product_matrix_item/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Save product_matrix_item
     * @param requestBody Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static saveProductMatrixItem(
        requestBody?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/save/object/product_matrix_item',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Delete product_matrix_item by id
     * @param id Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static deleteProductMatrixItemById(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/delete/object/product_matrix_item/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

}
