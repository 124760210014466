import { company } from "@src/api";
import { EEntityList, EntityModel } from './entity.models';

export enum EUnit {
  pc,
  gr,
  ml,
}

export const UnitsList = [
  {
    displayName: 'шт.',
    id: 0,
  },
  {
    displayName: 'гр.',
    id: 1,
  },
  {
    displayName: 'мл.',
    id: 2,
  },
];

export class GoodsModel extends EntityModel {
  id: string;
  code?: string;
  name?: string;
  company?: company;
}