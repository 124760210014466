import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileHandle } from '@shared/directives/drag-directive.directive';
import { DataRestService } from '@shared/services';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { EEntityList } from '@shared/models';

@Component({
  selector: 'hoho-drag-and-drop',
  templateUrl: './drag-and-drop.component.html',
  styleUrls: ['./drag-and-drop.component.scss'],
})
export class DragAndDropComponent {
  @Input() requiredFileType: string = 'image/png, image/jpeg, image/jpg';
  uploadSub: Subscription;
  uploadProgress: number;
  isImage: boolean;
  @ViewChild('fileInput') fileInputRef: ElementRef;
  @Output() uploadEvent = new EventEmitter<{ id: string; fileName: string }>();
  @Output() imgUrl = new EventEmitter<SafeUrl>();
  @Input() entity: EEntityList;
  @Input() ctrl: UntypedFormControl;
  @Input() submitted: boolean;
  @Input() maxFileSize: number = 4194304;
  @Input() type: 'image' | 'video' | 'file' | 'content' = 'image';
  constructor(private dataRestService: DataRestService, private sanitizer: DomSanitizer) {}

  filesDropped(files: FileHandle[]): void {
    this.loadFile(files[0].file);
  }

  onFileSelected(event: Event) {
    const target = event.target as HTMLInputElement;
    const file: File = target.files[0];
    if (file) {
      this.loadFile(file);
    }
  }

  private loadFile(file: File): void {
    var re = /(?:\.([^.]+))?$/;
    var ext = re.exec(file.name)[1];
    this.isImage = ['png', 'jpeg', 'jpg'].indexOf(ext.toLowerCase()) > -1;
    if (file.size > this.maxFileSize) {
      this.ctrl.setErrors({
        size: true,
      });
    } else {
      this.ctrl.setErrors(null);
      const formData = new FormData();
      formData.append('files', file, file.name);
      const upload$ = this.dataRestService.postFile<string>(formData).pipe(finalize(() => this.reset()));

      this.uploadSub = upload$.subscribe((event: HttpEvent<string>) => {
        if (event instanceof HttpResponse) {
          this.ctrl?.setValue({
            url: this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file)),
            content: event.body[0],
            name: file.name,
            entity: this.entity,
          });
          if (this.isImage) {
            this.imgUrl.emit(this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file)));
          }

          this.uploadEvent.emit({ id: event.body[0], fileName: file.name });
        } else if (event.type == HttpEventType.UploadProgress) {
          this.uploadProgress = Math.round(100 * (event.loaded / event.total));
        }
      });
    }
  }
  cancelUpload(): void {
    this.uploadSub.unsubscribe();
    this.reset();
  }

  reset(): void {
    this.uploadProgress = null;
    this.uploadSub = null;
  }
  deleteFile(): void {
    this.ctrl.setValue([]);
    this.fileInputRef.nativeElement.value = '';
    this.isImage = false;
  }
}
