import {
  EVMStatus,
  VendingMachineInfoModel,
  VendingMachineListModel,
  VendingMachinePageInfoModel,
  VendingMachineStateModel,
} from '@shared/models';
import { CommunicationLevelEnum } from '../enums/communication-level.enum';
import { EquipmentEnum } from '../enums/equipment.enum';
import { FillingLevelEnum } from '../enums/filling-level.enum';
import { MachineStateEnum } from '../enums/machine-state.enum';
import { EquipmentState, IEquipmentCardModel, IInfoCardModel, IPlacementCardModel } from '../models/card.models';

export class ResponseModelsTransformService {
  static vmInfoModelToInfoCardModel(model: VendingMachineInfoModel): IInfoCardModel {
    const result = new IInfoCardModel(model);

    result.currencySymbol = '₽';
    result.fundsReceivedAmount = model.cashAtTheMachine;
    result.changeAmount = model.change;
    result.cashProceedsAmount = model.cashPerDay;
    result.cashlessProceedsAmount = model.cashlessPerDay;
    result.ingredientsFillingLevel = model.ingredientFill;
    result.machineState = model.status === EVMStatus.Online ? MachineStateEnum.Working : MachineStateEnum.Off;

    return result;
  }

  static vmListModelToPlacementCardModel(model: VendingMachineListModel): IPlacementCardModel {
    const result = new IPlacementCardModel(model);

    result.organization = model.company;
    result.placementLocation = model.placement;
    result.machineState = model.status === EVMStatus.Online ? MachineStateEnum.Working : MachineStateEnum.Off;

    return result;
  }

  static vmPageInfoModelToPlacementCardModel(model: VendingMachinePageInfoModel): IPlacementCardModel {
    const result = new IPlacementCardModel(model);

    result.organization = model.company;
    result.placementLocation = model.placement;
    result.machineState = model.status === EVMStatus.Online ? MachineStateEnum.Working : MachineStateEnum.Off;
    result.locationFormats = model.locationFormats;

    return result;
  }

  static vmStateModelToEquipmentCardModel(model: VendingMachineStateModel): IEquipmentCardModel {
    const result = new IEquipmentCardModel(model);

    result.communicationLevel =
      model.status === EVMStatus.Online
        ? CommunicationLevelEnum.GoodConnectionLevel
        : CommunicationLevelEnum.NoConnection;

    result.equipmentStates = [];
    result.equipmentStates.push(new EquipmentState(EquipmentEnum.Network, model.networkState));
    result.equipmentStates.push(new EquipmentState(EquipmentEnum.CashlessPaymentTerminal, model.cashlessState));
    result.equipmentStates.push(new EquipmentState(EquipmentEnum.BillAcceptor, model.billAcceptorState));
    result.equipmentStates.push(new EquipmentState(EquipmentEnum.CoinBox, model.coinAcceptorState));
    result.equipmentStates.push(new EquipmentState(EquipmentEnum.Receipt, model.cashRegisterState));

    result.downtime = model.downtime;
    result.machineState = model.status === EVMStatus.Online ? MachineStateEnum.Working : MachineStateEnum.Off;

    return result;
  }
}
