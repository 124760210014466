import { Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportDownloadsService } from '@app/shared/services/report-downloads.service';
import { ListBuilderComponent } from '@core/modules/list-builder/list-builder.component';
import { EListBuilderViews, IListBuilderProps } from '@core/modules/list-builder/models/list-builder.models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ITabContent } from '@shared/components/feature-tabs/feature-tabs.models';
import {
  EEntityList,
  EModules,
  EReportsFeatures,
  EVMStatus,
  IPagingRequestFilters,
  IServerRequestPayload,
  IVendingMachineBase,
  ModuleFeatureType,
} from '@shared/models';
import { IOtherReportsModel } from '@shared/models/reports.models';
import { DataRestService, UserService } from '@shared/services';
import { auto_report } from '@src/api';
import { ReplaySubject } from 'rxjs';
import { ReportsRoutingNames } from '../../reports.rounting';
import { CreateUpdateFormComponent } from '../create-update-form/create-update-form.component';
import { paths } from './tabs.config';

@UntilDestroy()
@Component({
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent implements OnInit, AfterViewInit {
  title = $localize`Отчеты`;
  EModules = EModules;
  activeFeature: EReportsFeatures;
  EReportsFeatures = EReportsFeatures;
  tabsContentList: ITabContent[] = [];
  byStateTotals$ = new ReplaySubject<{
    workingCount: number;
    offCount: number;
  }>();

  salesListBuilderProps: IListBuilderProps<IVendingMachineBase> = {
    entity: EEntityList.vm_vend,
    views: [EListBuilderViews.table],
  };
  salesRequestPayload: IServerRequestPayload = {
    aggregate: { sum: ['price', 'cnt'], avg: ['avgPrice'] },
  };

  stateListBuilderProps: IListBuilderProps<IVendingMachineBase> = {
    entity: EEntityList.vending_machine,
    views: [EListBuilderViews.table],
  };

  autoListBuilderProps: IListBuilderProps<auto_report> = {
    entity: EEntityList.auto_report,
    views: [EListBuilderViews.table],
    editFunc: this.edit.bind(this),
  };

  otherListBuilderProps: IListBuilderProps<IOtherReportsModel> = {
    entity: EEntityList.report_menu_item,
    views: [EListBuilderViews.table],
  };
  otherReportRequestPayload: IServerRequestPayload = {
    sort: [{ field: 'isElected', desc: true }],
  };

  groupView: boolean = false;
  @ViewChild('sales') salesTemp: TemplateRef<HTMLElement>;
  @ViewChild('state') stateTemp: TemplateRef<HTMLElement>;
  @ViewChild('auto') autoTemp: TemplateRef<HTMLElement>;
  @ViewChild('other') otherTemp: TemplateRef<HTMLElement>;
  @ViewChild('stateReportsTable') stateReportsTableBuilderComponent: ListBuilderComponent<IVendingMachineBase>;
  @ViewChild('autoReportsTable') autoReportsTableBuilderComponent: ListBuilderComponent<auto_report>;
  @ViewChild('otherReportsTable') otherReportsTableBuilderComponent: ListBuilderComponent<IOtherReportsModel>;

  constructor(
    private router: Router,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private restService: DataRestService,
    private dialog: MatDialog,
    private dataRestService: DataRestService,
    public reportDownloadsService: ReportDownloadsService,
    private cd: ChangeDetectorRef,
  ) {}
  ngOnInit(): void {
    this.activatedRoute.params.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res.tab) {
        if (res.tab) {
          switch (res.tab) {
            case ReportsRoutingNames.sales.name:
              this.activeFeature = EReportsFeatures.BySale;
              break;
            case ReportsRoutingNames.auto.name:
              this.activeFeature = EReportsFeatures.AutoReports;
              break;
            case ReportsRoutingNames.states.name:
              this.activeFeature = EReportsFeatures.ByState;
              break;
            case ReportsRoutingNames.other.name:
              this.activeFeature = EReportsFeatures.OtherReports;
              break;
          }
        }
        this.cd.markForCheck();
      }
    });
  }

  ngAfterViewInit(): void {
    this.tabsContentList = [
      { name: $localize`По продажам`, template: this.salesTemp, feature: EReportsFeatures.BySale },
      { name: $localize`По состоянию`, template: this.stateTemp, feature: EReportsFeatures.ByState },
      { name: $localize`Автоматические отчеты`, template: this.autoTemp, feature: EReportsFeatures.AutoReports },
      { name: $localize`Другие отчеты`, template: this.otherTemp, feature: EReportsFeatures.OtherReports },
    ];
  }

  changeTab(feat: ModuleFeatureType): void {
    let url: string;
    switch (feat) {
      case EReportsFeatures.BySale:
        url = ReportsRoutingNames.sales.path;
        break;
      case EReportsFeatures.ByState:
        url = ReportsRoutingNames.states.path;
        this.stateReportsTableBuilderComponent.contentSubject$.pipe(untilDestroyed(this)).subscribe((res) => {
          this.loadByStateTotals(this.stateReportsTableBuilderComponent.getFilter());
        });
        break;
      case EReportsFeatures.AutoReports:
        url = ReportsRoutingNames.auto.path;
        break;
      case EReportsFeatures.OtherReports:
        url = ReportsRoutingNames.other.path;
        break;
    }

    this.router.navigate([url], { replaceUrl: true, queryParams: { filter: null } });
  }

  loadByStateTotals(filter: IPagingRequestFilters) {
    const payload = {
      paths: ['status'],
      group: ['status'],
      aggregate: {
        count: true,
      },
      filter: filter,
    };
    this.restService
      .group<{ status: EVMStatus; count: number }>(EEntityList.vending_machine, 'groupView', 0, 200, payload)
      .subscribe((x) => {
        this.byStateTotals$.next({
          workingCount: x.content.find((i) => i.status === EVMStatus.Online)?.count ?? 0,
          offCount: x.content.filter((i) => i.status !== EVMStatus.Online).reduce((acc, x) => acc + x.count ?? 0, 0),
        });
      });
  }

  openCreateUpdateUserModal(): void {
    const dialogRef = this.dialog.open(CreateUpdateFormComponent, {
      width: '20rem',
    });
    dialogRef.afterClosed().subscribe((isSuccess: boolean) => {
      if (isSuccess) {
        this.autoReportsTableBuilderComponent.reload();
      }
    });
  }

  edit(entity: auto_report): void {
    this.dataRestService.get<auto_report>(EEntityList.auto_report, entity.id, paths).subscribe((res: auto_report) => {
      res.entity = EEntityList.auto_report;
      const dialogRef = this.dialog.open(CreateUpdateFormComponent, {
        width: '20rem',
        data: res,
      });
      dialogRef.afterClosed().subscribe((isSuccess: boolean) => {
        if (isSuccess) {
          this.autoReportsTableBuilderComponent.reload();
        }
      });
    });
  }

  changeGroupView(): void {
    this.router.navigate([], { replaceUrl: true, queryParams: { filter: null } });
    this.groupView = !this.groupView;
  }

  downloadsView() {
    this.reportDownloadsService.show();
  }

  scrollToTop() {
    document.getElementsByTagName('mat-sidenav-content')[0].scroll({ top: 0, left: 0, behavior: 'smooth' });
  }
}
