import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ListComponent as CompaniesListComponent } from '@app/pages/directory/modules/companies/components/list/list.component';
import { ListComponent as UsersListComponent } from '@app/pages/directory/modules/users/components/list/list.component';
import { ITabContent } from '@shared/components/feature-tabs/feature-tabs.models';
import { EModules, ModuleFeatureType } from '@shared/models/module.models';
import { UserService } from '@shared/services';

@Component({
  selector: 'hoho-feature-tabs',
  templateUrl: './feature-tabs.component.html',
  styleUrls: ['./feature-tabs.component.scss'],
})
export class FeatureTabsComponent implements OnInit {
  @Output() changeTab = new EventEmitter<ModuleFeatureType>();
  @Input() module: EModules;

  @Input() activeFeature: ModuleFeatureType;
  availableFeatures: ITabContent[];

  constructor(private userService: UserService) {}

  ngOnInit(): void {}

  get activeTabIndex(): number {
    return this.availableFeatures ? this.availableFeatures.findIndex((i) => i.feature === this.activeFeature) : 0;
  }

  @Input()
  set tabsList(value: ITabContent[]) {
    if (!value) {
      return;
    }
    const module = this.userService.modules.find((j) => j.module === this.module);
    this.availableFeatures = value.filter((i) => {
      return module ? module.features.includes(i.feature) : false;
    });
  }
}
