<hoho-create-update-dialog
  [formGroup]="formGroup"
  [entityType]="EEntityList.company"
  [transformRequestData]="setAddress"
  (afterSave)="afterSave()"
  (submitted)="submittedEvent()"
  [title]="title"
>
  <mat-form-field class="w-full">
    <mat-label i18n>Наименование</mat-label>
    <input cdkFocusInitial required formControlName="name" matInput />
    <mat-error name="nameError" *ngIf="formGroup.get('name').invalid">{{ getErrorMessage('name', formGroup) }}</mat-error>
  </mat-form-field>

  <!--  <div class="mat-form-field w-full">-->
  <!--    <hoho-address-search appearance="standard" [addressControl]="formGroup.get('address')">-->
  <!--      <mat-error name="addressError" *ngIf="formGroup.get('address').invalid">{{-->
  <!--        getErrorMessage('address')-->
  <!--      }}</mat-error>-->
  <!--    </hoho-address-search>-->
  <!--  </div>-->
  <mat-form-field class="w-full">
    <mat-label i18n="@@address">Адрес</mat-label>
    <input required formControlName="address" matInput />
    <mat-error name="addressError" *ngIf="formGroup.get('address').invalid">{{ getErrorMessage('address', formGroup) }}</mat-error>
  </mat-form-field>

  <mat-form-field class="w-full">
    <mat-label i18n>ИНН</mat-label>
    <input required formControlName="orgCode" matInput />
    <mat-error name="orgCodeError" *ngIf="formGroup.get('orgCode').invalid">{{ getErrorMessage('orgCode', formGroup) }}</mat-error>
  </mat-form-field>

  <mat-form-field class="w-full">
    <mat-label i18n="@@phone">Телефон</mat-label>
    <input required formControlName="phone" matInput />
    <mat-error name="phoneError" *ngIf="formGroup.get('phone').invalid">{{ getErrorMessage('phone', formGroup) }}</mat-error>
  </mat-form-field>

  <mat-form-field class="w-full">
    <mat-label i18n>Ответственное лицо</mat-label>
    <input formControlName="responsiblePerson" matInput />
    <mat-error name="responsiblePersonError" *ngIf="formGroup.get('responsiblePerson').invalid">{{ getErrorMessage('responsiblePerson', formGroup) }}</mat-error>
  </mat-form-field>

  <mat-checkbox class="mb-3" color="primary" [formControlName]="'lkEnabled'" i18n>
    <span class="w-56 whitespace-pre-wrap">ЛК</span>
  </mat-checkbox>

  <mat-form-field class="w-full">
    <mat-label i18n>Примечание</mat-label>
    <input formControlName="comment" matInput />
  </mat-form-field>
</hoho-create-update-dialog>

