import { Component } from '@angular/core';

@Component({
  selector: 'second-layout',
  templateUrl: './second-layout.component.html',
  styleUrls: ['./second-layout.component.scss'],
})
export class SecondLayoutComponent {
  constructor() {}
}
