import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ICreatePushSpec } from '@app/pages/marketing/components/create-update-push/create-push.models';
import { EEntityList, IEntity, IPagingResponsePayload, IServerRequestPayload } from '@app/shared/models';
import { getErrorMessage } from '@app/shared/utilities/forms.untils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DataRestService } from '@shared/services';
import { RegexPatternURL } from '@shared/utilities';
import { SafeUrl } from '@angular/platform-browser';
import {
  marketing_push_notification_file,
  marketing_push_notification_individual_customers,
  marketing_push_notifications,
} from '@src/api';
import { IMarketingCustomerGroup } from '../group-create-update/group-create-update.models';
import { concatMap, from, last, Observable, tap, map, switchMap } from 'rxjs';
import { DialogService } from '@app/shared/modules/dialogs/services/dialog.service';
import { CreateUpdateDialogComponent } from '@app/shared/modules/dialogs/components/create-update-dialog/create-update-dialog.component';

@UntilDestroy()
@Component({
  templateUrl: './create-update-push.component.html',
  styleUrls: ['./create-update-push.component.scss'],
})
export class CreateUpdatePushComponent implements OnInit {
  EEntityList = EEntityList;
  isLoading: boolean = false;
  submitted: boolean = false;
  formGroup: UntypedFormGroup;
  groupList: { name: string; id: string; entity: EEntityList.marketing_customers_group }[] = [];
  groupFilterPaths = ['id', 'name'];
  slUserFilterPaths = ['id', 'login', 'phoneNumber', 'email'];
  groupFilterEntity = EEntityList.marketing_customers_group;
  slUserFilterEntity = EEntityList.sl_user;
  preview: boolean = false;
  imgUrl: SafeUrl;
  title: string;
  getErrorMessage = getErrorMessage;
  @ViewChild(CreateUpdateDialogComponent) createUpdateDialog: CreateUpdateDialogComponent<any>;
  constructor(
    @Inject(MAT_DIALOG_DATA) public entity: marketing_push_notifications,
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private dialogRef: MatDialogRef<CreateUpdatePushComponent>,
    private dialogService: DialogService,
    private dataRestService: DataRestService,
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    if (this.entity) {
      if ((this.entity.file as marketing_push_notification_file)?.url) {
        this.imgUrl = (this.entity.file as marketing_push_notification_file).url;
      }
      this.title = $localize`Редактировать уведомление`;
      this.formGroup.patchValue(this.entity);
      if (this.entity.marketingCustomersGroups?.length) {
        this.formGroup.get('marketingCustomersGroups').patchValue(
          this.entity.marketingCustomersGroups.map((i) => {
            return i.marketingCustomersGroup.id;
          }),
        );
      } else {
        this.formGroup.get('marketingCustomersGroups').patchValue(null);
      }
      if (this.entity.individualCustomers?.length) {
        this.formGroup.get('individualCustomers').patchValue(
          this.entity.individualCustomers.map((i: marketing_push_notification_individual_customers) => {
            return i.slUserId;
          }),
        );
      } else {
        this.formGroup.get('individualCustomers').patchValue(null);
      }
    } else {
      this.title = $localize`Создать новое уведомление`;
    }
    this.loadGroups();
  }

  private initForm(): void {
    const validator = (formGroup: UntypedFormGroup) => {
      return formGroup.value.marketingCustomersGroups?.length || formGroup.value.individualCustomers?.length
        ? null
        : {
            recipientsRequired: true,
          };
    };
    this.formGroup = this.fb.group(
      {
        id: [],
        entity: [EEntityList.marketing_push_notifications],
        mailingStartTime: [null, [Validators.required]],
        messageSubject: [null, [Validators.required, Validators.maxLength(250)]],
        messageText: [null, [Validators.required, Validators.maxLength(250)]],
        linkToNewsPage: [null, [Validators.pattern(RegexPatternURL)]],
        marketingCustomersGroups: [null],
        file: [],
        individualCustomers: [null],
      },
      { validator },
    );
  }

  loadGroups(): void {
    const payload: IServerRequestPayload = {
      paths: ['id', 'name', 'entity'],
      sort: null,
    };
    this.dataRestService
      .page<{ name: string; id: string; entity: EEntityList.marketing_customers_group }>(
        EEntityList.marketing_customers_group,
        '',
        0,
        -1,
        payload,
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        (res: IPagingResponsePayload<{ name: string; id: string; entity: EEntityList.marketing_customers_group }>) => {
          this.groupList = res.content;
        },
      );
  }

  get marketingCustomersGroupsControl(): UntypedFormControl {
    return this.formGroup.get('marketingCustomersGroups') as UntypedFormControl;
  }

  get individualCustomersControl(): UntypedFormControl {
    return this.formGroup.get('individualCustomers') as UntypedFormControl;
  }

  afterSave(): void {
    this.dialogRef.close(true);
  }

  get fileCtrl(): UntypedFormControl {
    return this.formGroup.get('file') as UntypedFormControl;
  }

  get mailingStartTimeCtrl(): UntypedFormControl {
    return this.formGroup.get('mailingStartTime') as UntypedFormControl;
  }

  transformRequestData(entity: any): ICreatePushSpec {
    if (entity.marketingCustomersGroups) {
      entity.marketingCustomersGroups = entity.marketingCustomersGroups.map((i: number | string) => {
        return {
          entity: EEntityList.marketing_push_notification_customer_group,
          marketingCustomersGroup: {
            id: i,
            entity: EEntityList.marketing_customers_group,
          },
        };
      });
    }

    if (entity.individualCustomers) {
      entity.individualCustomers = entity.individualCustomers.map((i: any) => {
        return {
          entity: EEntityList.marketing_push_notification_individual_customers,
          slUserId: i,
        };
      });
    }

    return entity as ICreatePushSpec;
  }

  public isLinkToNewsPage(): boolean {
    const url = this.formGroup.get('linkToNewsPage').value;
    return url != null;
  }
  public LinkToNewsPage() {
    const url = this.formGroup.get('linkToNewsPage').value;
    if (url) window.open(url, '_blank');
  }

  getTotalGroupLength(): void {
    this.isLoading = true;
    this.cd.markForCheck();
    const marketingCustomersGroups = this.formGroup.value.marketingCustomersGroups;
    if (marketingCustomersGroups && marketingCustomersGroups.length) {
      let totalUserCount = 0;
      console.log(marketingCustomersGroups);
      from(marketingCustomersGroups)
        .pipe(
          concatMap((groupId: any) => this.getPayloadFilter(groupId)),
          tap((count: any) => ((totalUserCount += count), console.log(count))),
          last(),
        )
        .subscribe(() => {
          //user count to show warning
          if (totalUserCount > 0) {
            this.tooManyPushWarning(totalUserCount);
          }
        });
    } else {
      this.createUpdateDialog.performSave();
    }
  }

  getPayloadFilter(groupId: string): Observable<number> {
    console.log(groupId);
    return this.dataRestService
      .get<IMarketingCustomerGroup>(EEntityList.marketing_customers_group, groupId, ['id', 'name', 'filter'])
      .pipe(
        switchMap((res: IMarketingCustomerGroup) => this.getUserGroupLength(res.filter)),
        untilDestroyed(this),
      );
  }

  getUserGroupLength(filterPayload: any): Observable<number> {
    const payload: {
      paths: string[];
      filter: any[];
      parameters?: any;
      sort?: any;
    } = {
      paths: ['id', 'entity', 'login', 'email', 'phoneNumber', 'cityName', 'orderCount', 'orderSum', 'search'],
      filter: [filterPayload],
      parameters: undefined,
      sort: undefined,
    };

    return this.dataRestService.page<IEntity>(EEntityList.sl_user, '', 0, -1, payload).pipe(
      map((res: IPagingResponsePayload<IEntity>) => res.count),
      untilDestroyed(this),
    );
  }

  tooManyPushWarning(totalUserCount: number) {
    this.dialogService.openConfirm({
      cancelCallback: () => {
        this.isLoading = false;
      },
      okCallback: () => {
        this.createUpdateDialog.performSave();
      },
      content:
        totalUserCount > 100 ? '' : `Вы действительно хотите создать уведомление для ${totalUserCount} пользователей`,
      contentHtml:
        totalUserCount > 100
          ? `<p>Вы действительно хотите создать уведомление для <b style="font-size: 1.2rem;">${totalUserCount} пользователей </b></p>`
          : '',
    });
  }
}
