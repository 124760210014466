<mat-tab-group
  [dynamicHeight]="true"
  (selectedTabChange)="changeTab.emit(availableFeatures[$event.index].feature)"
  [selectedIndex]="activeTabIndex"
  class="mt-6 tab-label-container-gutter"
>
  <mat-tab *ngFor="let tab of availableFeatures" [label]="tab.name">
    <ng-template matTabContent>
      <ng-container *ngTemplateOutlet="tab.template"></ng-container>
    </ng-template>
  </mat-tab>
</mat-tab-group>
