export const WS_API = {
  EVENTS: {
    command_msg_send: 1,
    command_msg_in: 2,
    ping: 3,
    pong: 4,
    command_connect_channel: 5, // connect to channel
    command_msg_get_prev: 6,
    command_error: 7, // какая-то ошибка
    command_channels: 8, // пришел список каналов(контактов)
    command_missed_update: 9, // обновление количества пропущенных сообщений в чате
    command_get_contacts: 10, // запрос списка контактов
    command_leave_room: 11, // вышел из чата
    command_call_in: 12, // звонок
    command_call_accept: 13, // принять звонок
    command_call_reject: 14, // отклонить звонок
    command_call_hangout: 15, // закончить звонок
    command_call_voice_data: 16, // звук
    command_crypt: 17, // pub_pem
    command_event_list: 18, // список событий (event_list)
    command_udp_port_voice: 19, // udp voice port number
    command_call_crypt: 20, // call crypt tumbler
    command_msg_delete: 31, // удалены сообщения в чате
    command_update_info: 32, // обновление информации о чате
    command_leave_chat: 33, // собеседник покинул чат(вышел из состава участников)
    command_add_member: 34, // в чат добавился новый участник
    command_msg_forward: 35, // пересылка сообщений
    cmd_call_ring: 36, // собеседнику доставлено событие входящего звонка
    cmd_msg_change_status: 37, // команда изменения статуса сообщений
    command_msg_get_next: 38, // команда запроса следующих сообщений
    command_msg_replace: 39, // команда замены сообщения
    command_msg_edit: 40, // команда редактирования сообщения пользователем
    command_logout: 41, // logout
    command_clear_chat: 42, // администратор очистил чат для всех
    cmd_msg_data: 200, // команда с любыми данными
    command_is_typing: 201, //
  },
  COMMANDS: {
    SEND_TEXT: 'set-text',
    REMOVE_TEXT: 'remove-text',
  },
};

export enum EWebsocketsEvents {
  command_msg_send = 1,
  command_msg_in = 2,
  ping = 3,
  pong = 4,
  command_connect_channel = 5, // connect to channel
  command_msg_get_prev = 6,
  command_error = 7, // какая-то ошибка
  command_channels = 8, // пришел список каналов(контактов)
  command_missed_update = 9, // обновление количества пропущенных сообщений в чате
  command_get_contacts = 10, // запрос списка контактов
  command_leave_room = 11, // вышел из чата
  command_call_in = 12, // звонок
  command_call_accept = 13, // принять звонок
  command_call_reject = 14, // отклонить звонок
  command_call_hangout = 15, // закончить звонок
  command_call_voice_data = 16, // звук
  command_crypt = 17, // pub_pem
  command_event_list = 18, // список событий (event_list)
  command_udp_port_voice = 19, // udp voice port number
  command_call_crypt = 20, // call crypt tumbler
  command_msg_delete = 31, // удалены сообщения в чате
  command_update_info = 32, // обновление информации о чате
  command_leave_chat = 33, // собеседник покинул чат(вышел из состава участников)
  command_add_member = 34, // в чат добавился новый участник
  command_msg_forward = 35, // пересылка сообщений
  cmd_call_ring = 36, // собеседнику доставлено событие входящего звонка
  cmd_msg_change_status = 37, // команда изменения статуса сообщений
  command_msg_get_next = 38, // команда запроса следующих сообщений
  command_msg_replace = 39, // команда замены сообщения
  command_msg_edit = 40, // команда редактирования сообщения пользователем
  command_logout = 41, // logout
  command_clear_chat = 42, // администратор очистил чат для всех
  cmd_msg_data = 200, // команда с любыми данными
  command_is_typing = 201, //
}
