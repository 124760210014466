<div class="flex items-center">
  <hoho-dot-indicator [state]="dotIndicatorState" class="mr-2"></hoho-dot-indicator>

  <p *ngIf="state === machineStateEnum.Working" class="text-body-4 text-black" i18n>
    Работает, связь установлена
  </p>

  <p *ngIf="state === machineStateEnum.NotStable" class="text-body-4 text-black" i18n>
    Работает нестабильно
  </p>

  <p *ngIf="state === machineStateEnum.Off" class="text-body-4 text-black" i18n>
    Выключен
  </p>
</div>

