import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import moment, { Moment } from 'moment';
import { HeaderCalendarComponent } from '@shared/modules/calendar-headers/components/header-calendar/header-calendar.component';

@Component({
  selector: 'hoho-date-time',
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.scss'],
})
export class DateTimeComponent implements OnInit {
  ngModel: string;
  time: boolean = false;
  show: boolean = false;
  selectedDate: Moment | null;
  hours: string = '00';
  minutes: string = '00';
  headerCalendarComponent = HeaderCalendarComponent;
  @Input() minDate: Moment = moment();
  @Input() ctrl: UntypedFormControl;
  @Input() submitted: boolean;
  @Input() placeholder: string;
  @Input() appearance: 'fill' | 'outline' = 'fill';
  constructor() {}

  ngOnInit(): void {
    if (this.ctrl.value) {
      this.selectedDate = moment(this.ctrl.value);
      this.ngModel = moment(this.ctrl.value).format('DD/MM/YYYY HH:mm');
      this.minutes = moment(this.ctrl.value).format('mm');
      this.hours = moment(this.ctrl.value).format('HH');
    }
  }

  changeDate(): void {
    this.time = true;
    setTimeout(this.setDateTime.bind(this), 10);
  }

  changeTime(): void {
    this.setDateTime();
  }

  setDateTime(): void {
    if (this.selectedDate) {
      const date = moment(this.selectedDate.format()).set({
        hours: parseInt(this.hours),
        minutes: parseInt(this.minutes),
      });
      this.ngModel = date.format('DD/MM/YYYY HH:mm');
      this.ctrl.setValue(date);
    }
  }
}
