import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutingNames } from '@app/app-routing.module';
import { TabsComponent } from '@app/pages/reports/components/tabs/tabs.component';
import { ReportsComponent } from '@app/pages/reports/reports.component';
import RoutePath from '@app/shared/models/route-path.model';
import { EModules } from '@shared/models';
import { PermissionsGuard } from '@shared/services';
import { QuicklinkModule } from 'ngx-quicklink';

export const ReportsRoutingNames = {
  root: new RoutePath('root', '', ':tab'),
  sales: new RoutePath('sales', AppRoutingNames.reports.path + '/sales', ':tab'),
  states: new RoutePath('states', AppRoutingNames.reports.path + '/states', ':tab'),
  auto: new RoutePath('auto', AppRoutingNames.reports.path + '/auto', ':tab'),
  other: new RoutePath('other', AppRoutingNames.reports.path + '/other', ':tab'),
};

const routes: Routes = [
  {
    path: '',
    component: ReportsComponent,
    canActivate: [PermissionsGuard],
    data: {
      module: EModules.ReportsModule,
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: ReportsRoutingNames.sales.path,
      },
      {
        path: ReportsRoutingNames.root.routeParams,
        component: TabsComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule, QuicklinkModule],
})
export class ReportsRouting {}
