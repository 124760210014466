/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { base_entity } from './base_entity';
import type { object_link } from './object_link';
import type { support_appeal_subject } from './support_appeal_subject';
import type { users } from './users';
import type { vending_machine } from './vending_machine';

/**
 * Обращение в техподдержку
 */
export type support_appeal = (base_entity & {
    id?: string;
    readonly fio?: string;
    readonly email?: string;
    readonly phoneNumber?: string;
    status?: support_appeal.status;
    readonly createDate?: string;
    readonly number?: string;
    readonly channelUrl?: string;
    users?: (users | object_link);
    readonly search?: string;
    subject?: (support_appeal_subject | object_link);
    readonly lastMessageDate?: string;
    readonly lastStatusDate?: string;
    vendingMachine?: (vending_machine | object_link);
    resolution?: number;
    refundAmount?: number;
});

export namespace support_appeal {

    export enum status {
        '_0' = 0,
        '_1' = 1,
        '_2' = 2,
        '_3' = 3,
        '_4' = 4,
    }


}

