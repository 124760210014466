/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { support_statistics_appeal } from '../models/support_statistics_appeal';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SupportStatisticsAppealService {

    /**
     * Select support_statistics_appeal by id
     * @param id Object ID
     * @returns support_statistics_appeal Successful operation
     * @throws ApiError
     */
    public static selectSupportStatisticsAppealById(
        id: string,
    ): CancelablePromise<support_statistics_appeal> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/get/object/support_statistics_appeal/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Save support_statistics_appeal
     * @param requestBody Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static saveSupportStatisticsAppeal(
        requestBody?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/save/object/support_statistics_appeal',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Delete support_statistics_appeal by id
     * @param id Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static deleteSupportStatisticsAppealById(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/delete/object/support_statistics_appeal/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

}
