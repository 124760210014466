import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { IAddressSearchResult, ILatLng, LatLngAndPlaceName } from '@shared/modules/maps/maps.models';
import { MapRestService } from '@shared/services';
import { YaReadyEvent, YaEvent } from 'angular8-yandex-maps';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'hoho-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss'],
})
export class MapsComponent<T> implements OnInit {
  @Output() entitySelected: EventEmitter<T | null> = new EventEmitter<T | null>();
  @Output() address: EventEmitter<IAddressSearchResult | null> = new EventEmitter();
  @Input() entities: T[];
  control: AbstractControl;
  map: ymaps.Map;
  yadblclick: Function;
  mapCenterLatLng$: number[] = [55.754563421715524, 37.61312789753772];
  placemarkLatLng$: number[] = [0, 0];
  isSinglePLacemark: boolean;
  @Input()
  set coordinatesControl(control: AbstractControl) {
    if (!control) {
      return;
    }
    control.valueChanges
      .pipe(untilDestroyed(this), debounceTime(500), distinctUntilChanged())
      .subscribe((value: string) => {});
    this.control = control;
  }

  constructor(private mapRestService: MapRestService) {}

  ngOnInit() {
    this.mapRestService.geoCodeResponseSubject.subscribe((response: LatLngAndPlaceName) => {
      if (response?.latLng == null) return;
      const latLng = response.latLng.split(' ').map(parseFloat);
      if (response.isNeedCenter) this.changeCenter(latLng);
      this.placemarkLatLng$ = latLng;
    });
    //single on info page /vm/page/id
    if (this.entities != undefined && this.entities.length === 1) {
      this.isSinglePLacemark = true;
      const entity: any = this.entities[0];
      if (entity && entity.coordinatesView) {
        const coordinates = JSON.parse(entity.coordinatesView);
        if (coordinates && coordinates.lat && coordinates.lng) {
          this.mapCenterLatLng$ = [coordinates.lat, coordinates.lng];
          this.placemarkLatLng$ = [coordinates.lat, coordinates.lng];
        }
      }
    }
    if (this.control && this.control.value) {
      let response = null;
      try {
        response = JSON.parse(this.control.value);
      } catch (e) {}
      if (response?.lat) {
        this.mapCenterLatLng$ = [response.lat, response.lng];
        this.placemarkLatLng$ = [response.lat, response.lng];
      } else {
        const latLng = this.control.value;
        this.mapCenterLatLng$ = latLng.split(',').map(Number);
        this.placemarkLatLng$ = latLng.split(',').map(Number);
      }
    }
  }

  private changeCenter(latLng: number[]) {
    this.mapCenterLatLng$ = latLng;
  }

  onReady(event: YaReadyEvent<ymaps.Map>): void {
    const { target } = event;
    if (!this.isSinglePLacemark) target.behaviors.disable(['dblClickZoom']);
  }

  public onDblClick(event: YaEvent): void {
    if (this.isSinglePLacemark) return;
    const latLngFromEvent = event.event.get('coords');
    const latLng: ILatLng = {
      lat: latLngFromEvent[0],
      lng: latLngFromEvent[1],
    };

    var latLngFromEventString: LatLngAndPlaceName = {
      latLng: latLng.lat + ' ' + latLng.lng,
    };

    this.placemarkLatLng$ = latLngFromEvent;
    this.mapRestService.geoCode({ latLng: latLng }, { placeName: undefined }).subscribe();
    this.mapRestService.geoCodeResponseSubject.next(latLngFromEventString);
  }

  public dragEnd(event: YaEvent): void {
    const latLngFromEvent = event['target']['geometry']['_coordinates'];

    const latLng: ILatLng = {
      lat: latLngFromEvent[0],
      lng: latLngFromEvent[1],
    };

    const latLngFromEventString: LatLngAndPlaceName = {
      latLng: latLng.lat + ' ' + latLng.lng,
    };

    this.mapRestService.geoCode({ latLng: latLng }, { placeName: undefined }).subscribe();
    this.mapRestService.geoCodeResponseSubject.next(latLngFromEventString);
  }
}
