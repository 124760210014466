/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { base_entity } from './base_entity';
import type { marketing_push_notification_customer_group } from './marketing_push_notification_customer_group';
import type { marketing_push_notification_file } from './marketing_push_notification_file';
import type { marketing_push_notification_individual_customers } from './marketing_push_notification_individual_customers';
import type { object_link } from './object_link';
import type { users } from './users';

/**
 * Push-уведомления
 */
export type marketing_push_notifications = (base_entity & {
    /**
     * Идентификатор
     */
    id?: string;
    /**
     * Дата и время начала рассылки
     */
    mailingStartTime: string;
    /**
     * Тема сообщения
     */
    messageSubject: string;
    /**
     * Основной текст сообщения
     */
    messageText: string;
    /**
     * Ссылка на страницу новости
     */
    linkToNewsPage?: string;
    /**
     * Дата создания
     */
    createdDate?: string;
    /**
     * Пользователи системы
     */
    createdUser?: (users | object_link);
    /**
     * Кол-во клиентов
     */
    customersCount?: number;
    /**
     * Количество открытых уведомлений
     */
    openedNotificationsCount?: number;
    /**
     * Процент открытых пушей
     */
    readonly openedNotificationsPercent?: number;
    /**
     * Статус
     */
    readonly status?: marketing_push_notifications.status;
    /**
     * Файл
     */
    file?: (marketing_push_notification_file | object_link);
    /**
     * Группы пользователей
     */
    marketingCustomersGroups?: Array<marketing_push_notification_customer_group>;
    /**
     * Клиенты уведомления
     */
    individualCustomers?: Array<marketing_push_notification_individual_customers>;
    /**
     * Поиск
     */
    readonly search?: string;
    /**
     * Клиенты
     */
    readonly customers?: any;
});

export namespace marketing_push_notifications {

    /**
     * Статус
     */
    export enum status {
        '_0' = 0,
        '_1' = 1,
    }


}

