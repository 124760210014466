import { company, ingredient, product, recipe, vm_model } from '@src/api';
import { DateTime } from 'luxon';
import { EEntityList, EntityModel, IVendingMachineBase, PriceListModel, GoodsModel } from '.';

export enum EMatrixType {
  'Recipe',
  'Piece',
}

export class ProductMatrixItemModel extends EntityModel {
  id: string;
  name: string;
  company: company;
  recipe?: recipe;
  goods?: GoodsModel;
  slotNumber: number;
  product?: product;
  slotCount: number;
  type: number;
  constructor(value?: ProductMatrixItemModel) {
    super(value);
    this.id = value?.id;
    this.name = value?.id;
    this.company = value?.company;
    this.goods = value?.goods;
    this.slotNumber = value?.slotNumber;
    this.slotCount = value?.slotCount;
    this.type = value?.type;
    this.recipe = value?.recipe || null;
    this.product = value?.product || null;
  }
}

export class ProductMatrixIngredientModel extends EntityModel {
  id: string;
  ingredient: ingredient;
  maximumAmount: number;
  criticalRemainder: number;
  position: number;
  constructor(value?: ProductMatrixIngredientModel) {
    super(value);
    this.id = value?.id;
    this.ingredient = value?.ingredient || null;
    this.maximumAmount = value?.maximumAmount;
    this.criticalRemainder = value?.criticalRemainder;
    this.position = value?.position;
  }
}

export class ProductMatrixModel extends EntityModel {
  id: string;
  name?: string;
  entity: EEntityList;
  company?: EntityModel;
  matrixType?: EMatrixType;
  defaultPriceList: PriceListModel;
  productMatrixItems?: ProductMatrixItemModel[];
  productMatrixIngredients?: ProductMatrixIngredientModel[];
  productMatrixVendingMachines?: VMProductMatrixModel[];
  vmModel?: vm_model;
  constructor(value?: ProductMatrixModel) {
    super(value);
    this.id = value?.id;
    this.name = value?.name;
    this.company = value?.company || new EntityModel();
    this.matrixType = value?.matrixType;
    this.productMatrixItems = value?.productMatrixItems || [new ProductMatrixItemModel()];
    this.productMatrixIngredients = value?.productMatrixIngredients || [new ProductMatrixIngredientModel()];
    this.productMatrixVendingMachines = value?.productMatrixVendingMachines || [new VMProductMatrixModel()];
    this.defaultPriceList = value?.defaultPriceList || new PriceListModel();
    this.vmModel = value?.vmModel || null;
  }
}

export class VMProductMatrixModel extends EntityModel {
  entity: EEntityList;
  id: string;

  vendingMachine?: IVendingMachineBase;
  productMatrix?: ProductMatrixModel;
  isActive: boolean;
  startOfAction: Date;
  endOfAction?: DateTime;
  constructor(value?: VMProductMatrixModel) {
    super(value);
    this.id = value?.id;
    this.vendingMachine = value?.vendingMachine;
    this.productMatrix = value?.productMatrix;
    this.isActive = value?.isActive;
    this.startOfAction = value?.startOfAction;
    this.endOfAction = value?.endOfAction;
  }
}
