import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numericWithCurrency',
})
export class NumericWithCurrencyPipe implements PipeTransform {
  transform(value: number, ...args: string[]): string {
    if (!value) {
      value = 0;
    }

    const currencySymbol: string = args[0];
    return value?.toLocaleString('ru-RU') + (currencySymbol ? ' ' + currencySymbol : '');
  }
}
