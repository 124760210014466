/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { base_entity } from './base_entity';
import type { module_feature } from './module_feature';
import type { object_link } from './object_link';

/**
 * Базовые настройки клиентского модуля
 */
export type feature_settings = (base_entity & {
    /**
     * Идентификатор
     */
    id?: string;
    /**
     * Настройки клиентского модуля
     */
    feature: (module_feature | object_link);
    /**
     * Доступ
     */
    access?: feature_settings.access;
});

export namespace feature_settings {

    /**
     * Доступ
     */
    export enum access {
        '_1' = 1,
        '_2' = 2,
        '_4' = 4,
        '_8' = 8,
    }


}

