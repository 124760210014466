import { Injectable } from '@angular/core';
import { ICreateParameter, IParameter, IReportInfo, IStatusInfo } from '@shared/models';
import { HttpService } from '@shared/services/http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OtherReportRestService {
  constructor(private http: HttpService) {}

  public list(): Observable<IReportInfo[]> {
    return this.http.post<IReportInfo[]>(`report/get/list`);
  }

  public parameters(reportId: string): Observable<IParameter[]> {
    return this.http.post<IParameter[]>(`report/get/parameters/${reportId}`);
  }

  public create(reportId: string, data: ICreateParameter[]): Observable<IStatusInfo> {
    return this.http.post<IStatusInfo>(`report/create/${reportId}`, data);
  }

  public template(reportId: string): Observable<string> {
    return null;
  }

  public status(processId: string): Observable<IStatusInfo> {
    return this.http.post<IStatusInfo>(`report/get/status/${processId}`);
  }

  public active(): Observable<IStatusInfo[]> {
    return this.http.post<IStatusInfo[]>(`report/get/active`);
  }

  public cancel(processId: string) {
    return this.http.post<any>(`report/cancel/${processId}`);
  }

  public result(processId: string) {
    return this.http.postBlob<any>(`report/get/result/${processId}`);
  }

  public file(fileId: string) {
    return this.http.postBlob<any>(`report/get/file/${fileId}`);
  }

  // public fileJson(fileId: string): Observable<ReportJson> {
  //   return this.http.post<ReportJson>(`report/get/file/${fileId}`);
  // }
}
