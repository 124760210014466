import { Pipe, PipeTransform } from '@angular/core';
import { EUnit } from '@shared/models';

@Pipe({
  name: 'ingredientUnit',
})
export class IngredientUnitPipe implements PipeTransform {
  transform(value: number): string {
    let text;
    switch (value) {
      case EUnit.pc:
        text = $localize`шт.`;
        break;
      case EUnit.gr:
        text = $localize`гр.`;
        break;
      case EUnit.ml:
        text = $localize`мл.`;
        break;
      default:
        text = $localize`ед.`;
    }

    return text;
  }
}
