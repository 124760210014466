import { ChangeDetectorRef, Component, forwardRef, Inject } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { takeUntil } from 'rxjs/operators';

const yearPerView = 24;

@Component({
  selector: 'hoho-year-header-calendar',
  templateUrl: './year-header-calendar.component.html',
  styleUrls: ['./year-header-calendar.component.scss'],
})
export class YearHeaderCalendarComponent<D> {
  private _destroyed = new Subject<void>();

  constructor(
    private _calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
    @Inject(forwardRef(() => MatCalendar)) public calendar: MatCalendar<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef,
  ) {
    _calendar.stateChanges.pipe(takeUntil(this._destroyed)).subscribe(() => cdr.markForCheck());
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  get periodLabel() {
    const currentYear = this._dateAdapter.format(this._calendar.activeDate, 'YYYY').toLocaleUpperCase();
    return parseInt(currentYear, 10) - yearPerView + 1 + '-' + currentYear;
  }

  previousClicked(mode: 'month' | 'year') {
    this.calendar.activeDate =
      this.calendar.currentView == 'month'
        ? this._dateAdapter.addCalendarMonths(this.calendar.activeDate, -1)
        : this._dateAdapter.addCalendarYears(
            this.calendar.activeDate,
            this.calendar.currentView == 'year' ? -1 : -yearPerView,
          );
  }

  nextClicked(mode: 'month' | 'year') {
    this.calendar.activeDate =
      this.calendar.currentView == 'month'
        ? this._dateAdapter.addCalendarMonths(this.calendar.activeDate, 1)
        : this._dateAdapter.addCalendarYears(
            this.calendar.activeDate,
            this.calendar.currentView == 'year' ? 1 : yearPerView,
          );
  }
}
