import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { MessageDialogComponent } from '../components/message-dialog/message-dialog.component';
import { IDialogModels } from '../models/dialog.models';

@Injectable()
export class DialogService {
  constructor(private dialog: MatDialog) {}
  public openMessage(data?: IDialogModels): MatDialogRef<MessageDialogComponent> {
    return this.dialog.open(MessageDialogComponent, {
      width: '20rem',
      disableClose: true,
      data: data || {},
    });
  }

  public openConfirm(data?: IDialogModels): MatDialogRef<ConfirmDialogComponent> {
    return this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      width: '20rem',
      data: data || {},
    });
  }
}
