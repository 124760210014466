import { IEntity } from '..';
import { EEntityList, EntityModel } from '@shared/models';
import { IOtherReportsModel } from '../reports.models';
import { users } from '@src/api';

export interface ICreateParameter {
  name: string;
  value: any;
}

export interface IReportInfo {
  id: string;
  name: string;
  orderNum: string;
  menuType: number;
  reportType: number;
  cRoles: ICRole[];
}

export interface ICRole {
  id: string;
}

export interface IParameter {
  name: string;
  required: boolean;
  parameter_type: string;
  type: string;
  entity: EEntityList;
  multi_value: boolean;
  entityValues: IFileInfo[];
  enumerable: IFileInfo[];
}

export interface IStatusInfo {
  process_id: string;
  status: number;
  percent: number;
  name: string;
  files: IFileInfo[];
  createEnd: Date;
  descr: string;
}

export interface IFileInfo {
  id: string;
  name: string;
  displayName: string;
}

export class IReportElectedRequest implements IEntity {
  entity: EEntityList;
  id: string | number;
  displayName?: string;
  name?: string;
  reportElectedId: string;
  reportMenuItem: IOtherReportsModel;
  users: users;
}

export class IReportCreateHistoryRequest extends EntityModel {
  createStart: Date;
  createEnd: Date;
  status: number;
  errorText: string;
  name: string;
  files: IFileInfo[];
  process_id: string;
  fileExtension: string;
}
