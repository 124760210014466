import { Component, Input } from '@angular/core';
import { DotIndicatorStateEnum } from './enum/dot-indicator-state.enum';

@Component({
  selector: 'hoho-dot-indicator',
  templateUrl: './dot-indicator.component.html',
  styleUrls: ['./dot-indicator.component.scss'],
})
export class DotIndicatorComponent {
  @Input() state: DotIndicatorStateEnum;
  stateEnum = DotIndicatorStateEnum;

  constructor() {}
}
