import { EEntityList } from '@shared/models/entity.models';

export interface IListBuilderProps<T> {
  entity: EEntityList;
  views: EListBuilderViews[];
  openFunc?: (entity: T) => void;
  editFunc?: (entity: T) => void;
  editResolveFunc?: (entity: T) => boolean;
}

export const PAGE_SIZE_DEFAULT = 20;
export const PAGE_SIZE_GRID = 12;
export const PAGE_SIZE_MAX = -1;
export const PAGE_TOTAL_MAX = 65000;

export enum EListBuilderViews {
  map,
  grid,
  table,
  chart,
}
