<div
  class="flex items-center justify-center bg-arizona rounded h-20 cursor-pointer mb-3"
  (click)="fileInput.click()"
  hohoDragDirective
  (files)="filesDropped($event)"
>
  <div class="text-margaret text-body-1 flex items-center" *ngIf="!ctrl?.value?.name && type === 'image'">
    <hoho-icon class="mr-3" iconName="image-upload"></hoho-icon> <ng-container i18n>Загрузить изображение</ng-container>
  </div>
  <div class="text-margaret text-body-1 flex items-center" *ngIf="!ctrl?.value?.name && type === 'video'">
    <hoho-icon class="mr-3" iconName="paperclip"></hoho-icon> <ng-container i18n>Загрузить видео файл</ng-container>
  </div>

  <div class="text-margaret text-body-1 flex items-center" *ngIf="!ctrl?.value?.name && (type === 'file' || type === 'content')">
    <hoho-icon class="mr-3" iconName="paperclip"></hoho-icon> <ng-container i18n>Загрузить файл</ng-container>
  </div>
  <div class="flex items-center px-3 gap-3 w-full" *ngIf="ctrl?.value?.name">
    <div class="w-14 h-14 relative">
      <div id="preloader" *ngIf="this.uploadProgress">
        <div class="dots"><span></span><span></span><span></span></div>
      </div>
      <div class="w-14 h-14 overflow-hidden items-center justify-center flex bg-seattle">
        <ng-container *ngIf="type === 'content'">
          <ng-container *ngIf="this.isImage === true">
            <img 
              *ngIf="!this.uploadProgress"
              class="w-14 h-14 object-contain rounded"
              alt="the push image"
              [src]="ctrl.value.url"
            />
          </ng-container>
          <ng-container *ngIf="this.isImage === false">
            <hoho-icon *ngIf="!this.uploadProgress" iconName="video"></hoho-icon>
          </ng-container>
        </ng-container>

        <img
          *ngIf="!this.uploadProgress && type === 'image'"
          class="w-14 h-14 object-contain rounded"
          alt="the push image"
          [src]="ctrl.value.url"
        />

        <hoho-icon *ngIf="!this.uploadProgress && type === 'video'" iconName="video"></hoho-icon>
      </div>
    </div>

    <div class="flex-grow truncate">
      {{ ctrl.value.name }}
    </div>
    <hoho-icon
      (click)="$event.preventDefault(); $event.stopPropagation(); deleteFile()"
      class="text-denver"
      iconName="trash"
    ></hoho-icon>
  </div>
</div>
<div class="text-body-4 text-california">
  <div class="mb-1.5" *ngIf="type === 'video'">Формат файла: .webm</div>
  <div class="mb-1.5" *ngIf="type === 'image'">Формат файла: png, jpg</div>
  <div class="mb-1.5" *ngIf="type === 'image'">Соотношение сторон изображения: 1:1</div>
  <div class="mb-1.5" *ngIf="type === 'content'">Соотношение сторон изображения: 16:9</div>
  <div class="mb-1.5">Максимальный размер файла: {{ maxFileSize | bytes: 0:'B':'MB' }}</div>
</div>
<input type="file" class="h-1 opacity-0" #fileInput [accept]="requiredFileType" (change)="onFileSelected($event)" />
<mat-hint class="mat-error -mt-5 ml-3" *ngIf="ctrl.errors?.required && submitted"
  >Поле обязательно для заполнения</mat-hint
>
<div class="mat-error -mt-5 mb-4" *ngIf="ctrl.errors?.size">
  Размер файла не должен превышать {{ maxFileSize | bytes: 0:'B':'MB' }}
</div>