import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EEntityList, IPagingResponsePayload, IServerRequestPayload } from '@shared/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { getErrorMessage } from '@shared/utilities/forms.untils';
import { DataRestService } from '@shared/services';
import { company } from '@src/api';
import { DialogService } from '@app/shared/modules/dialogs/services/dialog.service';

@UntilDestroy()
@Component({
  selector: 'hoho-merge-companies',
  templateUrl: './merge-companies.component.html',
  styleUrls: ['./merge-companies.component.scss'],
})
export class MergeCompaniesComponent implements OnInit {
  companyList: company[] = [];
  entityType: EEntityList;
  title: string;
  isLoading: boolean = false;
  submitted: boolean = false;
  formGroup = this.fb.group({
    company: this.fb.group({
      id: [null, [Validators.required]],
      entity: [EEntityList.company],
    }),
  });
  getErrorMessage = getErrorMessage;
  constructor(
    @Inject(MAT_DIALOG_DATA) private entity: company,
    private dialogRef: MatDialogRef<MergeCompaniesComponent>,
    private fb: UntypedFormBuilder,
    private dialogService: DialogService,
    private dataRestService: DataRestService,
  ) {}

  ngOnInit() {
    this.loadCompany();
  }

  loadCompany(): void {
    this.isLoading = true;
    const payload: IServerRequestPayload = {
      paths: ['id', 'name'],
      filter: { 'isUniqum': true },
      sort: null,
    };
    this.dataRestService
      .page<company>(EEntityList.company, '', 0, -1, payload)
      .pipe(untilDestroyed(this))
      .subscribe((res: IPagingResponsePayload<company>) => {
        this.companyList = res.content;
        this.isLoading = false;
      });
  }

  afterSave(that: any): void {
    that.dialogRef.close(true);
  }

  submittedEvent(): void {
    this.submitted = true;
  }

  get companyControl(): UntypedFormControl {
    return this.formGroup.get('company.id') as UntypedFormControl;
  }

  merge(): void {
    if (this.formGroup.invalid)
      return;
    const okCallback = () => {
      this.isLoading = true;
      this.dataRestService
        .mergeCompany(this.entity.id, this.companyControl.value)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.isLoading = false;
          this.dialogService.openMessage({
            cancelCallback: null,
            okCallback: () => {
              this.afterSave(this)
            }, 
            content: $localize`Слияние выполнено`,
          });
        });
    };
    this.dialogService.openConfirm({
      cancelCallback: null,
      okCallback: okCallback,
      content: $localize`Вы действительно хотите выполнить слияние?`,
    });
  }
}
