import { Injectable } from '@angular/core';
import { CanActivateChild, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService } from '@shared/services/user.service';
import { AppRoutingNames } from '@app/app-routing.module';

@Injectable({
  providedIn: 'root',
})
export class AuthCanActivateGuard implements CanActivateChild {
  constructor(private userService: UserService, private router: Router) {}

  public canActivateChild(): Observable<boolean> {
    if (this.userService.permission) {
      return of(this.userService.permission);
    } else {
      this.router.navigate([AppRoutingNames.login.path]);
    }
  }
}
