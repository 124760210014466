import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'hoho-vending-machine-dialog-card',
  templateUrl: './vending-machine-dialog-card.component.html',
  styleUrls: ['./vending-machine-dialog-card.component.scss'],
})
export class VendingMachineDialogCardComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<VendingMachineDialogCardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }
}
