import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, switchMap, tap } from 'rxjs/operators';
import { IAddressSearchResult, LatLngAndPlaceName } from '@shared/modules/maps/maps.models';
import { MapRestService } from '@shared/services';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgSelectComponent } from '@ng-select/ng-select';

@UntilDestroy()
@Component({
  selector: 'hoho-address-search',
  templateUrl: './address-search.component.html',
  styleUrls: ['./address-search.component.scss'],
})
export class AddressSearchComponent implements OnInit {
  @ViewChild('mySelect') mySelect: NgSelectComponent;

  @Input() coordinatesControl: AbstractControl;
  @Input() addressControl: AbstractControl;
  @Input() submitted: boolean = false;
  @Input() appearance: 'legacy' | 'standard' | 'fill' | 'outline' = 'outline';

  addressesList$: Observable<any[]>;

  addressInput$ = new Subject<string>();
  addressLoading: boolean = false;
  addressGroup: UntypedFormGroup;

  constructor(private mapRestService: MapRestService) {}

  ngOnInit(): void {
    this.addressGroup = new UntypedFormGroup({
      address: this.addressControl,
    });
    this.loadAddress();

    this.mapRestService.geoCodeResponseSubject.subscribe((response: LatLngAndPlaceName) => {
      if (response == null) return;

      if (response.latLng) {
        var latLng = response.latLng?.split(' ');
        this.coordinatesControl.setValue(latLng);
      }
      if (response.placeName) {
        this.onNeedUpdate(response.placeName);
      }
    });
  }

  get canShowError(): boolean {
    return this.addressControl.dirty || this.submitted;
  }

  trackByFn(item: IAddressSearchResult) {
    return item.osm_id;
  }

  private loadAddress() {
    this.addressesList$ = concat(
      of([]), // default items
      this.addressInput$.pipe(
        debounceTime(500),
        untilDestroyed(this),
        tap((i) => {
          this.addressLoading = true;
        }),
        switchMap((term) => {
          return this.mapRestService.searchSuggest(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => (this.addressLoading = false)),
          );
        }),
      ),
    );
  }

  async onNeedUpdate(placeName: string) {
	this.mySelect.handleClearClick();
    this.mySelect.clearModel();
    this.addressControl.setValue(placeName);
  }

  async onChange() {
    var placeName = this.mySelect.selectedValues.toString();
    this.mapRestService
      .geoCode({ latLng: undefined }, { placeName: placeName }, { isNeedCenter: true })
      .pipe()
      .subscribe();
  }

  get validator() {
    return this.addressGroup.get('address').validator({} as AbstractControl);
  }
}
