<hoho-header [title]="title">
    <!-- <button
      [disabled]="!vmAccess?.create"
      class="sm:my-0 my-3"
      color="primary"
      mat-flat-button
      type="button"
      (click)="goToCreateVending()"
      i18n="@@add"
    >
      Добавить
    </button> -->

<div
  class="bg-margaret cursor-pointer rounded-md py-2 px-4 text-natasha flex items-center text-body-1 mt-5"
  [matMenuTriggerFor]="menu" style="height: 40px;"
>
  <ng-container>Добавить</ng-container> <hoho-icon class="sm:ml-4 ml-auto" iconName="ellipsis-v"></hoho-icon>
</div>

  <mat-menu #menu="matMenu" yPosition="below">
    <div class="navigation-menu-item rounded-md hover:bg-arizona mx-2" (click)="goToCreateVending()" mat-menu-item i18n>
      Кофемашину
    </div>
    <div class="navigation-menu-item rounded-md hover:bg-arizona mx-2" (click)="goToCreateSnack()"  mat-menu-item i18n>Холодильник</div>
  </mat-menu>
</hoho-header>
<hoho-group-nav></hoho-group-nav>

<hoho-feature-tabs
  [module]="EModules.VendingMachineModule"
  [activeFeature]="activeFeature"
  [tabsList]="tabsContentList"
  (changeTab)="changeTab($event)"
>
</hoho-feature-tabs>

<ng-template #information>
  <div class="px-gutter">
    <hoho-statistic class="mt-5 block"></hoho-statistic>
    <hoho-list-builder
      class="mt-5 block"
      context="info"
      i18n-searchPlaceholder=""
      searchPlaceholder="Поиск по всем параметрам"
      [multiselect]="false"
      [props]="listBuilderProps"
      [access]="vmAccess"
      [gridCardTemp]="gridCardViewInfo"
    ></hoho-list-builder>
  </div>
</ng-template>
<ng-template #gridCardViewInfo let-entity="entity">
  <hoho-vending-machine-card [model]="entity" [cardType]="eVendingMachineContextsList.info"></hoho-vending-machine-card>
</ng-template>

<ng-template #state>
  <div class="px-gutter" [id]="EVendingMachineFeatures.State">
    <hoho-state-statistic class="mt-5 block"></hoho-state-statistic>
    <hoho-list-builder
      class="mt-5 block"
      context="state"
      [multiselect]="false"
      i18n-searchPlaceholder=""
      searchPlaceholder="Поиск по всем параметрам"
      [props]="listBuilderProps"
      [access]="vmAccess"
      [gridCardTemp]="gridCardViewState"
    ></hoho-list-builder>
  </div>
</ng-template>
<ng-template #gridCardViewState let-entity="entity">
  <hoho-vending-machine-card
    [model]="entity"
    [cardType]="eVendingMachineContextsList.state"
  ></hoho-vending-machine-card>
</ng-template>
<ng-template #list>
  <div class="px-gutter" [id]="EVendingMachineFeatures.List">
    <hoho-list-builder
      class="mt-5 block"
      context="list"
      [multiselect]="false"
      [props]="listBuilderProps"
      [showExcel]="true"
      i18n-searchPlaceholder=""
      searchPlaceholder="Поиск по всем параметрам"
      excelFileNameBase="Список автоматов"
      i18n-excelFileNameBase
      [gridCardTemp]="gridCardViewList"
      [access]="vmAccess"
    ></hoho-list-builder>
  </div>
</ng-template>
<ng-template #gridCardViewList let-entity="entity">
  <hoho-vending-machine-card [model]="entity" [cardType]="eVendingMachineContextsList.list"></hoho-vending-machine-card>
</ng-template>
