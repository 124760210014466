/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export { ApiError } from './core/ApiError';
export { CancelablePromise, CancelError } from './core/CancelablePromise';
export { OpenAPI } from './core/OpenAPI';
export type { OpenAPIConfig } from './core/OpenAPI';

export { auto_report } from './models/auto_report';
export type { base_entity } from './models/base_entity';
export type { brand } from './models/brand';
export type { card_payment_type } from './models/card_payment_type';
export type { city } from './models/city';
export type { company } from './models/company';
export { coupon } from './models/coupon';
export { custom_support_appeal } from './models/custom_support_appeal';
export { custom_waypoint } from './models/custom_waypoint';
export type { erp_collection } from './models/erp_collection';
export type { erp_ingredient_fill } from './models/erp_ingredient_fill';
export type { erp_ingredient_fill_item } from './models/erp_ingredient_fill_item';
export { feature_settings } from './models/feature_settings';
export type { feedback } from './models/feedback';
export type { feedback_note } from './models/feedback_note';
export type { feedback_reason } from './models/feedback_reason';
export { ingredient } from './models/ingredient';
export type { location_format } from './models/location_format';
export type { machine_in_group } from './models/machine_in_group';
export type { mailing_error } from './models/mailing_error';
export type { mailing_error_category } from './models/mailing_error_category';
export type { mailing_error_mailing_error_category } from './models/mailing_error_mailing_error_category';
export type { mailing_error_telegram_bot_user } from './models/mailing_error_telegram_bot_user';
export type { mailing_error_vending_machine } from './models/mailing_error_vending_machine';
export { manual_writeoff_basis_video } from './models/manual_writeoff_basis_video';
export { manual_writeoff_orders } from './models/manual_writeoff_orders';
export type { marketing_customers_group } from './models/marketing_customers_group';
export type { marketing_push_notification_customer_group } from './models/marketing_push_notification_customer_group';
export type { marketing_push_notification_file } from './models/marketing_push_notification_file';
export type { marketing_push_notification_individual_customers } from './models/marketing_push_notification_individual_customers';
export { marketing_push_notifications } from './models/marketing_push_notifications';
export type { marketing_stories } from './models/marketing_stories';
export type { marketing_stories_file } from './models/marketing_stories_file';
export type { marketplace_point } from './models/marketplace_point';
export type { module } from './models/module';
export type { module_feature } from './models/module_feature';
export type { object_link } from './models/object_link';
export type { price_list } from './models/price_list';
export type { price_list_item } from './models/price_list_item';
export { product } from './models/product';
export { product_matrix } from './models/product_matrix';
export { product_matrix_coffee } from './models/product_matrix_coffee';
export { product_matrix_freezer } from './models/product_matrix_freezer';
export type { product_matrix_ingredient } from './models/product_matrix_ingredient';
export type { product_matrix_item } from './models/product_matrix_item';
export { product_matrix_item_coffee } from './models/product_matrix_item_coffee';
export type { product_matrix_item_freezer } from './models/product_matrix_item_freezer';
export { promocode } from './models/promocode';
export { promotion } from './models/promotion';
export type { recipe } from './models/recipe';
export type { recipe_item } from './models/recipe_item';
export { report_create_history } from './models/report_create_history';
export type { report_elected } from './models/report_elected';
export type { report_link } from './models/report_link';
export { report_menu_item } from './models/report_menu_item';
export { role } from './models/role';
export { role_modules } from './models/role_modules';
export { service_route } from './models/service_route';
export { service_route_execution } from './models/service_route_execution';
export { service_route_execution_waypoint } from './models/service_route_execution_waypoint';
export { service_route_performer } from './models/service_route_performer';
export type { service_route_responsible_person } from './models/service_route_responsible_person';
export type { service_route_waypoint } from './models/service_route_waypoint';
export type { sl_making_coffee_log } from './models/sl_making_coffee_log';
export { sl_order } from './models/sl_order';
export { sl_order_video } from './models/sl_order_video';
export { sl_user } from './models/sl_user';
export { sl_wallet } from './models/sl_wallet';
export type { sl_wallet_operation } from './models/sl_wallet_operation';
export type { source } from './models/source';
export { support_appeal } from './models/support_appeal';
export type { support_appeal_subject } from './models/support_appeal_subject';
export type { support_statistics_appeal } from './models/support_statistics_appeal';
export { telegram_bot_users } from './models/telegram_bot_users';
export { user_request } from './models/user_request';
export type { user_roles } from './models/user_roles';
export type { userRoles } from './models/userRoles';
export { users } from './models/users';
export { vending_machine } from './models/vending_machine';
export { vending_machine_coffee } from './models/vending_machine_coffee';
export { vending_machine_freezer } from './models/vending_machine_freezer';
export type { vending_machine_product_matrix } from './models/vending_machine_product_matrix';
export type { vm_cash_state } from './models/vm_cash_state';
export type { vm_city } from './models/vm_city';
export type { vm_event } from './models/vm_event';
export type { vm_group } from './models/vm_group';
export type { vm_ingredient_item_state } from './models/vm_ingredient_item_state';
export type { vm_ingredient_state } from './models/vm_ingredient_state';
export type { vm_location_formats } from './models/vm_location_formats';
export type { vm_log } from './models/vm_log';
export type { vm_manufacturer } from './models/vm_manufacturer';
export type { vm_model } from './models/vm_model';
export { vm_vend } from './models/vm_vend';
export { vm_vend_group } from './models/vm_vend_group';

export { AutoReportService } from './services/AutoReportService';
export { BrandService } from './services/BrandService';
export { CardPaymentTypeService } from './services/CardPaymentTypeService';
export { CityService } from './services/CityService';
export { CompanyService } from './services/CompanyService';
export { CouponService } from './services/CouponService';
export { CustomSupportAppealService } from './services/CustomSupportAppealService';
export { CustomWaypointService } from './services/CustomWaypointService';
export { ErpCollectionService } from './services/ErpCollectionService';
export { ErpIngredientFillService } from './services/ErpIngredientFillService';
export { ErpIngredientFillItemService } from './services/ErpIngredientFillItemService';
export { FeatureSettingsService } from './services/FeatureSettingsService';
export { FeedbackService } from './services/FeedbackService';
export { FeedbackNoteService } from './services/FeedbackNoteService';
export { FeedbackReasonService } from './services/FeedbackReasonService';
export { IngredientService } from './services/IngredientService';
export { LocationFormatService } from './services/LocationFormatService';
export { MachineInGroupService } from './services/MachineInGroupService';
export { MailingErrorService } from './services/MailingErrorService';
export { MailingErrorCategoryService } from './services/MailingErrorCategoryService';
export { MailingErrorMailingErrorCategoryService } from './services/MailingErrorMailingErrorCategoryService';
export { MailingErrorTelegramBotUserService } from './services/MailingErrorTelegramBotUserService';
export { MailingErrorVendingMachineService } from './services/MailingErrorVendingMachineService';
export { ManualWriteoffBasisVideoService } from './services/ManualWriteoffBasisVideoService';
export { ManualWriteoffOrdersService } from './services/ManualWriteoffOrdersService';
export { MarketingCustomersGroupService } from './services/MarketingCustomersGroupService';
export { MarketingPushNotificationCustomerGroupService } from './services/MarketingPushNotificationCustomerGroupService';
export { MarketingPushNotificationFileService } from './services/MarketingPushNotificationFileService';
export { MarketingPushNotificationIndividualCustomersService } from './services/MarketingPushNotificationIndividualCustomersService';
export { MarketingPushNotificationsService } from './services/MarketingPushNotificationsService';
export { MarketingStoriesService } from './services/MarketingStoriesService';
export { MarketingStoriesFileService } from './services/MarketingStoriesFileService';
export { MarketplacePointService } from './services/MarketplacePointService';
export { ModuleService } from './services/ModuleService';
export { ModuleFeatureService } from './services/ModuleFeatureService';
export { PriceListService } from './services/PriceListService';
export { PriceListItemService } from './services/PriceListItemService';
export { ProductService } from './services/ProductService';
export { ProductMatrixService } from './services/ProductMatrixService';
export { ProductMatrixCoffeeService } from './services/ProductMatrixCoffeeService';
export { ProductMatrixFreezerService } from './services/ProductMatrixFreezerService';
export { ProductMatrixIngredientService } from './services/ProductMatrixIngredientService';
export { ProductMatrixItemService } from './services/ProductMatrixItemService';
export { ProductMatrixItemCoffeeService } from './services/ProductMatrixItemCoffeeService';
export { ProductMatrixItemFreezerService } from './services/ProductMatrixItemFreezerService';
export { PromocodeService } from './services/PromocodeService';
export { PromotionService } from './services/PromotionService';
export { RecipeService } from './services/RecipeService';
export { RecipeItemService } from './services/RecipeItemService';
export { ReportCreateHistoryService } from './services/ReportCreateHistoryService';
export { ReportElectedService } from './services/ReportElectedService';
export { ReportLinkService } from './services/ReportLinkService';
export { ReportMenuItemService } from './services/ReportMenuItemService';
export { RoleService } from './services/RoleService';
export { RoleModulesService } from './services/RoleModulesService';
export { ServiceRouteService } from './services/ServiceRouteService';
export { ServiceRouteExecutionService } from './services/ServiceRouteExecutionService';
export { ServiceRouteExecutionWaypointService } from './services/ServiceRouteExecutionWaypointService';
export { ServiceRoutePerformerService } from './services/ServiceRoutePerformerService';
export { ServiceRouteResponsiblePersonService } from './services/ServiceRouteResponsiblePersonService';
export { ServiceRouteWaypointService } from './services/ServiceRouteWaypointService';
export { SlMakingCoffeeLogService } from './services/SlMakingCoffeeLogService';
export { SlOrderService } from './services/SlOrderService';
export { SlOrderVideoService } from './services/SlOrderVideoService';
export { SlUserService } from './services/SlUserService';
export { SlWalletService } from './services/SlWalletService';
export { SlWalletOperationService } from './services/SlWalletOperationService';
export { SourceService } from './services/SourceService';
export { SupportAppealService } from './services/SupportAppealService';
export { SupportAppealSubjectService } from './services/SupportAppealSubjectService';
export { SupportStatisticsAppealService } from './services/SupportStatisticsAppealService';
export { TelegramBotUsersService } from './services/TelegramBotUsersService';
export { UserRequestService } from './services/UserRequestService';
export { UserRolesService } from './services/UserRolesService';
export { UsersService } from './services/UsersService';
export { VendingMachineService } from './services/VendingMachineService';
export { VendingMachineCoffeeService } from './services/VendingMachineCoffeeService';
export { VendingMachineFreezerService } from './services/VendingMachineFreezerService';
export { VendingMachineProductMatrixService } from './services/VendingMachineProductMatrixService';
export { VmCashStateService } from './services/VmCashStateService';
export { VmCityService } from './services/VmCityService';
export { VmEventService } from './services/VmEventService';
export { VmGroupService } from './services/VmGroupService';
export { VmIngredientItemStateService } from './services/VmIngredientItemStateService';
export { VmIngredientStateService } from './services/VmIngredientStateService';
export { VmLocationFormatsService } from './services/VmLocationFormatsService';
export { VmLogService } from './services/VmLogService';
export { VmManufacturerService } from './services/VmManufacturerService';
export { VmModelService } from './services/VmModelService';
export { VmVendService } from './services/VmVendService';
export { VmVendGroupService } from './services/VmVendGroupService';
