import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { DaysList, EPeriodicity } from '@shared/modules/time-period/components/select-period/select-period.models';
import { deepClone } from '@shared/utilities';
import * as moment from 'moment';

@Component({
  selector: 'hoho-select-period',
  templateUrl: './select-period.component.html',
  styleUrls: ['./select-period.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectPeriodComponent implements OnInit {
  daysList: { name: string; id: number; active: boolean }[] = deepClone(DaysList).reverse();
  everyDay: boolean = false;
  minutes: string = '00';
  hours: string = '00';
  submitted: boolean = false;
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA)
    private data: {
      periodicity: EPeriodicity;
      days: number; // битовая маска;
      sendTime: string; // 23:59
    },
    private bottomSheetRef: MatBottomSheetRef<SelectPeriodComponent>,
  ) {}

  ngOnInit(): void {
    this.everyDay = this.data.periodicity !== EPeriodicity.weekly;
    const HHmm: string[] = this.data.sendTime?.split(':');
    if (HHmm) {
      this.hours = HHmm[0];
      this.minutes = HHmm[1];
    }
    this.daysList = this.daysList.map((i) => {
      // битовая маска
      i.active = !!(i.id & this.data.days);
      return i;
    });
  }

  apply(): void {
    this.submitted = true;
    if (!this.everyDay && !this.daysList.find((i) => i.active)) {
      return;
    }
    this.bottomSheetRef.dismiss({
      periodicity: this.everyDay ? EPeriodicity.everyDay : EPeriodicity.weekly,
      // битовая маска
      daysBites: this.daysList.reduce((acc, i) => {
        return i.active ? i.id | acc : acc;
      }, 0),
      sendTime: moment().set({ hours: parseInt(this.hours), minutes: parseInt(this.minutes) }),
    });
  }

  get daysError(): boolean {
    return !this.everyDay && !this.daysList.find((i) => i.active) && this.submitted;
  }
}
