/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { base_entity } from './base_entity';
import type { city } from './city';
import type { object_link } from './object_link';
import type { support_appeal_subject } from './support_appeal_subject';
import type { users } from './users';
import type { vending_machine } from './vending_machine';

/**
 * Ручная заявка в тех.поддержку
 */
export type custom_support_appeal = (base_entity & {
    id?: string;
    fio?: string;
    phoneNumber?: string;
    contactPhoneNumber?: string;
    phoneNumberForRefund?: string;
    status?: custom_support_appeal.status;
    createDate?: string;
    readonly number?: string;
    users: (users | object_link);
    subject?: (support_appeal_subject | object_link);
    city: (city | object_link);
    resolution?: number;
    refundAmount?: number;
    description?: string;
    vendingMachine: (vending_machine | object_link);
    readonly search?: string;
    readonly mainPhoneNumber?: string;
    readonly resolutionName?: string;
});

export namespace custom_support_appeal {

    export enum status {
        '_0' = 0,
        '_1' = 1,
    }


}

