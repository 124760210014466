/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { card_payment_type } from '../models/card_payment_type';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CardPaymentTypeService {

    /**
     * Select card_payment_type by id
     * @param id Object ID
     * @returns card_payment_type Successful operation
     * @throws ApiError
     */
    public static selectCardPaymentTypeById(
        id: string,
    ): CancelablePromise<card_payment_type> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/get/object/card_payment_type/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Save card_payment_type
     * @param requestBody Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static saveCardPaymentType(
        requestBody?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/save/object/card_payment_type',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Delete card_payment_type by id
     * @param id Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static deleteCardPaymentTypeById(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/delete/object/card_payment_type/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

}
