export const paths = [
  'id',
  'email',
  'createDate',
  'phone',
  'isLiked',
  'text',
  'fio',
  'productName',
  'datePurchase',
  'vendingMachine.id',
  'vendingMachine.name',
  'vendingMachine.nameAndSerial',
  'feedbackReason.id',
  'feedbackReason.name',
  'search',
  'address',
  'slUserId',
  'feedbackNote.id',
];