export const paths = [
  'entity',
  'id',
  'displayName',
  'name',
  'address',
  'phone',
  'comment',
  'vendingMachineCount',
  'orgCode',
  'isUniqum',
  'responsiblePerson',
  'lkEnabled',
];
