/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { machine_in_group } from '../models/machine_in_group';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MachineInGroupService {

    /**
     * Select machine_in_group by id
     * @param id Object ID
     * @returns machine_in_group Successful operation
     * @throws ApiError
     */
    public static selectMachineInGroupById(
        id: string,
    ): CancelablePromise<machine_in_group> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/get/object/machine_in_group/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Save machine_in_group
     * @param requestBody Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static saveMachineInGroup(
        requestBody?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/save/object/machine_in_group',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Delete machine_in_group by id
     * @param id Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static deleteMachineInGroupById(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/delete/object/machine_in_group/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

}
