<div class="w-full py-4">
  <div class="w-full bg-arizona rounded-xl p-1 flex mb-5 h-12">
    <div
      class="flex-grow cursor-pointer p-2 rounded-md text-center whitespace-nowrap"
      [class.bg-natasha]="everyDay"
      [class.text-california]="everyDay"
      (click)="everyDay = true"
      i18n
    >
      Каждый день
    </div>
    <div
      class="flex-grow cursor-pointer p-2 rounded-md text-center whitespace-nowrap"
      [class.bg-natasha]="!everyDay"
      [class.text-california]="!everyDay"
      (click)="everyDay = false"
      i18n
    >
      День недели
    </div>
  </div>

  <div class="flex justify-between h-9">
    <ng-container *ngIf="!everyDay">
      <div
        class="rounded-md text-body-2 w-9 h-9 cursor-pointer flex items-center justify-center hover:text-betty hover:bg-arizona"
        [class.bg-margaret]="day.active"
        [class.text-natasha]="day.active"
        (click)="day.active = !day.active"
        *ngFor="let day of daysList"
      >
        {{ day.name }}
      </div>
    </ng-container>
  </div>
  <mat-error *ngIf="daysError" class="absolute" i18n>Выберите хотя бы один день недели</mat-error>
  <hoho-minutes-hour
    [minutes]="minutes"
    (minutesChange)="minutes = $event"
    [hours]="hours"
    (hoursChange)="hours = $event"
  ></hoho-minutes-hour>
  <button class="w-full" color="accent" mat-flat-button type="button" (click)="apply()" i18n>Применить</button>
</div>
