import { Component, HostListener, OnInit } from '@angular/core';
import { ICrumb } from '@app/shared/@vex/components/breadcrumbs/breadcrumbs.component';
import { DataRestService, UserService } from '@app/shared/services';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from '@app/shared/modules/dialogs/services/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { EEntityList } from '@app/shared/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DirectoryRoutingNames } from '@app/pages/directory/directory.routing';
import { CreateUpdateUsersComponent } from '../create-update-users/create-update-users.component';
import { IAccess } from '@app/core/modules/list-builder/models';
import { company, users } from '@src/api';
import { paths } from './page.config';
import { toType } from '@app/shared/utilities';

@UntilDestroy()
@Component({
  selector: 'hoho-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent implements OnInit {
  @HostListener('window:beforeunload', ['$event'])
  uID: string;
  isLoading: boolean = false;
  crumbs: ICrumb[] = [
    { label: $localize`Пользователи`, link: DirectoryRoutingNames.users.path },
    { label: $localize`Страница пользователя` },
  ];
  user: users;
  fio: string = '';
  access: IAccess;
  $company = toType<company>();

  constructor(
    private dataRestService: DataRestService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private dialog: MatDialog,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.access = this.userService.Access(EEntityList.users);
    this.uID = this.activatedRoute.snapshot.params['id'];
    this.getUser();
  }

  changeTab(event: number): void {
    this.router.navigate(['.'], { relativeTo: this.activatedRoute, queryParams: { tabIndex: event } });
  }

  getUser(): void {
    if (this.uID) {
      this.dataRestService
        .get<users>(EEntityList.users, this.uID, paths)
        .pipe(untilDestroyed(this))
        .subscribe((res: users) => {
          this.user = res;
          if (this.user.lastName) this.fio = this.user.lastName;
          if (this.user.firstName) this.fio += ' ' + this.user.firstName;
          if (this.user.middleName) this.fio += ' ' + this.user.middleName;
          if (this.user.company) this.user.company.entity = EEntityList.company;
        });
    }
  }

  edit(): void {
    const dialogRef = this.dialog.open(CreateUpdateUsersComponent, {
      data: this.user,
      width: '20rem',
    });
    dialogRef.afterClosed().subscribe((isSuccess: boolean) => {
      if (isSuccess) {
        this.getUser();
      }
    });
  }

  block(): void {
    const okCallback = () => {
      setTimeout(() => {
        this.user.flags = 0;
        this.user.active = false;
        this.save();
      }, 1000);
    };
    this.dialogService.openConfirm({
      cancelCallback: null,
      okCallback: okCallback,
      content: $localize`Вы действительно хотите заблокировать пользователя?`,
    });
  }

  unblock(): void {
    const okCallback = () => {
      setTimeout(() => {
        this.user.flags = 1;
        this.user.active = true;
        this.save();
      }, 1000);
    };
    this.dialogService.openConfirm({
      cancelCallback: null,
      okCallback: okCallback,
      content: $localize`Вы действительно хотите разблокировать пользователя?`,
    });
  }

  save(): void {
    this.dataRestService
      .save<users>(this.user, EEntityList.users)
      .pipe(untilDestroyed(this))
      .subscribe(() => {});
  }
}
