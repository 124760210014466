<hoho-secondary-header [crumbs]="crumbs" *ngIf="!isLoading" [title]="title" i18n-title>
</hoho-secondary-header>
<form [formGroup]="formGroup" class="mx-gutter mt-5">
  <div class="grid gap-x-4 md:grid-cols-2 grid-cols-1 lg:grid-cols-3">
    <mat-form-field appearance="outline">
      <mat-label i18n>Название группы</mat-label>
      <input required name="name" matInput formControlName="name" />
      <mat-error name="nameError" *ngIf="formGroup.get('name').invalid">{{ getErrorMessage('name', formGroup) }}</mat-error>
    </mat-form-field>

    <div class="rounded-md bg-shpongle text-california col-span-2 flex items-center px-3 mb-6">
      <hoho-icon class="mr-2.5 text-suzanne" iconName="exclamation-circle" size="1rem"></hoho-icon>
      В созданную группу попадут отфильтрованные вами клиенты.
    </div>
  </div>
  <div class="rounded-md flex p-2.5 bg-arizona text-subtitle-3 justify-between mt-5 mb-5">
    <div class="flex-grow" i18n>Фильтры</div>
    <div class="text-margaret cursor-pointer text-body-2" *ngIf="showRest" (click)="restFilter()" i18n>Сбросить</div>
  </div>
  <div class="grid gap-x-4 md:grid-cols-2 grid-cols-1 lg:grid-cols-3">
    <div>
      <div class="text-body-2 mb-4">Личные данные</div>
      <hoho-select
        [submitted]="submitted"
        [items]="genderTypeList"
        [clearable]="true"
        name="genderType"
        bindLabel="name"
        [control]="genderTypeControl"
        placeholder="Пол"
        i18n-placeholder
      >
        <mat-error *ngIf="genderTypeControl.invalid">{{ getErrorMessage('genderType', formGroup) }}</mat-error>
      </hoho-select>
      <hoho-multi-datepicker
        [ctrl]="birthdayControl"
        placeholder="Дата рождения"
        i18n-placeholder
      ></hoho-multi-datepicker>
      <hoho-select
        [submitted]="submitted"
        [items]="cities"
        bindValue="id"
        [clearable]="true"
        [control]="cityControl"
        name="city"
        [searchable]="true"
        placeholder="Город"
        i18n-placeholder
      >
        <mat-error *ngIf="cityControl.invalid">{{ getErrorMessage('city', formGroup) }}</mat-error>
      </hoho-select>

      <hoho-equals-select
        placeholder="Дней с момента регистрации"
        i18n-placeholder
        inputPlaceholder="дней"
        i18n-inputPlaceholder
        [ctrl]="daysAfterRegistrationCtrl"
      ></hoho-equals-select>
    </div>
    <div>
      <div class="text-body-2 mb-4">Покупки клиента</div>
      <mat-form-field class="w-full" appearance="outline">
        <mat-label i18n>Покупок за последний месяц</mat-label>
        <input hohoDigitMask name="ordersInLastMonth" matInput formControlName="ordersInLastMonth" />
        <mat-error name="ordersInLastMonthError" *ngIf="formGroup.get('ordersInLastMonth').invalid">{{
          getErrorMessage('ordersInLastMonth', formGroup)
        }}</mat-error>
      </mat-form-field>

      <mat-form-field class="w-full" appearance="outline">
        <mat-label i18n>Покупок всего</mat-label>
        <input hohoDigitMask name="orderCount" matInput formControlName="orderCount" />
        <mat-error name="orderCountError" *ngIf="formGroup.get('orderCount').invalid">{{
          getErrorMessage('orderCount', formGroup)
        }}</mat-error>
      </mat-form-field>

      <hoho-modal-filter
        i18n-placeholder
        class="mb-5"
        listFilterPath="vendingMachine"
        [paths]="vmsFilterPaths"
        [multiply]="true"
        [submitted]="submitted"
        *ngIf="!isLoading"
        placeholder="Покупка в определенных автоматах"
        [ctrl]="vendingMachineIdControl"
        [entity]="EEntityList.vending_machine"
        [rowTemplate]="row"
      ></hoho-modal-filter>

      <ng-template #row let-item="item">
        <div class="flex-col">
          <div class="text-body-2 select-none">{{ item.name || 'Наименование автомата' }}</div>
          <div class="text-body-5 select-none">{{ item.nameAndSerial }}</div>
          <div class="text-body-5 text-california select-none">{{ item.address }}</div>
        </div>
      </ng-template>

      <mat-form-field class="w-full" appearance="outline">
        <mat-label i18n>Всего потрачено на кофе</mat-label>
        <input hohoDigitMask name="orderSum" matInput formControlName="orderSum" />
        <mat-error name="orderSumError" *ngIf="formGroup.get('orderSum').invalid">{{
          getErrorMessage('orderSum', formGroup)
        }}</mat-error>
      </mat-form-field>
    </div>
    <div>
      <div class="text-body-2 mb-4">Баллы клиента</div>
      <hoho-equals-select
        placeholder="Баллов на счете"
        i18n-placeholder
        [clearable]="true"
        inputPlaceholder="баллы"
        i18n-inputPlaceholder
        [ctrl]="walletBalanceCtrl"
      ></hoho-equals-select>
    </div>
  </div>
</form>
<div class="text-subtitle-3 mx-gutter">Список клиентов</div>
<hoho-list-builder
  #tableBuilder
  context="marketingGroup"
  [multiselect]="false"
  [showExcel]="true"
  [requestPayload]="requestPayload"
  class="mt-5 block px-gutter"
  [props]="groupBuilderProps"
  [hideToolbar]="true"
  [noLink]="true"
  [access]="userRequestAccess"
></hoho-list-builder>

<div class="flex justify-end px-gutter">
  <button
    (click)="submit()"
    color="primary"
    class="ml-2"
    [disabled]="formGroup.invalid || (tableBuilder.isLoading$ | async)"
    mat-flat-button
  >
    <ng-container *ngIf="!formGroup.value.id" i18n>Создать группу клиентов</ng-container>
    <ng-container *ngIf="formGroup.value.id" i18n>Обновить группу клиентов</ng-container>
  </button>
</div>
