import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SelectPeriodComponent } from '@shared/modules/time-period/components/select-period/select-period.component';
import { DaysList, EPeriodicity } from '@shared/modules/time-period/components/select-period/select-period.models';
import * as moment from 'moment';
import { Moment } from 'moment';

@UntilDestroy()
@Component({
  selector: 'hoho-time-period',
  templateUrl: './time-period.component.html',
  styleUrls: ['./time-period.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimePeriodComponent implements OnInit {
  constructor(private bottomSheet: MatBottomSheet) {}
  @Input() periodicity: UntypedFormControl;
  @Input() days: UntypedFormControl;
  @Input() sendTime: UntypedFormControl;

  @Input() placeholder: string;
  ngModel: string;
  ngOnInit(): void {
    this.setNgModel();
  }

  openBottomSheet(): void {
    const ref = this.bottomSheet.open(SelectPeriodComponent, {
      panelClass: 'mat-bottom-sheet-container-medium',
      data: {
        periodicity: this.periodicity.value || null,
        days: this.days.value || null,
        sendTime: this.sendTime.value ? moment(this.sendTime.value).format('HH:mm') : null,
      },
    });

    ref
      .afterDismissed()
      .pipe(untilDestroyed(this))

      .subscribe((res: { periodicity: EPeriodicity; daysBites: number; sendTime: Moment }) => {
        if (!res) {
          return;
        }
        this.periodicity.setValue(res.periodicity);
        this.days.setValue(res.periodicity === EPeriodicity.weekly ? res.daysBites : 0);
        this.sendTime.setValue(res.sendTime);
        this.setNgModel();
      });
  }
  setNgModel() {
    if (!this.sendTime.value) {
      return;
    }
    this.ngModel =
      (this.periodicity.value === EPeriodicity.weekly ? this.parseMask() : $localize`Каждый день,`) +
      ' ' +
      moment(this.sendTime.value).format('HH:mm');
  }

  parseMask() {
    return DaysList.reduce((acc, i) => {
      return !!(i.id & this.days.value) ? i.name + ', ' + acc : acc;
    }, '');
  }
}
