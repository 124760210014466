import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  EEntityList,
  EntityModel,
  IDeleteEntityItem,
  IPagingResponsePayload,
  IServerRequestPayload,
} from '@app/shared/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DataRestService } from '@shared/services';
import { ControlsOf, deepClone, getErrorMessage, RegexPatternConstants } from '@shared/utilities';
import { auto_report } from '@src/api';

@UntilDestroy()
@Component({
  selector: 'hoho-create-update-form',
  templateUrl: './create-update-form.component.html',
  styleUrls: ['./create-update-form.component.scss'],
})
export class CreateUpdateFormComponent implements OnInit {
  formGroup: UntypedFormGroup;
  EEntityList = EEntityList;
  deleteEntityList: IDeleteEntityItem[];
  submitted: boolean = false;
  title: string;
  reportLinksList: EntityModel[] = [];
  selectRecipientsView = false;
  selectedItems: EntityModel[] = [];
  recipients: string[] = [];
  emailCtrl: UntypedFormControl;
  getErrorMessage = getErrorMessage;
  constructor(
    @Inject(MAT_DIALOG_DATA) public entity: auto_report,
    private dialogRef: MatDialogRef<CreateUpdateFormComponent>,
    private fb: FormBuilder,
    private dataRestService: DataRestService,
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.loadReportLinks();
    this.title = this.entity ? `Редактировать авто отчет` : `Создать авто отчет`;
    if (this.entity) {
      this.formGroup.patchValue(this.entity);
    }
    this.emailCtrl = this.fb.control(null, [Validators.pattern(RegexPatternConstants.email)]);
  }
  initForm(): void {
    this.formGroup = this.fb.group<ControlsOf<auto_report>>({
      id: new FormControl(),
      entity: new FormControl(EEntityList.auto_report),
      report: new FormControl(null, [Validators.required]),
      periodicity: new FormControl(null, [Validators.required]),
      days: new FormControl(0),
      sendTime: new FormControl(null, Validators.required),
      recipients: new FormControl([], [Validators.required]),
    });
    if (this.entity) {
      this.formGroup.patchValue(this.entity);
    }
  }

  afterSave(): void {
    this.dialogRef.close(true);
  }
  submit() {
    this.submitted = true;
  }

  loadReportLinks(): void {
    const payload: IServerRequestPayload = {
      paths: ['id', 'entity', 'displayName', 'name'],
      sort: [],
    };
    this.dataRestService
      .page<EntityModel>(EEntityList.report_link, '', 0, -1, payload)
      .pipe(untilDestroyed(this))
      .subscribe((res: IPagingResponsePayload<EntityModel>) => {
        this.reportLinksList = res.content;
      });
  }

  get reportCtrl(): UntypedFormControl {
    return this.formGroup.get('report') as UntypedFormControl;
  }

  addRecipientItem() {
    if (this.emailCtrl.invalid) {
      return;
    }
    this.recipients.push(this.emailCtrl.value);
    this.emailCtrl.reset();
  }
  removeRecipientItem(index: number) {
    this.recipients.splice(index, 1);
  }

  get periodicityCtrl(): UntypedFormControl {
    return this.formGroup.get('periodicity') as UntypedFormControl;
  }

  get daysCtrl(): UntypedFormControl {
    return this.formGroup.get('days') as UntypedFormControl;
  }
  get sendTimeCtrl(): UntypedFormControl {
    return this.formGroup.get('sendTime') as UntypedFormControl;
  }
  applyRecipients(): void {
    this.formGroup.get('recipients').setValue(this.recipients);
  }

  selectRecipients() {
    this.selectRecipientsView = true;
    this.recipients = deepClone(this.formGroup.get('recipients').value);
  }
}
