import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PSAgreementModel, PSWalletModel, PSWalletOperation, PaymentCard, VmTarif } from '@app/shared/models/personal-area.models';
import { ICurrentUser } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class PersonalAreaService {
	readonly INVOICE_PAYMENT_TYPE = '1';
  readonly ADD_CARD_PAYMENT_TYPE = '2';
  readonly INVOICE_AUTOPAYMENT_TYPE = 1;
  readonly CARD_AUTOPAYMENT_TYPE = 2;
	// 
  private userSubject = new BehaviorSubject<ICurrentUser>(null);
  private agreementSubject = new BehaviorSubject<PSAgreementModel>(null);
  private walletSubject = new BehaviorSubject<PSWalletModel>(null);
  private walletOperationSubject = new BehaviorSubject<PSWalletOperation[]>(null);
  private paymentCardsSubject = new BehaviorSubject<PaymentCard[]>(null);
  private tarifsSubject = new BehaviorSubject<VmTarif[]>(null);

  constructor() {}

  setUser(user: ICurrentUser): void {
    this.userSubject.next(user);
  }
  getUser(): Observable<ICurrentUser> {
    return this.userSubject.asObservable();
  }
  //
  setAgreement(agreement: PSAgreementModel): void {
    this.agreementSubject.next(agreement);
  }
  getAgreement(): Observable<PSAgreementModel> {
    return this.agreementSubject.asObservable();
  }
  //
  setWallet(wallet: PSWalletModel): void {
    this.walletSubject.next(wallet);
  }
  getWallet(): Observable<PSWalletModel> {
    return this.walletSubject.asObservable();
  }
	//
	setWalletOperations(walletOperations: PSWalletOperation[]): void {
    this.walletOperationSubject.next(walletOperations);
  }
  getWalletOperations(): Observable<PSWalletOperation[]> {
    return this.walletOperationSubject.asObservable();
  }
  //
  setPaymentCards(paymentCards: PaymentCard[]): void {
    this.paymentCardsSubject.next(paymentCards);
  }
  getPaymentCards(): Observable<PaymentCard[]> {
    return this.paymentCardsSubject.asObservable();
  }
	// 
	setTarifs(tarifs: VmTarif[]): void {
    this.tarifsSubject.next(tarifs);
  }
  getTarifs(): Observable<VmTarif[]> {
    return this.tarifsSubject.asObservable();
  }
}
