import Dexie from 'dexie';
import { EWebsocketsEvents } from './websocket.events';

class MessagesDatabase extends Dexie {
  constructor() {
    super('MessagesDatabase');

    this.version(1).stores({
      messages: '++id,text',
    });
  }
}

export interface IWsMessage {
  data: string;
  event: number;
}
export const modelParser = (event: EWebsocketsEvents, messages: IWsMessage[]): Promise<IWsMessage[]> => {
  return new Promise((r) =>
    r(
      messages.map((i: IWsMessage) => {
        return i;
      }),
    ),
  );
  // if (event === WS_API.EVENTS.command_msg_in) {
  //   return new Promise((r) =>
  //     r(
  //       messages.map((i: IWsMessage) => {
  //         return { text: i.message, id: i.id };
  //       }),
  //     ),
  //   );
  // }
};

export enum EWebsocketConnectStatus {
  Open,
  Error,
  Close,
}
