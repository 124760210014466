/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { source } from '../models/source';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SourceService {

    /**
     * Select source by id
     * @param id Object ID
     * @returns source Successful operation
     * @throws ApiError
     */
    public static selectSourceById(
        id: string,
    ): CancelablePromise<source> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/get/object/source/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Save source
     * @param requestBody Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static saveSource(
        requestBody?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/save/object/source',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Delete source by id
     * @param id Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static deleteSourceById(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/delete/object/source/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

}
