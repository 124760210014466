import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import multiply from '@iconify/icons-uil/multiply';

@Component({
  selector: 'hoho-dialog-container',
  templateUrl: './dialog-container.component.html',
  styleUrls: ['./dialog-container.component.scss'],
})
export class DialogContainerComponent<T> implements OnInit {
  multiply = multiply;
  @Input() dialogRef: MatDialogRef<T>;
  constructor() {}

  ngOnInit(): void {}
}
