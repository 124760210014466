import { Component, Input, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import icHome from '@iconify/icons-ic/twotone-home';
import { trackByLabel } from '../../utils/track-by';

export interface ICrumb {
  label: string;
  link?: string;
  queryParams?: Params;
}
@Component({
  selector: 'vex-breadcrumbs',
  template: `
    <div class="flex items-center">
      <ng-container *ngFor="let crumb of crumbs; trackBy: trackByLabel; let i = index">
        <hoho-icon class="mr-2 -mb-0.5 text-primary" *ngIf="i !== 0" iconName="angle-right-b"></hoho-icon>
        <vex-breadcrumb>
          <a class="text-primary" *ngIf="crumb.link" [routerLink]="[crumb.link]" [queryParams]="crumb.queryParams">{{ crumb.label }}</a>
          <div *ngIf="!crumb.link" class="text-texas">{{ crumb.label }}</div>
        </vex-breadcrumb>
      </ng-container>
    </div>
  `,
})
export class BreadcrumbsComponent implements OnInit {
  @Input() crumbs: ICrumb[] = [];

  trackByLabel = trackByLabel;

  icHome = icHome;

  constructor() {}

  ngOnInit() {}
}
