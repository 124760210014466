/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { promotion } from '../models/promotion';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PromotionService {

    /**
     * Select promotion by id
     * @param id Object ID
     * @returns promotion Successful operation
     * @throws ApiError
     */
    public static selectPromotionById(
        id: string,
    ): CancelablePromise<promotion> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/get/object/promotion/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Save promotion
     * @param requestBody Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static savePromotion(
        requestBody?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/save/object/promotion',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Delete promotion by id
     * @param id Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static deletePromotionById(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/delete/object/promotion/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

}
