import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutingNames } from '@app/app-routing.module';
import { BehaviorSubject } from 'rxjs';
import { OtherReportRestService } from '.';
import { IStatusInfo } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ReportDownloadsService implements OnDestroy {
  public isVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public activeReports$: BehaviorSubject<IStatusInfo[]> = new BehaviorSubject<IStatusInfo[]>([]);
  public completedProsess$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private activeReports: IStatusInfo[] = [];
  private activeProses: Prosess[] = [];

  private intervalId: NodeJS.Timeout;

  constructor(private otherReportRestService: OtherReportRestService, private router: Router) {}

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  public show() {
    this.refresh();
    this.isVisible$.next(true);
  }

  public hide() {
    this.isVisible$.next(false);
  }

  public downloadReport(reportStatusInfo: IStatusInfo) {
    if (reportStatusInfo) {
      if (!this.activeProses.some((item) => item.id == reportStatusInfo.process_id)) {
        this.activeProses.push(new Prosess(reportStatusInfo.process_id, ProsessAction.download));
        if (!this.intervalId) {
          this.checkStatus();
        }
      }
    }
  }

  public viewReport(reportStatusInfo: IStatusInfo) {
    if (reportStatusInfo) {
      if (!this.activeProses.some((item) => item.id == reportStatusInfo.process_id)) {
        this.activeProses.push(new Prosess(reportStatusInfo.process_id, ProsessAction.view));
        if (!this.intervalId) {
          this.checkStatus();
        }
      }
    }
  }

  public refresh() {
    this.otherReportRestService.active().subscribe((statusInfo: IStatusInfo[]) => {
      this.activeReports$.next(statusInfo);
      this.activeReports = statusInfo;
      statusInfo.forEach((item) => {
        if (!this.activeProses.some((i) => i.id == item.process_id)) {
          this.activeProses.push(new Prosess(item.process_id, ProsessAction.status));
          if (!this.intervalId) {
            this.checkStatus();
          }
        }
      });
    });
  }

  private checkStatus() {
    const _checkStatus = () => {
      let newActiveProses: Prosess[] = [];
      this.activeProses.forEach((item) => {
        this.otherReportRestService.status(item.id).subscribe(async (statusInfo: IStatusInfo) => {
          if (statusInfo.status == 0) {
            newActiveProses.push(item);
          } else {
            this.completedProsess$.next(item.id);
            this.activeReports[this.activeReports.findIndex((i) => i.process_id == item.id)] = statusInfo;
            this.activeReports$.next(this.activeReports);
            if (item.action === ProsessAction.download) {
              this.otherReportRestService.file(statusInfo.files[0].id).subscribe(async (data: Blob) => {
                this.downloadFile(data, statusInfo.files[0].name);
              });
            } else if (item.action === ProsessAction.view) {
              if (statusInfo.status == 1) {
                const url = this.router.serializeUrl(
                  this.router.createUrlTree([AppRoutingNames.reportExcelView.name + '/' + statusInfo.files[0].id]),
                );
                console.log(url);
                window.open(url, '_blank');
              }
            }
          }
        });
      });
      this.activeProses = newActiveProses;
    };

    this.intervalId = setInterval(_checkStatus, 5000);
  }

  public downloadFile = (response: Blob, name: string) => {
    let downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(response);
    downloadLink.download = name;
    document.body.appendChild(downloadLink);
    downloadLink.click();
  };
}

export class Prosess {
  id: string;
  action: ProsessAction;

  constructor(id: string, action: ProsessAction) {
    this.id = id;
    this.action = action;
  }
}

export enum ProsessAction {
  download,
  view,
  status,
}
