import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetModule, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { ComponentsModule } from '@shared/components/components.module';
import { DirectivesModule } from '@shared/directives/directives.module';
import { TimePeriodComponent } from '@shared/modules/time-period/time-period.component';
import { SelectPeriodComponent } from './components/select-period/select-period.component';

@NgModule({
  declarations: [TimePeriodComponent, SelectPeriodComponent],
  imports: [
    CommonModule,
    MatMenuModule,
    MatBottomSheetModule,
    MatInputModule,
    ComponentsModule,
    MatButtonModule,
    FormsModule,
    DirectivesModule,
    ReactiveFormsModule,
  ],
  exports: [TimePeriodComponent],
  providers: [
    { provide: MatBottomSheetRef, useValue: {} },
    { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} },
  ],
})
export class TimePeriodModule {}
