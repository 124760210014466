<form (ngSubmit)="register()" [formGroup]="formGroup" #ngForm>
  <ng-container>
    <div matDialogTitle>
      <div class="flex content-between w-full">
        <div class="text-subtitle-3 flex-grow">Регистрация точки для сплитования</div>
        <hoho-icon class="ml-4 text-florida hover:text-margaret transition-colors delay-500 ease-in cursor-pointer"
          size="1rem" iconName="multiply" mat-dialog-close=""></hoho-icon>
      </div>
    </div>
    <mat-dialog-content>

      <div class="relative flex justify-center py-8" *ngIf="isLoading">
        <mat-spinner diameter="50" class="mt-8 self-center"></mat-spinner>
      </div>
      <div *ngIf="!isLoading">
        <mat-form-field *ngIf="!!formGroup.get('shopCode').value" class="w-full pb-4">
          <mat-label i18n>Код точки</mat-label>
          <input cdkFocusInitial formControlName="shopCode" matInput />
          <mat-error name="shopCodeError" *ngIf="formGroup.get('shopCode').invalid">{{
            getErrorMessage('shopCode', formGroup) }}</mat-error>
        </mat-form-field>

        <mat-form-field class="w-full pb-4">
          <mat-label i18n>Название магазина в SMS и на странице проверки 3DS на иностранном языке</mat-label>
          <input cdkFocusInitial required formControlName="billingDescriptor" matInput />
          <mat-error name="billingDescriptorError" *ngIf="formGroup.get('billingDescriptor').invalid">{{
            getErrorMessage('billingDescriptor', formGroup) }}</mat-error>
        </mat-form-field>

        <mat-form-field class="w-full pb-4">
          <mat-label i18n>Полное наименование организации</mat-label>
          <input required formControlName="fullName" matInput />
          <mat-error name="fullNameError" *ngIf="formGroup.get('fullName').invalid">{{ getErrorMessage('fullName',
            formGroup) }}</mat-error>
        </mat-form-field>

        <mat-form-field class="w-full pb-4">
          <mat-label i18n>Сокращенное наименование организации</mat-label>
          <input required formControlName="name" matInput />
          <mat-hint>необходимо заполнить кириллицей и обязательно указать организационно-правовую
            форму (например ОАО, ЗАО, ИП)</mat-hint>
          <mat-error name="nameError" *ngIf="formGroup.get('name').invalid">{{ getErrorMessage('name', formGroup)
            }}</mat-error>
        </mat-form-field>

        <mat-form-field class="w-full pb-4">
          <mat-label i18n>ИНН</mat-label>
          <input required formControlName="inn" matInput />
          <mat-error name="innError" *ngIf="formGroup.get('inn').invalid">{{ getErrorMessage('inn', formGroup)
            }}</mat-error>
        </mat-form-field>

        <mat-form-field class="w-full pb-4">
          <mat-label i18n>КПП</mat-label>
          <input required formControlName="kpp" matInput />
          <mat-hint>в случае, если у регистрируемой точки нет КПП, то необходимо передать нули: 000000000</mat-hint>
          <mat-error name="kppError" *ngIf="formGroup.get('kpp').invalid">{{ getErrorMessage('kpp', formGroup)
            }}</mat-error>
        </mat-form-field>

        <hoho-select appearance="standard" [submitted]="submitted" [items]="AddressTypes" appendTo="body"
          [searchable]="false" [clearable]="false" [control]="addressesTypeControl"
          placeholder="Тип адреса организации*" i18n-placeholder>
          <mat-error name="typeError" *ngIf="addressesTypeControl.invalid">{{
            getErrorMessage('type', formGroup)
            }}</mat-error>
        </hoho-select>

        <mat-form-field class="w-full pb-4 pt-4">
          <mat-label i18n>Почтовый индекс организации</mat-label>
          <input required [formControl]="addressesZipControl" matInput />
          <mat-error name="zipError" *ngIf="addressesZipControl.invalid">{{ getErrorMessage('zip', formGroup)
            }}</mat-error>
        </mat-form-field>

        <hoho-select appearance="standard" [submitted]="submitted" [items]="ConutryCodes" appendTo="body"
          [searchable]="true" [clearable]="false" [control]="addressesCountryControl" placeholder="Страна организации*"
          i18n-placeholder>
          <mat-error name="countryError" *ngIf="addressesCountryControl.invalid">{{
            getErrorMessage('country', formGroup)
            }}</mat-error>
        </hoho-select>

        <mat-form-field class="w-full pb-4 pt-4">
          <mat-label i18n>Город или населенный пункт организации</mat-label>
          <input required [formControl]="addressesCityControl" matInput />
          <mat-hint>допустимы только символы («.» и «,»). При наличии других спецсимволов запрос
            отобьется ошибкой</mat-hint>
          <mat-error name="cityError" *ngIf="addressesCityControl.invalid">{{ getErrorMessage('city', formGroup)
            }}</mat-error>
        </mat-form-field>

        <mat-form-field class="w-full pb-4">
          <mat-label i18n>Улица, дом организации</mat-label>
          <input required [formControl]="addressesStreetControl" matInput />
          <mat-hint>допустимы только символы («.» и «,»). При наличии других спецсимволов запрос
            отобьется ошибкой</mat-hint>
          <mat-error name="streetError" *ngIf="addressesStreetControl.invalid">{{ getErrorMessage('street', formGroup)
            }}</mat-error>
        </mat-form-field>

        <mat-form-field class="w-full pb-4">
          <mat-label i18n>Email, на который будет выполняться отправка реестров</mat-label>
          <input required formControlName="email" matInput />
          <mat-error name="emailError" *ngIf="formGroup.get('email').invalid">{{ getErrorMessage('email', formGroup)
            }}</mat-error>
        </mat-form-field>

        <mat-form-field class="w-full pb-4">
          <mat-label i18n>Имя руководителя</mat-label>
          <input required [formControl]="ceoFirstNameControl" matInput />
          <mat-error name="firstNameError" *ngIf="ceoFirstNameControl.invalid">{{ getErrorMessage('firstName',
            formGroup)
            }}</mat-error>
        </mat-form-field>

        <mat-form-field class="w-full pb-4">
          <mat-label i18n>Фамилия руководителя</mat-label>
          <input required [formControl]="ceoLastNameControl" matInput />
          <mat-error name="lastNameError" *ngIf="ceoLastNameControl.invalid">{{ getErrorMessage('lastName', formGroup)
            }}</mat-error>
        </mat-form-field>

        <mat-form-field class="w-full pb-4">
          <mat-label i18n>Отчество руководителя</mat-label>
          <input required [formControl]="ceoMiddleNameControl" matInput />
          <mat-error name="middleNameError" *ngIf="ceoMiddleNameControl.invalid">{{ getErrorMessage('middleName',
            formGroup) }}</mat-error>
        </mat-form-field>

        <mat-form-field class="w-full pb-4">
          <mat-label i18n>Дата рождения руководителя</mat-label>
          <input [max]="nowDate" readonly matInput [matDatepicker]="pickerFrom" (click)="pickerFrom.open()"
            [formControl]="ceoBirthDateControl" />
          <mat-datepicker-toggle matSuffix [for]="pickerFrom">
            <hoho-icon iconName="calender" matDatepickerToggleIcon></hoho-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #pickerFrom></mat-datepicker>
          <mat-error name="birthDateError" *ngIf="ceoBirthDateControl.invalid">{{
            getErrorMessage('birthDate', formGroup)
            }}</mat-error>
        </mat-form-field>

        <mat-form-field class="w-full pb-4">
          <mat-label i18n>Контактный телефон руководителя</mat-label>
          <input required [formControl]="ceoPhoneControl" matInput />
          <mat-error name="phoneError" *ngIf="ceoPhoneControl.invalid">{{ getErrorMessage('phone', formGroup)
            }}</mat-error>
        </mat-form-field>

        <hoho-select appearance="standard" [submitted]="submitted" [items]="ConutryCodes" appendTo="body"
          [searchable]="true" [clearable]="false" [control]="ceoCountryControl"
          placeholder="Страна гражданства руководителя*" i18n-placeholder>
          <mat-error name="countryError" *ngIf="ceoCountryControl.invalid">{{
            getErrorMessage('country', formGroup)
            }}</mat-error>
        </hoho-select>

        <mat-form-field class="w-full pb-4 pt-4">
          <mat-label i18n>Расчетный или казначейский счет</mat-label>
          <input required [formControl]="bankAccountAccountControl" matInput />
          <mat-error name="accountError" *ngIf="bankAccountAccountControl.invalid">{{ getErrorMessage('account',
            formGroup) }}</mat-error>
        </mat-form-field>

        <mat-form-field class="w-full pb-4">
          <mat-label i18n>Наименование банка</mat-label>
          <input required [formControl]="bankAccountBankNameControl" matInput />
          <mat-error name="bankNameError" *ngIf="bankAccountBankNameControl.invalid">{{ getErrorMessage('bankName',
            formGroup) }}</mat-error>
        </mat-form-field>

        <mat-form-field class="w-full pb-4">
          <mat-label i18n>БИК</mat-label>
          <input required [formControl]="bankAccountBikControl" matInput />
          <mat-error name="bikError" *ngIf="bankAccountBikControl.invalid">{{ getErrorMessage('bik', formGroup)
            }}</mat-error>
        </mat-form-field>

        <mat-form-field class="w-full pb-4">
          <mat-label i18n>Назначение платежа</mat-label>
          <input required [formControl]="bankAccountDetailsControl" matInput />
          <mat-error name="detailsError" *ngIf="bankAccountDetailsControl.invalid">{{ getErrorMessage('details',
            formGroup) }}</mat-error>
        </mat-form-field>
      </div>

    </mat-dialog-content>
    <mat-dialog-actions>
      <button class="w-full ml-0" color="primary" mat-flat-button type="submit" [disabled]="isLoading">
        <ng-container>{{isEdit ? 'Обновить' : 'Зарегистрировать'}}</ng-container>
      </button>
    </mat-dialog-actions>
  </ng-container>
</form>
