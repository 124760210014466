<hoho-create-update-dialog
  [formGroup]="formGroup"
  [initEntity]="entity"
  [entityType]="EEntityList.marketing_stories"
  (afterSave)="afterSave()"
  [transformRequestData]="transformRequestData"
  (submitted)="submittedEvent()"
  *ngIf="formGroup"
  [title]="title"
>
  <ng-container>

    <mat-form-field class="w-full">
      <mat-label i18n>Тема</mat-label>
      <input formControlName="storiesSubject" matInput />
      <mat-error name="storiesSubjectError" *ngIf="formGroup.get('storiesSubject').invalid">{{
        getErrorMessage('storiesSubject', formGroup)
      }}</mat-error>
    </mat-form-field>

    <mat-form-field class="w-full">
      <mat-label i18n>Основной текст</mat-label>
      <textarea matInput formControlName="storiesText"></textarea>
      <mat-error name="storiesTextError" *ngIf="formGroup.get('storiesText').invalid">{{
        getErrorMessage('storiesText', formGroup)
      }}</mat-error>
    </mat-form-field>

    <mat-form-field class="w-full">
      <mat-label i18n>Ссылка</mat-label>
      <input formControlName="linkToUrl" matInput />
      <mat-error name="linkToUrlError" *ngIf="formGroup.get('linkToUrl').invalid">{{
        getErrorMessage('linkToUrl', formGroup)
      }}</mat-error>
    </mat-form-field>

    <span class="w-56 whitespace-pre-wrap">Контент</span>
    <hoho-drag-and-drop
      [submitted]="submitted"
      (imgUrl)="contentUrl = $event"
      [ctrl]="contentCtrl"
      [entity]="EEntityList.marketing_stories_file"
      [maxFileSize]="10000000"
      [type]="'content'"
      [requiredFileType]="'image/png, image/jpeg, image/jpg, video/mp4, video/quicktime'"></hoho-drag-and-drop>

      <span class="w-56 whitespace-pre-wrap">Превью</span>
      <hoho-drag-and-drop
        [submitted]="submitted"
        (imgUrl)="previewUrl = $event"
        [ctrl]="previewCtrl"
        [entity]="EEntityList.marketing_stories_file"
        [type]="'image'"
        [requiredFileType]="'image/png, image/jpeg, image/jpg, video/mp4, video/quicktime'"></hoho-drag-and-drop>

    <mat-checkbox class="mb-3" color="primary" [formControlName]="'isMustSee'" i18n>
      <span class="w-56 whitespace-pre-wrap">Обязательно к просмотру</span>
    </mat-checkbox>

    <mat-checkbox class="mb-3" color="primary" [formControlName]="'isActual'" i18n>
      <span class="w-56 whitespace-pre-wrap">Актуальность</span>
    </mat-checkbox>

  </ng-container>

</hoho-create-update-dialog>
