import { IEntity } from '@shared/models';

export function TransformToEntitiesList<T>(originList: (string | number | T)[], entityList: T[]): T[] {
  const entities = entityList as any as IEntity[];
  if (typeof originList[0] === 'string' || typeof originList[0] === 'number') {
    if (typeof originList[0] === 'string' && typeof entities[0].id === 'string') {
      const ids = originList as string[];
      return ids.map((i: string) => {
        return entityList.find((j: any) => j.id === i);
      });
    } else if (typeof originList[0] === 'string' && typeof entities[0].id === 'number') {
      const ids = originList as string[];
      return ids.map((i: string) => {
        return entityList.find((j: any) => j.id === Number(i));
      });
    } else if (typeof originList[0] === 'number' && typeof entities[0].id === 'number') {
      const ids = originList as number[];
      return ids.map((i: number) => {
        return entityList.find((j: any) => j.id === i);
      });
    } else {
      throw 'Input error';
    }
  } else {
    // Add an algorithm for transforming mixed arrays
    return originList as T[];
  }
}

export function TransformToStringArray<T>(originList: (string | number | T)[]): string[] {
  if (typeof originList[0] === 'string' || typeof originList[0] === 'number') {
    if (typeof originList[0] === 'string') {
      return originList as string[];
    } else if (typeof originList[0] === 'number') {
      const ids = originList as number[];
      return ids.map((i: number) => i.toString(10));
    } else {
      throw 'Input error';
    }
  } else {
    const list = originList as any as IEntity[];
    return list.map((i) => {
      if (typeof i.id === 'string') {
        return i.id;
      } else if (typeof i.id === 'number') {
        return i.id.toString(10);
      }
    });
  }
}
