/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { base_entity } from './base_entity';
import type { role_modules } from './role_modules';
import type { user_roles } from './user_roles';

/**
 * Роли пользователей в системе
 */
export type role = (base_entity & {
    /**
     * Идентификатор роли
     */
    id?: string;
    /**
     * Наименование
     */
    name: string;
    /**
     * Описание
     */
    readonly description?: string;
    /**
     * Модули
     */
    roleModules?: Array<role_modules>;
    /**
     * Пользователи
     */
    roleUsers?: Array<user_roles>;
    readonly search?: string;
    /**
     * Компания
     */
    readonly companyName?: string;
    /**
     * Кол-во пользователей
     */
    readonly userCount?: number;
    /**
     * Статус
     */
    readonly status?: role.status;
    disable?: boolean;
});

export namespace role {

    /**
     * Статус
     */
    export enum status {
        '_0' = 0,
        '_1' = 1,
    }


}

