/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { auto_report } from '../models/auto_report';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AutoReportService {

    /**
     * Select auto_report by id
     * @param id Object ID
     * @returns auto_report Successful operation
     * @throws ApiError
     */
    public static selectAutoReportById(
        id: string,
    ): CancelablePromise<auto_report> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/get/object/auto_report/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Save auto_report
     * @param requestBody Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static saveAutoReport(
        requestBody?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/save/object/auto_report',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Delete auto_report by id
     * @param id Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static deleteAutoReportById(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/delete/object/auto_report/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

}
