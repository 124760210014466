import { IFilterFormSpec } from '@app/pages/marketing/components/group-create-update/group-create-update.models';
import { FilterType } from '@shared/models';
import {
  EMultiTypes,
  IDatePeriod,
  IDateTypeListItem,
} from '@shared/modules/custom-inputs/components/multi-datepicker/multi-datepicker.models';
import moment, { Moment } from 'moment';

export function getFilters(res: IFilterFormSpec): FilterType[] {
  const filterArray: FilterType[] = [];

  if (res.birthday && res.birthday.value) {
    const birthday: IDateTypeListItem = res.birthday as IDateTypeListItem;
    switch (birthday.type) {
      case EMultiTypes.certainDatePicker:
        filterArray.push(['birthday', '=', birthday.value, 'mmddyyyy'], 'and');
        break;
      case EMultiTypes.yearDatePicker:
        filterArray.push(['birthday', '=', birthday.value, 'yyyy'], 'and');
        break;
      case EMultiTypes.rangeDatePicker:
        const value = birthday.value as IDatePeriod;
        filterArray.push(
          ['birthday', '>=', value.to, 'mmddyyyy'],
          'and',
          ['birthday', '<=', value.from, 'mmddyyyy'],
          'and',
        );
        break;
      case EMultiTypes.monthDatePicker:
        filterArray.push(['birthday', '=', birthday.value, 'mmdd'], 'and');
        break;
    }
  }

  if (res.genderType || res.genderType === 0) {
    filterArray.push(['genderType', '=', res.genderType], 'and');
  }
  if (res.city) {
    filterArray.push(['cityId', '=', res.city], 'and');
  }
  if (res.daysAfterRegistration || res.daysAfterRegistration?.value === 0) {
    filterArray.push(
      ['daysAfterRegistration', res.daysAfterRegistration.symbol, res.daysAfterRegistration.value],
      'and',
    );
  }
  if (res.ordersInLastMonth || res?.ordersInLastMonth === 0) {
    filterArray.push(['ordersInLastMonth', '>=', res.ordersInLastMonth], 'and');
  }
  if (res.orderCount || res?.orderCount === 0) {
    filterArray.push(['orderCount', '>=', res.orderCount], 'and');
  }
  if (res?.vendingMachineId && res?.vendingMachineId.length > 0) {
    filterArray.push(['orders', 'exists', 'vendingMachineId', res.vendingMachineId], 'and');
  }

  if (res.orderSum || res.orderSum === 0) {
    filterArray.push(['orderSum', '>=', res.orderSum], 'and');
  }

  if (res.walletBalance || res.walletBalance?.value === 0) {
    filterArray.push(['walletBalance', res.walletBalance.symbol, res.walletBalance.value], 'and');
  }

  if (filterArray[filterArray.length - 1] === 'and') {
    filterArray.splice(filterArray.length - 1);
  }
  return filterArray;
}

export function parseFilters(res: FilterType[]): IFilterFormSpec {
  const filterForm: IFilterFormSpec = {};
  res.forEach((i: FilterType) => {
    if (Array.isArray(i)) {
      const filterType: string = i[0];
      const value: string | number = i[2];
      const equality: string = i[1];
      // vendingMachineId
      if (filterType === 'orders') {
        filterForm.vendingMachineId = i[3] as string[];
      }
      // birthday
      if (filterType === 'birthday') {
        const value: Moment = moment(i[2]);
        const mask: string = i[3];
        const equality: string = i[1];
        if (equality == '=' && mask === 'mmddyyyy')
          filterForm.birthday = {
            type: EMultiTypes.certainDatePicker,
            value: value,
          };
        if (equality == '=' && mask === 'mmdd')
          filterForm.birthday = {
            type: EMultiTypes.monthDatePicker,
            value: value,
          };
        if (equality == '=' && mask === 'yyyy')
          filterForm.birthday = {
            type: EMultiTypes.yearDatePicker,
            value: value,
          };
        if (equality == '>=')
          if (!filterForm.birthday) {
            filterForm.birthday = {
              type: EMultiTypes.rangeDatePicker,
              value: {
                to: value,
              },
            };
          } else {
            filterForm.birthday.value.to = value;
          }

        if (equality == '<=')
          if (!filterForm.birthday) {
            filterForm.birthday = {
              type: EMultiTypes.rangeDatePicker,
              value: {
                from: value,
              },
            };
          } else {
            filterForm.birthday.value.from = value;
          }
      }
      // city
      if (filterType === 'cityId') {
        filterForm.city = value as string;
      }

      // genderType
      if (filterType === 'genderType') {
        filterForm.genderType = value as number;
      }

      // daysAfterRegistration
      if (filterType === 'daysAfterRegistration') {
        filterForm.daysAfterRegistration = {
          value: value as number,
          symbol: equality,
        };
      }

      // ordersInLastMonth
      if (filterType === 'ordersInLastMonth') {
        filterForm.ordersInLastMonth = value as number;
      }

      // orderCount
      if (filterType === 'orderCount') {
        filterForm.orderCount = value as number;
      }

      // orderSum
      if (filterType === 'orderSum') {
        filterForm.orderSum = value as number;
      }

      // walletBalance
      if (filterType === 'walletBalance') {
        filterForm.walletBalance = {
          value: value as number,
          symbol: equality,
        };
      }
    }
  });
  return filterForm;
}
