export const paths = [
  'entity',
  'id',
  'displayName',
  'name',
  'recipients',
  'periodicity.id',
  'periodicity.name',
  'report.entity',
  'report.id',
  'report.displayName',
  'report.name',
  'days',
  'sendTime',
];
