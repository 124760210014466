/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { base_entity } from './base_entity';

/**
 * Ингредиент
 */
export type ingredient = (base_entity & {
    /**
     * Еденица измерения
     */
    unit?: ingredient.unit;
    /**
     * Название
     */
    name: string;
    /**
     * Идентификатор
     */
    id: number;
    readonly search?: string;
    code?: string;
});

export namespace ingredient {

    /**
     * Еденица измерения
     */
    export enum unit {
        '_0' = 0,
        '_1' = 1,
        '_2' = 2,
    }


}

