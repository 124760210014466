import { EquipmentEnum } from '@shared/modules/grid-cards/modules/vending-machine-card/enums/equipment.enum';
import { EquipmentState } from '@shared/modules/grid-cards/modules/vending-machine-card/models/card.models';
import { Pipe, PipeTransform } from '@angular/core';
import { EquipmentStateEnum } from '@app/shared/modules/grid-cards/modules/vending-machine-card/enums/equipment-state.enum';

@Pipe({
  name: 'vmEquipmentState',
})
export class VmEquipmentStatePipe implements PipeTransform {
  transform(value: EquipmentState): string {
    let stateName: string;
    let equipmentName: string;

    if (value.state === EquipmentStateEnum.Absent) stateName = $localize`Не установлено`;
    else if (value.state === EquipmentStateEnum.WorksProperly) stateName = $localize`Исправно`;
    else stateName = $localize`Есть ошибки`;

    switch (value.equipment) {
      case EquipmentEnum.Network:
        equipmentName = $localize`Сеть`;
        break;
      case EquipmentEnum.CoinBox:
        equipmentName = $localize`Монетоприемник`;
        break;
      case EquipmentEnum.BillAcceptor:
        equipmentName = $localize`Банкнотоприемник`;
        break;
      case EquipmentEnum.CashlessPaymentTerminal:
        equipmentName = $localize`Считыватель карт`;
        break;
      case EquipmentEnum.Receipt:
        equipmentName = $localize`ККТ`;
        break;

      default:
        break;
    }

    return `${stateName} (${equipmentName})`;
  }
}
