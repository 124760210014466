<hoho-create-update-dialog
  [formGroup]="formGroup"
  [entityType]="EEntityList.feedback_note"
  (afterSave)="afterSave()"
  (submitted)="submittedEvent()"
  [title]="title"
>
  <mat-form-field class="w-full mb-4">
    <mat-label i18n>Добавить примечание</mat-label>
    <input cdkFocusInitial required formControlName="text" matInput />
    <mat-error name="textError" *ngIf="formGroup.get('text').invalid">{{ getErrorMessage('text', formGroup) }}</mat-error>
  </mat-form-field>
</hoho-create-update-dialog>
