import { Component, Input } from '@angular/core';
import { IconStoreService } from '@shared/services/icon-store.service';

@Component({
  selector: 'hoho-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
  private _iconName: string;
  get iconName(): string {
    return this._iconName;
  }
  @Input() set iconName(value: string) {
    this._iconName = value;
    this.isDefaultIcon = this.iconStoreService.isDefaultIcon(value);
    if (this.isDefaultIcon) {
      this.icon = this.iconStoreService.getIcon(value);
    }
  }

  icon: object;
  isDefaultIcon: boolean;
  @Input() size: string = '1rem';
  @Input() className: string;

  constructor(private iconStoreService: IconStoreService) {}
}
