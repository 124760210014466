/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { base_entity } from './base_entity';
import type { object_link } from './object_link';
import type { sl_order } from './sl_order';
import type { sl_wallet } from './sl_wallet';

/**
 * Клиент
 */
export type sl_user = (base_entity & {
    /**
     * Идентификатор
     */
    id?: number;
    /**
     * ФИО
     */
    readonly login: string;
    /**
     * Email
     */
    readonly email?: string;
    /**
     * Телефон
     */
    readonly phoneNumber?: string;
    /**
     * Дата регистрации
     */
    readonly dateCreated?: string;
    /**
     * Пол
     */
    readonly genderType: sl_user.genderType;
    /**
     * Заказы
     */
    readonly orders?: Array<sl_order>;
    /**
     * Количество покупок
     */
    readonly orderCount?: number;
    /**
     * Количество бонусов
     */
    readonly walletBalance?: number;
    /**
     * Кошельки
     */
    readonly wallets?: Array<sl_wallet>;
    /**
     * Дата последней покупки
     */
    readonly lastOrderDate?: string;
    /**
     * Клиент
     */
    readonly slUser?: (sl_user | object_link);
    /**
     * Клиенты
     */
    readonly slUsers?: Array<sl_user>;
    /**
     * Кол-во приглашенных клиентов
     */
    readonly refCount?: number;
    isBlocked?: boolean;
    readonly favoriteProductName?: string;
    readonly birthday?: string;
    readonly instagram?: string;
    readonly status?: sl_user.status;
    readonly freezeBalance?: number;
    /**
     * Сумма покупок
     */
    readonly orderSum?: number;
    /**
     * Дней с момента регистрации
     */
    readonly daysAfterRegistration?: number;
    /**
     * Покупок за последний месяц
     */
    readonly ordersInLastMonth?: number;
    /**
     * Идентификатор города
     */
    readonly cityId?: string;
    /**
     * Название города
     */
    readonly cityName?: string;
    /**
     * Поиск
     */
    readonly search?: string;
});

export namespace sl_user {

    /**
     * Пол
     */
    export enum genderType {
        '_0' = 0,
        '_1' = 1,
    }

    export enum status {
        '_0' = 0,
        '_1' = 1,
    }


}

