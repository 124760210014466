/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { base_entity } from './base_entity';
import type { company } from './company';
import type { object_link } from './object_link';
import type { price_list } from './price_list';
import type { product_matrix_ingredient } from './product_matrix_ingredient';
import type { product_matrix_item } from './product_matrix_item';
import type { users } from './users';
import type { vending_machine_product_matrix } from './vending_machine_product_matrix';
import type { vm_model } from './vm_model';

/**
 * Товарная матрица
 */
export type product_matrix = (base_entity & {
    /**
     * Идентификатор
     */
    id?: string;
    /**
     * Название
     */
    name?: string;
    /**
     * Тип
     */
    matrixType?: product_matrix.matrixType;
    /**
     * Состав продуктовой матрицы
     */
    productMatrixItems?: Array<product_matrix_item>;
    /**
     * Модель ТА
     */
    vmModel?: (vm_model | object_link);
    /**
     * Компания
     */
    company?: (company | object_link);
    /**
     * Прайс-лист по умолчанию
     */
    defaultPriceList?: (price_list | object_link);
    /**
     * Товарные матрицы аппарата
     */
    readonly vendingMachineProductMatrixs?: Array<vending_machine_product_matrix>;
    /**
     * Количество VM
     */
    readonly vmCountCalc?: number;
    /**
     * Количество слотов
     */
    readonly slotCountCalc?: number;
    /**
     * Шаблон
     */
    isTemplate?: boolean;
    /**
     * Создавший пользователь
     */
    readonly createdUser?: (users | object_link);
    /**
     * Хэш
     */
    readonly hash?: string;
    readonly matrixTypeName?: string;
    readonly search?: string;
    /**
     * Ингредиенты
     */
    productMatrixIngredients?: Array<product_matrix_ingredient>;
    readonly telemetronId?: string;
});

export namespace product_matrix {

    /**
     * Тип
     */
    export enum matrixType {
        '_0' = 0,
        '_1' = 1,
    }


}

