import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateUpdatePushComponent } from '@app/pages/marketing/components/create-update-push/create-update-push.component';
import { MarketingRoutingNames } from '@app/pages/marketing/marketing.routing';
import { ListBuilderComponent } from '@core/modules/list-builder/list-builder.component';
import { EListBuilderViews, IAccess, IListBuilderProps } from '@core/modules/list-builder/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ITabContent } from '@shared/components/feature-tabs/feature-tabs.models';
import { EEntityList, EMarketingFeatures, EModules, IEntity, ModuleFeatureType } from '@shared/models';
import { DataRestService, UserRequestService, UserService } from '@shared/services';
import { IPushList } from '@app/pages/marketing/components/tabs/lists.models';
import { IAdditionalColumn } from '@core/modules/list-builder/list-builder.models';
import { marketing_push_notifications, marketing_stories } from '@src/api';
import { CreateUpdateStoriesComponent } from '../create-update-stories/create-update-stories.component';

@UntilDestroy()
@Component({
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent implements OnInit, AfterViewInit {
  pushBuilderProps: IListBuilderProps<IPushList> = {
    entity: EEntityList.marketing_push_notifications,
    views: [EListBuilderViews.table],
  };

  groupBuilderProps: IListBuilderProps<IEntity> = {
    entity: EEntityList.marketing_customers_group,
    views: [EListBuilderViews.table],
  };
  
  storiesBuilderProps: IListBuilderProps<IEntity> = {
    entity: EEntityList.marketing_stories,
    views: [EListBuilderViews.table],
  };

  EModules = EModules;
  activeFeature: EMarketingFeatures;
  EMarketingFeatures = EMarketingFeatures;
  tabsContentList: ITabContent[] = [];
  marketingRoutingNames = MarketingRoutingNames;
  userRequestAccess: IAccess;
  additionalColumns: IAdditionalColumn[];
  additionalColumnsStories: IAdditionalColumn[];
  @ViewChild('pushListBuilder') pushListBuilder: ListBuilderComponent<IEntity>;
  @ViewChild('storiesListBuilder') storiesListBuilder: ListBuilderComponent<IEntity>;
  @ViewChild('pushRef') pushTemp: TemplateRef<HTMLElement>;
  @ViewChild('groupRef') groupTemp: TemplateRef<HTMLElement>;
  @ViewChild('storiesRef') storiesTemp: TemplateRef<HTMLElement>;
  @ViewChild('link') link: TemplateRef<HTMLTemplateElement>;
  @ViewChild('storieslink') storieslink: TemplateRef<HTMLTemplateElement>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public userRequestService: UserRequestService,
    private userService: UserService,
    private dialog: MatDialog,
    private dataRestService: DataRestService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res.tab) {
        switch (res.tab) {
          case MarketingRoutingNames.push.name:
            this.activeFeature = EMarketingFeatures.PushNotifications;
            break;
          case MarketingRoutingNames.group.name:
            this.activeFeature = EMarketingFeatures.GroupUsers;
            break;
          case MarketingRoutingNames.stories.name:
            this.activeFeature = EMarketingFeatures.Stories;
            break;
        }
      }
      this.cd.markForCheck();
    });

    this.userRequestAccess = this.userService.Access(EEntityList.user_request);
    this.userRequestService.refresh();
  }

  ngAfterViewInit(): void {
    this.tabsContentList = [
      { name: $localize`Push-уведомления`, template: this.pushTemp, feature: EMarketingFeatures.PushNotifications },
      { name: $localize`Группы клиентов`, template: this.groupTemp, feature: EMarketingFeatures.GroupUsers },
      { name: $localize`Stories`, template: this.storiesTemp, feature: EMarketingFeatures.Stories },
    ];
    this.additionalColumns = [
      {
        name: 'link',
        template: this.link,
        desc: '',
      },
    ];
    this.additionalColumnsStories = [
      {
        name: 'link',
        template: this.storieslink,
        desc: '',
      },
    ];
  }

  changeTab(feat: ModuleFeatureType): void {
    let url: string;
    switch (feat) {
      case EMarketingFeatures.PushNotifications:
        url = MarketingRoutingNames.push.path;
        break;
      case EMarketingFeatures.GroupUsers:
        url = MarketingRoutingNames.group.path;
        break;
      case EMarketingFeatures.Stories:
        url = MarketingRoutingNames.stories.path;
        break;
    }

    this.router.navigate([url], { replaceUrl: true });
  }

  openCreateUpdateStoriesModal(id?: string): void {
    if (id) { 
      const paths = [
        'id',
        'entity',
        'storiesSubject',
        'storiesText',
        'linkToUrl',
        'isActual',
        'isMustSee',
        'files.id',
        'files.url',
        'files.name',
        'files.orderNumber',
        'files.entity',
      ];
      this.dataRestService
        .get<marketing_stories>(EEntityList.marketing_stories, id, paths)
        .pipe(untilDestroyed(this))
        .subscribe((res: marketing_stories) => {
          this.openStoriesModal(res);
        });
    } else {
      this.openStoriesModal();
    }
  }

  openCreateUpdateModal(id?: string): void {
    if (id) {
      const paths = [
        'id',
        'entity',
        'mailingStartTime',
        'messageSubject',
        'messageText',
        'linkToNewsPage',
        'individualCustomers.id',
        'individualCustomers.slUserId',
        'individualCustomers.entity',
        'marketingCustomersGroups.id',
        'marketingCustomersGroups.entity',
        'marketingCustomersGroups.marketingCustomersGroup.id',
        'marketingCustomersGroups.marketingCustomersGroup.entity',
        'marketingCustomersGroups.marketingCustomersGroup.name',
        'marketingCustomersGroups.marketingCustomersGroup.displayName',
        'file.id',
        'file.url',
        'file.name',
        'file.entity',
      ];
      this.dataRestService
        .get<marketing_push_notifications>(EEntityList.marketing_push_notifications, id, paths)
        .pipe(untilDestroyed(this))
        .subscribe((res: marketing_push_notifications) => {
          this.openModal(res);
        });
    } else {
      this.openModal();
    }
  }

  private openModal(item?: marketing_push_notifications) {
    const dialogRef = this.dialog.open(CreateUpdatePushComponent, {
      width: '20rem',
      data: item,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.pushListBuilder.reload();
      }
    });
  }

  private openStoriesModal(item?: marketing_stories) {
    const dialogRef = this.dialog.open(CreateUpdateStoriesComponent, {
      width: '20rem',
      data: item,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.storiesListBuilder.reload();
      }
    });
  }
}
