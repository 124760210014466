<hoho-header [title]="title">
  <button
    class="sm:my-0 my-3"
    color="primary"
    mat-flat-button
    type="button"
    *ngIf="activeFeature === EReportsFeatures.AutoReports"
    (click)="openCreateUpdateUserModal()"
    i18n
  >
    Создать авто отчет
  </button>
</hoho-header>

<hoho-feature-tabs
  [module]="EModules.ReportsModule"
  [activeFeature]="activeFeature"
  [tabsList]="tabsContentList"
  (changeTab)="changeTab($event)"
>
</hoho-feature-tabs>

<ng-template #sales>
  <div class="bg-arizona rounded-xl p-1 flex mb-5 mt-5 block mx-gutter">
    <button
      class="flex-grow cursor-pointer p-2 rounded-md text-center"
      (click)="changeGroupView()"
      [class.bg-natasha]="!groupView"
      [class.text-california]="groupView"
    >
      Детальный отчет
    </button>
    <button
      class="flex-grow cursor-pointer p-2 rounded-md shadow text-center"
      (click)="changeGroupView()"
      [class.bg-natasha]="groupView"
      [class.text-california]="!groupView"
    >
      Общий отчет
    </button>
  </div>
  <hoho-list-builder
    [multiselect]="false"
    [noLink]="true"
    *ngIf="!groupView"
    class="mt-5 block px-gutter"
    [props]="salesListBuilderProps"
    [requestPayload]="salesRequestPayload"
    [totalTemplate]="totalTemplate"
    [showExcel]="true"
    excelFileNameBase="Отчёт по продажам"
  ></hoho-list-builder>
  <hoho-list-builder
    alias="groupView"
    [multiselect]="false"
    [noLink]="true"
    *ngIf="groupView"
    class="mt-5 block px-gutter"
    [props]="salesListBuilderProps"
    [requestPayload]="salesRequestPayload"
    [totalTemplate]="totalTemplate"
    [showExcel]="true"
    excelFileNameBase="Отчёт по продажам"
  ></hoho-list-builder>
  <ng-template #totalTemplate let-aggregatedData="aggregatedData" let-totalCount="totalCount">
    <h2>
      <ng-container i18n>Итого:</ng-container>
      {{ aggregatedData?.sum?.cnt || totalCount }}
      <ng-container i18n>транзакций</ng-container>
      <ng-container *ngIf="(aggregatedData?.sum?.price ?? 0) > 0" i18n> на </ng-container>
      {{ aggregatedData?.sum?.price ? (aggregatedData?.sum?.price | currency: 'RUB':'symbol':'2.0-0') : '0 руб.'}}
    </h2>
  </ng-template>
</ng-template>

<ng-template #state>
  <hoho-list-builder
    #stateReportsTable
    [multiselect]="false"
    class="mt-5 block px-gutter"
    [alias]="'groupView'"
    [props]="stateListBuilderProps"
    [totalTemplate]="totalTemplate"
    [showExcel]="true"
    [haveParams]="true"
    excelFileNameBase="Отчёт по состоянию"
  ></hoho-list-builder>
  <ng-template #totalTemplate>
    <h2>
      <ng-container i18n>Итого:</ng-container>
      {{ (byStateTotals$ | async)?.workingCount }}
      <ng-container i18n>в работе</ng-container>,
      {{ (byStateTotals$ | async)?.offCount }}
      <ng-container i18n>отключено</ng-container>
    </h2>
  </ng-template>
</ng-template>

<ng-template #auto>
  <hoho-list-builder
    #autoReportsTable
    [multiselect]="true"
    [noLink]="true"
    class="mt-5 block px-gutter"
    alias=""
    [props]="autoListBuilderProps"
    [showExcel]="true"
    excelFileNameBase="Автоматический отчёты"
  ></hoho-list-builder>
</ng-template>

<ng-template #other>
  <hoho-list-builder
    #otherReportsTable
    [multiselect]="false"
    [noLink]="true"
    class="mt-5 block px-gutter"
    alias=""
    [props]="otherListBuilderProps"
    [requestPayload]="otherReportRequestPayload"
    [showExcel]="false"
    excelFileNameBase="Другие отчёты"
  ></hoho-list-builder>
  <report-file-downloads *ngIf="reportDownloadsService.isVisible$ | async">

  </report-file-downloads>
  <div class="btn-scroll-to-top cursor-pointer sm:hidden" (click)="scrollToTop()">
    <hoho-icon size="5rem" iconName="scroll-to-top"></hoho-icon>
  </div>
  <div class="btn-downloads cursor-pointer" (click)="downloadsView()">
    <hoho-icon size="3rem" iconName="downloads"></hoho-icon>
  </div>
</ng-template>
