import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ControlsOf, getErrorMessage, passwordValidator, RegexPatternConstants } from '@shared/utilities';
import { AlertService, DataRestService, UserService } from '@shared/services';
import { EEntityList, ICurrentUser, IServerRequestPayload } from '@shared/models';
import { switchMap } from 'rxjs/operators';
import { ProfileService } from '@shared/@vex/layout/toolbar/modules/profile/profile.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { city, company, object_link, users } from '@src/api';
import { paths } from './profile.config';

@UntilDestroy()
@Component({
  selector: 'hoho-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  providers: [ProfileService],
})
export class ProfileComponent implements OnInit {
  formGroup: UntypedFormGroup;
  passwordForm: UntypedFormGroup;
  maxDate = new Date();
  cities: city[];
  getErrorMessage = getErrorMessage;
  constructor(
    private userService: UserService,
    private dataRestService: DataRestService,
    private profileService: ProfileService,
    public dialogRef: MatDialogRef<ProfileComponent>,
    private alertService: AlertService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.formGroup = new FormGroup<ControlsOf<users>>({
      id: new FormControl('', [Validators.required]),
      entity: new FormControl(EEntityList.users),
      firstName: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(20)]),
      lastName: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(20)]),
      middleName: new FormControl('', [Validators.minLength(2), Validators.maxLength(20)]),
      email: new FormControl('', [Validators.required, Validators.pattern(RegexPatternConstants.email)]),
      birthday: new FormControl(undefined, Validators.required),
      cityName: new FormControl(undefined, Validators.required),
      company: new FormGroup<ControlsOf<company | object_link>>({
        id: new FormControl(''),
        entity: new FormControl(''),
      }),
    });

    this.passwordForm = new UntypedFormGroup({
      password: new UntypedFormControl('', [Validators.required]),
      newPassword: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20),
        passwordValidator(),
      ]),
      confirmPassword: new UntypedFormControl('', [Validators.required]),
    });

    this.userService.user$
      .pipe(
        switchMap((user: ICurrentUser) => this.dataRestService.get<users>(EEntityList.users, user.id, paths)),
        untilDestroyed(this),
      )
      .subscribe((res: users) => {
        this.formGroup.patchValue(res);
      });
    this.getCites();
  }

  updateProfile(): void {
    this.dataRestService
      .save(this.profileService.getEntitySaveValue(this.formGroup.value), EEntityList.users)
      .pipe()
      .subscribe(() => {
        this.userService.loadUser();
        this.dialogRef.close();
      });
  }

  updatePassword(): void {
    this.dataRestService
      .changePassword(this.passwordForm.value.password, this.passwordForm.value.newPassword)
      .pipe()
      .subscribe(() => {
        this.userService.loadUser();
        this.alertService.showSuccess(
          'Пароль успешно обновлен. Сейчас вы будете перенаправлены на страницу авторизации.',
        );
        setTimeout(() => {
          this.userService.logOut();
        }, 5000);
        this.dialogRef.close();
      });
  }

  get cityControl(): UntypedFormControl {
    return this.formGroup.get('cityName') as UntypedFormControl;
  }
  private getCites() {
    const citesPayload: IServerRequestPayload = {
      filter: {},
      paths: ['id', 'name', 'entity'],
    };
    this.dataRestService
      .page<city>(EEntityList.city, '', 0, -1, citesPayload)
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.cities = res.content;
      });
  }
}
