<div class="dropdown bg-natasha rounded-2xl w-80">
	<!--  <a class="flex cursor-pointer p-4 content-between w-full">-->
	<a class="flex cursor-pointer p-4 content-between w-full" (click)="openDialog()">
		<div class="flex items-center cursor-pointer pr-2">
			<div *ngIf="user"
				class="mr-4 text-sm cursor-pointer rounded-lg h-11 w-11 flex items-center center justify-center trans-ease-out text-primary bg-shpongle hover:bg-hover">
				<mat-icon class="w-4 h-4" height="1rem" width="1rem" [icIcon]="userCircle"></mat-icon>
			</div>
			<div>
				<div class="text-body-3">{{ user.lastName }} {{ user.firstName }} {{ user.middleName }}</div>
				<div class="text-texas text-body-4">{{ user.roles[0].roleName }}</div>
			</div>
		</div>
		<hoho-icon iconName="angle-right-b" class="text-betty"></hoho-icon>
	</a>
	<a class="flex cursor-pointer p-4 content-between w-full" (click)="logOut()">
		<div class="flex items-center flex-grow">
			<hoho-icon iconName="arrow-from-right" class="text-margaret mr-3"></hoho-icon>
			Выйти
		</div>
		<hoho-icon iconName="angle-right-b" class="text-betty"></hoho-icon>
	</a>
</div>