/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { report_link } from '../models/report_link';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReportLinkService {

    /**
     * Select report_link by id
     * @param id Object ID
     * @returns report_link Successful operation
     * @throws ApiError
     */
    public static selectReportLinkById(
        id: string,
    ): CancelablePromise<report_link> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/get/object/report_link/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Save report_link
     * @param requestBody Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static saveReportLink(
        requestBody?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/save/object/report_link',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Delete report_link by id
     * @param id Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static deleteReportLinkById(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/delete/object/report_link/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

}
