import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ComponentsModule } from '@shared/components/components.module';
import { IconModule } from '@visurel/iconify-angular';
import { RelativeDateTimeModule } from '../../../../pipes/relative-date-time/relative-date-time.module';
import { UserDropdownComponent } from './user-dropdown/user-dropdown.component';
import { UserComponent } from './user.component';

@NgModule({
  declarations: [UserComponent, UserDropdownComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatRippleModule,
    MatMenuModule,
    MatButtonModule,
    RelativeDateTimeModule,
    RouterModule,
    MatTooltipModule,
    IconModule,
    ComponentsModule,
  ],
  exports: [UserComponent],
})
export class UserModule {}
