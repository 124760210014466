/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { city } from '../models/city';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CityService {

    /**
     * Select city by id
     * @param id Object ID
     * @returns city Successful operation
     * @throws ApiError
     */
    public static selectCityById(
        id: string,
    ): CancelablePromise<city> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/get/object/city/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Save city
     * @param requestBody Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static saveCity(
        requestBody?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/save/object/city',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Delete city by id
     * @param id Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static deleteCityById(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/delete/object/city/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

}
