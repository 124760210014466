
<!-- need fix tabs -->
<!-- <hoho-feature-tabs
  [module]="EModules.PersonalAreaModule"
  [activeFeature]="activeFeature"
  [tabsList]="tabsContentList"
  (changeTab)="changeTab($event)"
>
</hoho-feature-tabs> -->

<ng-container *ngTemplateOutlet="mainTab"></ng-container>
<ng-template #mainTab>
	<main-tab *ngIf="activeFeature == EPersonalAreaFeatures.Main"></main-tab>
</ng-template>

<ng-container *ngTemplateOutlet="invoicesTab"></ng-container>
<ng-template #invoicesTab>
	<hoho-invoice-list *ngIf="activeFeature == EPersonalAreaFeatures.Invoices"></hoho-invoice-list>
</ng-template>
