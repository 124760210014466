<vex-page-layout>
  <hoho-secondary-header [crumbs]="crumbs" [title]="title"> </hoho-secondary-header>
  <div class="px-gutter mt-4">
    <hoho-list-builder
      class="mt-3 sm:mt-0 block"
      #tableBuilder
      [noLink]="true"
      [multiselect]="false"
      [additionalColumns]="additionalColumns"
      [props]="tableBuilderProps"
      context="graphic"
    ></hoho-list-builder>
  </div>
  <ng-template #link let-item="item">
    <div class="link flex items-center whitespace-nowrap" *ngIf="permissionsGuard.haveAccess(EModules.FeedbackModule, EFeedbackFeatures.Page)" [routerLink]="'/feedback/page/' + item.id">
      <ng-container i18n>Карточка отзыва</ng-container> <hoho-icon class="ml-2" iconName="arrow-right"></hoho-icon>
    </div>
  </ng-template>
</vex-page-layout>
