<div class="w-full h-full overflow-y-auto bg-auth relative flex flex-col items-center sm:justify-center">
  <div class="hidden sm:block logo-container">
    <a href="/"><img alt="Hohoro" src="./assets/img/base/logo_horizontal.svg" class="logo" /></a>

  </div>

  <div class="w-full flex justify-center sm:hidden p-3.5 bg-natasha shadow-bs">
    <a href="/"><img alt="" src="./assets/img/base/logo_horizontal_black.svg" class="logo" /></a>
  </div>

  <ng-content></ng-content>
</div>
