/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { product_matrix_coffee } from '../models/product_matrix_coffee';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProductMatrixCoffeeService {

    /**
     * Select product_matrix_coffee by id
     * @param id Object ID
     * @returns product_matrix_coffee Successful operation
     * @throws ApiError
     */
    public static selectProductMatrixCoffeeById(
        id: string,
    ): CancelablePromise<product_matrix_coffee> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/get/object/product_matrix_coffee/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Save product_matrix_coffee
     * @param requestBody Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static saveProductMatrixCoffee(
        requestBody?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/save/object/product_matrix_coffee',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Delete product_matrix_coffee by id
     * @param id Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static deleteProductMatrixCoffeeById(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/delete/object/product_matrix_coffee/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

}
