import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgForm, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IDeleteEntityItem, ISaveParameter } from '@shared/models';
import { EEntityList } from '@shared/models/entity.models';
import { DataRestService } from '@shared/services';
import { deepClone, getCollectionDeleteEntities } from '@shared/utilities';
import { CreateStepComponent } from '@shared/modules/dialogs/components/create-step/create-step.component';
import { MatDialogRef } from '@angular/material/dialog';

@UntilDestroy()
@Component({
  selector: 'hoho-create-update-dialog',
  templateUrl: './create-update-dialog.component.html',
  styleUrls: ['./create-update-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateUpdateDialogComponent<T> implements OnInit, AfterContentInit, OnChanges {
  @Input() entityType: EEntityList;
  @Input() formGroup: UntypedFormGroup;
  @Input() initEntity: T;
  @Input() deleteEntityList: IDeleteEntityItem[];
  @Input() title: string;
  @Input() transformRequestData: (entity: T) => T;
  @Input() multi: boolean = false;
  @Input() actions: TemplateRef<HTMLTemplateElement>;
  @Input() saveList: boolean = false;
  @Input() parameters: ISaveParameter<unknown>[];
  @Input() activeView: string;
  @Input() isLoading: boolean = false;
  @Output() beforeSave: EventEmitter<void> = new EventEmitter();
  @Output() afterSave: EventEmitter<string | undefined> = new EventEmitter();
  @Output() submitted = new EventEmitter(true);
  @ViewChild('ngForm') ngForm: NgForm;
  @ContentChildren(CreateStepComponent)
  _steps: QueryList<CreateStepComponent>;
  isUpdate: boolean;
  constructor(private dataRestService: DataRestService, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.isUpdate = !!this.formGroup.value.id;
  }

  ngAfterContentInit(): void {
    this.cd.detectChanges();
    this._steps.changes.subscribe((res) => {
      this.cd.markForCheck();
    });
    //
    // this._steps.map((vcr: ViewContainerRef, index: number) => {
    //   const factory = this._resolver.resolveComponentFactory(this.tabs[index].component);
    //   vcr.createComponent(factory);
    // });
  }

  save() {
    if (this.beforeSave.observed) {
      this.beforeSave.emit();
    }
    else {
      this.performSave();
    }
  }

  performSave():void {
    this.submitted.emit(true);
    let entity = this.formGroup.value as T;
    let transformedEntity;
    this.formGroup.markAllAsTouched();
    if (this.formGroup.invalid) {
      return;
    }
    if (this.transformRequestData) {
      transformedEntity = deepClone(this.transformRequestData(entity));
    }
    this.isLoading = true;
    if (this.saveList) {
      this.dataRestService
        .saveList<T>(transformedEntity || entity, this.entityType, this.deleteEntityList)
        .pipe(untilDestroyed(this))
        .subscribe(
          () => {
            this.cd.markForCheck();
            this.isLoading = false;
            this.afterSave.emit();
          },
          () => {
            this.cd.markForCheck();
            this.isLoading = false;
          },
        );
    } else {
      const e = entity as any;
      e.entity = this.entityType;
      this.deleteEntityList = entity ? getCollectionDeleteEntities(this.initEntity, entity) : this.deleteEntityList;
      this.dataRestService
        .save<T>(entity, this.entityType, this.deleteEntityList, this.parameters)
        .pipe(untilDestroyed(this))
        .subscribe(
          (res) => {
            this.cd.markForCheck();
            this.isLoading = false;
            this.afterSave.emit(res);
          },
          () => {
            this.cd.markForCheck();
            this.isLoading = false;
          },
        );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      this.cd.markForCheck();
    }, 1);
  }
}
