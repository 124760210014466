import { Component, Input } from '@angular/core';
import { IVendingMachineBase } from '@shared/models';

@Component({
  selector: 'hoho-machine-location',
  templateUrl: './machine-location.component.html',
  styleUrls: ['./machine-location.component.scss'],
})
export class MachineLocationComponent {
  @Input() model: IVendingMachineBase;

  constructor() {}
}
