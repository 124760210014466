export class EnumUtils {
  /**
   * Returns the enum name by value
   * @param enumType enum type *Enum object
   * @param value number or *Enum property
   */
  static getName(enumType: any, value: number): string {
    return Object.assign(enumType)[value];
  }

  /**
   * Returns the enum value by name
   * @param enumType enum type *Enum object
   * @param value number or *Enum property
   */
  static getValue(enumType: any, name: string): number {
    return Object.assign(enumType)[name];
  }

  /**
   * Returns the enum keys
   * @param enumObj enum object
   * @param enumType the enum type
   */
  static getEnumKeys(enumObj: any, enumType: EnumType): any[] {
    return EnumUtils.getEnumValues(enumObj, enumType).map((value) => enumObj[value]);
  }

  /**
   * Returns the enum values
   * @param enumObj enum object
   * @param enumType the enum type
   */
  static getEnumValues(enumObj: any, enumType: EnumType): any[] {
    return Object.keys(enumObj).filter((key) => typeof enumObj[key] === enumType);
  }
}

export enum EnumType {
  Number = 'number',
  String = 'string',
}
