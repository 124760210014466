<hoho-create-update-dialog
  [formGroup]="formGroup"
  [initEntity]="entity"
  [entityType]="EEntityList.marketing_push_notifications"
  (beforeSave)="getTotalGroupLength()"
  (afterSave)="afterSave()"
  [transformRequestData]="transformRequestData"
  *ngIf="formGroup"
  [actions]="preview ? returnBackBtn : null"
  [title]="title"
  [isLoading]="isLoading">
  <ng-container *ngIf="!preview">
    <hoho-date-time
      [submitted]="submitted"
      [ctrl]="mailingStartTimeCtrl"
      placeholder="Дата и время начала*"
      i18n-placeholder
    ></hoho-date-time>

    <mat-form-field class="w-full">
      <mat-label i18n>Тема сообщения</mat-label>
      <input required formControlName="messageSubject" matInput />
      <mat-error name="messageSubjectError" *ngIf="formGroup.get('messageSubject').invalid">{{
        getErrorMessage('messageSubject', formGroup)
      }}</mat-error>
    </mat-form-field>

    <mat-form-field class="w-full">
      <mat-label i18n>Основной текст сообщения</mat-label>
      <textarea required matInput formControlName="messageText"></textarea>
      <mat-error name="messageTextError" *ngIf="formGroup.get('messageText').invalid">{{
        getErrorMessage('messageText', formGroup)
      }}</mat-error>
    </mat-form-field>

    <hoho-drag-and-drop [submitted]="submitted" (imgUrl)="imgUrl = $event" [ctrl]="fileCtrl" [entity]="EEntityList.marketing_push_notification_file"></hoho-drag-and-drop>

    <mat-form-field class="w-full">
      <mat-label i18n>Ссылка на страницу новости</mat-label>
      <input formControlName="linkToNewsPage" matInput />
      <mat-error name="linkToNewsPageError" *ngIf="formGroup.get('linkToNewsPage').invalid">{{
        getErrorMessage('linkToNewsPage', formGroup)
      }}</mat-error>
    </mat-form-field>
    <div class="flex items-center justify-between mb-4 relative">
      <div
        class="text-body-2 mr-4"
        [class.text-california]="!formGroup.errors?.recipientsRequired || !submitted"
        [class.text-barbara]="formGroup.errors?.recipientsRequired && submitted"
        i18n
      >
        Получатели*
      </div>
      <div
        class="h-px bg-colorado flex-grow"
        [class.bg-barbara]="formGroup.errors?.recipientsRequired && submitted"
      ></div>
    </div>

    <hoho-modal-filter
      [paths]="groupFilterPaths"
      i18n-placeholder
      appearance="standard"
      placeholder="Группа пользователей"
      class="w-full"
      listTitle="Список групп"
      listFilterPath="id"
      bindLabel="name"
      [entity]="groupFilterEntity"
      [ctrl]="marketingCustomersGroupsControl"
      [rowTemplate]="row"
      [disabled]="!!individualCustomersControl.value?.length"
      [submitted]="submitted"
      [haveSubscriptWrapper]="true"
    ></hoho-modal-filter>

    <ng-template #row let-item="item">
      <div class="flex-col">
        <div class="text-body-2 select-none">{{ item.name }}</div>
      </div>
    </ng-template>

    <hoho-modal-filter
      [paths]="slUserFilterPaths"
      i18n-placeholder
      appearance="standard"
      placeholder="Пользователи"
      class="w-full"
      listFilterPath="id"
      listTitle="Список пользователей"
      bindLabel="login"
      [disabled]="!!marketingCustomersGroupsControl.value?.length"
      [entity]="slUserFilterEntity"
      [ctrl]="individualCustomersControl"
      [rowTemplate]="usersRow"
      [submitted]="submitted"
      [autocomplete]="true"
      [haveSubscriptWrapper]="true"
    ></hoho-modal-filter>

    <ng-template #usersRow let-item="item">
      <div class="flex-col">
        <div class="text-body-2 select-none">{{ item.login }}</div>
        <div class="text-body-5 text-california select-none">
          {{ item.phoneNumber ? item.phoneNumber + ',' : '' }} {{ item.email }}
        </div>
      </div>
    </ng-template>

    <div
      class="text-center mb-4"
      [class.text-california]="formGroup.invalid"
      [class.cursor-not-allowed]="formGroup.invalid"
      [class.text-margaret]="formGroup.valid"
      [class.cursor-pointer]="formGroup.valid"
      (click)="formGroup.valid ? (preview = true) : null"
    >
      Предпросмотр уведомления
    </div>
  </ng-container>

  <ng-container *ngIf="preview">
    <div class="iphone mb-4">
      <div class="custom-padding">
        <div class="p-4">
          <img
            *ngIf="imgUrl"
            [src]="imgUrl"
            class="w-full rounded-bl-3xl rounded-br-3xl preview-img mb-3 bg-natasha"
            alt=""
          />
        </div>
        <div class="text-container">
          <div class="text-subtitle-3 mb-4">{{ formGroup.value.messageSubject }}</div>
          <div class="text-body-4">{{ formGroup.value.messageText }}</div>
          <button
            *ngIf="isLinkToNewsPage()"
            class="my-3 mt-3 url-btn"
            mat-flat-button
            type="button"
            (click)="LinkToNewsPage()"
            i18n
          >
            Перейти к новостям
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</hoho-create-update-dialog>
<ng-template #returnBackBtn>
  <button class="w-full ml-0 mb-4" color="accent" mat-flat-button type="submit" (click)="preview = false">Назад</button>
</ng-template>
