import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRoutingNames } from '@app/app-routing.module';
import { ListBuilderComponent } from '@app/core/modules/list-builder/list-builder.component';
import { CreateUpdateGroupComponent } from '@app/pages/vending-machines/components/create-update-group/create-update-group.component';
import { VendingMachinesRoutingNames } from '@app/pages/vending-machines/vending-machines.routing';
import { EListBuilderViews, IAccess, IListBuilderProps } from '@core/modules/list-builder/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ICrumb } from '@shared/@vex/components/breadcrumbs/breadcrumbs.component';
import { ITabContent } from '@shared/components/feature-tabs/feature-tabs.models';
import {
  EEntityList,
  EVendingMachineContextsList,
  IServerRequestPayload,
  IUserActiveModule,
  IVendingMachineBase,
} from '@shared/models';
import { EModules, EVendingMachineFeatures, ModuleFeatureType } from '@shared/models/module.models';
import { DataRestService, UserService } from '@shared/services';
import { service_route } from '@src/api';

@UntilDestroy()
@Component({
  selector: 'hoho-group-page',
  templateUrl: './group-page.component.html',
  styleUrls: ['./group-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupPageComponent implements OnInit, AfterViewInit {
  public crumbs: ICrumb[] = [
    { label: $localize`Мои автоматы`, link: AppRoutingNames.vendingMachines.path },
    { label: 'Группа' },
  ];
  name: string;
  listBuilderProps: IListBuilderProps<IVendingMachineBase> = {
    entity: EEntityList.vending_machine,
    views: [EListBuilderViews.table, EListBuilderViews.grid, EListBuilderViews.map],
  };
  vmGroupAccess: IAccess;
  requestPayload: IServerRequestPayload;
  groupID: string;
  moduleInfo: IUserActiveModule;
  EVendingMachineFeatures = EVendingMachineFeatures;
  EModules = EModules;
  activeFeature: EVendingMachineFeatures;
  tabsContentList: ITabContent[] = [];
  eVendingMachineContextsList = EVendingMachineContextsList;
  @ViewChild('information') informationTemp: TemplateRef<HTMLElement>;
  @ViewChild('state') stateTemp: TemplateRef<HTMLElement>;
  @ViewChild('list') listTemp: TemplateRef<HTMLElement>;
  @ViewChild('informationBuilder') informationBuilderComponent: ListBuilderComponent<service_route>;
  @ViewChild('stateBuilder') stateBuilderComponent: ListBuilderComponent<service_route>;
  @ViewChild('listBuilder') listBuilderComponent: ListBuilderComponent<service_route>;
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private dataRestService: DataRestService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.moduleInfo = this.userService.modules.find((i) => i.module === EModules.VendingMachineModule);
    this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res.tab) {
        if (res.tab) {
          this.activeFeature = this.moduleInfo.features.find(
            (i) => i.toLowerCase() === res.tab,
          ) as EVendingMachineFeatures;
        }
      }
    });
    this.groupID = this.activatedRoute.snapshot.params.id;
    this.loadGroup();
    this.vmGroupAccess = this.userService.Access(EEntityList.vm_group);
    this.requestPayload = { filter: { inGroup: this.groupID } };
  }

  ngAfterViewInit(): void {
    this.tabsContentList = [
      { name: $localize`Информация`, template: this.informationTemp, feature: EVendingMachineFeatures.Information },
      { name: $localize`Состояние автоматов`, template: this.stateTemp, feature: EVendingMachineFeatures.State },
      { name: $localize`Список`, template: this.listTemp, feature: EVendingMachineFeatures.List },
    ];
  }

  private loadGroup(): void {
    this.dataRestService
      .get<{ name: string }>(EEntityList.vm_group, this.groupID, ['name'])
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.name = res.name;
        this.cd.detectChanges();
      });
  }

  openEditGroupModal() {
    const dialogRef = this.dialog.open(CreateUpdateGroupComponent, {
      data: { name: this.name, id: this.groupID },
      panelClass: 'w-80',
    });
    dialogRef.afterClosed().subscribe((isSuccess: boolean) => {
      if (isSuccess) {
        this.loadGroup();
        this.currentListBuilder().reload();
      }
    });
  }

  changeTab(feat: ModuleFeatureType): void {
    switch (feat) {
      case EVendingMachineFeatures.Information:
        this.router.navigate([], {
          replaceUrl: true,
          queryParams: { tab: EVendingMachineFeatures.Information.toLowerCase() },
        });
        break;
      case EVendingMachineFeatures.List:
        this.router.navigate([], {
          replaceUrl: true,
          queryParams: { tab: EVendingMachineFeatures.List.toLowerCase() },
        });
        break;
      case EVendingMachineFeatures.State:
        this.router.navigate([], {
          replaceUrl: true,
          queryParams: { tab: EVendingMachineFeatures.State.toLowerCase() },
        });
        break;
    }
  }

  currentListBuilder(): ListBuilderComponent<service_route> {
    switch (this.activeFeature) {
      case EVendingMachineFeatures.Information:
        return this.informationBuilderComponent;
      case EVendingMachineFeatures.State:
        return this.stateBuilderComponent;
      case EVendingMachineFeatures.List:
        return this.listBuilderComponent;
    }
  }
}
