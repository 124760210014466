import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BaseLayoutComponent } from '@app/base-layout/base-layout.component';
import { ConfigPanelModule } from '@shared/@vex/components/config-panel/config-panel.module';
import { SidebarModule } from '@shared/@vex/components/sidebar/sidebar.module';
import { LayoutModule } from '@shared/@vex/layout/layout.module';
import { SidenavModule } from '@shared/@vex/layout/sidenav/sidenav.module';
import { ToolbarModule } from '@shared/@vex/layout/toolbar/toolbar.module';

@NgModule({
  declarations: [BaseLayoutComponent],
  imports: [CommonModule, LayoutModule, SidenavModule, ToolbarModule, ConfigPanelModule, SidebarModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BaseLayoutModule {}
