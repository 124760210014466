import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export enum Style {
  light = 'hohoro-style-light',
  default = 'hohoro-style-default',
  dark = 'hohoro-style-dark',
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class StyleService {
  defaultStyle = Style.default;

  private _styleSubject = new BehaviorSubject<Style>(
    localStorage.getItem('style') ? localStorage.getItem('style') as Style : this.defaultStyle,
  );

  style$ = this._styleSubject.asObservable();

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.style$.pipe(untilDestroyed(this)).subscribe((style) => this._updateStyle(style));
  }

  setStyle(style: Style) {
    localStorage.setItem('style', style);
    this._styleSubject.next(style);
  }

  private _updateStyle(style: Style) {
    const { body } = this.document;

    Object.values(Style)
      .filter((s) => s !== style)
      .forEach((value) => {
        if (body.classList.contains(value)) {
          body.classList.remove(value);
        }
      });

    body.classList.add(style);
  }
}
