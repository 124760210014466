<div class="mx-gutter pt-5">
  <vex-breadcrumbs class="block" [crumbs]="crumbs"></vex-breadcrumbs>
  <div class="grid grid-cols-1 md:grid-cols-2 gap-4 w-full sm:flex content-between items-center mt-4">
    <div class="flex-grow mr-6 flex items-center">
      <div>
        <div class="flex">
          <h2>{{ title }}</h2>
          <div
            class="rounded-md ml-3.5  text-body-3 px-1.5 py-1 text-natasha bg-{{ badgeColor ? badgeColor : 'primary' }}"
            *ngIf="badgeTitle"
          >
            {{ badgeTitle }}
          </div>
        </div>
        <div class="text-body-5" *ngIf="subtitle">{{ subtitle }}</div>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
</div>
