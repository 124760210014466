/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { base_entity } from './base_entity';
import type { object_link } from './object_link';
import type { product_matrix_item } from './product_matrix_item';
import type { vending_machine } from './vending_machine';

/**
 * Промокод
 */
export type promotion = (base_entity & {
    /**
     * Идентификатор
     */
    id?: string;
    /**
     * Кол-во начисляемых баллов
     */
    value: number;
    /**
     * Наименование
     */
    name: string;
    /**
     * Промокод
     */
    promocode: string;
    /**
     * Кол-во применений
     */
    readonly usesCount: number;
    /**
     * Кол-во доступных
     */
    readonly availableCount?: number;
    /**
     * Дата начала использования
     */
    startDate: string;
    /**
     * Дата окончания использования
     */
    finishDate: string;
    /**
     * Тип промокода
     */
    promocodeType: promotion.promocodeType;
    /**
     * Статус
     */
    isBlocked: boolean;
    /**
     * Клиенты
     */
    users?: string;
    maxUsesCount: number;
    readonly search?: string;
    promocodeGiftType: number;
    readonly productName?: string;
    readonly address?: string;
    productMatrixItem?: (product_matrix_item | object_link);
    vendingMachine?: (vending_machine | object_link);
});

export namespace promotion {

    /**
     * Тип промокода
     */
    export enum promocodeType {
        '_0' = 0,
        '_1' = 1,
        '_2' = 2,
    }


}

