import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { SecondLayoutComponent } from '@shared/@vex/layout/second-layout/second-layout.component';
import { ProgressBarModule } from '../components/progress-bar/progress-bar.module';
import { SearchModule } from '../components/search/search.module';
import { LayoutComponent } from './layout.component';
import { FooterComponent } from './footer/footer.component';
import { IconModule } from '@visurel/iconify-angular';


@NgModule({
  declarations: [LayoutComponent, SecondLayoutComponent, FooterComponent],
  imports: [CommonModule, RouterModule, MatSidenavModule, ProgressBarModule, SearchModule, IconModule],
  exports: [LayoutComponent, SecondLayoutComponent, FooterComponent],
})
export class LayoutModule {}
