import { Component, Input } from '@angular/core';
import { DotIndicatorStateEnum } from '@shared/components/dot-indicator/enum/dot-indicator-state.enum';
import { MachineStateEnum } from '../../enums/machine-state.enum';

@Component({
  selector: 'hoho-machine-state',
  templateUrl: './machine-state.component.html',
  styleUrls: ['./machine-state.component.scss'],
})
export class MachineStateComponent {
  private _state: MachineStateEnum;
  get state() {
    return this._state;
  }
  @Input() set state(value: MachineStateEnum) {
    this._state = value;
    switch (value) {
      case MachineStateEnum.Working:
        this.dotIndicatorState = DotIndicatorStateEnum.Accept;
        break;
      case MachineStateEnum.NotStable:
        this.dotIndicatorState = DotIndicatorStateEnum.Attention;
        break;
      case MachineStateEnum.Off:
        this.dotIndicatorState = DotIndicatorStateEnum.Error;
        break;
      default:
        break;
    }
  }
  machineStateEnum = MachineStateEnum;

  dotIndicatorState: DotIndicatorStateEnum;

  constructor() {}
}
