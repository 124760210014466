/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { base_entity } from './base_entity';
import type { object_link } from './object_link';
import type { sl_user } from './sl_user';
import type { sl_wallet_operation } from './sl_wallet_operation';

/**
 * Кошелек
 */
export type sl_wallet = (base_entity & {
    /**
     * Идентификатор
     */
    id?: number;
    /**
     * Клиент
     */
    readonly slUser: (sl_user | object_link);
    /**
     * Баланс
     */
    readonly balance: number;
    /**
     * Валюта
     */
    readonly currencyId: sl_wallet.currencyId;
    /**
     * Операции кошелька
     */
    readonly walletOperations?: Array<sl_wallet_operation>;
});

export namespace sl_wallet {

    /**
     * Валюта
     */
    export enum currencyId {
        '_10' = 10,
        '_20' = 20,
    }


}

