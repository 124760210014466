<hoho-create-update-dialog *ngIf="!isLoading" [formGroup]="formGroup" [parameters]="parameters" [entityType]="entityType" (afterSave)="afterSave()"
  (submitted)="submittedEvent()" [title]="title">
  <mat-form-field class="w-full">
    <mat-label i18n>Введите фамилию</mat-label>
    <input cdkFocusInitial hohoNoSpecialCharactersMask required formControlName="lastName" matInput />
    <mat-error name="lastNameError" *ngIf="formGroup.get('lastName').invalid">{{ getErrorMessage('lastName', formGroup) }}
    </mat-error>
  </mat-form-field>

  <mat-form-field class="w-full">
    <mat-label i18n>Введите имя</mat-label>
    <input cdkFocusInitial hohoNoSpecialCharactersMask required formControlName="firstName" matInput />
    <mat-error name="firstNameError" *ngIf="formGroup.get('firstName').invalid">{{ getErrorMessage('firstName', formGroup) }}
    </mat-error>
  </mat-form-field>

  <mat-form-field class="w-full">
    <mat-label i18n>Введите отчество</mat-label>
    <input cdkFocusInitial hohoNoSpecialCharactersMask formControlName="middleName" matInput />
    <mat-error name="middleName" *ngIf="formGroup.get('middleName').invalid">{{ getErrorMessage('middleName', formGroup) }}
    </mat-error>
  </mat-form-field>

  <mat-form-field class="w-full">
    <mat-label i18n>Введите эл. почту</mat-label>
    <input required formControlName="email" matInput />
    <mat-error name="emailError" *ngIf="formGroup.get('email').invalid">{{ getErrorMessage('email', formGroup) }}</mat-error>
  </mat-form-field>

  <mat-form-field class="w-full">
    <mat-label i18n>Укажите дату рождения</mat-label>
    <input
      [max]="maxDate"
      readonly
      matInput
      required
      [matDatepicker]="picker"
      (click)="picker.open()"
      formControlName="birthday"
    />
    <mat-datepicker-toggle matSuffix [for]="picker">
      <hoho-icon iconName="calender" matDatepickerToggleIcon></hoho-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-error *ngIf="formGroup.get('birthday').invalid">{{ getErrorMessage('birthday', formGroup) }}</mat-error>
  </mat-form-field>

  <hoho-select
      [submitted]="submitted"
      [items]="companyList"
      appearance="fill"
      [control]="companyControl"
      [addTag]="true"
      [searchable]="true"
      placeholder="Выберите компанию*"
      i18n-placeholder
    >
      <mat-error *ngIf="companyControl.invalid">{{ getErrorMessage('id', formGroup) }}</mat-error>
  </hoho-select>

  <hoho-select
      [submitted]="submitted"
      [items]="cities"
      appearance="fill"
      [bindValue]="'name'"
      [control]="cityControl"
      [searchable]="true"
      placeholder="Выберите ваш город*"
      i18n-placeholder
    >
      <mat-error *ngIf="cityControl.invalid">{{ getErrorMessage('cityName', formGroup) }}</mat-error>
  </hoho-select>

  <hoho-select
      *ngIf="entity"
      [submitted]="submitted"
      [items]="roleList"
      appearance="fill"
      [control]="rolesControl"
      [multiple]="true"
      placeholder="Выберите роль*"
      i18n-placeholder
    >
      <mat-error *ngIf="companyControl.invalid">{{ getErrorMessage('roles', formGroup) }}</mat-error>
  </hoho-select>

  <hoho-password-with-repeat
    *ngIf="!entity"
    appearance="fill"
    [mainPasswordControl]="formGroup.get('password')"
    [repeatPasswordControl]="formGroup.get('confirmPassword')"
  ></hoho-password-with-repeat>
</hoho-create-update-dialog>
