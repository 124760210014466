import { EquipmentEnum } from '../enums/equipment.enum';
import { CommunicationLevelEnum } from '../enums/communication-level.enum';
import { FillingLevelEnum } from '../enums/filling-level.enum';
import { MachineStateEnum } from '../enums/machine-state.enum';
import { IVendingMachineBase, LocationFormat } from '@shared/models';
import { EquipmentStateEnum } from '../enums/equipment-state.enum';

export class IInfoCardModel extends IVendingMachineBase {
  currencySymbol: string;
  fundsReceivedAmount: number;
  changeAmount: number;
  cashProceedsAmount: number;
  cashlessProceedsAmount: number;
  machineState: MachineStateEnum;
  ingredientsFillingLevel: FillingLevelEnum;
}

export class IPlacementCardModel extends IVendingMachineBase {
  organization: string;
  placementLocation: string;
  machineState: MachineStateEnum;
  locationFormats: { locationFormat: LocationFormat }[];
}

export class IEquipmentCardModel extends IVendingMachineBase {
  machineState: MachineStateEnum;
  communicationLevel: CommunicationLevelEnum;
  equipmentStates: EquipmentState[];
  downtime: number;
}

export class EquipmentState {
  constructor(public equipment: EquipmentEnum, public state: EquipmentStateEnum, public errors?: string[]) {}
}

export class EquipmentStateWithIcon extends EquipmentState {
  constructor(public equipment: EquipmentEnum, public state: EquipmentStateEnum, public iconName: string) {
    super(equipment, state);
  }
}
