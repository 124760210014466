import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRoutingNames } from '@app/app-routing.module';
import { ListBuilderComponent } from '@app/core/modules/list-builder/list-builder.component';
import { EListBuilderViews, IAccess, IListBuilderProps } from '@app/core/modules/list-builder/models';
import { ICrumb } from '@app/shared/@vex/components/breadcrumbs/breadcrumbs.component';
import { EEntityList, IChangeClientBlock, IServerRequestPayload } from '@app/shared/models';
import { DialogService } from '@app/shared/modules/dialogs/services/dialog.service';
import { DataRestService, ExecuteRestService, UserService } from '@app/shared/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { sl_order, sl_user } from '@src/api';
import { ClientsRoutingNames } from '../../clients.routing';
import { paths } from './page.config';

@UntilDestroy()
@Component({
  selector: 'hoho-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageComponent implements OnInit {
  @ViewChild('tableBuilder') tableBuilderComponent: ListBuilderComponent<sl_order>;
  tableBuilderProps: IListBuilderProps<sl_order> = {
    entity: EEntityList.sl_wallet_operation,
    views: [EListBuilderViews.table],
  };
  orderPayload: IServerRequestPayload;
  clientID: string;
  isLoading: boolean = false;
  crumbs: ICrumb[] = [
    { label: $localize`Клиенты`, link: AppRoutingNames.clients.path },
    { label: $localize`Страница клиента` },
  ];
  client: sl_user;
  inviterLogin: string;
  slClientAccess: IAccess;

  constructor(
    private dataRestService: DataRestService,
    private executeRestService: ExecuteRestService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private userService: UserService,
    private cd: ChangeDetectorRef,
  ) {
    this.activatedRoute.paramMap.subscribe((paramMap) => {
      const id = paramMap.get('id');
      if (id) {
        this.isLoading = true;
        this.clientID = id;
        this.getClient();
      }
    });
  }

  ngOnInit(): void {
    this.clientID = this.activatedRoute.snapshot.params['id'];
    this.getClient();
    this.orderPayload = {
      filter: { 'slWallet.slUser.id': this.clientID }
    };
    this.slClientAccess = this.userService.Access(EEntityList.sl_user);
  }

  changeTab(event: number): void {
    this.router.navigate(['.'], { relativeTo: this.activatedRoute, queryParams: { tabIndex: event } });
  }

  onGoToInviter(): void {
    if (!this.client.slUser) {
      return;
    }
    this.router.navigate([ClientsRoutingNames.clientPage.path + '/' + this.client.slUser?.id]);
  }

  getClient(): void {
    this.isLoading = true;
    this.dataRestService
      .get<sl_user>(EEntityList.sl_user, this.clientID, paths)
      .pipe(untilDestroyed(this))
      .subscribe(
        (res: sl_user) => {
          this.isLoading = false;
          this.client = res;
          this.cd.detectChanges();
          if (!this.client.slUser?.id) {
            return;
          }

          this.dataRestService
            .get<sl_user>(EEntityList.sl_user, this.client.slUser.id.toString(), ['login'])
            .pipe(untilDestroyed(this))
            .subscribe((res: sl_user) => {
              this.inviterLogin = res.login;
            });
        },
        () => {
          this.isLoading = true;
        },
      );
  }

  changeStatus(): void {
    this.dialogService.openConfirm({
      cancelCallback: null,
      okCallback: this.save.bind(this),
      content: $localize`Вы действительно хотите ${
        this.client.isBlocked ? 'разблокировать' : 'заблокировать'
      } пользователя?`,
    });
  }

  private save(): void {
    var data: IChangeClientBlock = {
      clientId: this.client.id,
      isBlocked: !this.client.isBlocked,
    };
    this.isLoading = true;
    this.executeRestService
      .changeClientBlock(data)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.client.isBlocked = !this.client.isBlocked;
          this.isLoading = false;
          this.cd.detectChanges();
        },
        () => {
          this.isLoading = false;
          this.cd.detectChanges();
        },
      );
  }
}
