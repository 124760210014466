import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutingNames } from '@app/app-routing.module';

import RoutePath from '@app/shared/models/route-path.model';
import { QuicklinkModule } from 'ngx-quicklink';
import { SalesAdministrationComponent } from '@app/pages/sales-administration/sales-administration.component';

export const SalesRoutingNames = {
  ordersList: new RoutePath('orders', AppRoutingNames.salesAdministration.path + '/orders'),
  orderPage: new RoutePath('page', AppRoutingNames.salesAdministration.path + '/orders/page', ':id'),
};

const routes: Routes = [
  {
    path: '',
    component: SalesAdministrationComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: SalesRoutingNames.ordersList.path,
      },

      {
        path: SalesRoutingNames.ordersList.name,
        loadChildren: () => import('./modules/orders/orders.module').then((m) => m.OrdersModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule, QuicklinkModule],
})
export class Routing {}
