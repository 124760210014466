import { AppRoutingNames } from '@app/app-routing.module';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EAPIEndpoints, IRequestOptions } from '@shared/models/http.models';
import { AlertService } from '@shared/services/alert.service';
import { environment } from '@environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry, tap } from 'rxjs/operators';
import { removeNullAndUndefinedProperties } from '@shared/utilities';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient, private router: Router, private alertService: AlertService) {}

  public get<T>(endPoint: string, options?: IRequestOptions, apiURL = EAPIEndpoints.BASE): Observable<T> {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    };

    options = Object.assign(
      {
        observe: 'response',
        headers: new HttpHeaders(headers),
      },
      options,
    );
    if (options.params) {
      options.params = removeNullAndUndefinedProperties(options.params);
    }
    return this.http.get<HttpResponse<T>>(environment[apiURL] + '/' + endPoint, options).pipe(
      map((i) => {
        return i.body;
      }),
      catchError((res) => {
        return this.errorHandler(res);
      }),
    );
  }

  public getExternal<T>(endPoint: string, options?: IRequestOptions): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    };

    options = Object.assign(
      {
        observe: 'response',
        headers: new HttpHeaders(headers),
      },
      options,
    );
    if (options.params) {
      options.params = removeNullAndUndefinedProperties(options.params);
    }
    return this.http.get<HttpResponse<T>>(endPoint, options).pipe(
      map((i) => {
        return i.body;
      }),
      catchError((res) => {
        return this.errorHandler(res);
      }),
    );
  }

  public post<T>(endPoint: string, data?: any, options?: IRequestOptions, apiURL = EAPIEndpoints.BASE): Observable<T> {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    };

    options = Object.assign(
      {
        observe: 'response',
        headers: new HttpHeaders(headers),
      },
      options,
    );

    return this.http
      .post<HttpResponse<T>>(environment[apiURL] + '/' + endPoint, removeNullAndUndefinedProperties(data), options)
      .pipe(
        map((i) => i.body as T),
        catchError((res) => {
          return this.errorHandler(res);
        }),
      ) as Observable<T>;
  }

  public postBlob<T>(
    endPoint: string,
    data?: any,
    options?: IRequestOptions,
    apiURL = EAPIEndpoints.BASE,
  ): Observable<T> {
    const headers = {
      'Content-Type': 'application/json',
    };

    options = Object.assign(
      {
        observe: 'response',
        responseType: 'blob',
        headers: new HttpHeaders(headers),
      },
      options,
    );

    return this.http
      .post<HttpResponse<T>>(environment[apiURL] + '/' + endPoint, removeNullAndUndefinedProperties(data), options)
      .pipe(
        map((i) => i.body as T),
        catchError((res) => {
          return Promise.resolve(res)
            .then(async x => {
              let json = JSON.parse(await x.error.text());
              return this.errorHandler(new HttpErrorResponse({
                error: { Message: json.Message },
                status: json.StatusCode
              }));
            });
        }),
      ) as Observable<T>;
  }

  public postFile<T>(
    endPoint: string,
    data?: FormData,
    options?: IRequestOptions,
    apiURL = EAPIEndpoints.BASE,
  ): Observable<HttpEvent<T>> {
    return this.http
      .post<T>(environment[apiURL] + '/' + endPoint, data, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        map((i) => i),
        catchError((res) => {
          return this.errorHandler(res);
        }),
      );
  }

  public postExternal<T>(endPoint: string, data?: any): any {
    return this.http.post<HttpResponse<T>>(endPoint, data).pipe(
      retry(1), // retry a failed request up to 3 times
      catchError((res) => {
        return this.errorHandler(res);
      }),
    );
  }

  public put<T>(
    endPoint: string,
    data?: object,
    options?: IRequestOptions,
    apiURL = EAPIEndpoints.BASE,
  ): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    };

    options = Object.assign(
      {
        observe: 'response',
        headers: new HttpHeaders(headers),
      },
      options,
    );

    return this.http.put<HttpResponse<T>>(environment[apiURL] + '/' + endPoint, data, options).pipe(
      catchError((res) => {
        return this.errorHandler(res);
      }),
    );
  }

  public putExternal<T>(endPoint: string, data?: any): any {
    return this.http.put<HttpResponse<T>>(endPoint, data).pipe(
      catchError((res) => {
        return this.errorHandler(res);
      }),
    );
  }

  public patch<T>(endPoint: string, data?: object, options?: IRequestOptions, apiURL = EAPIEndpoints.BASE): any {
    options = Object.assign(
      {
        observe: 'response',
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }),
      },
      options,
    );
    return this.http.patch<HttpResponse<T>>(environment[apiURL] + '/' + endPoint, data, options).pipe(
      catchError((res) => {
        return this.errorHandler(res);
      }),
    );
  }

  public delete<T>(endPoint: string, options?: IRequestOptions, apiURL = EAPIEndpoints.BASE): any {
    options = Object.assign(
      {
        observe: 'response',
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }),
      },
      options,
    );
    return this.http.delete<HttpResponse<T>>(environment[apiURL] + '/' + endPoint, options).pipe(
      tap((res) => {
        //
      }),
      catchError((res) => {
        return this.errorHandler(res);
      }),
    );
  }

  errorHandler(error: HttpErrorResponse): Observable<never> {
    this.handleError(error, this.router, this.alertService);
    return throwError(() => error.message || 'server error.');
  }

  private handleError(res: HttpErrorResponse, router: Router, alertService: AlertService): any {
    const serverError: { message?: string; Message?: string; StatusCode: number } | null = res.error;
    if (res.status === 403) {
      this.alertService.showError($localize`Доступ запрещен.`);
    }
    if (res.status === 401) {
      router.navigate([AppRoutingNames.login.path]);
      this.alertService.showError(serverError?.message || $localize`Ошибка авторизации.`);
    }
    if (res.status === 404) {
      router.navigate([AppRoutingNames.notFound.path]);
    }

    if (res.status === 400) {
      this.alertService.showError(serverError?.Message || $localize`Ошибка валидации.`);
    }

    if (res.status === 500) {
      router.navigate([AppRoutingNames.serverError.path]);
    }
    return throwError(res);
  }
}

export function httpServiceCreator(http: HttpClient, router: Router, alertService: AlertService): HttpService {
  return new HttpService(http, router, alertService);
}
