import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatRippleModule } from '@angular/material/core';
import { IconModule } from '@visurel/iconify-angular';
import { RouterModule } from '@angular/router';
import { UserModule } from './modules/user/user.module';
import { ToolbarComponent } from './toolbar.component';
import { NavigationItemModule } from '../../components/navigation-item/navigation-item.module';
import { MegaMenuModule } from '../../components/mega-menu/mega-menu.module';
import { ContainerModule } from '../../directives/container/container.module';
import { MatDialogModule } from '@angular/material/dialog';
import { ProfileModule } from '@shared/@vex/layout/toolbar/modules/profile/profile.module';
import { ComponentsModule } from '@shared/components/components.module';

@NgModule({
  declarations: [ToolbarComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatRippleModule,
    UserModule,
    IconModule,
    RouterModule,
    NavigationItemModule,
    MegaMenuModule,
    ContainerModule,
    MatDialogModule,
    ProfileModule,
    ComponentsModule,
  ],
  exports: [ToolbarComponent],
})
export class ToolbarModule {}
