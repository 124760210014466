<form [formGroup]="formGroup">
  <hoho-password-input
    [appearance]="appearance"
    [formControlName]="mainPasswordControlName"
    [placeholder]="mainPasswordPlaceholder"
  ></hoho-password-input>
  <div
    *ngIf="!mainPasswordControl.touched && mainPasswordControl.invalid"
    class="text-body-4 text-californiapl-2.5 relative mb-2 -top-5 px-3"
    i18n
  >
    Пароль должен содержать не менее восьми знаков, включать латинские буквы, цифры и специальные символы .!@_$-+*
  </div>
  <hoho-password-input
    [appearance]="appearance"
    [formControlName]="repeatPasswordControlName"
    [placeholder]="repeatPasswordPlaceholder"
  ></hoho-password-input>
</form>
