import { Component, Input } from '@angular/core';
import { CommunicationLevelEnum } from '../../enums/communication-level.enum';

@Component({
  selector: 'hoho-machine-communication-level',
  templateUrl: './machine-communication-level.component.html',
  styleUrls: ['./machine-communication-level.component.scss'],
})
export class MachineCommunicationLevelComponent {
  @Input() value: CommunicationLevelEnum;
  communicationLevelEnum = CommunicationLevelEnum;

  constructor() {}
}
