<hoho-secondary-header [crumbs]="crumbs" *ngIf="!isLoading" [title]="company?.name">
</hoho-secondary-header>
<div class="mx-gutter mt-5">
  <ng-container *ngIf="!isLoading && company">
    <div>
      <div class="rounded-xl bg-shpongle p-3 mb-5">
        <div class="text-subtitle-3" i18n>Основная информация</div>
      </div>
      <div class="grid lg:grid-cols-2 lg:grid-rows-1 md:grid-cols-1 md:grid-rows-2 lg:gap-4 ml-3">
        <!--Организация-->
        <div class="pb-4 mb-4 border-b border-arizona">
          <div class="text-body-5 mb-2 text-green-4" i18n>Организация</div>
          <div i18n>{{company.name}}</div>
        </div>
        <!--Ответственное лицо-->
        <div class="pb-4 mb-4 border-b border-arizona">
          <div class="text-body-5 mb-2 text-green-4" i18n>Ответственное лицо</div>
          <div i18n>{{company.responsiblePerson}}</div>
        </div>
      </div>
      <div class="grid lg:grid-cols-2 lg:grid-rows-1 md:grid-cols-1 md:grid-rows-2 lg:gap-4 ml-3">
        <!--Адрес-->
        <div class="pb-4 mb-4 border-b border-arizona">
          <div class="text-body-5 mb-2 text-green-4" i18n>Адрес</div>
          <div i18n>{{company.address}}</div>
        </div>
        <!--Количество ТА-->
        <div class="pb-4 mb-4 border-b border-arizona">
          <div class="text-body-5 mb-2 text-green-4" i18n>Количество ТА</div>
          <div i18n>{{company.vendingMachineCount}}</div>
        </div>
      </div>
      <div class="grid lg:grid-cols-2 lg:grid-rows-1 md:grid-cols-1 md:grid-rows-2 lg:gap-4 ml-3">
        <!--ИНН-->
        <div class="pb-4 mb-4 border-b border-arizona">
          <div class="text-body-5 mb-2 text-green-4" i18n>ИНН</div>
          <div i18n>{{company.orgCode}}</div>
        </div>
        <!--Примечание-->
        <div class="pb-4 mb-4 border-b border-arizona">
          <div class="text-body-5 mb-2 text-green-4" i18n>Примечание</div>
          <div i18n>{{company.comment}}</div>
        </div>
      </div>
      <div class="grid lg:grid-cols-2 lg:grid-rows-1 md:grid-cols-1 md:grid-rows-1 lg:gap-4 ml-3">
        <!--Телефон-->
        <div class="pb-4 mb-4 border-b border-arizona">
          <div class="text-body-5 mb-2 text-green-4" i18n>Телефон</div>
          <div i18n>{{company.phone}}</div>
        </div>
        <!--ЛК-->
        <div class="pb-4 mb-4 border-b border-arizona">
          <div class="text-body-5 mb-2 text-green-4" i18n>ЛК</div>
          <div i18n>{{company.lkEnabled ? 'Есть' : 'Нет'}}</div>
        </div>
      </div>
      <div class="text-body-5 mb-4 text-green-4 mx-3 " i18n>Управление</div>
      <div class="grid lg:flex md:grid-cols-2 md:grid-rows-1 gap-4 ml-3">
        <hoho-button class="lg:w-40" [ngClass]="!access?.update ? 'disabled' : ''" (click)="access?.update && edit()"
          iconName="edit" color="margaret" i18n>Редактировать</hoho-button>
        <hoho-button
          *ngIf="permissionsGuard.haveAccess(EModules.DictionariesModule, EDictionariesFeatures.Merge) && !company.isUniqum"
          class="lg:w-40" (click)="access?.update && openMerge()" iconName="edit" color="margaret"
          i18n>Слияние</hoho-button>
        <hoho-button
          *ngIf="permissionsGuard.haveAccess(EModules.DictionariesModule, EDictionariesFeatures.RegisterMarketplacePoint)"
          class="lg:w-40" (click)="access?.update && openMarketplace()" iconName="edit" color="margaret"
          i18n>Сплитование</hoho-button>
        <hoho-button class="lg:w-40" [ngClass]="!access?.delete ? 'disabled' : ''" (click)="access?.delete && remove()"
          iconName="trash-alt" color="california" i18n>Удалить</hoho-button>
      </div>
    </div>
  </ng-container>
</div>
