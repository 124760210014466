import { DateTime } from 'luxon';

export class VmLogResponseModel {
  id: number;
  systemDateTime: DateTime | string;
  isOneTime: boolean;
  isHasEnd: boolean;
  error: {
    code: number;
    description: string;
    hardware: {
      id: number;
      name: string;
    };
  };

  constructor(value?: VmLogResponseModel) {
    this.id = value?.id;
    this.systemDateTime = value?.systemDateTime;
    this.isOneTime = value?.isOneTime;
    this.isHasEnd = value?.isHasEnd;
    this.error = {
      code: value?.error?.code,
      description: value?.error?.description,
      hardware: {
        id: value?.error?.hardware?.id,
        name: value?.error?.hardware?.name,
      },
    };
  }
}
