<div class="flex content-between w-full">
  <div class="text-subtitle-3 flex-grow" mat-dialog-title>{{ data.title }}</div>
  <hoho-icon
    class="ml-4 text-florida hover:text-margaret transition-colors delay-500 ease-in cursor-pointer"
    size="0.875rem"
    iconName="multiply"
    (click)="closeModal()"
  ></hoho-icon>
</div>
<div class="text-body-4 mb-4" [innerHtml]="data.content | safeHtml"></div>
<div class="text-body-4 mb-4" [innerHtml]="data.contentHtml"></div>
<div class="grid grid-cols-2 gap-3">
  <button mat-button (click)="closeModal()">
    {{ data.cancelLabel }}
  </button>
  <button mat-button color="primary" (click)="ok()" cdkFocusInitial>{{ data.okLabel }}</button>
</div>
