import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile.component';
import { ProfileDropdownComponent } from './components/profile-dropdown/profile-dropdown.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { ComponentsModule } from '@shared/components/components.module';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CustomInputsModule } from '@shared/modules/custom-inputs/custom-inputs.module';
import { DirectivesModule } from '@shared/directives/directives.module';

@NgModule({
  declarations: [ProfileComponent, ProfileDropdownComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatDialogModule,
    ComponentsModule,
    MatButtonModule,
    MatInputModule,
    MatDatepickerModule,
    CustomInputsModule,
    DirectivesModule,
  ],
})
export class ProfileModule {}
