<hoho-secondary-header [crumbs]="crumbs" *ngIf="!isLoading" [title]="client?.login"> </hoho-secondary-header>
<div class="mx-gutter mt-5">
  <ng-container *ngIf="!isLoading && client">
    <div>
      <div class="rounded-xl bg-shpongle p-3 mb-5">
        <div class="text-subtitle-3" i18n>Основная информация</div>
      </div>
      <div class="grid lg:grid-cols-2 lg:grid-rows-1 md:grid-cols-1 md:grid-rows-2 lg:gap-4 ml-3">
        <!--ФИО-->
        <div class="pb-4 mb-4 border-b border-arizona">
          <div class="text-body-5 mb-2 text-green-4" i18n>ФИО</div>
          <div>{{ client.login }}</div>
        </div>
        <!--Пригласитель-->
        <div class="pb-4 mb-4 border-b border-arizona">
          <div class="text-body-5 mb-2 text-green-4" i18n>Пригласитель</div>
          <div class="text-margaret cursor-pointer" (click)="onGoToInviter()">{{ inviterLogin }}</div>
        </div>
      </div>
      <div class="grid lg:grid-cols-2 lg:grid-rows-1 md:grid-cols-1 md:grid-rows-2 lg:gap-4 ml-3">
        <!--Email-->
        <div class="pb-4 mb-4 border-b border-arizona">
          <div class="text-body-5 mb-2 text-green-4" i18n>Email</div>
          <div i18n>{{ client.email }}</div>
        </div>
        <!--Дата регистрации-->
        <div class="pb-4 mb-4 border-b border-arizona">
          <div class="text-body-5 mb-2 text-green-4" i18n>Дата регистрации</div>
          <div i18n>{{ client.dateCreated | date }}</div>
        </div>
      </div>
      <div class="grid lg:grid-cols-2 lg:grid-rows-1 md:grid-cols-1 md:grid-rows-2 lg:gap-4 ml-3">
        <!--Телефон-->
        <div class="pb-4 mb-4 border-b border-arizona">
          <div class="text-body-5 mb-2 text-green-4" i18n>Телефон</div>
          <div i18n>{{ client.phoneNumber }}</div>
        </div>
        <!--Кол-во приглашенных клиентов-->
        <div class="pb-4 mb-4 border-b border-arizona">
          <div class="text-body-5 mb-2 text-green-4" i18n>Кол-во приглашенных клиентов</div>
          <div i18n>{{ client.refCount }}</div>
        </div>
      </div>
      <div class="grid lg:grid-cols-2 lg:grid-rows-1 md:grid-cols-1 md:grid-rows-1 lg:gap-4 ml-3">
        <!--Дата рождения -->
        <div class="pb-4 mb-4 border-b border-arizona">
          <div class="text-body-5 mb-2 text-green-4" i18n>Дата рождения</div>
          <div i18n>{{ client.birthday | date }}</div>
        </div>
        <!--Кол-во бонусов на счету-->
        <div class="pb-4 mb-4 border-b border-arizona">
          <div class="text-body-5 mb-2 text-green-4" i18n>Кол-во бонусов на счету</div>
          <div>{{ client.walletBalance }}</div>
          <span *ngIf="client.freezeBalance" class="text-suzanne"
            >({{ client.freezeBalance }} <ng-container>заморожено</ng-container>)</span
          >
        </div>
      </div>
      <div class="grid lg:grid-cols-2 lg:grid-rows-1 md:grid-cols-1 md:grid-rows-1 lg:gap-4 ml-3">
        <!--Пол-->
        <div class="pb-4 mb-4 border-b border-arizona">
          <div class="text-body-5 mb-2 text-green-4" i18n>Пол</div>
          <div>
            <ng-container *ngIf="client.genderType === 1" i18n> Мужской </ng-container>
            <ng-container *ngIf="client.genderType === 0" i18n> Женский </ng-container>
          </div>
        </div>
        <!--Кол-во покупок-->
        <div class="pb-4 mb-4 border-b border-arizona">
          <div class="text-body-5 mb-2 text-green-4" i18n>Кол-во покупок</div>
          <div i18n>{{ client.orderCount }}</div>
        </div>
      </div>
      <div class="grid lg:grid-cols-2 lg:grid-rows-1 md:grid-cols-1 md:grid-rows-1 lg:gap-4 ml-3">
        <!--Город пребывания-->
        <div class="pb-4 mb-4 border-b border-arizona">
          <div class="text-body-5 mb-2 text-green-4" i18n>Город пребывания</div>
          <div i18n>{{ client.cityName }}</div>
        </div>
        <!--Дата последней покупки-->
        <div class="pb-4 mb-4 border-b border-arizona">
          <div class="text-body-5 mb-2 text-green-4" i18n>Дата последней покупки</div>
          <div i18n>{{ client.lastOrderDate | date }}</div>
        </div>
      </div>
      <div class="grid lg:grid-cols-2 lg:grid-rows-1 md:grid-cols-1 md:grid-rows-1 lg:gap-4 ml-3">
        <!--Статус-->
        <div class="pb-4 mb-4 border-b border-arizona">
          <div class="text-body-5 mb-2 text-green-4" i18n>Статус</div>
          <div *ngIf="client.isBlocked" class="text-barbara" i18n>Заблокирован</div>
          <div *ngIf="!client.isBlocked" class="text-tiffany" i18n>Активный</div>
        </div>
      </div>
      <div class="text-body-5 mb-4 text-green-4 mx-3" i18n>Управление</div>
      <div class="grid lg:flex md:grid-cols-2 md:grid-rows-1 gap-4 ml-3">
        <hoho-button
          class="lg:w-40"
          [ngClass]="slClientAccess?.update ? null : 'disabled'"
          *ngIf="!client.isBlocked"
          (click)="slClientAccess?.update && changeStatus()"
          iconName="trash-alt"
          color="barbara"
          i18n
          >Заблокировать</hoho-button
        >
        <hoho-button
          class="lg:w-40"
          [ngClass]="slClientAccess?.update ? null : 'disabled'"
          *ngIf="client.isBlocked"
          (click)="slClientAccess?.update && changeStatus()"
          iconName="trash-alt"
          color="tiffany"
          i18n
          >Разблокировать</hoho-button
        >
      </div>
    </div>
    <h2 class="mt-4 mb-2">История всех операций</h2>
    <hoho-list-builder
      class="mt-5"
      #tableBuilder
      [multiselect]="false"
      [props]="tableBuilderProps"
      [requestPayload]="orderPayload"
      [showExcel]="true"
      [noLink]="true"
      excelFileNameBase="{{ client.login }}_{{ client.id }}"
    >
    </hoho-list-builder>
  </ng-container>
</div>
