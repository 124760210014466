/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { base_entity } from './base_entity';

/**
 * Видео ордера для ручного списания
 */
export type manual_writeoff_basis_video = (base_entity & {
    /**
     * Идентификатор
     */
    id?: string;
    /**
     * Имя файла
     */
    name: string;
    /**
     * Описание
     */
    fileDescription?: string;
    /**
     * Дата создания
     */
    createDateTime?: string;
    /**
     * Идентификатор ордера
     */
    orderId: number;
    /**
     * Ссылка
     */
    readonly url?: string;
    content?: Blob;
    readonly status?: manual_writeoff_basis_video.status;
});

export namespace manual_writeoff_basis_video {

    export enum status {
        '_0' = 0,
        '_1' = 1,
        '_2' = 2,
    }


}

