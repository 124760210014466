import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutingNames } from '@app/app-routing.module';
import RoutePath from '@app/shared/models/route-path.model';
import { QuicklinkModule } from 'ngx-quicklink';
import { SupportComponent } from './support.component';
import { PermissionsGuard } from '@shared/services';
import { EModules } from '@shared/models';

export const SupportRoutingNames = {
  slAppeals: new RoutePath('sl-appeals', AppRoutingNames.support.path + '/sl-appeals'),
  customAppeals: new RoutePath('custom-appeals', AppRoutingNames.support.path + '/custom-appeals'),
  statisticsAppeals: new RoutePath('statistics-appeals', AppRoutingNames.support.path + '/statistics-appeals'),
};
export const SLAppealsRoutingNames = {
  page: new RoutePath('page', SupportRoutingNames.slAppeals.path + '/page', ':id'),
  list: new RoutePath('list', SupportRoutingNames.slAppeals.path + '/list'),
};
export const CustomAppealsRoutingNames = {
  page: new RoutePath('page', SupportRoutingNames.customAppeals.path + '/page', ':id'),
  list: new RoutePath('list', SupportRoutingNames.customAppeals.path + '/list'),
  create: new RoutePath('create', SupportRoutingNames.customAppeals.path + '/create'),
  edit: new RoutePath('edit', SupportRoutingNames.customAppeals.path + '/edit', ':id'),
};
export const StatisticsAppealsRoutingNames = {
  list: new RoutePath('list', SupportRoutingNames.slAppeals.path + '/list'),
};
const routes: Routes = [
  {
    path: '',
    component: SupportComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: SupportRoutingNames.slAppeals.name,
      },
      {
        path: SupportRoutingNames.slAppeals.name,
        loadChildren: () => import('./modules/sl-appeals/sl-appeals.module').then((m) => m.SlAppealsModule),
        canActivate: [PermissionsGuard],
        data: {
          module: EModules.SupportModule,
        },
      },
      {
        path: SupportRoutingNames.customAppeals.name,
        loadChildren: () => import('./modules/custom-appeals/custom-appeals.module').then((m) => m.CustomAppealsModule),
        canActivate: [PermissionsGuard],
        data: {
          module: EModules.SupportModule,
        },
      },
      {
        path: SupportRoutingNames.statisticsAppeals.name,
        loadChildren: () =>
          import('./modules/statistics-appeals/statistics-appeals.module').then((m) => m.StatisticsAppealsModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule, QuicklinkModule],
})
export class Routing {}
