import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { fadeInRight400ms } from '@shared/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from '@shared/@vex/animations/fade-in-up.animation';
import { stagger60ms } from '@shared/@vex/animations/stagger.animation';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'hoho-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  animations: [stagger60ms, fadeInUp400ms, fadeInRight400ms],
})
export class SelectComponent<T> implements OnInit {
  @Input() items: T[];
  @Input() multiple: boolean = false;
  @Input() placeholder: string;
  @Input() bindLabel: string = 'name';
  @Input() bindValue: string = 'id';
  @Input() control: UntypedFormControl = new UntypedFormControl();
  @Input() submitted: boolean = false;
  @Input() searchable: boolean = false;
  @Input() clearable: boolean = true;
  @Input() haveSubscriptWrapper: boolean = true;
  @Input() isOpen: boolean = null;
  @Input() optionTmp: TemplateRef<any>;
  @Input() appendTo: string = null;
  @Input() appearance: 'outline' | 'standard' | 'fill' = 'outline';
  @Input() addTag: boolean = false;
  @Input() disabled: boolean;
  @ViewChild('selectRef') selectRef: NgSelectComponent;
  constructor() {}

  ngOnInit(): void {}

  get canShowError(): boolean {
    return this.control.dirty || this.submitted;
  }

  selectAll() {
    // multiple only!
    this.control.setValue(this.items.map((x: any) => x[this.bindValue]));
  }

  unselectAll() {
    // multiple only!
    this.control.setValue([]);
  }

  setSelectedLabel(label: string): void {
    if (this.selectRef.hasValue) {
      this.selectRef.selectedItems[0].label = label;
    }
  }
}
