/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { mailing_error_mailing_error_category } from '../models/mailing_error_mailing_error_category';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MailingErrorMailingErrorCategoryService {

    /**
     * Select mailing_error_mailing_error_category by id
     * @param id Object ID
     * @returns mailing_error_mailing_error_category Successful operation
     * @throws ApiError
     */
    public static selectMailingErrorMailingErrorCategoryById(
        id: string,
    ): CancelablePromise<mailing_error_mailing_error_category> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/get/object/mailing_error_mailing_error_category/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Save mailing_error_mailing_error_category
     * @param requestBody Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static saveMailingErrorMailingErrorCategory(
        requestBody?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/save/object/mailing_error_mailing_error_category',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Delete mailing_error_mailing_error_category by id
     * @param id Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static deleteMailingErrorMailingErrorCategoryById(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/delete/object/mailing_error_mailing_error_category/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

}
