import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import icNotificationsOff from '@iconify/icons-ic/twotone-notifications-off';
import { UserService } from '@shared/services/user.service';
import { PopoverRef } from '@shared/@vex/components/popover/popover-ref';
import { ICurrentUser } from '@shared/models';
import userCircle from '@iconify/icons-uil/user-circle';
import { ProfileComponent } from '@shared/@vex/layout/toolbar/modules/profile/profile.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'vex-toolbar-user-dropdown',
  templateUrl: './user-dropdown.component.html',
  styleUrls: ['./user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDropdownComponent implements OnInit {
  icNotificationsOff = icNotificationsOff;
  user: ICurrentUser;
  userCircle = userCircle;
  constructor(
    private cd: ChangeDetectorRef,
    private popoverRef: PopoverRef<UserDropdownComponent>,
    private router: Router,
    private userService: UserService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.user = (this.popoverRef.data as unknown) as ICurrentUser;
  }

  logOut() {
    this.userService.logOut();
    this.popoverRef.close();
  }

  openDialog(): void {
    this.dialog.open(ProfileComponent, {
      data: {},
      width: '40rem',
    });
    this.popoverRef.close();
  }
}
