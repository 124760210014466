/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { marketing_push_notification_individual_customers } from '../models/marketing_push_notification_individual_customers';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MarketingPushNotificationIndividualCustomersService {

    /**
     * Select marketing_push_notification_individual_customers by id
     * @param id Object ID
     * @returns marketing_push_notification_individual_customers Successful operation
     * @throws ApiError
     */
    public static selectMarketingPushNotificationIndividualCustomersById(
        id: string,
    ): CancelablePromise<marketing_push_notification_individual_customers> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/get/object/marketing_push_notification_individual_customers/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Save marketing_push_notification_individual_customers
     * @param requestBody Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static saveMarketingPushNotificationIndividualCustomers(
        requestBody?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/save/object/marketing_push_notification_individual_customers',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Delete marketing_push_notification_individual_customers by id
     * @param id Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static deleteMarketingPushNotificationIndividualCustomersById(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/delete/object/marketing_push_notification_individual_customers/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

}
