<div class="toolbar w-full px-gutter flex items-center" vexContainer>
  <button (click)="openSidenav()" *ngIf="mobileQuery" type="button">
   <hoho-icon iconName="bars"></hoho-icon>
  </button>

  <button (click)="toggleCollapse()" *ngIf="!mobileQuery" type="button">
   <hoho-icon [class.rotate-180]="collapsed" class="transform" iconName="menu-fold"></hoho-icon>
  </button>

  <a *ngIf="isVerticalLayout$ | async" [routerLink]="['/']" class="ltr:mr-4 rtl:ml-4 flex items-center">
    <img class="w-8 select-none" src="./assets/img/base/logo_horizontal_black.svg" />
    <h1 *ngIf="!mobileQuery" class="title ltr:pl-4 rtl:pr-4 m-0 select-none opacity-0">Admin</h1>
  </a>
  <span class="flex flex-grow"></span>

  <div class="-mx-1 flex items-center">
    <div class="px-1">
    </div>

    <div class="px-1">

      <vex-toolbar-user></vex-toolbar-user>
    </div>
  </div>
</div>
