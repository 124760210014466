import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private snackBar: MatSnackBar) {}

  public showSuccess(msg: string): void {
    this.snackBar.open(msg, 'OK', {
      duration: 5000,
      horizontalPosition: 'right',
      panelClass: 'success-snack',
    });
  }

  public showInfo(msg: string): void {
    this.snackBar.open(msg, 'OK', {
      duration: 5000,
      horizontalPosition: 'right',
      panelClass: 'neutral-snack',
    });
  }

  public showError(errMsg: string): void {
    this.snackBar.open(errMsg, 'OK', {
      duration: 5000,
      horizontalPosition: 'right',
      panelClass: 'error-snack',
    });
  }
}
