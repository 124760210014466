import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import angleLeftB from '@iconify-icons/uil/angle-left-b';
import angleRightBIcon from '@iconify-icons/uil/angle-right-b';
import angleUpIcon from '@iconify-icons/uil/angle-up';
import angleDownIcon from '@iconify-icons/uil/angle-down';
import arrowFromRightIcon from '@iconify-icons/uil/arrow-from-right';
import arrowRightIcon from '@iconify-icons/uil/arrow-right';
import barsIcon from '@iconify-icons/uil/bars';
import checkIcon from '@iconify-icons/uil/check';
import checkCircle from '@iconify-icons/uil/check-circle';
import clockIcon from '@iconify-icons/uil/clock';
import cogIcon from '@iconify-icons/uil/cog';
import copyIcon from '@iconify-icons/uil/copy';
import creditCardIcon from '@iconify-icons/uil/credit-card';
import editIcon from '@iconify-icons/uil/edit';
import ellipsisVIcon from '@iconify-icons/uil/ellipsis-v';
import exclamationCircle from '@iconify-icons/uil/exclamation-circle';
import eyeIcon from '@iconify-icons/uil/eye';
import eyeSlashIcon from '@iconify-icons/uil/eye-slash';
import filterIcon from '@iconify-icons/uil/filter';
import importIcon from '@iconify-icons/uil/import';
import locationPointIcon from '@iconify-icons/uil/location-point';
import lockAltIcon from '@iconify-icons/uil/lock-alt';
import lockOpenAltIcon from '@iconify-icons/uil/lock-open-alt';
import mapIcon from '@iconify-icons/uil/map';
import mapPinIcon from '@iconify-icons/uil/map-pin';
import moneyBillIcon from '@iconify-icons/uil/money-bill';
import moneyInsert from '@iconify-icons/uil/money-insert';
import moneyInsertIcon from '@iconify-icons/uil/money-insert';
import moneyWithdrawIcon from '@iconify-icons/uil/money-withdraw';
import moneyWithdrawalIcon from '@iconify-icons/uil/money-withdrawal';
import multiplyIcon from '@iconify-icons/uil/multiply';
import redoIcon from '@iconify-icons/uil/redo';
import searchIcon from '@iconify-icons/uil/search';
import signalAlt3Icon from '@iconify-icons/uil/signal-alt-3';
import syncIcon from '@iconify-icons/uil/sync';
import thIcon from '@iconify-icons/uil/th';
import ticketIcon from '@iconify-icons/uil/ticket';
import trashIcon from '@iconify-icons/uil/trash';
import trashAltIcon from '@iconify-icons/uil/trash-alt';
// import userIcon from '@iconify-icons/uil/user';
import usersAltIcon from '@iconify-icons/uil/users-alt';
import waterGlassIcon from '@iconify-icons/uil/water-glass';
import envelopeAltIcon from '@iconify-icons/uil/envelope-alt';
import phoneIcon from '@iconify-icons/uil/phone';
import thumbsUpIcon from '@iconify-icons/uil/thumbs-up';
import thumbsDownIcon from '@iconify-icons/uil/thumbs-down';
import imageUploadIcon from '@iconify-icons/uil/image-upload';
import editAltIcon from '@iconify-icons/uil/edit-alt';
import paperclipIcon from '@iconify-icons/uil/paperclip';
import videoIcon from '@iconify-icons/uil/video';
import plusIcon from '@iconify-icons/uil/plus';
import minusIcon from '@iconify-icons/uil/minus';
import times from '@iconify-icons/uil/times'
import userIcon from '@iconify-icons/uil/user'
import arrowLeft from '@iconify-icons/uil/arrow-left'
import telegram from '@iconify-icons/uil/telegram'


import { IconService } from '@visurel/iconify-angular';

@Injectable({
  providedIn: 'root',
})
export class IconStoreService {
  constructor(
    private iconService: IconService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {
    this.initDefaultIcons();
    this.initCustomIcons();
  }

  public getIcon(name: string): object {
    return this.iconService.get(name);
  }

  public isDefaultIcon(name: string): boolean {
    try {
      this.iconService.get(name);
      return true;
    } catch (error) {
      return false;
    }
  }

  private initDefaultIcons() {
    this.iconService.register('water-glass', waterGlassIcon);
    this.iconService.register('angle-right-b', angleRightBIcon);
    this.iconService.register('money-withdraw', moneyWithdrawIcon);
    this.iconService.register('money-insert', moneyInsertIcon);
    this.iconService.register('money-bill', moneyBillIcon);
    this.iconService.register('moneyInsert', moneyInsert);
    this.iconService.register('credit-card', creditCardIcon);
    this.iconService.register('location-point', locationPointIcon);
    this.iconService.register('angle-left-b', angleLeftB);
    this.iconService.register('map', mapIcon);
    this.iconService.register('th', thIcon);
    this.iconService.register('redo', redoIcon);
    this.iconService.register('sync', syncIcon);
    this.iconService.register('import', importIcon);
    this.iconService.register('arrow-from-right', arrowFromRightIcon);
    this.iconService.register('signal-alt-3', signalAlt3Icon);
    this.iconService.register('money-withdrawal', moneyWithdrawalIcon);
    this.iconService.register('edit', editIcon);
    this.iconService.register('search', searchIcon);
    this.iconService.register('cog', cogIcon);
    this.iconService.register('filter', filterIcon);
    this.iconService.register('check-circle', checkCircle);
    this.iconService.register('exclamation-circle', exclamationCircle);

    this.iconService.register('eye', eyeIcon);
    this.iconService.register('eye-slash', eyeSlashIcon);

    this.iconService.register('multiply', multiplyIcon);
    this.iconService.register('trash-alt', trashAltIcon);

    this.iconService.register('lock-alt', lockAltIcon);
    this.iconService.register('lock-open-alt', lockOpenAltIcon);
    this.iconService.register('check', checkIcon);
    this.iconService.register('ellipsis-v', ellipsisVIcon);
    this.iconService.register('trash', trashIcon);
    this.iconService.register('copy', copyIcon);
    this.iconService.register('bars', barsIcon);
    this.iconService.register('clock', clockIcon);
    this.iconService.register('map-pin', mapPinIcon);
    // this.iconService.register('user', userIcon);
    this.iconService.register('users-alt', usersAltIcon);
    this.iconService.register('ticket', ticketIcon);
    this.iconService.register('angle-up', angleUpIcon);
    this.iconService.register('angle-down', angleDownIcon);
    this.iconService.register('envelope-alt', envelopeAltIcon);
    this.iconService.register('phone', phoneIcon);
    this.iconService.register('thumbs-up', thumbsUpIcon);
    this.iconService.register('thumbs-down', thumbsDownIcon);
    this.iconService.register('arrow-right', arrowRightIcon);
    this.iconService.register('image-upload', imageUploadIcon);
    this.iconService.register('edit-alt', editAltIcon);
    this.iconService.register('paperclip', paperclipIcon);
    this.iconService.register('video', videoIcon);
    this.iconService.register('plus', plusIcon);
    this.iconService.register('minus', minusIcon);
    this.iconService.register('times', times);
    this.iconService.register('user', userIcon);
    this.iconService.register('arrowLeft', arrowLeft);
    this.iconService.register('telegram', telegram);
  }

  private initCustomIcons() {
    this.iconRegistry.addSvgIcon(
      'card-atm',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/img/custom-icons/card-atm.svg'),
    );

    this.iconRegistry.addSvgIcon(
      'coin-withdrawal',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/img/custom-icons/coin-withdrawal.svg'),
    );

    this.iconRegistry.addSvgIcon(
      'receipt',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/img/custom-icons/receipt.svg'),
    );

    this.iconRegistry.addSvgIcon(
      'communication-level-no',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/img/custom-icons/communication-level-no.svg'),
    );

    this.iconRegistry.addSvgIcon(
      'communication-level-low',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/img/custom-icons/communication-level-low.svg'),
    );

    this.iconRegistry.addSvgIcon(
      'communication-level-middle',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/img/custom-icons/communication-level-middle.svg'),
    );

    this.iconRegistry.addSvgIcon(
      'communication-level-high',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/img/custom-icons/communication-level-high.svg'),
    );

    this.iconRegistry.addSvgIcon(
      'calender',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/img/custom-icons/calender.svg'),
    );

    this.iconRegistry.addSvgIcon(
      'grid-row-3',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/img/custom-icons/grid-row-3.svg'),
    );

    this.iconRegistry.addSvgIcon(
      'unblock',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/img/custom-icons/unblock.svg'),
    );

    this.iconRegistry.addSvgIcon(
      'file-type-excel',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/img/custom-icons/file-type-excel.svg'),
    );

    this.iconRegistry.addSvgIcon(
      'pin-angle',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/img/custom-icons/pin-angle.svg'),
    );

    this.iconRegistry.addSvgIcon(
      'pin-angle-fill',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/img/custom-icons/pin-angle-fill.svg'),
    );

    this.iconRegistry.addSvgIcon(
      'file-load',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/img/custom-icons/file-load.svg'),
    );

    this.iconRegistry.addSvgIcon(
      'file-upload-canceled',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/img/custom-icons/file-upload-canceled.svg'),
    );

    this.iconRegistry.addSvgIcon(
      'file-downloaded',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/img/custom-icons/file-downloaded.svg'),
    );

    this.iconRegistry.addSvgIcon(
      'downloads',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/img/custom-icons/downloads.svg'),
    );

    this.iconRegistry.addSvgIcon(
      'scroll-to-top',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/img/custom-icons/scroll-to-top.svg'),
    );

    this.iconRegistry.addSvgIcon(
      'chart',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/img/custom-icons/chart.svg'),
    );

    this.iconRegistry.addSvgIcon(
      'menu-fold',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/img/custom-icons/menu-fold.svg'),
    );

    this.iconRegistry.addSvgIcon(
      'loading',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/img/custom-icons/loading.svg'),
    );

    this.iconRegistry.addSvgIcon(
      'times',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/img/custom-icons/times.svg'),
    );
  }
}
