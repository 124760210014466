<hoho-header title="Справочники" i18n-title>
  <button
    class="sm:my-0 my-3"
    color="primary"
    mat-flat-button
    type="button"
    *ngIf="activeFeature === EDictionariesFeatures.Company"
    (click)="openCreateUpdateCompaniesModal()"
    i18n
    [disabled]="!companyAccess?.create"
  >
    Добавить компанию
  </button>
  <button
    class="sm:my-0 my-3"
    color="primary"
    mat-flat-button
    type="button"
    *ngIf="activeFeature === EDictionariesFeatures.User"
    (click)="openCreateUpdateUserModal()"
    i18n
    [disabled]="!userAccess?.create"
  >
    Добавить пользователя
  </button>

  <button
    class="sm:my-0 my-3"
    color="primary"
    mat-flat-button
    type="button"
    *ngIf="activeFeature === EDictionariesFeatures.Roles"
    (click)="openCreateUpdateRoleModal()"
    i18n
    [disabled]="!roleAccess?.create"
  >
    Добавить роль
  </button>
</hoho-header>

<hoho-feature-tabs
  [module]="EModules.DictionariesModule"
  [activeFeature]="activeFeature"
  [tabsList]="tabsContentList"
  (changeTab)="changeTab($event)"
>
</hoho-feature-tabs>

<ng-template #companies>
  <hoho-companies-list
    [access]="companyAccess"
    #companiesListComponent
    class="block mt-5 px-gutter"
  ></hoho-companies-list>
</ng-template>

<ng-template #users>
  <hoho-users-list [access]="userAccess" #usersListComponent class="block mt-5 px-gutter"></hoho-users-list>
</ng-template>

<ng-template #roles>
  <hoho-roles [access]="roleAccess" #rolesComponent class="block mt-5 px-gutter"></hoho-roles>
</ng-template>
