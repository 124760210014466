<p class="text-body-5 mt-4 mb-1.5 text-green-4" i18n>Состояние</p>
<hoho-machine-state [state]="model.machineState"></hoho-machine-state>

<p class="text-body-5 mb-1.5 text-green-4 mt-4" i18n>Уровень связи</p>
<hoho-machine-communication-level [value]="model.communicationLevel"></hoho-machine-communication-level>

<p class="text-body-5 mb-1.5 text-green-4 mt-4" i18n>Оборудование</p>
<div class="flex items-center">
  <hoho-icon
    *ngFor="let equipment of equipmentStates; let index = index"
    [iconName]="equipment.iconName"
    class="mr-4"
    [className]="
      equipment.state === equipmentStateEnum.Absent 
      ? 'text-colorado' 
      : equipment.state === equipmentStateEnum.WorksProperly
        ? 'text-accept'
        : 'text-error'
    "
    [matTooltip]="equipment | vmEquipmentState"
  ></hoho-icon>
</div>

<p class="text-body-5 mb-1.5 text-green-4 mt-4" i18n>Время простоя за календарный день</p>
<p class="text-body-4">
  {{ model.downtime | secondsToString }}
</p>

<div class="w-full border-b border-gren-1 my-4"></div>
