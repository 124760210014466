import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutingNames, PromoRoutingNames } from '@app/app-routing.module';
import { DirectoryRoutingNames } from '@app/pages/directory/directory.routing';
import { MarketingRoutingNames } from '@app/pages/marketing/marketing.routing';
import { PlanogramsRoutingNames } from '@app/pages/planograms/planograms.routing';
import { ReportsRoutingNames } from '@app/pages/reports/reports.rounting';
import { ServiceMachineRoutingNames } from '@app/pages/service-machines/service-machines.routing';
import { UserRequestsRoutingNames } from '@app/pages/user-requests/user-requests.routing';
import { VendingMachinesRoutingNames } from '@app/pages/vending-machines/vending-machines.routing';
import { environment } from '@environments/environment';
import apps from '@iconify-icons/uil/apps';
import books from '@iconify-icons/uil/books';
import chatBubbleUser from '@iconify-icons/uil/chat-bubble-user';
import clipboardNotes from '@iconify-icons/uil/clipboard-notes';
import moneyInsert from '@iconify-icons/uil/money-insert';

import lifeRing from '@iconify-icons/uil/life-ring';
import robot from '@iconify-icons/uil/robot';
import monitor from '@iconify-icons/uil/monitor';
import houseUser from '@iconify-icons/uil/house-user';
import megaphone from '@iconify-icons/uil/megaphone';
import envelopeStar from '@iconify-icons/uil/envelope-star';

import process from '@iconify-icons/uil/process';
import ticket from '@iconify-icons/uil/ticket';
import usersAlt from '@iconify-icons/uil/users-alt';
import waterGlass from '@iconify-icons/uil/water-glass';
import book from '@iconify-icons/uil/book-open';
import {
  EFeedbackFeatures,
  EMarketingModuleFeatures,
  EPromoFeatures,
  EServiceVendingMachinesFeatures,
  IUserActiveModule,
} from '@shared/models';
import {
  EClientsFeatures,
  EDictionariesFeatures,
  EModules,
  EPersonalAreaFeatures,
  EPlanogramFeatures,
  EReportsFeatures,
  ESalesAdministrationFeatures,
  ESupportModuleFeatures,
  EUserRequestFeatures,
  EVendingMachineFeatures,
} from '@shared/models/module.models';
import { AlertService } from '@shared/services';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  NavigationDropdown,
  NavigationItem,
  NavigationLink,
  NavigationSubheading,
} from '../interfaces/navigation-item.interface';
import { ClientsRoutingNames } from '@app/pages/clients/clients.routing';
import { FeedbackRoutingNames } from '@app/pages/feedback/feedback.routing';
import { SupportRoutingNames } from '@app/pages/support/support.routing';
import { SalesRoutingNames } from '@app/pages/sales-administration/sales-administration.routing';
import { PersonalAreaRoutingNames } from '@app/pages/personal-area/personal-area.routing';
import { KnowledgeBaseComponent } from '@app/pages/static/knowledge-base/knowledge-base.component';
import { KnowledgeBaseModule } from '@app/pages/static/knowledge-base/knowledge-base.module';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  items: NavigationItem[] = [
    {
      type: 'link',
      label: $localize`Мониторинг`,
      icon: monitor,
      route: AppRoutingNames.monitoring.path,
      module: EModules.MonitoringModule,
    },
		{
      type: 'dropdown',
      label: 'Личный кабинет',
      icon: houseUser,
      route: AppRoutingNames.personalArea.path,
      module: EModules.PersonalAreaModule,
      badge: {
        value$: new BehaviorSubject<string>(' ! '),
        bgClass: 'bg-barbara whitespace-pre',
        textClass: 'text-natasha',
      },
			children: [
				{
          type: 'link',
          label: 'Главная',
          route: PersonalAreaRoutingNames.main.path,
          module: EModules.PersonalAreaModule,
          // feature: EPersonalAreaFeatures.Main,
        },
				{
          type: 'link',
          label: 'Счета',
          route: PersonalAreaRoutingNames.invoices.path,
          module: EModules.PersonalAreaModule,
          // feature: EPersonalAreaFeatures.Invoices,
        },
			]
    },
    {
      type: 'dropdown',
      label: $localize`Мои автоматы`,
      icon: waterGlass,
      route: AppRoutingNames.vendingMachines.path,
      module: EModules.VendingMachineModule,
      children: [
        {
          type: 'link',
          label: $localize`Информация`,
          route: VendingMachinesRoutingNames.information.path,
          module: EModules.VendingMachineModule,
          feature: EVendingMachineFeatures.Information,
        },
        {
          type: 'link',
          label: $localize`Состояние автоматов`,
          route: VendingMachinesRoutingNames.state.path,
          module: EModules.VendingMachineModule,
          feature: EVendingMachineFeatures.State,
        },
        {
          type: 'link',
          label: $localize`Список`,
          route: VendingMachinesRoutingNames.list.path,
          module: EModules.VendingMachineModule,
          feature: EVendingMachineFeatures.List,
        },
      ],
    },
    {
      type: 'dropdown',
      label: $localize`Отчеты`,
      icon: clipboardNotes,
      route: AppRoutingNames.reports.path,
      module: EModules.ReportsModule,
      children: [
        {
          type: 'link',
          label: $localize`По продажам`,
          route: ReportsRoutingNames.sales.path,
          module: EModules.ReportsModule,
          feature: EReportsFeatures.BySale,
        },
        {
          type: 'link',
          label: $localize`По состоянию`,
          route: ReportsRoutingNames.states.path,
          module: EModules.ReportsModule,
          feature: EReportsFeatures.ByState,
        },
        {
          type: 'link',
          label: $localize`Автоматические отчеты`,
          route: ReportsRoutingNames.auto.path,
          module: EModules.ReportsModule,
          feature: EReportsFeatures.AutoReports,
        },
        {
          type: 'link',
          label: $localize`Другие отчеты`,
          route: ReportsRoutingNames.other.path,
          module: EModules.ReportsModule,
          feature: EReportsFeatures.OtherReports,
        },
      ],
    },
    {
      type: 'dropdown',
      label: $localize`Справочники`,
      icon: books,
      route: 'directory',
      module: EModules.DictionariesModule,
      children: [
        {
          type: 'link',
          label: $localize`Компании`,
          route: DirectoryRoutingNames.companies.path,
          module: EModules.DictionariesModule,
          feature: EDictionariesFeatures.Company,
        },
        {
          type: 'link',
          label: $localize`Пользователи`,
          route: DirectoryRoutingNames.users.path,
          module: EModules.DictionariesModule,
          feature: EDictionariesFeatures.User,
        },
        {
          type: 'link',
          label: $localize`Роли`,
          route: DirectoryRoutingNames.roles.path,
          module: EModules.DictionariesModule,
          feature: EDictionariesFeatures.Roles,
        },
      ],
    },
    {
      type: 'dropdown',
      label: $localize`Заявки`,
      icon: chatBubbleUser,
      route: AppRoutingNames.userRequests.path,
      module: EModules.UserRequestModule,
      badge: {
        value$: new BehaviorSubject<string>(''),
        bgClass: 'bg-barbara',
        textClass: 'text-natasha',
      },
      children: [
        {
          type: 'link',
          label: $localize`Новые заявки`,
          route: UserRequestsRoutingNames.new.path,
          module: EModules.UserRequestModule,
          feature: EUserRequestFeatures.New,
        },
        {
          type: 'link',
          label: $localize`Заблокированные`,
          route: UserRequestsRoutingNames.blocked.path,
          module: EModules.UserRequestModule,
          feature: EUserRequestFeatures.Blocked,
        },
        {
          type: 'link',
          label: $localize`Неподтвержденные`,
          route: UserRequestsRoutingNames.unconfirmed.path,
          module: EModules.UserRequestModule,
          feature: EUserRequestFeatures.EmailNotConfirmed,
        },
      ],
    },
    {
      type: 'dropdown',
      label: $localize`Планограммы`,
      icon: apps,
      route: AppRoutingNames.planograms.path,
      module: EModules.PlanogrammModule,
      children: [
        {
          type: 'link',
          label: $localize`Товары`,
          route: PlanogramsRoutingNames.products.path,
          module: EModules.PlanogrammModule,
          feature: EPlanogramFeatures.Product,
        },
        {
          type: 'link',
          label: $localize`Ингредиенты`,
          route: PlanogramsRoutingNames.ingredients.path,
          module: EModules.PlanogrammModule,
          feature: EPlanogramFeatures.Ingredient,
        },
        {
          type: 'link',
          label: $localize`Товарные матрицы`,
          route: PlanogramsRoutingNames.productMatrices.path,
          module: EModules.PlanogrammModule,
          feature: EPlanogramFeatures.ProductMatrix,
        },
        {
          type: 'link',
          label: $localize`Рецепты`,
          route: PlanogramsRoutingNames.recipes.path,
          module: EModules.PlanogrammModule,
          feature: EPlanogramFeatures.Recipe,
        },
        {
          type: 'link',
          label: $localize`Прайс-листы`,
          route: PlanogramsRoutingNames.prices.path,
          module: EModules.PlanogrammModule,
          feature: EPlanogramFeatures.PriceLists,
        },
      ],
    },
    {
      type: 'dropdown',
      label: $localize`Акции`,
      icon: ticket,
      route: AppRoutingNames.promo.path,
      module: EModules.PromoModule,
      children: [
        {
          type: 'link',
          label: $localize`Промо`,
          route: PromoRoutingNames.promocodes.path,
          module: EModules.PromoModule,
          feature: EPromoFeatures.Promocodes,
        },
        {
          type: 'link',
          label: $localize`Купоны`,
          route: PromoRoutingNames.coupons.path,
          module: EModules.PromoModule,
          feature: EPromoFeatures.Coupons,
        },
      ],
    },
    {
      type: 'dropdown',
      label: $localize`Клиенты`,
      icon: usersAlt,
      route: AppRoutingNames.clients.path,
      module: EModules.ClientsModule,
      children: [
        {
          type: 'link',
          label: $localize`Список клиентов`,
          route: ClientsRoutingNames.list.path,
          module: EModules.ClientsModule,
          feature: EClientsFeatures.Card,
        },
        {
          type: 'link',
          label: $localize`Продажи`,
          route: ClientsRoutingNames.orders.path,
          module: EModules.ClientsModule,
          feature: EClientsFeatures.Card,
        },
      ],
    },
    {
      type: 'dropdown',
      label: $localize`Обслуживание аппаратов`,
      icon: process,
      route: AppRoutingNames.serviceMachine.path,
      module: EModules.ServiceVendingMachines,
      children: [
        {
          type: 'link',
          label: $localize`Маршруты`,
          route: ServiceMachineRoutingNames.routes.path,
          module: EModules.ServiceVendingMachines,
          feature: EServiceVendingMachinesFeatures.Routes,
        },
        {
          type: 'link',
          label: $localize`Инкассирование`,
          route: ServiceMachineRoutingNames.encashment.path,
          module: EModules.ServiceVendingMachines,
          feature: EServiceVendingMachinesFeatures.Encashment,
        },
        {
          type: 'link',
          label: $localize`Загрузка ингредиентов`,
          route: ServiceMachineRoutingNames.loadingIngredients.path,
          module: EModules.ServiceVendingMachines,
          feature: EServiceVendingMachinesFeatures.LoadingIngredients,
        },
      ],
    },

    {
      type: 'dropdown',
      label: $localize`Обратная связь`,
      icon: envelopeStar,
      route: AppRoutingNames.feedback.path,
      module: EModules.FeedbackModule,
      children: [
        {
          type: 'link',
          label: $localize`График`,
          route: FeedbackRoutingNames.dashboard.path,
          module: EModules.FeedbackModule,
          feature: EFeedbackFeatures.Chart,
        },
        {
          type: 'link',
          label: $localize`Таблица`,
          route: FeedbackRoutingNames.table.path,
          module: EModules.FeedbackModule,
          feature: EFeedbackFeatures.Table,
        },
        {
          type: 'link',
          label: $localize`Отзывы`,
          route: FeedbackRoutingNames.reviews.path,
          module: EModules.FeedbackModule,
          feature: EFeedbackFeatures.Reviews,
        },
      ],
    },

    {
      type: 'dropdown',
      label: $localize`Маркетинг`,
      icon: megaphone,
      route: AppRoutingNames.marketing.path,
      module: EModules.MarketingModule,
      badge: {
        value$: new BehaviorSubject<string>(''),
        bgClass: 'bg-barbara',
        textClass: 'text-natasha',
      },
      children: [
        {
          type: 'link',
          label: $localize`Push-уведомления`,
          route: MarketingRoutingNames.push.path,
          module: EModules.MarketingModule,
          feature: EMarketingModuleFeatures.PushNotifications,
        },
        {
          type: 'link',
          label: $localize`Группы клиентов`,
          route: MarketingRoutingNames.group.path,
          module: EModules.MarketingModule,
          feature: EMarketingModuleFeatures.GroupUsers,
        },
        {
          type: 'link',
          label: $localize`Stories`,
          route: MarketingRoutingNames.stories.path,
          module: EModules.MarketingModule,
          feature: EMarketingModuleFeatures.Stories,
        },
      ],
    },
    {
      type: 'dropdown',
      label: $localize`Тех.поддержка`,
      icon: lifeRing,
      route: AppRoutingNames.support.path,
      module: EModules.SupportModule,
      children: [
        {
          type: 'link',
          label: $localize`Заявки СЛ`,
          route: SupportRoutingNames.slAppeals.path,
          module: EModules.SupportModule,
          feature: ESupportModuleFeatures.SlAppeals,
        },
        {
          type: 'link',
          label: $localize`Прочие заявки`,
          route: SupportRoutingNames.customAppeals.path,
          module: EModules.SupportModule,
          feature: ESupportModuleFeatures.CustomAppeals,
        },
        {
          type: 'link',
          label: $localize`Статистика`,
          route: SupportRoutingNames.statisticsAppeals.path,
          module: EModules.SupportModule,
          feature: ESupportModuleFeatures.StatisticsAppeals,
        },
      ],
    },
    {
      type: 'link',
      label: $localize`Чат-бот`,
      icon: robot,
      route: AppRoutingNames.chatBot.path,
      module: EModules.MailingErrorModule,
    },
    {
      type: 'link',
      label: 'База знаний',
      icon: book,
      route: AppRoutingNames.knowledgeBase.path,
      module: EModules.PersonalAreaModule,
    },
  ];

  private _openChangeSubject = new Subject<NavigationDropdown>();

  openChange$ = this._openChangeSubject.asObservable();

  constructor(private router: Router, private alertService: AlertService) {
    if (!environment.production) {
      this.items.push({
        type: 'dropdown',
        label: $localize`Админ. продаж`,
        icon: moneyInsert,
        route: AppRoutingNames.salesAdministration.path,
        module: EModules.SalesAdministrationModule,
        children: [
          {
            type: 'link',
            label: $localize`Ручное списание`,
            route: SalesRoutingNames.ordersList.path,
            module: EModules.SalesAdministrationModule,
            feature: ESalesAdministrationFeatures.ManualWriteOff,
          },
        ],
      });
    }
  }

  triggerOpenChange(item: NavigationDropdown) {
    this._openChangeSubject.next(item);
  }

  isLink(item: NavigationItem): item is NavigationLink {
    return item.type === 'link';
  }

  isDropdown(item: NavigationItem): item is NavigationDropdown {
    return item.type === 'dropdown';
  }

  isSubheading(item: NavigationItem): item is NavigationSubheading {
    return item.type === 'subheading';
  }

  goToDefaultUrl(modules: IUserActiveModule[]) {
    const allowedPath = this.items.find((i) => {
      const link = i as NavigationLink | NavigationDropdown;
      return !!modules.find((j: IUserActiveModule) => j.module === link.module);
    }) as NavigationLink | NavigationDropdown;
    if (allowedPath) {
      this.router.navigate([allowedPath.route]);
      return;
    }
    this.alertService.showError($localize`Доступ запрещен. Обратитесь к руководству.`);
  }
}
