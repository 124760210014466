import { IAccess } from '@core/modules/list-builder/models';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DirectoryRoutingNames } from '@app/pages/directory/directory.routing';
import { CreateUpdateCompaniesComponent } from '@app/pages/directory/modules/companies/components/create-update-companies/create-update-companies.component';
import { ListComponent as CompaniesListComponent } from '@app/pages/directory/modules/companies/components/list/list.component';
import { CreateUpdateRoleComponent } from '@app/pages/directory/modules/roles/components/create-update-companies/create-update-role.component';
import { CreateUpdateUsersComponent } from '@app/pages/directory/modules/users/components/create-update-users/create-update-users.component';
import { ListComponent as UsersListComponent } from '@app/pages/directory/modules/users/components/list/list.component';
import { RolesComponent } from '@app/pages/directory/modules/roles/roles.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ITabContent } from '@shared/components/feature-tabs/feature-tabs.models';
import { EDictionariesFeatures, EEntityList, EModules, ModuleFeatureType } from '@shared/models';
import { UserService } from '@shared/services';

@UntilDestroy()
@Component({
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent implements OnInit, AfterViewInit {
  EDictionariesFeatures = EDictionariesFeatures;
  EModules = EModules;
  activeFeature: EDictionariesFeatures;
  tabsContentList: ITabContent[] = [];

  companyAccess: IAccess;
  userAccess: IAccess;
  roleAccess: IAccess;

  @ViewChild('companiesListComponent') companiesListComponent: CompaniesListComponent;
  @ViewChild('usersListComponent') usersListComponent: UsersListComponent;
  @ViewChild('rolesComponent') rolesComponent: RolesComponent;

  @ViewChild('companies') companiesTemp: TemplateRef<HTMLElement>;
  @ViewChild('users') usersTemp: TemplateRef<HTMLElement>;
  @ViewChild('roles') rolesTemp: TemplateRef<HTMLElement>;
  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res.tab) {
        switch (res.tab) {
          case DirectoryRoutingNames.roles.name:
            this.activeFeature = EDictionariesFeatures.Roles;
            break;
          case DirectoryRoutingNames.companies.name:
            this.activeFeature = EDictionariesFeatures.Company;
            break;
          case DirectoryRoutingNames.users.name:
            this.activeFeature = EDictionariesFeatures.User;
            break;
        }
        this.cd.markForCheck();
      }
    });
    this.companyAccess = this.userService.Access(EEntityList.company);
    this.userAccess = this.userService.Access(EEntityList.users);
    this.roleAccess = this.userService.Access(EEntityList.role);
  }

  ngAfterViewInit(): void {
    this.tabsContentList = [
      { name: $localize`Компании`, template: this.companiesTemp, feature: EDictionariesFeatures.Company },
      { name: $localize`Пользователи`, template: this.usersTemp, feature: EDictionariesFeatures.User },
      { name: $localize`Роли`, template: this.rolesTemp, feature: EDictionariesFeatures.Roles },
    ];
  }

  changeTab(feat: ModuleFeatureType): void {
    let url: string;
    switch (feat) {
      case EDictionariesFeatures.Company:
        url = DirectoryRoutingNames.companies.path;
        break;
      case EDictionariesFeatures.User:
        url = DirectoryRoutingNames.users.path;
        break;
      case EDictionariesFeatures.Roles:
        url = DirectoryRoutingNames.roles.path;
        break;
    }

    this.companiesListComponent?.tableBuilderComponent?.searchCtrl?.setValue(null);
    this.usersListComponent?.tableBuilderComponent?.searchCtrl?.setValue(null);

    this.router.navigate([url], { replaceUrl: true });
  }

  openCreateUpdateCompaniesModal(): void {
    const dialogRef = this.dialog.open(CreateUpdateCompaniesComponent, {
      width: '20rem',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((isSuccess: boolean) => {
      if (isSuccess) {
        this.companiesListComponent.tableBuilderComponent.reload();
      }
    });
  }

  openCreateUpdateUserModal(): void {
    const dialogRef = this.dialog.open(CreateUpdateUsersComponent, {
      width: '20rem',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((isSuccess: boolean) => {
      if (isSuccess) {
        this.usersListComponent.tableBuilderComponent.reload();
      }
    });
  }

  openCreateUpdateRoleModal(): void {
    const dialogRef = this.dialog.open(CreateUpdateRoleComponent, {
      width: '20rem',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((isSuccess: boolean) => {
      if (isSuccess) {
        this.rolesComponent.rolesListComponent.tableBuilderComponent.reload();
      }
    });
  }
}
