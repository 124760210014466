<div
  class="flex items-center cursor-pointer px-2"
  (click)="showPopover(originRef)"
  #originRef
  [class.bg-hover]="dropdownOpen"
>
  <div
    class="mr-4 text-sm cursor-pointer rounded-lg h-11 w-11 flex items-center center justify-center trans-ease-out text-primary bg-shpongle hover:bg-hover"
  >
    <mat-icon class="w-4 h-4" height="1rem" width="1rem" [icIcon]="userCircle"></mat-icon>
  </div>
  <div>
    <div class="text-body-3">{{ user?.lastName }} {{ user?.firstName }} {{ user?.middleName }}</div>
    <div class="text-texas text-body-4">{{ user?.roles[0].roleName }}</div>
  </div>
</div>
