import { Type } from '@angular/core';
import { EEntityList } from '@shared/models';

export enum EAggregationsList {
  avg = 4,
  sum = 3,
  max = 2,
  min = 1,
}

export interface IServerGridColumn {
  colDescr: string;
  colOrder: number;
  colPath: string;
  formatType?: number;
  id?: string;
  composite?: boolean;
  isSorted?: boolean;
  isVisible?: boolean;
  isVisibleTemp?: boolean;
  sortable?: boolean;
  sortDirection?: number;
  sortOrder?: number;
  isEditable?: boolean;
  formatTemplate?: EColumnComponentsList;
  filterTemplate?: EFilterTemplateList;
  filterOrder?: number;
  filterType?: number;
  aggregation?: EAggregationsList;
  filter?: IDataRequestFilter;
  uqo?: IUQO;
}

export interface IDataRequestFilter {
  entity: EEntityList;
  multi_value: boolean;
  name: string;
  parameter_type: EFilterParametersTypes;
  type?: EFilterTypes;
  required: boolean;
  enumerable?: IEnumerableItem[];
}

export enum EFilterParametersTypes {
  Entity = 'Entity',
  Const = 'Const',
  Int32 = 'Int32',
  Choice = 'Choice',
}
export enum EFilterTypes {
  DateTime = 'DateTime',
  String = 'String',
  Int64 = 'Int64',
}

export enum EFilterTemplateList {
  modal = 'modal',
}

export interface IUQO {
  name?: string;
  required?: boolean;
  parameter_type: string;
  type: string;
  multi_value: boolean;
  enumerable?: IEnumerableItem[];
}

export interface IGridColumnComponents extends IServerGridColumn {
  columnComponent?: Type<any>;
  filterComponent: Type<any>;
  additionalPaths: string[];
  isSortable: boolean;
}

export interface IEnumerableItem {
  name: string;
  id: number | string;
}

export enum EColumnComponentsList {
  SimpleTextComponent,
  AddressComponent,
  StatusComponent,
  EventsComponent,
  LoadingComponent,
  MobileOperatorComponent,
  SendToWorkVendingMachineComponent,
  VmFounds = 'VmFounds',
  VmRevenuePerDay = 'VmRevenuePerDay',
  VmStatus = 'VmStatus',
  VmIngredientFill = 'VmIngredientFill',
  VmConnectionLevel = 'VmConnectionLevel',
  VmEquipment = 'VmEquipment',
  VmDowntime = 'VmDowntime',
  unit = 'unit',
  enum = 'enum',
  boolean = 'boolean',
  Actions = 'actions',
  ProductMatrixSlots = 'ProductMatrixSlots',
  ProductMatrixName = 'ProductMatrixName',
  PersonFio = 'personFio',
  PersonFioWithLink = 'personFioWithLink',
  ClientFioWithLink = 'clientFioWithLink',
  LimitedText = 'limitedText',
  CompanyNameWithLink = 'companyNameWithLink',
  PromocodeType = 'PromocodeType',
  PromocodeStatus = 'PromocodeStatus',
  ClientStatus = 'ClientStatus',
  nameWithExecutionTime = 'nameWithExecutionTime',
  responsiblePersons = 'responsiblePersons',
  waypointOrderNumber = 'waypointOrderNumber',
  waypointNameWithLink = 'waypointNameWithLink',
  waypointExecutionTime = 'waypointExecutionTime',
  coordinates = 'coordinates',
  waypointStatus = 'waypointStatus',
  vmNameWithIngredientFillLink = 'vmNameWithIngredientFillLink',
  ingredientsLeftPercent = 'ingredientsLeftPercent',
  vmNameWithCollectionLink = 'vmNameWithCollectionLink',
  PriceListName = 'PriceListName',
  RoleNameWithLink = 'roleNameWithLink',
  RoleStatusWithActions = 'roleStatusWithActions',
  weekDays = 'weekDays',
  vendReceipt = 'vendReceipt',
  reportMenuItem = 'reportMenuItem',
  marketingGroupNameWithLink = 'marketingGroupNameWithLink',
  numberOfPercent = 'numberOfPercent',
  pushNotificationCustomers = 'pushNotificationCustomers',
  orderId = 'orderId',
  operationOrderId = 'operationOrderId',
  orderFio = 'orderFio',
  orderStatus = 'orderStatus',
  vmNameWithLink = 'vmNameWithLink',
  IsLiked = 'IsLiked',
  AbsoluteDateTime = 'AbsoluteDateTime',
  supportAppealStatus = 'supportAppealStatus',
  customSupportAppealStatus = 'customSupportAppealStatus',
  goodsCountInOrder = 'goodsCountInOrder',
  downloadInvoiceFile = 'downloadInvoiceFile',
}

export enum EFilterComponentsList {
  single = 'single',
}

export interface IGroupRowItem<T> {
  row: T;
  count: number;
  sum?: T;
  min?: T;
  max?: T;
  avg?: T;
}
