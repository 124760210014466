import { Injectable } from '@angular/core';
import { ICheckTelegramBotUserRequestModel } from '@app/shared/models/telegram-bot-users.models';
import { YaMapGeocodeResponse, YaMapSuggestResponse } from '@app/shared/modules/maps/maps.models';
import { IChangeClientBlock, ICompleteWaypoint, IProductMatrixApply } from '@shared/models';
import { HttpService } from '@shared/services/http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExecuteRestService {
  constructor(private http: HttpService) {}

  public exitOnRoute(id: string): Observable<void> {
    const data = {
      serviceRouteId: id,
    };
    return this.http.post<void>(`execute/process/ExitOnRoute`, data);
  }

  public startExecuteWaypoint(id: string): Observable<void> {
    const data = {
      waypointId: id,
    };
    return this.http.post<void>(`execute/process/StartExecuteWaypoint`, data);
  }

  public completeWaypoint(data: ICompleteWaypoint): Observable<void> {
    return this.http.post<void>(`execute/process/CompleteWaypoint`, data);
  }

  public productMatrixApply(data: IProductMatrixApply): Observable<void> {
    return this.http.post<void>(`execute/process/ProductMatrixApply`, data);
  }

  public changeClientBlock(data: IChangeClientBlock): Observable<void> {
    return this.http.post<void>(`execute/process/ChangeClientBlock`, data);
  }

  public checkTelegramBotUser(model: ICheckTelegramBotUserRequestModel): Observable<string> {
    return this.http.post<string>(`execute/process/CheckTelegramBotUser`, model);
  }

}
