import { EEntityList, IEntity, IVendingMachineBase } from '@shared/models';
import { vending_machine } from '@src/api';
import { IVendingMachineResponse } from '../components/create-update-group/create-update-group.models';

export interface IVMGroup {
  id: string;
  name: string;
  vendingMachines: Pick<IVendingMachineBase, 'id' | 'name' | 'address'>[];
}
export class VMGroup {
  id: string;
  name: string;
  vmCount: number;
}
export class VM implements IEntity {
  entity: EEntityList;
  id: string | number;
  displayName?: string;

  constructor(id: string) {
    this.entity = EEntityList.vending_machine;
    this.id = id;
  }
}
export class VMInGroup implements IEntity {
  entity: EEntityList;
  id: string | number;
  displayName?: string;
  vendingMachine: VM;

  constructor(vm: VM) {
    this.entity = EEntityList.machine_in_group;
    this.vendingMachine = vm;
  }
}
export class SaveVMGroup extends VMGroup implements IEntity {
  id: string;
  name: string;
  entity: EEntityList;
  displayName?: string;
  machines: VMInGroup[];

  constructor(name: string, vm: vending_machine[], id?: string) {
    super();
    this.entity = EEntityList.vm_group;
    this.id = id;
    this.name = name;
    this.machines = [];
    vm.forEach((element) => {
      this.machines.push(new VMInGroup(new VM(element.id)));
    });
  }
}

export class VMGroupWithMachines {
  id: string;
  name: string;
  machines: MInGroup[];
}

export class MInGroup {
  id: string;
  vendingMachine: IVendingMachineResponse;
}
