import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, LOCALE_ID, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Event, Navigation, NavigationStart, Router, RouterEvent } from '@angular/router';

import { NgSelectConfig } from '@ng-select/ng-select';
import { ConfigName } from '@shared/@vex/interfaces/config-name.model';
import { NavigationDropdown } from '@shared/@vex/interfaces/navigation-item.interface';
import { ConfigService } from '@shared/@vex/services/config.service';
import { LayoutService } from '@shared/@vex/services/layout.service';
import { NavigationService } from '@shared/@vex/services/navigation.service';
import { SplashScreenService } from '@shared/@vex/services/splash-screen.service';
import { Style, StyleService } from '@shared/@vex/services/style.service';
import { UserRequestService } from '@shared/services/user-request.service';
import { UserService } from '@shared/services/user.service';
import { Settings } from 'luxon';
import * as moment from 'moment';
import { filter, map, pairwise, tap } from 'rxjs/operators';
import { AppRoutingNames } from './app-routing.module';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private configService: ConfigService,
    private styleService: StyleService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    protected layoutService: LayoutService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    // Не убирать!
    protected splashScreenService: SplashScreenService,
    private router: Router,
    private userService: UserService,
    private config: NgSelectConfig,
    private matDialogRef: MatDialog,
    public userRequestService: UserRequestService,
  ) {
    moment.locale('ru');
    this.userService.loadUser();

    this.config.notFoundText = $localize`Ничего не найдено`;

    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.route.queryParamMap
      .pipe(map((queryParamMap) => queryParamMap.has('rtl') && coerceBooleanProperty(queryParamMap.get('rtl'))))
      .subscribe((isRtl) => {
        this.document.body.dir = isRtl ? 'rtl' : 'ltr';
        this.configService.updateConfig({
          rtl: isRtl,
        });
      });

    this.route.queryParamMap
      .pipe(filter((queryParamMap) => queryParamMap.has('layout')))
      .subscribe((queryParamMap) => this.configService.setConfig(queryParamMap.get('layout') as ConfigName));

    this.route.queryParamMap
      .pipe(filter((queryParamMap) => queryParamMap.has('style')))
      .subscribe((queryParamMap) => this.styleService.setStyle(queryParamMap.get('style') as Style));

    this.router.events
      .pipe(
        filter((event: RouterEvent | Event) => {
          return event instanceof NavigationStart;
        }),
        pairwise(),
      )
      .subscribe((events: any[]) => {
        if (events.length == 2) {
          var previous = events[0].url;
          var current = events[1].url;
          if (previous.split('?')[0] != current.split('?')[0]) {
            this.matDialogRef.closeAll();
          }
        } else {
          this.matDialogRef.closeAll();
        }
      });

    this.userRequestService.requests$.subscribe((x) => {
      const targetNavDropdown = this.navigationService.items.find(
        (x) => (x as NavigationDropdown).route === AppRoutingNames.userRequests.path,
      ) as NavigationDropdown;
      if (targetNavDropdown.route === AppRoutingNames.userRequests.path) {
        let newRequestsValue: string;
        if (!x || !x.newCount) {
          newRequestsValue = '';
        } else if (x.newCount > 99) {
          newRequestsValue = '99+';
        } else {
          newRequestsValue = x.newCount.toString();
        }
        targetNavDropdown.badge.value$.next(newRequestsValue);
      }
      if (targetNavDropdown) {
        const dropdownItem = targetNavDropdown as NavigationDropdown;
        const newRequestItem = dropdownItem.children.find((x) => {
          return x.route === AppRoutingNames.reports.path;
        });

        let newRequestsValue: string;
        if (!x || !x.newCount) {
          newRequestsValue = '';
        } else if (x.newCount > 99) {
          newRequestsValue = '99+';
        } else {
          newRequestsValue = x.newCount.toString();
        }
        newRequestItem && newRequestItem.badge.value$.next(newRequestsValue);
      }
    });
  }
}
