import { ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRoutingNames } from '@app/app-routing.module';
import { IMarketingCustomerGroup } from '@app/pages/marketing/components/group-page/group-page.models';
import { MarketingRoutingNames } from '@app/pages/marketing/marketing.routing';
import { ListBuilderComponent } from '@core/modules/list-builder/list-builder.component';
import { EListBuilderViews, IListBuilderProps } from '@core/modules/list-builder/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ICrumb } from '@shared/@vex/components/breadcrumbs/breadcrumbs.component';
import { EEntityList, IServerRequestPayload } from '@shared/models';
import { DialogService } from '@shared/modules/dialogs/services/dialog.service';
import { DataRestService } from '@shared/services';
import { getErrorMessage } from '@shared/utilities';
import { sl_order, vm_cash_state } from '@src/api';

@UntilDestroy()
@Component({
  selector: 'hoho-group-page',
  templateUrl: './group-page.component.html',
  styleUrls: ['./group-page.component.scss'],
})
export class GroupPageComponent implements OnInit {
  @ViewChild('tableBuilder') tableBuilderComponent: ListBuilderComponent<sl_order>;
  @HostListener('window:beforeunload', ['$event'])
  id: string;
  isLoading: boolean = false;
  crumbs: ICrumb[] = [
    { label: $localize`Маркетинг`, link: AppRoutingNames.marketing.path },
    { label: $localize`Группы клиентов`, link: MarketingRoutingNames.group.path },
    { label: $localize`Страница группы клиентов` },
  ];
  group: IMarketingCustomerGroup;
  formGroup: UntypedFormGroup;
  tableBuilderProps: IListBuilderProps<vm_cash_state> = {
    entity: EEntityList.sl_user,
    views: [EListBuilderViews.table],
  };
  filterPayload: IServerRequestPayload;
  marketingRoutingNames = MarketingRoutingNames;
  getErrorMessage = getErrorMessage;
  constructor(
    private dataRestService: DataRestService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.params['id'];
    this.getEntity();

    this.formGroup = this.fb.group({
      actualAmountVm: this.fb.control(null),
      loadedForChange: this.fb.control(null),
      unloadedFromChange: this.fb.control(null),
      collectedAmount: this.fb.control(null),
    });
  }

  getEntity(): void {
    this.isLoading = true;
    if (this.id) {
      this.dataRestService
        .get<IMarketingCustomerGroup>(EEntityList.marketing_customers_group, this.id, ['id', 'name', 'filter'])
        .pipe(untilDestroyed(this))
        .subscribe((res: IMarketingCustomerGroup) => {
          this.group = res;
          this.filterPayload = { filter: res.filter };
          this.isLoading = false;
          this.cd.detectChanges();
        });
    }
  }
}
