import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToString',
})
export class SecondsToStringPipe implements PipeTransform {
  transform(value: number): string {
    if (!value) {
      return '0с';
    }

    const hourCount = Math.floor(value / 3600);
    const hoursSeconds = hourCount * 3600;
    const leftSecondWithoutHours = value - hoursSeconds;
    const minuteCount = Math.floor(leftSecondWithoutHours / 60);
    const minutesSeconds = minuteCount * 60;
    const leftSeconds = value - hoursSeconds - minutesSeconds;

    let result: string = '';
    if (hourCount > 0) result += hourCount.toString() + 'ч ';
    if (minuteCount > 0) result += minuteCount.toString() + 'м ';
    if (leftSeconds > 0) result += leftSeconds.toString() + 'с';

    return result;
  }
}
