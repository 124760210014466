<div matDialogTitle>
  <div class="flex content-between w-full">
    <div class="text-subtitle-3 flex-grow" i18n>Профиль</div>
    <hoho-icon
      class="ml-4 text-florida hover:text-margaret transition-colors delay-500 ease-in cursor-pointer"
      size="1rem"
      iconName="multiply"
      mat-dialog-close=""
    ></hoho-icon>
  </div>
</div>
<mat-dialog-content>
  <mat-tab-group [dynamicHeight]="true">
    <mat-tab label="Ваши данные" i18n-label>
      <ng-template matTabContent>
        <form (ngSubmit)="updateProfile()" [formGroup]="formGroup" #ngForm *ngIf="formGroup">
          <div class="grid grid-cols-2 gap-x-4 mt-4">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label i18n>Имя</mat-label>
              <input hohoNoSpecialCharactersMask matInput formControlName="firstName" />
              <mat-error *ngIf="formGroup.get('firstName').invalid">{{ getErrorMessage('firstName', formGroup) }}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" class="w-full">
              <mat-label i18n>Фамилия</mat-label>
              <input hohoNoSpecialCharactersMask matInput formControlName="lastName" />
              <mat-error *ngIf="formGroup.get('lastName').invalid">{{
                getErrorMessage('lastName', formGroup)
              }}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" class="w-full">
              <mat-label i18n>Отчество</mat-label>
              <input hohoNoSpecialCharactersMask matInput formControlName="middleName" />
              <mat-error *ngIf="formGroup.get('middleName').invalid">{{
                getErrorMessage('middleName', formGroup)
              }}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" class="w-full">
              <mat-label i18n>Эл. почта</mat-label>
              <input matInput formControlName="email" />
              <mat-error *ngIf="formGroup.get('email').invalid">{{ getErrorMessage('email', formGroup) }}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" class="w-full">
              <mat-label i18n>Укажите дату рождения</mat-label>
              <input
                [max]="maxDate"
                readonly
                matInput
                [matDatepicker]="$any(picker)"
                (click)="picker.open()"
                formControlName="birthday"
              />
              <mat-datepicker-toggle matSuffix [for]="picker">
                <hoho-icon iconName="calender" matDatepickerToggleIcon></hoho-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error *ngIf="formGroup.get('birthday').invalid">{{
                getErrorMessage('birthday', formGroup)
              }}</mat-error>
            </mat-form-field>

            <hoho-select
              [submitted]="ngForm.submitted"
              [items]="cities"
              appendTo="body"
              appearance="fill"
              [bindValue]="'name'"
              [clearable]="false"
              [control]="cityControl"
              [addTag]="true"
              placeholder="Выберите ваш город"
              i18n-placeholder
            >
              <mat-error *ngIf="cityControl.invalid">{{ getErrorMessage('city', formGroup) }}</mat-error>
            </hoho-select>
          </div>
          <div class="flex justify-end">
            <button class="ml-0" color="accent" mat-flat-button type="submit" i18n>Сохранить данные</button>
          </div>
        </form>
      </ng-template>
    </mat-tab>
    <mat-tab label="Смена пароля" i18n-label>
      <ng-template matTabContent>
        <form class="mt-4" (ngSubmit)="updatePassword()" [formGroup]="passwordForm" *ngIf="passwordForm">
          <mat-form-field appearance="fill" class="w-full">
            <mat-label i18n>Текущий пароль</mat-label>
            <input type="password" matInput formControlName="password" />
            <mat-error *ngIf="passwordForm.get('password').invalid">{{
              getErrorMessage('password', passwordForm)
            }}</mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" class="w-full mb-4">
            <mat-label i18n>Новый пароль</mat-label>
            <input type="password" matInput formControlName="newPassword" />
            <mat-error *ngIf="passwordForm.get('newPassword').invalid">{{
              getErrorMessage('newPassword', passwordForm)
            }}</mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" class="w-full">
            <mat-label i18n>Повторите новый пароль</mat-label>
            <input type="password" matInput formControlName="confirmPassword" />
            <mat-error *ngIf="passwordForm.get('confirmPassword').invalid">{{
              getErrorMessage('confirmPassword', passwordForm)
            }}</mat-error>
          </mat-form-field>
          <div class="flex justify-end">
            <button class="ml-0" color="accent" [disabled]="passwordForm.invalid" mat-flat-button type="submit" i18n>
              Сохранить пароль
            </button>
          </div>
        </form>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>
