import { Component, HostBinding, Input, OnInit } from '@angular/core';
import icMenu from '@iconify/icons-ic/twotone-menu';
import { StyleService } from '@shared/@vex/services/style.service';
import { LanguageService } from '@shared/services';
import { map } from 'rxjs/operators';
import { PopoverService } from '../../components/popover/popover.service';
import { ConfigService } from '../../services/config.service';
import { LayoutService } from '../../services/layout.service';

import { NavigationService } from '../../services/navigation.service';
import { EConfigModifications } from '@shared/@vex/interfaces/config.model';

@Component({
  selector: 'vex-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
  @Input() mobileQuery: boolean;
  @HostBinding('class.shadow-b')
  defaultLocale: string;

  isVerticalLayout$ = this.configService.config$.pipe(map((config) => config.layout === 'vertical'));

  icMenu = icMenu;

  @Input() collapsed: boolean;

  collapsedOpen$ = this.layoutService.sidenavCollapsedOpen$;
  constructor(
    private layoutService: LayoutService,
    private configService: ConfigService,
    private navigationService: NavigationService,
    private popoverService: PopoverService,
    private styleService: StyleService,
    private languageService: LanguageService,
  ) {
    this.defaultLocale = this.languageService.languageCode;
  }

  ngOnInit() {}

  openSidenav() {
    this.layoutService.openSidenav();
  }

  changeLanguage(lng: string): void {
    this.languageService.changeLanguage(lng);
  }

  toggleCollapse() {
    this.configService.modifyUserSetting(
      EConfigModifications.SIDE_NAV_COLLAPSED,
      this.collapsed ? 'expanded' : 'collapsed',
    );
    this.collapsed ? this.layoutService.expandSidenav() : this.layoutService.collapseSidenav();
  }
}
