<div class="mx-gutter pt-6">
  <vex-breadcrumbs class="block mb-4" [crumbs]="crumbs"></vex-breadcrumbs>
  <div class="w-full flex flex-col sm:flex-row justify-between">
    <div class="flex flex-grow text-left">
      <h2 class="mr-2">{{ name }}</h2>
      <div class="text-margaret cursor-pointer" [ngClass]="(!vmGroupAccess?.update && !vmGroupAccess?.delete) ? 'disabled' : ''" (click)="openEditGroupModal()" i18n>Редактировать</div>
    </div>
  </div>
</div>

<hoho-feature-tabs
  [module]="EModules.VendingMachineModule"
  [activeFeature]="activeFeature"
  [tabsList]="tabsContentList"
  (changeTab)="changeTab($event)"
>
</hoho-feature-tabs>

<ng-template #information>
  <div class="px-gutter">
    <hoho-statistic  [requestPayload]="requestPayload" class="mt-5 block"></hoho-statistic>
    <hoho-list-builder
      #informationBuilder
      class="mt-5 block"
      context="info"
      i18n-searchPlaceholder
      searchPlaceholder="Поиск по всем параметрам"
      [requestPayload]="requestPayload"
      [multiselect]="false"
      [props]="listBuilderProps"
      [gridCardTemp]="gridCardViewInfo"
    ></hoho-list-builder>
  </div>
</ng-template>
<ng-template #gridCardViewInfo let-entity="entity">
  <hoho-vending-machine-card [model]="entity" [cardType]="eVendingMachineContextsList.info"></hoho-vending-machine-card>
</ng-template>

<ng-template #state>
  <div class="px-gutter" [id]="EVendingMachineFeatures.State">
    <hoho-state-statistic class="mt-5 block"></hoho-state-statistic>
    <hoho-list-builder
      #stateBuilder
      class="mt-5 block"
      context="state"
      [multiselect]="false"
      [requestPayload]="requestPayload"
      i18n-searchPlaceholder
      searchPlaceholder="Поиск по всем параметрам"
      [props]="listBuilderProps"
      [gridCardTemp]="gridCardViewState"
    ></hoho-list-builder>
  </div>
</ng-template>
<ng-template #gridCardViewState let-entity="entity">
  <hoho-vending-machine-card
    [model]="entity"
    [cardType]="eVendingMachineContextsList.state"
  ></hoho-vending-machine-card>
</ng-template>

<ng-template #list>
  <div class="px-gutter" [id]="EVendingMachineFeatures.List">
    <hoho-list-builder
      #listBuilder
      class="mt-5 block"
      context="list"
      [multiselect]="false"
      [requestPayload]="requestPayload"
      [props]="listBuilderProps"
      [showExcel]="true"
      i18n-searchPlaceholder
      searchPlaceholder="Поиск по всем параметрам"
      excelFileNameBase="Список автоматов"
      i18n-excelFileNameBase
      [gridCardTemp]="gridCardViewList"
    ></hoho-list-builder>
  </div>
</ng-template>
<ng-template #gridCardViewList let-entity="entity">
  <hoho-vending-machine-card [model]="entity" [cardType]="eVendingMachineContextsList.list"></hoho-vending-machine-card>
</ng-template>
