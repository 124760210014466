import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRoutingNames } from '@app/app-routing.module';
import { IAccess } from '@app/core/modules/list-builder/models';
import { AddNoteComponent } from '@app/pages/feedback/components/add-note/add-note.component';
import { ICrumb } from '@app/shared/@vex/components/breadcrumbs/breadcrumbs.component';
import { EEntityList, EFeedbackFeatures, EModules, IGroupingRequestPayload, IPagingResponsePayload } from '@app/shared/models';
import { DialogService } from '@app/shared/modules/dialogs/services/dialog.service';
import { AlertService, DataRestService, PermissionsGuard, UserService } from '@app/shared/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as moment from 'moment';
import { ClientsRoutingNames } from '@app/pages/clients/clients.routing';
import { paths } from './page.config';
import { feedback, feedback_note, feedback_reason, vending_machine } from '@src/api';
import { toType } from '@app/shared/utilities';

@UntilDestroy()
@Component({
  selector: 'hoho-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageComponent implements OnInit {
  @HostListener('window:beforeunload', ['$event'])
  uID: string;
  isLoading: boolean = false;
  crumbs: ICrumb[] = [
    { label: $localize`Обратная связь`, link: AppRoutingNames.feedback.path },
    { label: $localize`Отзывы`, link: AppRoutingNames.feedback.path + '/reviews' },
    { label: $localize`Страница отзыва` },
  ];
  entity: feedback;
  title: string = $localize`Карточка отзыва`;
  access: IAccess;
  dateTime: string;
  notes: feedback_note[];
  userId: string;
  $vending_machine = toType<vending_machine>();
  $feedback_reason = toType<feedback_reason>();
  clientsRoutingNames = ClientsRoutingNames;
  EModules = EModules;
  EFeedbackFeatures = EFeedbackFeatures;
  constructor(
    private dataRestService: DataRestService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private dialog: MatDialog,
    private userService: UserService,
    private cd: ChangeDetectorRef,
    private permissionsGuard: PermissionsGuard,
    private alertService: AlertService,
  ) {}

  ngOnInit(): void {
    if (!this.permissionsGuard.haveAccess(EModules.FeedbackModule, EFeedbackFeatures.Page)) {
      this.alertService.showError($localize`Доступ запрещен.`);
      return;
    }
    this.access = this.userService.Access(EEntityList.users);
    this.uID = this.activatedRoute.snapshot.params['id'];
    this.getEntity();
    this.getFeedbackNotes();

    this.userService.user$.pipe(untilDestroyed(this)).subscribe((res) => {
      this.userId = res.id;
    });
  }

  getFeedbackNotes(): void {
    const payload: IGroupingRequestPayload = {
      paths: ['id', 'user.fio', 'text', 'dateCreated'],
      filter: {
        'feedback.id': this.uID,
      },
    };
    this.dataRestService
      .page<feedback_note>(EEntityList.feedback_note, null, 0, -1, payload)
      .pipe(untilDestroyed(this))
      .subscribe((res: IPagingResponsePayload<feedback_note>) => {
        this.notes = res.content;
        this.cd.detectChanges();
      });
  }

  changeTab(event: number): void {
    this.router.navigate(['.'], { relativeTo: this.activatedRoute, queryParams: { tabIndex: event } });
  }

  getEntity(): void {
    this.dataRestService
      .get<feedback>(EEntityList.feedback, this.uID, paths)
      .pipe(untilDestroyed(this))
      .subscribe((res: feedback) => {
        this.entity = res;
        this.dateTime = moment(res.datePurchase).format('DD.MM.YYYY, HH:mm');
        this.cd.detectChanges();
      });
  }

  openAddNoteModal(): void {
    const dialogRef = this.dialog.open(AddNoteComponent, {
      panelClass: ['w-80', 'overflow-visible'],
      data: {
        feedback: this.entity,
        userId: this.userId,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((res: string) => {
        if (res) {
          this.notes.unshift({
            id: null,
            text: res,
            entity: EEntityList.feedback_note,
            feedback: this.entity,
            users: {
              id: this.userId,
              entity: EEntityList.users,
            },
            dateCreated: moment().format(),
          });
          this.cd.detectChanges();
        }
      });
  }
}
