/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { service_route_performer } from '../models/service_route_performer';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ServiceRoutePerformerService {

    /**
     * Select service_route_performer by id
     * @param id Object ID
     * @returns service_route_performer Successful operation
     * @throws ApiError
     */
    public static selectServiceRoutePerformerById(
        id: string,
    ): CancelablePromise<service_route_performer> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/get/object/service_route_performer/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Save service_route_performer
     * @param requestBody Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static saveServiceRoutePerformer(
        requestBody?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/save/object/service_route_performer',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

    /**
     * Delete service_route_performer by id
     * @param id Object ID
     * @returns any Success
     * @throws ApiError
     */
    public static deleteServiceRoutePerformerById(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/delete/object/service_route_performer/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Access denided`,
                404: `Not found`,
            },
        });
    }

}
